import React from "react";

export const GreenLeave = () => {
  return (
    <svg
      width="77"
      height="111"
      viewBox="0 0 77 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "50px", height: "50px" }}
    >
      <path
        d="M3.85337 110.65C-1.95929 88.7709 -2.22691 61.8132 12.8168 40.3167C28.8309 17.4337 47.3713 7.62771 75.8878 0.615988C77.794 34.4734 75.2372 44.9415 60.2967 70.129C43.7971 93.7057 24.0859 103.046 3.85337 110.65Z"
        fill="#10c695"
      />
    </svg>
  );
};
