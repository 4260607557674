import React from "react";
import dronestyle from "./dronestyle.scss";

export const Drone = () => {
  return (
    <svg
      width="202"
      height="138"
      viewBox="0 0 202 138"
      fill="none"
      id="test"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "100", width: "100" }}
    >
      <g opacity="0.5">
        <path
          d="M58.2084 96.0617C78.3191 96.0617 94.6221 91.2903 94.6221 85.4044C94.6221 79.5186 78.3191 74.7471 58.2084 74.7471C38.0976 74.7471 21.7947 79.5186 21.7947 85.4044C21.7947 91.2903 38.0976 96.0617 58.2084 96.0617Z"
          fill="#0CB08A"
          fill-opacity="0.28"
        />
        <path
          d="M164.636 78.5114C184.746 78.5114 201.049 73.74 201.049 67.8541C201.049 61.9683 184.746 57.1968 164.636 57.1968C144.525 57.1968 128.222 61.9683 128.222 67.8541C128.222 73.74 144.525 78.5114 164.636 78.5114Z"
          fill="#0CB08A"
          fill-opacity="0.28"
        />
        <path
          d="M147.883 21.5084C167.994 21.5084 184.297 16.7369 184.297 10.8511C184.297 4.96522 167.994 0.193787 147.883 0.193787C127.772 0.193787 111.469 4.96522 111.469 10.8511C111.469 16.7369 127.772 21.5084 147.883 21.5084Z"
          fill="#0CB08A"
          fill-opacity="0.28"
        />
        <path
          d="M36.7667 39.694C56.8774 39.694 73.1804 34.9226 73.1804 29.0367C73.1804 23.1508 56.8774 18.3794 36.7667 18.3794C16.656 18.3794 0.353027 23.1508 0.353027 29.0367C0.353027 34.9226 16.656 39.694 36.7667 39.694Z"
          fill="#0CB08A"
          fill-opacity="0.28"
        />
        <path
          d="M147.565 17.8873C148.217 17.2202 149.091 16.8073 149.997 16.5372C151.714 15.9972 153.669 15.9178 155.274 16.712C156.879 17.5061 158.008 19.3326 157.579 21.0321C154.464 21.5085 151.348 21.9691 148.233 22.4456C145.626 22.8268 146.421 19.0626 147.565 17.8873Z"
          fill="#DD4A72"
        />
        <path
          d="M171.168 73.8261C172.297 75.0809 172.519 76.9074 172.058 78.5433L171.963 78.5116C171.454 76.5103 169.452 75.2079 167.449 74.7632C165.43 74.3185 163.348 74.5409 161.282 74.525C155.337 74.4932 149.425 72.4602 144.72 68.839L143.957 68.8707C143.909 67.5684 143.782 66.266 143.56 64.9795L143.878 64.9318C147.867 68.2831 152.747 70.5543 157.865 71.4914C159.677 71.8408 161.536 71.9996 163.38 71.9996C164.763 71.9996 166.146 71.9043 167.512 72.0949C167.56 72.0949 167.608 72.1108 167.655 72.1267C168.959 72.3173 170.278 72.8255 171.168 73.8261Z"
          fill="#03684D"
        />
        <path
          d="M159.677 67.7907C160.138 67.6795 160.614 67.616 161.091 67.5842C162.665 67.473 164.254 67.5207 165.828 67.743C166.225 67.7907 166.607 67.8542 167.004 67.9336C167.226 69.3313 167.449 70.7131 167.671 72.0949V72.1266C167.624 72.1108 167.576 72.1108 167.528 72.0949C166.162 71.9043 164.779 71.9996 163.396 71.9996C161.552 71.9996 159.693 71.8408 157.881 71.4913L157.944 71.1737C157.213 70.459 157.499 69.1566 158.262 68.4895C158.644 68.1242 159.136 67.9177 159.677 67.7907Z"
          fill="#0CB08A"
        />
        <path
          d="M165.812 67.727C164.238 67.5046 162.649 67.457 161.075 67.5682C160.599 67.5999 160.122 67.6635 159.661 67.7746C159.232 66.5517 159.597 65.0746 160.583 64.2169C161.632 63.2957 163.316 63.1845 164.493 63.9469C165.669 64.7093 166.241 66.2976 165.812 67.6317V67.727Z"
          fill="#0CB08A"
        />
        <path
          d="M155.862 16.807C157.356 18.6494 157.865 21.3018 156.847 23.4142C156.053 25.066 154.463 26.2254 152.826 27.0513C151.189 27.8772 149.425 28.449 147.82 29.3543C145.276 30.7838 143.258 32.9756 141.271 35.1198C136.853 39.9322 132.418 44.7447 127.999 49.5571L127.411 49.6048C127.221 48.9377 126.919 48.3183 126.442 47.81C124.805 46.0471 121.769 46.4441 119.941 47.9848C118.113 49.5254 116.174 51.9713 115.602 54.2902C115.014 56.6567 115.618 59.2615 117.128 61.1674L116.46 61.6756C105.35 63.8833 92.8891 66.5199 81.779 68.7276C83.4638 66.1546 82.7645 62.5015 80.73 60.1986C78.6956 57.8956 75.6915 55.9102 72.6081 56.0691C71.0186 56.1485 69.7312 57.5144 69.2385 59.0391C69.0954 59.4838 69.0001 59.9444 68.9524 60.405L68.6981 60.3574C65.853 57.8003 62.2291 56.3232 58.6847 54.8937C54.282 53.1307 49.8793 51.3519 45.4766 49.5889C43.6011 48.8424 41.7097 48.08 39.707 47.8895C37.8474 47.6989 35.9878 48.0006 34.1122 48.1118C32.2526 48.2071 30.2817 48.0642 28.6923 47.0794C26.7055 45.8565 25.6724 43.4105 25.879 41.0917L26.038 41.1075C26.483 38.4551 29.344 36.8986 32.0142 36.5174C36.7348 35.8504 41.3282 37.5975 45.7945 39.424C46.6528 39.7734 47.5111 40.1228 48.3694 40.4722C63.6755 46.603 80.6029 48.6677 96.9421 46.3965C114.315 43.9664 130.415 33.8968 142.336 21.0159C145.737 17.347 150.998 15.6793 155.862 16.8388V16.807Z"
          fill="#CFCFCF"
        />
        <path
          d="M143.608 74.7472C143.91 72.8095 144.021 70.84 143.958 68.8706L144.721 68.8388C149.425 72.4759 155.322 74.5089 161.282 74.5248C163.349 74.5248 165.431 74.3025 167.449 74.7631C169.452 75.2078 171.439 76.526 171.963 78.5114L172.059 78.5431C171.9 79.1626 171.63 79.7502 171.28 80.2744C169.992 82.1962 167.831 83.3715 165.637 84.1021C163.492 84.8009 161.123 85.1504 158.994 84.4197C157.388 83.8639 156.053 82.7362 154.655 81.7514C151.301 79.369 147.439 77.7172 143.386 76.9231L143.227 76.8278C143.56 76.1925 143.608 75.4619 143.608 74.7472Z"
          fill="#CFCFCF"
        />
        <path
          d="M143.719 11.2641C143.29 9.81872 142.892 8.26222 143.401 6.84866C143.846 5.62569 145.006 4.68861 146.294 4.49802C147.597 4.30742 148.964 4.86332 149.759 5.89569C150.537 6.92807 150.712 8.94518 150.172 10.1205V10.1523C148.042 9.97755 145.896 10.6605 143.894 11.4705C143.862 11.4705 143.846 11.4864 143.814 11.5023L143.719 11.2641Z"
          fill="#0CB08A"
        />
        <path
          d="M143.56 74.2231C143.147 69.2359 140.921 64.4075 137.377 60.8657L137.218 60.818C137.647 60.421 137.679 59.7539 137.663 59.1821C137.647 57.1809 137.631 55.1956 137.615 53.1943C137.615 52.5273 137.568 51.7808 137.17 51.2884L137.584 50.9708C140.699 55.0526 142.733 59.9127 143.56 64.9634C143.767 66.2499 143.894 67.5523 143.957 68.8547C144.021 70.8242 143.91 72.7936 143.608 74.7313H143.592C143.576 74.5725 143.56 74.3978 143.56 74.2231Z"
          fill="#0CB08A"
        />
        <path
          d="M143.592 74.7472C143.624 75.4619 143.56 76.1925 143.21 76.8278C142.559 78.019 141.112 78.4955 139.809 78.8608C138.251 79.2896 136.694 79.7344 135.136 80.1632C133.451 80.6397 131.767 81.1003 130.098 81.5768C129.573 74.8425 129.065 68.1241 128.54 61.3898C130.956 61.3263 133.356 61.2787 135.772 61.2151C136.296 61.1992 136.869 61.1675 137.234 60.8181L137.393 60.8657C140.922 64.4234 143.147 69.2359 143.576 74.2231C143.56 74.3978 143.576 74.5725 143.592 74.7472Z"
          fill="#03684D"
        />
        <path
          d="M142.908 15.6954C142.399 16.013 141.954 16.3942 141.525 16.823L141.446 16.7436C140.588 15.5842 140.683 13.8212 141.684 12.7729C142.256 12.1694 143.035 11.8041 143.814 11.5023C143.846 11.5023 143.862 11.4864 143.894 11.4706C145.896 10.6764 148.042 9.97758 150.172 10.1523C150.378 10.1682 150.585 10.1999 150.792 10.2317C151.586 10.3746 152.397 10.6764 152.858 11.3117C153.43 12.1376 153.271 13.71 153.08 14.6947C151.618 14.0277 149.933 13.7577 148.312 13.8847C146.405 14.0277 144.529 14.6471 142.908 15.6954Z"
          fill="#0CB08A"
        />
        <path
          d="M140.461 122.3C140.652 123.904 140.842 125.667 139.952 127.033C138.935 128.637 136.837 129.13 134.962 129.495C121.229 132.084 107.512 134.657 93.7796 137.246C91.0776 137.754 87.9464 138.151 85.8643 136.356C84.0205 134.752 83.7344 132.052 83.5914 129.606C83.0987 121.458 82.59 113.295 82.0973 105.147L82.002 104.924C83.1304 104.448 84.2748 104.019 85.4351 103.67L85.5146 103.908C86.4047 112.31 87.2788 120.712 88.153 129.114C88.2802 130.4 88.4709 131.814 89.4087 132.703C90.6961 133.942 92.7465 133.688 94.4948 133.37C106.543 131.083 118.606 128.796 130.654 126.509C132.418 126.175 134.342 125.747 135.47 124.333C136.615 122.888 136.551 120.839 136.392 118.996C135.565 109.308 133.817 99.6989 131.21 90.3281L130.781 90.3599C130.559 87.4216 130.336 84.4992 130.114 81.5768C131.799 81.1003 133.483 80.6397 135.152 80.1632L135.168 80.2267C136.917 94.2512 138.681 108.276 140.461 122.3Z"
          fill="#0CB08A"
        />
        <path
          d="M137.679 59.1822C137.679 59.7699 137.663 60.4211 137.234 60.8181C136.853 61.1676 136.296 61.1993 135.772 61.2152C133.356 61.2787 130.956 61.3264 128.54 61.3899C128.302 58.3404 128.063 55.3068 127.841 52.2732C127.793 51.6856 127.745 51.0662 127.634 50.4785L128.015 50.3514C130.495 50.4626 132.974 50.5897 135.454 50.7009C135.994 50.7326 136.582 50.7644 136.996 51.0979C137.075 51.1615 137.139 51.225 137.186 51.2885C137.584 51.7809 137.632 52.5274 137.632 53.1944C137.647 55.1798 137.663 57.181 137.679 59.1822Z"
          fill="#0CB08A"
          fill-opacity="0.28"
        />
        <path
          d="M128.524 61.3898C129.049 68.1241 129.557 74.8425 130.082 81.5768C130.304 84.4992 130.527 87.4375 130.749 90.3599C130.781 90.7093 130.797 91.0429 130.829 91.3764C130.972 93.1394 131.067 95.0294 130.129 96.5065C128.842 98.5077 126.204 99.0001 123.851 99.3178C115.84 100.398 107.401 100.302 99.231 101.065L99.0562 99.2066C106.479 98.0313 113.87 96.7289 121.229 95.2677C123.088 94.9024 125.139 94.41 126.204 92.8217C127.157 91.3923 126.998 89.5181 126.792 87.8028C126.537 85.7063 126.283 83.6098 126.045 81.5132C125.743 79.0197 125.441 76.5261 125.139 74.0484C124.757 70.9512 124.296 67.6476 122.166 65.3764C120.831 63.9628 119.003 63.1211 117.637 61.7393C117.446 61.5487 117.287 61.3581 117.128 61.1516C115.602 59.2616 115.014 56.6409 115.602 54.2744C116.19 51.9555 118.113 49.5096 119.941 47.9689C121.769 46.4283 124.821 46.0313 126.442 47.7942C126.919 48.3025 127.221 48.9219 127.411 49.589C127.491 49.8749 127.57 50.1608 127.618 50.4625C127.729 51.0502 127.777 51.6696 127.825 52.2573C128.063 55.3226 128.302 58.3562 128.524 61.3898Z"
          fill="#0CB08A"
        />
        <path
          d="M116.461 61.6758L117.128 61.1675C117.287 61.374 117.462 61.5646 117.637 61.7552C119.004 63.137 120.832 63.9788 122.167 65.3923C124.296 67.6636 124.757 70.9672 125.139 74.0643C125.441 76.5579 125.727 79.0515 126.045 81.5292L125.266 81.6245C113.409 84.1181 101.536 86.5958 89.6788 89.0894C89.1384 89.2005 88.5821 89.3276 88.0258 89.4388C86.7225 89.7088 85.292 89.9629 84.1159 89.3435C82.5741 88.5176 82.1132 86.564 81.8112 84.8487L81.8589 84.8328C80.4443 79.6709 80.3807 74.1437 81.6364 68.9342C81.684 68.8707 81.7476 68.7912 81.7953 68.7277C92.8895 66.52 105.335 63.8835 116.461 61.6758Z"
          fill="#03684D"
        />
        <path
          d="M99.0562 99.2225L99.231 101.081C97.6416 101.224 96.068 101.398 94.5104 101.621L94.2561 99.9531C95.8614 99.7149 97.4508 99.4767 99.0562 99.2225Z"
          fill="#0CB08A"
        />
        <path
          d="M89.7582 89.55C90.3781 91.313 90.1715 93.3142 89.2178 94.9184C88.6774 95.8237 87.9304 96.5702 87.39 97.4596C86.8496 98.349 86.5158 99.4767 86.9132 100.446L86.9926 100.986C85.1648 101.24 83.337 101.478 81.5091 101.7L81.4773 101.51C81.0959 98.9367 79.9356 96.5543 79.1409 94.0766C78.3462 91.6148 77.9329 88.8194 79.0614 86.4688L78.8548 86.1511C79.7608 85.5476 80.7621 85.087 81.8111 84.817C82.1131 86.5323 82.574 88.4859 84.1158 89.3118C85.292 89.9312 86.7224 89.6771 88.0258 89.4071C88.5662 89.2959 89.1225 89.1688 89.6788 89.0576L89.7582 89.55Z"
          fill="#0CB08A"
        />
        <path
          d="M81.8428 84.8327L81.7951 84.8486C80.7461 85.1186 79.7288 85.5792 78.8388 86.1827C77.5672 87.0245 76.4864 88.1839 75.7235 89.5022C75.5487 87.4374 75.3897 85.3568 75.2149 83.2921C74.9447 79.8614 74.7698 76.0495 76.9156 73.3654C78.2666 71.6818 80.3646 70.6812 81.6203 68.9341C80.3646 74.1436 80.4441 79.6708 81.8428 84.8327Z"
          fill="#CFCFCF"
        />
        <path
          d="M81.7794 68.7276C81.7317 68.807 81.684 68.8705 81.6204 68.9341C80.3648 70.6812 78.2667 71.6818 76.9157 73.3653C74.77 76.0654 74.9448 79.8614 75.215 83.292C75.374 85.3568 75.5488 87.4374 75.7237 89.5022C76.0733 93.7587 76.4071 98.0312 76.7568 102.304C78.3303 102.113 79.9197 101.922 81.4933 101.716C83.3211 101.494 85.1489 101.239 86.9768 101.001C89.4086 100.668 91.8404 100.318 94.2563 99.953L94.5106 101.621C91.4113 102.065 88.3596 102.701 85.4191 103.654C84.2589 104.019 83.1145 104.432 81.986 104.908C81.3343 105.178 80.6986 105.448 80.0628 105.766C77.5674 106.973 74.1978 105.814 72.974 103.32C72.4018 102.161 72.2905 100.827 72.1633 99.54C71.9408 96.9829 71.7183 94.4099 71.4799 91.8528C71.162 88.1839 70.8282 84.4991 70.5103 80.8302C70.3991 79.4961 70.2719 78.146 70.1607 76.796C70.1607 76.7166 70.1448 76.6372 70.1448 76.5578C69.7633 72.2853 69.3978 68.0129 69.0163 63.7404C68.9209 62.6286 68.8256 61.4851 68.9368 60.3733C68.9845 59.9127 69.0799 59.4521 69.2229 59.0074C69.7156 57.4826 71.0031 56.1167 72.5925 56.0373C75.676 55.8785 78.68 57.8638 80.7144 60.1668C82.7489 62.5016 83.4641 66.1546 81.7794 68.7276Z"
          fill="#0CB08A"
        />
        <path
          d="M70.5261 80.8305C70.844 84.4994 71.1778 88.1842 71.4957 91.8531L71.3209 91.869C69.3659 92.9649 67.7447 94.6802 66.791 96.7132C66.2188 97.9521 65.8532 99.3021 64.9949 100.366C63.8188 101.812 61.8956 102.447 60.0677 102.733C57.3975 103.13 54.5048 102.892 52.2955 101.367C50.8014 100.334 49.7206 98.635 49.4822 96.872C49.959 95.7761 50.9603 94.8708 52.0729 94.3308C53.5352 93.6161 55.2041 93.3937 56.8094 93.092C58.4147 92.8061 60.0836 92.3931 61.3711 91.3925C62.0227 90.8843 62.5472 90.249 63.0876 89.6136C65.5195 86.6912 67.9354 83.7688 70.3513 80.8464L70.5261 80.8305Z"
          fill="#CFCFCF"
        />
        <path
          d="M61.8953 86.6593C62.5628 85.9604 63.1668 85.1822 63.7708 84.4198C65.7894 81.815 67.8079 79.2103 69.8265 76.5896L70.1444 76.5579C70.1444 76.6373 70.1603 76.7167 70.1603 76.7961C70.2715 78.1302 70.3987 79.4803 70.51 80.8303L70.3192 80.8462C67.9033 83.7686 65.4715 86.691 63.0556 89.6134C62.531 90.2488 62.0065 90.8841 61.339 91.3923C60.0515 92.3929 58.3985 92.8059 56.7773 93.0918C55.172 93.3935 53.5031 93.6 52.0409 94.3306C50.9442 94.8706 49.9428 95.7759 49.4501 96.8718C49.3388 96.0142 49.4342 95.1406 49.768 94.3306C50.2448 93.1871 51.1349 92.2976 52.1998 91.5988C52.9786 91.0905 53.821 90.6935 54.6634 90.3599C56.682 89.5817 58.8436 89.0576 60.592 87.7869C61.0688 87.4375 61.4979 87.0722 61.8953 86.6593Z"
          fill="#03684D"
        />
        <path
          d="M61.0213 84.261C61.4505 84.4516 61.7366 84.8804 61.8478 85.341C61.9591 85.7699 61.9273 86.2305 61.8955 86.6752C61.4982 87.0722 61.069 87.4534 60.624 87.787C58.8756 89.0576 56.714 89.5817 54.6954 90.36C53.853 90.6935 52.9947 91.0906 52.2318 91.5988C51.8345 90.5029 51.2146 88.454 51.8345 87.4852C52.2159 86.8975 52.8994 86.564 53.551 86.2781C53.9325 86.1193 54.314 85.9604 54.6954 85.8016C56.3007 85.1822 57.9379 84.6739 59.6226 84.2769C59.8452 84.2292 60.0677 84.1657 60.2902 84.1657C60.5445 84.1339 60.7829 84.1498 61.0213 84.261Z"
          fill="#0CB08A"
        />
        <path
          d="M60.2902 84.1496C60.0677 84.1655 59.8293 84.2132 59.6227 84.2608C57.9379 84.6579 56.3008 85.1661 54.6955 85.7856C54.5047 84.6102 54.3458 83.3873 54.6796 82.2437C55.0451 81.0207 56.1577 79.909 57.4293 80.0042C58.367 80.0678 59.1935 80.7666 59.6227 81.6084C60.0359 82.3867 60.1949 83.2761 60.2902 84.1496Z"
          fill="#0CB08A"
        />
        <path
          d="M155.831 16.8389C150.967 15.6636 145.706 17.3472 142.305 21.0161C130.384 33.8811 114.299 43.9666 96.9106 46.3967C80.5713 48.6679 63.6598 46.6031 48.3378 40.4724C47.4795 40.123 46.6371 39.7736 45.7629 39.4241L46.351 37.9788C46.51 38.0265 46.6689 38.0741 46.8437 38.1059C55.8876 40.123 64.9791 42.156 74.2295 42.7754C95.8775 44.2366 117.986 37.6453 135.327 24.5897C137.902 22.652 139.237 19.1419 141.51 16.8389C141.939 16.4101 142.384 16.0289 142.893 15.7113C144.498 14.663 146.373 14.0436 148.297 13.9165C149.918 13.8053 151.587 14.0595 153.065 14.7265C153.923 15.1236 154.734 15.6477 155.401 16.3307C155.56 16.4895 155.703 16.6483 155.846 16.8389H155.831Z"
          fill="#03684D"
        />
        <path
          d="M39.4687 34.278C36.8779 33.468 34.017 33.3409 31.4739 34.278C30.5997 34.5957 29.7573 35.0563 29.0262 35.6439L28.899 35.4692C28.597 34.0715 29.3759 32.6103 30.4726 31.6891C30.8699 31.3556 31.2991 31.0856 31.76 30.8473C32.5706 30.4344 33.4448 30.1326 34.319 29.8309C35.0978 29.5608 35.8766 29.3067 36.7031 29.2273C37.2117 29.1797 37.7521 29.2114 38.2448 29.3861C38.5309 29.4656 38.8011 29.6085 39.0396 29.7832C39.7071 30.2597 40.1204 31.0379 40.2952 31.848C40.4859 32.658 40.47 33.4839 40.4541 34.3098L39.9455 34.4527C39.7866 34.3892 39.6276 34.3415 39.4687 34.278Z"
          fill="#0CB08A"
        />
        <path
          d="M38.2449 29.3863C37.7521 29.2275 37.2117 29.1798 36.7031 29.2275C35.8766 29.3069 35.0978 29.561 34.319 29.831C33.4448 30.1328 32.5706 30.4345 31.76 30.8475L31.6329 30.6093C31.5852 29.4022 31.5216 28.1474 31.8872 26.988C32.2527 25.8286 33.1428 24.7644 34.319 24.5103C35.4475 24.2721 36.6713 24.8438 37.3707 25.765C38.07 26.6862 38.3561 27.8774 38.3561 29.0369L38.2449 29.3863Z"
          fill="#0CB08A"
        />
        <path
          d="M32.0143 36.5016C29.36 36.8827 26.499 38.4392 26.0381 41.0917L25.8792 41.0758C25.895 40.6946 25.9586 40.3134 26.054 39.9481C26.499 38.2328 27.5957 36.7239 29.0103 35.6439C29.7573 35.0562 30.5838 34.6115 31.458 34.278C34.0011 33.3409 36.8621 33.468 39.4528 34.278C39.6118 34.3256 39.7707 34.3892 39.9297 34.4527C42.1548 35.3104 44.0939 37.248 46.3668 37.9469L45.7787 39.3922C41.3283 37.5657 36.719 35.8186 32.0143 36.5016Z"
          fill="#03684D"
        />
      </g>
    </svg>
  );
};
