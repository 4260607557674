import React from "react";

export const VR = () => {
  return (
    <svg
      width="217"
      height="226"
      viewBox="0 0 217 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "100", width: "100" }}
    >
      <g opacity="0.5">
        <path
          d="M43.5194 21.3556C43.4442 21.338 39.3203 40.7409 39.0042 42.5093C37.6948 49.7638 36.8244 57.1486 37.2257 64.531C37.883 76.559 41.5955 89.7434 53.8517 94.6324C54.7348 95.0151 55.6683 95.2685 56.6236 95.3849C57.9832 95.5079 59.3377 95.1893 60.6597 94.8557C68.6867 92.8263 76.187 89.2217 84.1689 87.2124C89.2711 85.9256 94.3231 84.2876 99.4704 83.1989C104.673 82.1027 110.161 81.4856 114.757 78.5909C117.684 76.7446 120.012 74.1083 122.616 71.8181C129.221 66.0311 146.095 56.8978 152.825 66.6481C154.35 68.8556 155.083 71.4719 155.381 74.1108C155.632 76.4386 154.594 79.3183 157.004 80.7682C158.153 81.4605 159.538 81.6161 160.865 81.8218C168.743 83.0473 176.116 86.4742 182.131 91.7076C185.693 94.818 188.327 96.1299 191.796 92.3823C194.689 89.2568 196.339 85.1931 198.434 81.5433C201.472 76.2505 204.421 70.9083 207.281 65.5168C207.843 64.4532 208.422 63.2843 208.199 62.1003C207.976 60.9163 206.945 59.9807 205.801 59.5442C202.63 58.3301 198.582 57.9865 195.238 57.1963L170.906 51.4519L106.033 36.1203L53.0365 23.6031C49.8633 22.8531 46.6951 22.0905 43.5194 21.3556Z"
          fill="#51DF9B"
          stroke="#51DF9B"
          stroke-miterlimit="10"
        />
        <path
          d="M60.203 58.5935C69.1933 63.1087 79.4729 61.7391 88.9097 59.6847C99.4051 57.402 110.344 56.2782 121.091 55.4203C134.135 54.3818 147.289 54.1811 160.323 55.4956C173.357 56.81 186.004 61.847 199.101 61.8244C221.865 61.7893 214.944 40.0661 206.734 27.2078C204.085 23.0588 201.552 18.7869 198.19 15.1898C191.523 8.05074 184.494 7.94539 175.444 7.94539H158.868C129.863 7.94539 100.421 4.21031 71.486 2.25622C65.8947 1.87745 59.9948 1.55888 54.993 4.08488C43.0603 10.1052 40.9256 27.0648 44.7083 38.2174C46.2585 42.7752 47.9894 47.3832 51.0346 51.1108C53.8366 54.5223 56.9069 56.9429 60.203 58.5935Z"
          fill="#0B9872"
          stroke="#0B9872"
          stroke-width="3"
          stroke-miterlimit="10"
        />
        <path
          d="M127.928 86.821C127.928 86.821 127.928 86.8035 127.928 86.7909C127.873 85.9832 125.086 86.0384 122.397 86.2742C121.952 86.3112 121.506 86.2211 121.11 86.0145C120.714 85.8078 120.385 85.493 120.162 85.1068C119.938 84.7205 119.828 84.2786 119.845 83.8325C119.863 83.3864 120.006 82.9544 120.26 82.5868C122.284 79.6841 124.461 76.8908 126.782 74.2186C128.891 71.7829 136.958 62.9306 140.771 64.7492C143.147 65.888 143.724 72.3197 144.306 74.6024C145.585 79.6343 145.043 85.8101 149.967 88.3286C155.423 91.1201 160.709 94.2292 165.801 97.64C174.751 103.635 179.271 114.279 178.729 125.007C178.44 130.535 176.935 135.931 174.322 140.81C171.312 146.404 165.74 149.59 162.412 154.986C158.519 161.307 156.622 168.684 155.428 175.959C153.474 187.749 151.623 199.162 148.633 210.748C148.078 212.893 147.491 215.066 146.36 216.969C143.332 222.079 137.653 223.148 132.19 223.178C132.023 223.179 131.86 223.129 131.723 223.035L101.371 202.079L101.283 202.027C92.12 197.281 82.8939 192.64 73.8133 187.749C73.682 187.677 73.5732 187.57 73.4991 187.44C73.4251 187.31 73.3886 187.161 73.3938 187.012C73.399 186.862 73.4457 186.717 73.5286 186.592C73.6115 186.468 73.7275 186.369 73.8635 186.306C77.7642 184.478 81.4883 182.295 84.9884 179.784C89.6491 176.46 93.9712 172.447 96.8108 167.475C100.38 161.227 101.399 153.882 101.391 146.655C101.39 146.502 101.433 146.352 101.514 146.222C101.596 146.093 101.713 145.989 101.852 145.924C101.99 145.859 102.145 145.836 102.297 145.856C102.448 145.876 102.591 145.939 102.708 146.038C105.197 148.027 110.374 149.372 112.213 149.979C117.79 151.786 123.733 152.156 129.491 151.052C134.006 150.22 138.654 148.002 142.748 146.036C145.846 144.548 157.252 138.008 147.785 136.205C138.318 134.401 128.385 133.21 118.722 133.556C109.496 133.89 99.6428 135.4 90.9736 131.311C85.3822 128.672 81.0552 123.668 78.8929 117.908C76.0784 110.408 78.1654 105.313 85.1013 101.433C92.5163 97.2985 100.637 94.5823 109.047 93.4233C113.304 92.8263 128.237 93.9325 127.928 86.821Z"
          fill="#0B9872"
        />
        <path
          d="M7.9895 78.2574L11.4035 68.5722L9.69776 66.0362L19.0969 54.3318H24.031L37.1251 44.599L38.723 44.0898L37.223 64.5311L28.2628 72.6635L14.8652 84.3679H13.5959L12.1811 86.766"
          fill="white"
        />
        <path
          d="M101.319 226C101.319 205.574 96.9967 185.423 89.07 166.62C75.8906 135.342 55.5396 107.937 46.2659 74.8684C44.3143 67.905 42.3753 56.9104 44.3394 48.1334C47.6581 33.3009 61.6677 34.6504 73.6255 34.9866"
          stroke="white"
          stroke-width="5"
          stroke-miterlimit="10"
        />
        <path
          d="M89.253 47.8323C105.821 49.0313 122.4 48.4193 139.001 48.635C150.539 48.783 162.078 49.0564 173.617 49.2295C179.357 49.3148 185.191 49.0389 190.926 49.39C196.472 49.7312 199.923 49.0288 200.861 42.9333C196.281 32.6888 191.61 22.4769 187.143 12.1797C187.06 11.8304 186.852 11.5238 186.558 11.3183C186.263 11.1129 185.904 11.023 185.547 11.066C164.359 9.56088 143.164 8.1503 121.961 6.8342C111.258 6.1519 100.55 5.47629 89.8375 4.80737C84.8206 4.49382 79.8037 4.02724 74.7868 3.88426C72.2583 3.81152 68.4931 3.59077 66.8626 6.00892C65.937 7.38104 66.1452 9.16455 66.5741 10.6721C67.5023 13.9206 68.458 17.159 69.4012 20.4024L73.6254 34.984C74.7542 38.8746 75.8654 43.5127 79.4726 45.9284C81.1959 47.0798 83.3532 47.3256 85.4201 47.5137C86.6961 47.6308 87.9737 47.737 89.253 47.8323Z"
          fill="#111827"
          stroke="#111827"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M116.384 111.208C116.359 111.224 116.341 111.248 116.332 111.276C116.323 111.305 116.324 111.335 116.336 111.363C116.347 111.39 116.368 111.412 116.394 111.426C116.421 111.439 116.451 111.443 116.48 111.436C122.294 110.14 128.132 108.501 134.084 108.032C141.201 107.473 148.249 108.737 155.067 110.649C155.107 110.66 155.15 110.659 155.189 110.644C155.228 110.629 155.262 110.602 155.284 110.566C155.306 110.531 155.317 110.489 155.313 110.448C155.31 110.406 155.293 110.366 155.265 110.335C154.746 109.748 154.061 108.853 153.861 108.619C152.03 106.475 150.246 104.325 147.539 103.274C145.051 102.308 142.442 102.534 139.841 102.461C137.082 102.383 134.302 102.173 131.596 102.875C130.724 103.123 129.877 103.447 129.062 103.843C124.575 105.895 120.491 108.512 116.384 111.208Z"
          fill="#51DF9B"
          stroke="#51DF9B"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M113.096 112.189C113.098 112.206 113.106 112.222 113.119 112.234C113.204 112.332 113.495 112.234 113.593 112.222C113.978 112.093 114.339 111.9 114.661 111.652C114.832 111.539 114.995 111.419 115.163 111.309C116.192 110.659 117.125 109.866 118.121 109.171C119.116 108.477 120.21 107.764 121.279 107.097C123.418 105.76 125.603 104.496 127.748 103.169C128.114 102.943 128.475 102.715 128.839 102.486C132.246 100.322 136.65 101.232 140.496 100.981C143.506 100.768 146.744 99.978 149.168 102.278C149.92 103.001 150.567 103.843 151.322 104.573C152.133 105.356 153.078 106.006 153.831 106.849C154.142 107.195 154.44 107.659 154.724 108.015C155.499 106.475 154.782 103.267 153.971 101.797C153.219 100.47 152.133 99.381 151.162 98.212C150.279 97.1484 149.471 95.987 148.337 95.2019C146.594 93.9903 143.822 93.446 141.72 93.5488C140.606 93.6015 139.578 94.1132 138.459 94.0881C136.397 94.0405 134.634 93.2378 132.514 93.7495C130.758 94.1759 129.223 95.2546 127.901 96.4837C125.714 98.518 123.609 100.64 121.565 102.818C119.761 104.742 117.99 106.696 116.227 108.657C115.344 109.635 114.462 110.616 113.583 111.6C113.452 111.702 113.066 112.019 113.096 112.189Z"
          fill="#111827"
          stroke="#111827"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M13.7839 89.8638C18.0057 87.5886 20.1303 81.3626 25.4056 81.2297C33.3825 81.0265 40.0625 94.4191 43.1855 100.354C50.1163 113.523 59.1793 132.339 53.5228 147.345C50.3722 155.708 38.7229 170.734 28.7794 163.331C22.3478 158.543 20.6195 151.155 19.7816 143.645L13.7839 89.8638Z"
          fill="#51DF9B"
        />
        <path
          d="M15.3871 94.093L15.4924 94.0805C16.9247 93.9701 20.276 99.0723 21.0662 100.138C24.578 104.904 27.1492 110.338 28.9502 115.964C31.6343 124.345 32.6979 133.15 33.4655 141.917C33.8492 146.372 34.8451 155.979 29.6802 158.4C27.7788 159.29 25.5563 158.206 23.8757 156.95C13.2173 148.97 13.0442 136.481 12.4898 124.365C12.249 119.037 12.0065 113.709 11.7624 108.381C11.6871 106.731 10.6336 102.301 11.4162 100.781C12.1512 99.3307 13.586 94.4116 15.3871 94.093Z"
          fill="#0B9872"
          stroke="#0B9872"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M7.98941 78.2572L15.3316 93.1574L11.6618 103.738L22.8093 125.549V144.769L10.1492 140.883L0 104.169L7.98941 78.2572Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
