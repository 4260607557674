import React from "react";

export const HooverTower = () => {
  return (
    <svg
      width="305"
      height="480"
      viewBox="0 0 305 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "75%", width: "75%" }}
    >
      <g opacity="0.75" filter="url(#filter0_dd_16_5373)">
        <g clip-path="url(#clip0_16_5373)">
          <circle cx="152" cy="157" r="97" fill="#D9D9D9" />
          <path
            d="M171.862 22.0122C171.862 13.0753 164.327 5.82675 155.029 5.82675C145.732 5.82675 138.196 13.0753 138.196 22.0122"
            fill="#0CB08A"
          />
          <path
            d="M40.6372 227.806L49.345 274.071L33.4897 270.38L34.4868 226.842L40.6372 227.806Z"
            fill="#0CB08A"
          />
          <path
            d="M162.009 132.371V234.177L120.762 220.594V132.371H162.009Z"
            fill="#0D9071"
          />
          <path
            d="M256.233 264.716V290.01L158.143 261.504V232.921L256.233 264.716Z"
            fill="#15766A"
          />
          <path
            d="M117.563 236.534V263.116L44.4505 295.461V271.611L117.563 236.534Z"
            fill="#15766A"
          />
          <path
            d="M99.6298 116.554L98.9306 133.083L183.846 132.371V112.65L99.6298 116.554Z"
            fill="white"
          />
          <path
            d="M62.3258 140.58L62.7595 156.888L98.9307 133.083L99.6299 116.554L62.3258 140.58Z"
            fill="white"
          />
          <path
            d="M183.846 112.65V132.371L241.2 151.398L235.536 130.966L183.846 112.65Z"
            fill="white"
          />
          <path
            d="M98.9306 246.374H120.761V132.371L98.9306 133.083V246.374Z"
            fill="#15766A"
          />
          <path d="M135.406 255.056V882.169H300.698V302.926" fill="white" />
          <path d="M4.00015 313.408V882.169H135.406V255.056" fill="#15766A" />
          <path
            d="M279.087 347.371C279.087 338.689 272.717 331.652 264.85 331.652C256.984 331.652 250.614 338.689 250.614 347.371"
            fill="#0D9071"
          />
          <path
            d="M279.083 347.368L279.083 882.166H250.61L250.61 347.368H279.083Z"
            fill="#0D9071"
          />
          <path
            d="M232.286 336.274C232.286 327.593 225.915 320.555 218.049 320.555C210.183 320.555 203.812 327.593 203.812 336.274"
            fill="#0D9071"
          />
          <path
            d="M232.284 336.275L232.284 882.17H203.811L203.811 336.275H232.284Z"
            fill="#0D9071"
          />
          <path
            d="M183.846 322.543C183.846 313.861 177.475 306.824 169.609 306.824C161.749 306.824 155.372 313.861 155.372 322.543"
            fill="#0D9071"
          />
          <path
            d="M183.846 322.548L183.846 882.168H155.373L155.373 322.548H183.846Z"
            fill="#0D9071"
          />
          <path
            d="M271.318 258.572V294.348L294.062 300.848V264.716L271.318 258.572Z"
            fill="white"
          />
          <path
            d="M232.719 148.589L232.655 256.675L250.174 262.547V153.645L232.719 148.589Z"
            fill="white"
          />
          <path
            d="M271.318 258.572V294.348L256.234 290.01V264.716L271.318 258.572Z"
            fill="#0CB08A"
          />
          <path
            d="M33.4897 266.282V300.408L15.647 308.209V272.141L33.4897 266.282Z"
            fill="#03684D"
          />
          <path
            d="M44.4507 271.611V295.461L33.4899 300.505V266.269L44.4507 271.611Z"
            fill="#0CB08A"
          />
          <path
            d="M62.7595 156.888L62.3257 263.693L49.345 269.623V161.53L62.7595 156.888Z"
            fill="white"
          />
          <path
            d="M162.008 234.177L183.846 241.499V132.371H162.008V234.177Z"
            fill="#15766A"
          />
          <path
            d="M232.719 148.589L232.195 256.558L199.533 246.782V136.793L232.719 148.589Z"
            fill="#0D9071"
          />
          <path
            d="M85.95 141.674V251.664L62.3258 263.693L62.7595 156.888L85.95 141.674Z"
            fill="#0D9071"
          />
          <path
            d="M219.421 184.876C219.421 178.35 214.417 173.054 208.247 173.054C202.077 173.054 197.073 178.344 197.073 184.876"
            fill="#0CB08A"
          />
          <path
            d="M197.079 184.876V231.05L219.421 239.842V184.876H197.079Z"
            fill="#0CB08A"
          />
          <path
            d="M183.846 132.371V240.852L199.533 246.782V136.793L183.846 132.371Z"
            fill="white"
          />
          <path
            d="M95.9526 188.787C95.9526 182.261 90.9481 176.965 84.7782 176.965C78.6083 176.965 73.6038 182.254 73.6038 188.787"
            fill="#0CB08A"
          />
          <path
            d="M73.6038 188.787V244.833L95.9526 236.177V188.787H73.6038Z"
            fill="#0CB08A"
          />
          <path
            d="M98.9307 133.083V245.727L85.95 251.664V141.674L98.9307 133.083Z"
            fill="white"
          />
          <path
            d="M157.949 175.909C157.949 169.125 150.905 163.622 142.217 163.622C133.529 163.622 126.485 169.125 126.485 175.909"
            fill="#0CB08A"
          />
          <path
            d="M126.485 175.909V234.177L157.949 225.171V175.909H126.485Z"
            fill="#0CB08A"
          />
          <path
            d="M135.406 214.936V254.739L158.143 261.504V221.708L135.406 214.936Z"
            fill="#15766A"
          />
          <path
            d="M135.406 214.936V255.056L117.563 263.116V221.831L135.406 214.936Z"
            fill="white"
          />
          <path
            d="M272.218 217.888L271.318 258.572L294.062 264.716L276.459 219.034L272.218 217.888Z"
            fill="white"
          />
          <path
            d="M272.218 217.888L271.318 258.572L256.233 264.716L268.67 219.487L272.218 217.888Z"
            fill="#0CB08A"
          />
          <path
            d="M34.4868 226.842L33.4898 266.282L15.647 272.141L28.932 230.908L34.4868 226.842Z"
            fill="#03684D"
          />
          <path
            d="M135.406 172.252V214.981L158.143 221.747L142.217 175.243L135.406 172.252Z"
            fill="#15766A"
          />
          <path
            d="M135.406 172.252V214.981L117.563 221.87L130.389 175.379L135.406 172.252Z"
            fill="white"
          />
          <path
            d="M146.03 59.0314H159.859V15.9588L146.755 17.1501L146.03 59.0314Z"
            fill="white"
          />
          <path
            d="M159.859 59.0314L173.636 61.1355L171.746 20.7173L159.859 15.9588V59.0314Z"
            fill="#15766A"
          />
          <path
            d="M137.115 60.6823L146.03 59.0314L146.755 17.1501L138.371 22.3747L137.115 60.6823Z"
            fill="#0D9071"
          />
          <mask
            id="mask0_16_5373"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="95"
            y="309"
            style={{ width: "20", height: "574" }}
            width="20"
            height="574"
          >
            <path
              d="M114.074 319.655C114.074 313.919 110.014 309.264 105.01 309.264C100.005 309.264 95.9461 313.913 95.9461 319.655"
              fill="#CFCFCF"
            />
            <path
              d="M114.076 319.656L114.076 882.17H95.948L95.948 319.656H114.076Z"
              fill="#CFCFCF"
            />
          </mask>
          <g mask="url(#mask0_16_5373)">
            <path
              d="M97.104 873.163C98.0549 873.163 98.8257 873.934 98.8257 874.885C98.8257 875.836 98.0549 876.607 97.104 876.607C96.1532 876.607 95.3823 875.836 95.3823 874.885C95.3823 873.934 96.1532 873.163 97.104 873.163Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 867.205C98.0549 867.205 98.8257 867.976 98.8257 868.927C98.8257 869.878 98.0549 870.649 97.104 870.649C96.1532 870.649 95.3823 869.878 95.3823 868.927C95.3823 867.976 96.1532 867.205 97.104 867.205Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 861.192C98.0549 861.192 98.8257 861.962 98.8257 862.913C98.8257 863.864 98.0549 864.635 97.104 864.635C96.1532 864.635 95.3823 863.864 95.3823 862.913C95.3823 861.962 96.1532 861.192 97.104 861.192Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 855.234C98.0549 855.234 98.8257 856.004 98.8257 856.955C98.8257 857.906 98.0549 858.677 97.104 858.677C96.1532 858.677 95.3823 857.906 95.3823 856.955C95.3823 856.004 96.1532 855.234 97.104 855.234Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.0952 849.286C98.0461 849.286 98.8169 850.056 98.8169 851.007C98.8169 851.958 98.0461 852.729 97.0952 852.729C96.1444 852.729 95.3735 851.958 95.3735 851.007C95.3735 850.056 96.1444 849.286 97.0952 849.286Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.0952 843.327C98.0461 843.327 98.8169 844.098 98.8169 845.049C98.8169 846 98.0461 846.771 97.0952 846.771C96.1444 846.771 95.3735 846 95.3735 845.049C95.3735 844.098 96.1444 843.327 97.0952 843.327Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 837.331C98.0549 837.331 98.8257 838.101 98.8257 839.052C98.8257 840.003 98.0549 840.774 97.104 840.774C96.1532 840.774 95.3823 840.003 95.3823 839.052C95.3823 838.101 96.1532 837.331 97.104 837.331Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 831.372C98.0549 831.372 98.8257 832.143 98.8257 833.094C98.8257 834.045 98.0549 834.816 97.104 834.816C96.1532 834.816 95.3823 834.045 95.3823 833.094C95.3823 832.143 96.1532 831.372 97.104 831.372Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 825.408C98.0549 825.408 98.8257 826.179 98.8257 827.13C98.8257 828.081 98.0549 828.851 97.104 828.851C96.1532 828.851 95.3823 828.081 95.3823 827.13C95.3823 826.179 96.1532 825.408 97.104 825.408Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 819.45C98.0549 819.45 98.8257 820.221 98.8257 821.172C98.8257 822.122 98.0549 822.893 97.104 822.893C96.1532 822.893 95.3823 822.122 95.3823 821.172C95.3823 820.221 96.1532 819.45 97.104 819.45Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 813.453C98.0549 813.453 98.8257 814.224 98.8257 815.175C98.8257 816.125 98.0549 816.896 97.104 816.896C96.1532 816.896 95.3823 816.125 95.3823 815.175C95.3823 814.224 96.1532 813.453 97.104 813.453Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.104 807.495C98.0549 807.495 98.8257 808.266 98.8257 809.217C98.8257 810.167 98.0549 810.938 97.104 810.938C96.1532 810.938 95.3823 810.167 95.3823 809.217C95.3823 808.266 96.1532 807.495 97.104 807.495Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 801.527C98.06 801.527 98.8309 802.298 98.8309 803.248C98.8309 804.199 98.06 804.97 97.1092 804.97C96.1583 804.97 95.3875 804.199 95.3875 803.248C95.3875 802.298 96.1583 801.527 97.1092 801.527Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 795.569C98.06 795.569 98.8309 796.339 98.8309 797.29C98.8309 798.241 98.06 799.012 97.1092 799.012C96.1583 799.012 95.3875 798.241 95.3875 797.29C95.3875 796.339 96.1583 795.569 97.1092 795.569Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 789.555C98.06 789.555 98.8309 790.326 98.8309 791.277C98.8309 792.228 98.06 792.999 97.1092 792.999C96.1583 792.999 95.3875 792.228 95.3875 791.277C95.3875 790.326 96.1583 789.555 97.1092 789.555Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 783.597C98.06 783.597 98.8309 784.368 98.8309 785.319C98.8309 786.27 98.06 787.041 97.1092 787.041C96.1583 787.041 95.3875 786.27 95.3875 785.319C95.3875 784.368 96.1583 783.597 97.1092 783.597Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.0989 777.649C98.0498 777.649 98.8206 778.42 98.8206 779.371C98.8206 780.322 98.0498 781.093 97.0989 781.093C96.148 781.093 95.3772 780.322 95.3772 779.371C95.3772 778.42 96.148 777.649 97.0989 777.649Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.0989 771.691C98.0498 771.691 98.8206 772.462 98.8206 773.413C98.8206 774.364 98.0498 775.134 97.0989 775.134C96.148 775.134 95.3772 774.364 95.3772 773.413C95.3772 772.462 96.148 771.691 97.0989 771.691Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 765.693C98.06 765.693 98.8309 766.463 98.8309 767.414C98.8309 768.365 98.06 769.136 97.1092 769.136C96.1583 769.136 95.3875 768.365 95.3875 767.414C95.3875 766.463 96.1583 765.693 97.1092 765.693Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 759.735C98.06 759.735 98.8309 760.505 98.8309 761.456C98.8309 762.407 98.06 763.178 97.1092 763.178C96.1583 763.178 95.3875 762.407 95.3875 761.456C95.3875 760.505 96.1583 759.735 97.1092 759.735Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 753.772C98.06 753.772 98.8309 754.542 98.8309 755.493C98.8309 756.444 98.06 757.215 97.1092 757.215C96.1583 757.215 95.3875 756.444 95.3875 755.493C95.3875 754.542 96.1583 753.772 97.1092 753.772Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 747.813C98.06 747.813 98.8309 748.584 98.8309 749.535C98.8309 750.486 98.06 751.257 97.1092 751.257C96.1583 751.257 95.3875 750.486 95.3875 749.535C95.3875 748.584 96.1583 747.813 97.1092 747.813Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 741.815C98.06 741.815 98.8309 742.586 98.8309 743.537C98.8309 744.487 98.06 745.258 97.1092 745.258C96.1583 745.258 95.3875 744.487 95.3875 743.537C95.3875 742.586 96.1583 741.815 97.1092 741.815Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1092 735.857C98.06 735.857 98.8309 736.628 98.8309 737.579C98.8309 738.529 98.06 739.3 97.1092 739.3C96.1583 739.3 95.3875 738.529 95.3875 737.579C95.3875 736.628 96.1583 735.857 97.1092 735.857Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 876.137C103.069 876.137 103.84 876.907 103.84 877.858C103.84 878.809 103.069 879.58 102.118 879.58C101.168 879.58 100.397 878.809 100.397 877.858C100.397 876.907 101.168 876.137 102.118 876.137Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 870.18C103.069 870.18 103.84 870.95 103.84 871.901C103.84 872.852 103.069 873.623 102.118 873.623C101.168 873.623 100.397 872.852 100.397 871.901C100.397 870.95 101.168 870.18 102.118 870.18Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 864.166C103.069 864.166 103.84 864.937 103.84 865.888C103.84 866.839 103.069 867.61 102.118 867.61C101.168 867.61 100.397 866.839 100.397 865.888C100.397 864.937 101.168 864.166 102.118 864.166Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 858.208C103.069 858.208 103.84 858.979 103.84 859.93C103.84 860.881 103.069 861.652 102.118 861.652C101.168 861.652 100.397 860.881 100.397 859.93C100.397 858.979 101.168 858.208 102.118 858.208Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.109 852.259C103.06 852.259 103.831 853.03 103.831 853.981C103.831 854.931 103.06 855.702 102.109 855.702C101.159 855.702 100.388 854.931 100.388 853.981C100.388 853.03 101.159 852.259 102.109 852.259Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.109 846.302C103.06 846.302 103.831 847.073 103.831 848.024C103.831 848.975 103.06 849.746 102.109 849.746C101.159 849.746 100.388 848.975 100.388 848.024C100.388 847.073 101.159 846.302 102.109 846.302Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 840.304C103.069 840.304 103.84 841.075 103.84 842.026C103.84 842.976 103.069 843.747 102.118 843.747C101.168 843.747 100.397 842.976 100.397 842.026C100.397 841.075 101.168 840.304 102.118 840.304Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 834.346C103.069 834.346 103.84 835.117 103.84 836.068C103.84 837.018 103.069 837.789 102.118 837.789C101.168 837.789 100.397 837.018 100.397 836.068C100.397 835.117 101.168 834.346 102.118 834.346Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 828.381C103.069 828.381 103.84 829.152 103.84 830.103C103.84 831.054 103.069 831.825 102.118 831.825C101.168 831.825 100.397 831.054 100.397 830.103C100.397 829.152 101.168 828.381 102.118 828.381Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 822.425C103.069 822.425 103.84 823.195 103.84 824.146C103.84 825.097 103.069 825.868 102.118 825.868C101.168 825.868 100.397 825.097 100.397 824.146C100.397 823.195 101.168 822.425 102.118 822.425Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 816.426C103.069 816.426 103.84 817.197 103.84 818.148C103.84 819.099 103.069 819.87 102.118 819.87C101.168 819.87 100.397 819.099 100.397 818.148C100.397 817.197 101.168 816.426 102.118 816.426Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.118 810.468C103.069 810.468 103.84 811.239 103.84 812.19C103.84 813.141 103.069 813.912 102.118 813.912C101.168 813.912 100.397 813.141 100.397 812.19C100.397 811.239 101.168 810.468 102.118 810.468Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 804.5C103.074 804.5 103.845 805.271 103.845 806.222C103.845 807.173 103.074 807.943 102.123 807.943C101.172 807.943 100.402 807.173 100.402 806.222C100.402 805.271 101.172 804.5 102.123 804.5Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 798.542C103.074 798.542 103.845 799.313 103.845 800.264C103.845 801.215 103.074 801.986 102.123 801.986C101.172 801.986 100.402 801.215 100.402 800.264C100.402 799.313 101.172 798.542 102.123 798.542Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 792.528C103.074 792.528 103.845 793.299 103.845 794.25C103.845 795.201 103.074 795.972 102.123 795.972C101.172 795.972 100.402 795.201 100.402 794.25C100.402 793.299 101.172 792.528 102.123 792.528Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 786.57C103.074 786.57 103.845 787.341 103.845 788.292C103.845 789.243 103.074 790.014 102.123 790.014C101.172 790.014 100.402 789.243 100.402 788.292C100.402 787.341 101.172 786.57 102.123 786.57Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.113 780.623C103.064 780.623 103.835 781.393 103.835 782.344C103.835 783.295 103.064 784.066 102.113 784.066C101.162 784.066 100.392 783.295 100.392 782.344C100.392 781.393 101.162 780.623 102.113 780.623Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.113 774.664C103.064 774.664 103.835 775.435 103.835 776.386C103.835 777.337 103.064 778.108 102.113 778.108C101.162 778.108 100.392 777.337 100.392 776.386C100.392 775.435 101.162 774.664 102.113 774.664Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 768.666C103.074 768.666 103.845 769.437 103.845 770.388C103.845 771.339 103.074 772.11 102.123 772.11C101.172 772.11 100.402 771.339 100.402 770.388C100.402 769.437 101.172 768.666 102.123 768.666Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 762.708C103.074 762.708 103.845 763.479 103.845 764.43C103.845 765.38 103.074 766.151 102.123 766.151C101.172 766.151 100.402 765.38 100.402 764.43C100.402 763.479 101.172 762.708 102.123 762.708Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 756.745C103.074 756.745 103.845 757.516 103.845 758.467C103.845 759.418 103.074 760.188 102.123 760.188C101.172 760.188 100.402 759.418 100.402 758.467C100.402 757.516 101.172 756.745 102.123 756.745Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 750.787C103.074 750.787 103.845 751.558 103.845 752.509C103.845 753.459 103.074 754.23 102.123 754.23C101.172 754.23 100.402 753.459 100.402 752.509C100.402 751.558 101.172 750.787 102.123 750.787Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 744.789C103.074 744.789 103.845 745.559 103.845 746.51C103.845 747.461 103.074 748.232 102.123 748.232C101.172 748.232 100.402 747.461 100.402 746.51C100.402 745.559 101.172 744.789 102.123 744.789Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 738.83C103.074 738.83 103.845 739.601 103.845 740.552C103.845 741.503 103.074 742.274 102.123 742.274C101.172 742.274 100.402 741.503 100.402 740.552C100.402 739.601 101.172 738.83 102.123 738.83Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 873.153C108.198 873.153 108.969 873.924 108.969 874.875C108.969 875.826 108.198 876.596 107.247 876.596C106.296 876.596 105.525 875.826 105.525 874.875C105.525 873.924 106.296 873.153 107.247 873.153Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 867.195C108.198 867.195 108.969 867.966 108.969 868.917C108.969 869.868 108.198 870.639 107.247 870.639C106.296 870.639 105.525 869.868 105.525 868.917C105.525 867.966 106.296 867.195 107.247 867.195Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 861.182C108.198 861.182 108.969 861.952 108.969 862.903C108.969 863.854 108.198 864.625 107.247 864.625C106.296 864.625 105.525 863.854 105.525 862.903C105.525 861.952 106.296 861.182 107.247 861.182Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 855.224C108.198 855.224 108.969 855.994 108.969 856.945C108.969 857.896 108.198 858.667 107.247 858.667C106.296 858.667 105.525 857.896 105.525 856.945C105.525 855.994 106.296 855.224 107.247 855.224Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.238 849.275C108.189 849.275 108.96 850.046 108.96 850.997C108.96 851.948 108.189 852.719 107.238 852.719C106.287 852.719 105.517 851.948 105.517 850.997C105.517 850.046 106.287 849.275 107.238 849.275Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.238 843.317C108.189 843.317 108.96 844.088 108.96 845.039C108.96 845.99 108.189 846.761 107.238 846.761C106.287 846.761 105.517 845.99 105.517 845.039C105.517 844.088 106.287 843.317 107.238 843.317Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 837.319C108.198 837.319 108.969 838.09 108.969 839.041C108.969 839.992 108.198 840.763 107.247 840.763C106.296 840.763 105.525 839.992 105.525 839.041C105.525 838.09 106.296 837.319 107.247 837.319Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 831.361C108.198 831.361 108.969 832.132 108.969 833.083C108.969 834.034 108.198 834.804 107.247 834.804C106.296 834.804 105.525 834.034 105.525 833.083C105.525 832.132 106.296 831.361 107.247 831.361Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 825.398C108.198 825.398 108.969 826.169 108.969 827.12C108.969 828.07 108.198 828.841 107.247 828.841C106.296 828.841 105.525 828.07 105.525 827.12C105.525 826.169 106.296 825.398 107.247 825.398Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 819.44C108.198 819.44 108.969 820.211 108.969 821.161C108.969 822.112 108.198 822.883 107.247 822.883C106.296 822.883 105.525 822.112 105.525 821.161C105.525 820.211 106.296 819.44 107.247 819.44Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 813.442C108.198 813.442 108.969 814.212 108.969 815.163C108.969 816.114 108.198 816.885 107.247 816.885C106.296 816.885 105.525 816.114 105.525 815.163C105.525 814.212 106.296 813.442 107.247 813.442Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.247 807.484C108.198 807.484 108.969 808.254 108.969 809.205C108.969 810.156 108.198 810.927 107.247 810.927C106.296 810.927 105.525 810.156 105.525 809.205C105.525 808.254 106.296 807.484 107.247 807.484Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 801.515C108.202 801.515 108.972 802.286 108.972 803.237C108.972 804.188 108.202 804.959 107.251 804.959C106.3 804.959 105.529 804.188 105.529 803.237C105.529 802.286 106.3 801.515 107.251 801.515Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 795.557C108.202 795.557 108.972 796.328 108.972 797.279C108.972 798.23 108.202 799.001 107.251 799.001C106.3 799.001 105.529 798.23 105.529 797.279C105.529 796.328 106.3 795.557 107.251 795.557Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 789.544C108.202 789.544 108.972 790.315 108.972 791.265C108.972 792.216 108.202 792.987 107.251 792.987C106.3 792.987 105.529 792.216 105.529 791.265C105.529 790.315 106.3 789.544 107.251 789.544Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 783.586C108.202 783.586 108.972 784.357 108.972 785.307C108.972 786.258 108.202 787.029 107.251 787.029C106.3 787.029 105.529 786.258 105.529 785.307C105.529 784.357 106.3 783.586 107.251 783.586Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.242 777.638C108.193 777.638 108.964 778.409 108.964 779.36C108.964 780.31 108.193 781.081 107.242 781.081C106.291 781.081 105.52 780.31 105.52 779.36C105.52 778.409 106.291 777.638 107.242 777.638Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.242 771.68C108.193 771.68 108.964 772.451 108.964 773.401C108.964 774.352 108.193 775.123 107.242 775.123C106.291 775.123 105.52 774.352 105.52 773.401C105.52 772.451 106.291 771.68 107.242 771.68Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 765.683C108.202 765.683 108.972 766.453 108.972 767.404C108.972 768.355 108.202 769.126 107.251 769.126C106.3 769.126 105.529 768.355 105.529 767.404C105.529 766.453 106.3 765.683 107.251 765.683Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 759.725C108.202 759.725 108.972 760.496 108.972 761.446C108.972 762.397 108.202 763.168 107.251 763.168C106.3 763.168 105.529 762.397 105.529 761.446C105.529 760.496 106.3 759.725 107.251 759.725Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 753.76C108.202 753.76 108.972 754.531 108.972 755.482C108.972 756.433 108.202 757.204 107.251 757.204C106.3 757.204 105.529 756.433 105.529 755.482C105.529 754.531 106.3 753.76 107.251 753.76Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 747.802C108.202 747.802 108.972 748.573 108.972 749.524C108.972 750.475 108.202 751.246 107.251 751.246C106.3 751.246 105.529 750.475 105.529 749.524C105.529 748.573 106.3 747.802 107.251 747.802Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 741.805C108.202 741.805 108.972 742.576 108.972 743.527C108.972 744.478 108.202 745.248 107.251 745.248C106.3 745.248 105.529 744.478 105.529 743.527C105.529 742.576 106.3 741.805 107.251 741.805Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 735.847C108.202 735.847 108.972 736.618 108.972 737.569C108.972 738.52 108.202 739.29 107.251 739.29C106.3 739.29 105.529 738.52 105.529 737.569C105.529 736.618 106.3 735.847 107.251 735.847Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 876.137C113.271 876.137 114.042 876.907 114.042 877.858C114.042 878.809 113.271 879.58 112.32 879.58C111.369 879.58 110.599 878.809 110.599 877.858C110.599 876.907 111.369 876.137 112.32 876.137Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 870.18C113.271 870.18 114.042 870.95 114.042 871.901C114.042 872.852 113.271 873.623 112.32 873.623C111.369 873.623 110.599 872.852 110.599 871.901C110.599 870.95 111.369 870.18 112.32 870.18Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 864.166C113.271 864.166 114.042 864.937 114.042 865.888C114.042 866.839 113.271 867.61 112.32 867.61C111.369 867.61 110.599 866.839 110.599 865.888C110.599 864.937 111.369 864.166 112.32 864.166Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 858.208C113.271 858.208 114.042 858.979 114.042 859.93C114.042 860.881 113.271 861.652 112.32 861.652C111.369 861.652 110.599 860.881 110.599 859.93C110.599 858.979 111.369 858.208 112.32 858.208Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.312 852.259C113.262 852.259 114.033 853.03 114.033 853.981C114.033 854.931 113.262 855.702 112.312 855.702C111.361 855.702 110.59 854.931 110.59 853.981C110.59 853.03 111.361 852.259 112.312 852.259Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.312 846.302C113.262 846.302 114.033 847.073 114.033 848.024C114.033 848.975 113.262 849.746 112.312 849.746C111.361 849.746 110.59 848.975 110.59 848.024C110.59 847.073 111.361 846.302 112.312 846.302Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 840.304C113.271 840.304 114.042 841.075 114.042 842.026C114.042 842.976 113.271 843.747 112.32 843.747C111.369 843.747 110.599 842.976 110.599 842.026C110.599 841.075 111.369 840.304 112.32 840.304Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 834.346C113.271 834.346 114.042 835.117 114.042 836.068C114.042 837.018 113.271 837.789 112.32 837.789C111.369 837.789 110.599 837.018 110.599 836.068C110.599 835.117 111.369 834.346 112.32 834.346Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 828.381C113.271 828.381 114.042 829.152 114.042 830.103C114.042 831.054 113.271 831.825 112.32 831.825C111.369 831.825 110.599 831.054 110.599 830.103C110.599 829.152 111.369 828.381 112.32 828.381Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 822.425C113.271 822.425 114.042 823.195 114.042 824.146C114.042 825.097 113.271 825.868 112.32 825.868C111.369 825.868 110.599 825.097 110.599 824.146C110.599 823.195 111.369 822.425 112.32 822.425Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 816.426C113.271 816.426 114.042 817.197 114.042 818.148C114.042 819.099 113.271 819.87 112.32 819.87C111.369 819.87 110.599 819.099 110.599 818.148C110.599 817.197 111.369 816.426 112.32 816.426Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.32 810.468C113.271 810.468 114.042 811.239 114.042 812.19C114.042 813.141 113.271 813.912 112.32 813.912C111.369 813.912 110.599 813.141 110.599 812.19C110.599 811.239 111.369 810.468 112.32 810.468Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 804.5C113.276 804.5 114.047 805.271 114.047 806.222C114.047 807.173 113.276 807.943 112.325 807.943C111.375 807.943 110.604 807.173 110.604 806.222C110.604 805.271 111.375 804.5 112.325 804.5Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 798.542C113.276 798.542 114.047 799.313 114.047 800.264C114.047 801.215 113.276 801.986 112.325 801.986C111.375 801.986 110.604 801.215 110.604 800.264C110.604 799.313 111.375 798.542 112.325 798.542Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 792.528C113.276 792.528 114.047 793.299 114.047 794.25C114.047 795.201 113.276 795.972 112.325 795.972C111.375 795.972 110.604 795.201 110.604 794.25C110.604 793.299 111.375 792.528 112.325 792.528Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 786.57C113.276 786.57 114.047 787.341 114.047 788.292C114.047 789.243 113.276 790.014 112.325 790.014C111.375 790.014 110.604 789.243 110.604 788.292C110.604 787.341 111.375 786.57 112.325 786.57Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.315 780.623C113.266 780.623 114.037 781.393 114.037 782.344C114.037 783.295 113.266 784.066 112.315 784.066C111.364 784.066 110.594 783.295 110.594 782.344C110.594 781.393 111.364 780.623 112.315 780.623Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.315 774.664C113.266 774.664 114.037 775.435 114.037 776.386C114.037 777.337 113.266 778.108 112.315 778.108C111.364 778.108 110.594 777.337 110.594 776.386C110.594 775.435 111.364 774.664 112.315 774.664Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 768.666C113.276 768.666 114.047 769.437 114.047 770.388C114.047 771.339 113.276 772.11 112.325 772.11C111.375 772.11 110.604 771.339 110.604 770.388C110.604 769.437 111.375 768.666 112.325 768.666Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 762.708C113.276 762.708 114.047 763.479 114.047 764.43C114.047 765.38 113.276 766.151 112.325 766.151C111.375 766.151 110.604 765.38 110.604 764.43C110.604 763.479 111.375 762.708 112.325 762.708Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 756.745C113.276 756.745 114.047 757.516 114.047 758.467C114.047 759.418 113.276 760.188 112.325 760.188C111.375 760.188 110.604 759.418 110.604 758.467C110.604 757.516 111.375 756.745 112.325 756.745Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 750.787C113.276 750.787 114.047 751.558 114.047 752.509C114.047 753.459 113.276 754.23 112.325 754.23C111.375 754.23 110.604 753.459 110.604 752.509C110.604 751.558 111.375 750.787 112.325 750.787Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 744.789C113.276 744.789 114.047 745.559 114.047 746.51C114.047 747.461 113.276 748.232 112.325 748.232C111.375 748.232 110.604 747.461 110.604 746.51C110.604 745.559 111.375 744.789 112.325 744.789Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 738.83C113.276 738.83 114.047 739.601 114.047 740.552C114.047 741.503 113.276 742.274 112.325 742.274C111.375 742.274 110.604 741.503 110.604 740.552C110.604 739.601 111.375 738.83 112.325 738.83Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 723.926C98.124 723.926 98.8948 724.697 98.8948 725.648C98.8948 726.598 98.124 727.369 97.1731 727.369C96.2222 727.369 95.4514 726.598 95.4514 725.648C95.4514 724.697 96.2222 723.926 97.1731 723.926Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 717.968C98.124 717.968 98.8948 718.738 98.8948 719.689C98.8948 720.64 98.124 721.411 97.1731 721.411C96.2222 721.411 95.4514 720.64 95.4514 719.689C95.4514 718.738 96.2222 717.968 97.1731 717.968Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 711.954C98.124 711.954 98.8948 712.725 98.8948 713.676C98.8948 714.627 98.124 715.398 97.1731 715.398C96.2222 715.398 95.4514 714.627 95.4514 713.676C95.4514 712.725 96.2222 711.954 97.1731 711.954Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 705.996C98.124 705.996 98.8948 706.767 98.8948 707.718C98.8948 708.669 98.124 709.44 97.1731 709.44C96.2222 709.44 95.4514 708.669 95.4514 707.718C95.4514 706.767 96.2222 705.996 97.1731 705.996Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1631 700.048C98.114 700.048 98.8848 700.819 98.8848 701.77C98.8848 702.721 98.114 703.492 97.1631 703.492C96.2122 703.492 95.4414 702.721 95.4414 701.77C95.4414 700.819 96.2122 700.048 97.1631 700.048Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1631 694.09C98.114 694.09 98.8848 694.861 98.8848 695.812C98.8848 696.763 98.114 697.533 97.1631 697.533C96.2122 697.533 95.4414 696.763 95.4414 695.812C95.4414 694.861 96.2122 694.09 97.1631 694.09Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 688.092C98.124 688.092 98.8948 688.863 98.8948 689.814C98.8948 690.764 98.124 691.535 97.1731 691.535C96.2222 691.535 95.4514 690.764 95.4514 689.814C95.4514 688.863 96.2222 688.092 97.1731 688.092Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 682.134C98.124 682.134 98.8948 682.904 98.8948 683.855C98.8948 684.806 98.124 685.577 97.1731 685.577C96.2222 685.577 95.4514 684.806 95.4514 683.855C95.4514 682.904 96.2222 682.134 97.1731 682.134Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 676.171C98.124 676.171 98.8948 676.942 98.8948 677.892C98.8948 678.843 98.124 679.614 97.1731 679.614C96.2222 679.614 95.4514 678.843 95.4514 677.892C95.4514 676.942 96.2222 676.171 97.1731 676.171Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 670.213C98.124 670.213 98.8948 670.983 98.8948 671.934C98.8948 672.885 98.124 673.656 97.1731 673.656C96.2222 673.656 95.4514 672.885 95.4514 671.934C95.4514 670.983 96.2222 670.213 97.1731 670.213Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 664.214C98.124 664.214 98.8948 664.985 98.8948 665.936C98.8948 666.887 98.124 667.658 97.1731 667.658C96.2222 667.658 95.4514 666.887 95.4514 665.936C95.4514 664.985 96.2222 664.214 97.1731 664.214Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1731 658.256C98.124 658.256 98.8948 659.027 98.8948 659.978C98.8948 660.929 98.124 661.699 97.1731 661.699C96.2222 661.699 95.4514 660.929 95.4514 659.978C95.4514 659.027 96.2222 658.256 97.1731 658.256Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.177 652.288C98.1279 652.288 98.8987 653.059 98.8987 654.01C98.8987 654.96 98.1279 655.731 97.177 655.731C96.2262 655.731 95.4553 654.96 95.4553 654.01C95.4553 653.059 96.2262 652.288 97.177 652.288Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 646.33C98.1276 646.33 98.8985 647.101 98.8985 648.052C98.8985 649.002 98.1276 649.773 97.1768 649.773C96.2259 649.773 95.4551 649.002 95.4551 648.052C95.4551 647.101 96.2259 646.33 97.1768 646.33Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 640.317C98.1276 640.317 98.8985 641.087 98.8985 642.038C98.8985 642.989 98.1276 643.76 97.1768 643.76C96.2259 643.76 95.4551 642.989 95.4551 642.038C95.4551 641.087 96.2259 640.317 97.1768 640.317Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 634.359C98.1276 634.359 98.8985 635.129 98.8985 636.08C98.8985 637.031 98.1276 637.802 97.1768 637.802C96.2259 637.802 95.4551 637.031 95.4551 636.08C95.4551 635.129 96.2259 634.359 97.1768 634.359Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1682 628.41C98.1191 628.41 98.8899 629.181 98.8899 630.132C98.8899 631.083 98.1191 631.854 97.1682 631.854C96.2174 631.854 95.4465 631.083 95.4465 630.132C95.4465 629.181 96.2174 628.41 97.1682 628.41Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1682 622.452C98.1191 622.452 98.8899 623.223 98.8899 624.174C98.8899 625.125 98.1191 625.896 97.1682 625.896C96.2174 625.896 95.4465 625.125 95.4465 624.174C95.4465 623.223 96.2174 622.452 97.1682 622.452Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 616.455C98.1276 616.455 98.8985 617.226 98.8985 618.177C98.8985 619.128 98.1276 619.899 97.1768 619.899C96.2259 619.899 95.4551 619.128 95.4551 618.177C95.4551 617.226 96.2259 616.455 97.1768 616.455Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 610.497C98.1276 610.497 98.8985 611.268 98.8985 612.219C98.8985 613.17 98.1276 613.941 97.1768 613.941C96.2259 613.941 95.4551 613.17 95.4551 612.219C95.4551 611.268 96.2259 610.497 97.1768 610.497Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 604.533C98.1276 604.533 98.8985 605.304 98.8985 606.254C98.8985 607.205 98.1276 607.976 97.1768 607.976C96.2259 607.976 95.4551 607.205 95.4551 606.254C95.4551 605.304 96.2259 604.533 97.1768 604.533Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 598.575C98.1276 598.575 98.8985 599.346 98.8985 600.296C98.8985 601.247 98.1276 602.018 97.1768 602.018C96.2259 602.018 95.4551 601.247 95.4551 600.296C95.4551 599.346 96.2259 598.575 97.1768 598.575Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 592.578C98.1276 592.578 98.8985 593.349 98.8985 594.299C98.8985 595.25 98.1276 596.021 97.1768 596.021C96.2259 596.021 95.4551 595.25 95.4551 594.299C95.4551 593.349 96.2259 592.578 97.1768 592.578Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1768 586.62C98.1276 586.62 98.8985 587.39 98.8985 588.341C98.8985 589.292 98.1276 590.063 97.1768 590.063C96.2259 590.063 95.4551 589.292 95.4551 588.341C95.4551 587.39 96.2259 586.62 97.1768 586.62Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 726.909C103.197 726.909 103.968 727.68 103.968 728.631C103.968 729.582 103.197 730.353 102.247 730.353C101.296 730.353 100.525 729.582 100.525 728.631C100.525 727.68 101.296 726.909 102.247 726.909Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 720.952C103.197 720.952 103.968 721.723 103.968 722.674C103.968 723.625 103.197 724.396 102.247 724.396C101.296 724.396 100.525 723.625 100.525 722.674C100.525 721.723 101.296 720.952 102.247 720.952Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 714.939C103.197 714.939 103.968 715.71 103.968 716.661C103.968 717.612 103.197 718.382 102.247 718.382C101.296 718.382 100.525 717.612 100.525 716.661C100.525 715.71 101.296 714.939 102.247 714.939Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.246 708.981C103.197 708.981 103.968 709.752 103.968 710.703C103.968 711.653 103.197 712.424 102.246 712.424C101.295 712.424 100.525 711.653 100.525 710.703C100.525 709.752 101.295 708.981 102.246 708.981Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.236 703.032C103.187 703.032 103.958 703.803 103.958 704.753C103.958 705.704 103.187 706.475 102.236 706.475C101.285 706.475 100.515 705.704 100.515 704.753C100.515 703.803 101.285 703.032 102.236 703.032Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.236 697.075C103.187 697.075 103.958 697.846 103.958 698.796C103.958 699.747 103.187 700.518 102.236 700.518C101.285 700.518 100.515 699.747 100.515 698.796C100.515 697.846 101.285 697.075 102.236 697.075Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 691.076C103.197 691.076 103.968 691.847 103.968 692.798C103.968 693.749 103.197 694.52 102.247 694.52C101.296 694.52 100.525 693.749 100.525 692.798C100.525 691.847 101.296 691.076 102.247 691.076Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 685.118C103.197 685.118 103.968 685.889 103.968 686.84C103.968 687.791 103.197 688.562 102.247 688.562C101.296 688.562 100.525 687.791 100.525 686.84C100.525 685.889 101.296 685.118 102.247 685.118Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 679.154C103.197 679.154 103.968 679.925 103.968 680.876C103.968 681.827 103.197 682.597 102.247 682.597C101.296 682.597 100.525 681.827 100.525 680.876C100.525 679.925 101.296 679.154 102.247 679.154Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 673.197C103.197 673.197 103.968 673.968 103.968 674.919C103.968 675.87 103.197 676.641 102.247 676.641C101.296 676.641 100.525 675.87 100.525 674.919C100.525 673.968 101.296 673.197 102.247 673.197Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 667.199C103.197 667.199 103.968 667.97 103.968 668.921C103.968 669.872 103.197 670.642 102.247 670.642C101.296 670.642 100.525 669.872 100.525 668.921C100.525 667.97 101.296 667.199 102.247 667.199Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.247 661.241C103.197 661.241 103.968 662.012 103.968 662.962C103.968 663.913 103.197 664.684 102.247 664.684C101.296 664.684 100.525 663.913 100.525 662.962C100.525 662.012 101.296 661.241 102.247 661.241Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 655.273C103.201 655.273 103.972 656.043 103.972 656.994C103.972 657.945 103.201 658.716 102.25 658.716C101.299 658.716 100.529 657.945 100.529 656.994C100.529 656.043 101.299 655.273 102.25 655.273Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 649.315C103.201 649.315 103.972 650.085 103.972 651.036C103.972 651.987 103.201 652.758 102.25 652.758C101.299 652.758 100.529 651.987 100.529 651.036C100.529 650.085 101.299 649.315 102.25 649.315Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 643.301C103.201 643.301 103.972 644.072 103.972 645.023C103.972 645.974 103.201 646.745 102.25 646.745C101.299 646.745 100.529 645.974 100.529 645.023C100.529 644.072 101.299 643.301 102.25 643.301Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 637.343C103.201 637.343 103.972 638.114 103.972 639.065C103.972 640.016 103.201 640.787 102.25 640.787C101.299 640.787 100.529 640.016 100.529 639.065C100.529 638.114 101.299 637.343 102.25 637.343Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.241 631.395C103.192 631.395 103.963 632.166 103.963 633.117C103.963 634.068 103.192 634.838 102.241 634.838C101.291 634.838 100.52 634.068 100.52 633.117C100.52 632.166 101.291 631.395 102.241 631.395Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.241 625.437C103.192 625.437 103.963 626.208 103.963 627.159C103.963 628.109 103.192 628.88 102.241 628.88C101.291 628.88 100.52 628.109 100.52 627.159C100.52 626.208 101.291 625.437 102.241 625.437Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 619.439C103.201 619.439 103.972 620.21 103.972 621.16C103.972 622.111 103.201 622.882 102.25 622.882C101.299 622.882 100.529 622.111 100.529 621.16C100.529 620.21 101.299 619.439 102.25 619.439Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 613.481C103.201 613.481 103.972 614.252 103.972 615.202C103.972 616.153 103.201 616.924 102.25 616.924C101.299 616.924 100.529 616.153 100.529 615.202C100.529 614.252 101.299 613.481 102.25 613.481Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 607.517C103.201 607.517 103.972 608.288 103.972 609.239C103.972 610.19 103.201 610.961 102.25 610.961C101.299 610.961 100.529 610.19 100.529 609.239C100.529 608.288 101.299 607.517 102.25 607.517Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 601.559C103.201 601.559 103.972 602.33 103.972 603.281C103.972 604.232 103.201 605.003 102.25 605.003C101.299 605.003 100.529 604.232 100.529 603.281C100.529 602.33 101.299 601.559 102.25 601.559Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 595.561C103.201 595.561 103.972 596.332 103.972 597.283C103.972 598.234 103.201 599.005 102.25 599.005C101.299 599.005 100.529 598.234 100.529 597.283C100.529 596.332 101.299 595.561 102.25 595.561Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.25 589.603C103.201 589.603 103.972 590.374 103.972 591.325C103.972 592.276 103.201 593.046 102.25 593.046C101.299 593.046 100.529 592.276 100.529 591.325C100.529 590.374 101.299 589.603 102.25 589.603Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.1455 729.884C98.0964 729.884 98.8672 730.655 98.8672 731.606C98.8672 732.556 98.0964 733.327 97.1455 733.327C96.1947 733.327 95.4238 732.556 95.4238 731.606C95.4238 730.655 96.1947 729.884 97.1455 729.884Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 729.884C108.212 729.884 108.982 730.655 108.982 731.606C108.982 732.556 108.212 733.327 107.261 733.327C106.31 733.327 105.539 732.556 105.539 731.606C105.539 730.655 106.31 729.884 107.261 729.884Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 723.926C108.212 723.926 108.982 724.697 108.982 725.648C108.982 726.598 108.212 727.369 107.261 727.369C106.31 727.369 105.539 726.598 105.539 725.648C105.539 724.697 106.31 723.926 107.261 723.926Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 717.912C108.212 717.912 108.982 718.683 108.982 719.634C108.982 720.585 108.212 721.356 107.261 721.356C106.31 721.356 105.539 720.585 105.539 719.634C105.539 718.683 106.31 717.912 107.261 717.912Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 711.954C108.212 711.954 108.982 712.725 108.982 713.676C108.982 714.627 108.212 715.398 107.261 715.398C106.31 715.398 105.539 714.627 105.539 713.676C105.539 712.725 106.31 711.954 107.261 711.954Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 706.006C108.202 706.006 108.972 706.777 108.972 707.728C108.972 708.679 108.202 709.45 107.251 709.45C106.3 709.45 105.529 708.679 105.529 707.728C105.529 706.777 106.3 706.006 107.251 706.006Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.251 700.048C108.202 700.048 108.972 700.819 108.972 701.77C108.972 702.721 108.202 703.492 107.251 703.492C106.3 703.492 105.529 702.721 105.529 701.77C105.529 700.819 106.3 700.048 107.251 700.048Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 694.05C108.212 694.05 108.982 694.821 108.982 695.772C108.982 696.722 108.212 697.493 107.261 697.493C106.31 697.493 105.539 696.722 105.539 695.772C105.539 694.821 106.31 694.05 107.261 694.05Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 688.092C108.212 688.092 108.982 688.863 108.982 689.814C108.982 690.764 108.212 691.535 107.261 691.535C106.31 691.535 105.539 690.764 105.539 689.814C105.539 688.863 106.31 688.092 107.261 688.092Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 682.129C108.212 682.129 108.982 682.9 108.982 683.85C108.982 684.801 108.212 685.572 107.261 685.572C106.31 685.572 105.539 684.801 105.539 683.85C105.539 682.9 106.31 682.129 107.261 682.129Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 676.171C108.212 676.171 108.982 676.942 108.982 677.892C108.982 678.843 108.212 679.614 107.261 679.614C106.31 679.614 105.539 678.843 105.539 677.892C105.539 676.942 106.31 676.171 107.261 676.171Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 670.172C108.212 670.172 108.982 670.943 108.982 671.894C108.982 672.845 108.212 673.616 107.261 673.616C106.31 673.616 105.539 672.845 105.539 671.894C105.539 670.943 106.31 670.172 107.261 670.172Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.261 664.214C108.212 664.214 108.982 664.985 108.982 665.936C108.982 666.887 108.212 667.658 107.261 667.658C106.31 667.658 105.539 666.887 105.539 665.936C105.539 664.985 106.31 664.214 107.261 664.214Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 658.246C108.216 658.246 108.986 659.017 108.986 659.968C108.986 660.919 108.216 661.69 107.265 661.69C106.314 661.69 105.543 660.919 105.543 659.968C105.543 659.017 106.314 658.246 107.265 658.246Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 652.288C108.216 652.288 108.986 653.059 108.986 654.01C108.986 654.96 108.216 655.731 107.265 655.731C106.314 655.731 105.543 654.96 105.543 654.01C105.543 653.059 106.314 652.288 107.265 652.288Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 646.275C108.216 646.275 108.986 647.045 108.986 647.996C108.986 648.947 108.216 649.718 107.265 649.718C106.314 649.718 105.543 648.947 105.543 647.996C105.543 647.045 106.314 646.275 107.265 646.275Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 640.317C108.216 640.317 108.986 641.087 108.986 642.038C108.986 642.989 108.216 643.76 107.265 643.76C106.314 643.76 105.543 642.989 105.543 642.038C105.543 641.087 106.314 640.317 107.265 640.317Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.256 634.368C108.207 634.368 108.977 635.139 108.977 636.09C108.977 637.041 108.207 637.812 107.256 637.812C106.305 637.812 105.534 637.041 105.534 636.09C105.534 635.139 106.305 634.368 107.256 634.368Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.256 628.41C108.207 628.41 108.977 629.181 108.977 630.132C108.977 631.083 108.207 631.854 107.256 631.854C106.305 631.854 105.534 631.083 105.534 630.132C105.534 629.181 106.305 628.41 107.256 628.41Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 622.413C108.216 622.413 108.986 623.184 108.986 624.135C108.986 625.086 108.216 625.857 107.265 625.857C106.314 625.857 105.543 625.086 105.543 624.135C105.543 623.184 106.314 622.413 107.265 622.413Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 616.455C108.216 616.455 108.986 617.226 108.986 618.177C108.986 619.128 108.216 619.899 107.265 619.899C106.314 619.899 105.543 619.128 105.543 618.177C105.543 617.226 106.314 616.455 107.265 616.455Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 610.491C108.216 610.491 108.986 611.262 108.986 612.213C108.986 613.164 108.216 613.934 107.265 613.934C106.314 613.934 105.543 613.164 105.543 612.213C105.543 611.262 106.314 610.491 107.265 610.491Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 604.533C108.216 604.533 108.986 605.304 108.986 606.254C108.986 607.205 108.216 607.976 107.265 607.976C106.314 607.976 105.543 607.205 105.543 606.254C105.543 605.304 106.314 604.533 107.265 604.533Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 598.536C108.216 598.536 108.986 599.307 108.986 600.257C108.986 601.208 108.216 601.979 107.265 601.979C106.314 601.979 105.543 601.208 105.543 600.257C105.543 599.307 106.314 598.536 107.265 598.536Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.265 592.578C108.216 592.578 108.986 593.349 108.986 594.299C108.986 595.25 108.216 596.021 107.265 596.021C106.314 596.021 105.543 595.25 105.543 594.299C105.543 593.349 106.314 592.578 107.265 592.578Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 726.899C113.339 726.899 114.11 727.67 114.11 728.621C114.11 729.572 113.339 730.343 112.388 730.343C111.437 730.343 110.667 729.572 110.667 728.621C110.667 727.67 111.437 726.899 112.388 726.899Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 720.941C113.339 720.941 114.11 721.712 114.11 722.663C114.11 723.614 113.339 724.385 112.388 724.385C111.437 724.385 110.667 723.614 110.667 722.663C110.667 721.712 111.437 720.941 112.388 720.941Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 714.928C113.339 714.928 114.11 715.698 114.11 716.649C114.11 717.6 113.339 718.371 112.388 718.371C111.437 718.371 110.667 717.6 110.667 716.649C110.667 715.698 111.437 714.928 112.388 714.928Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 708.97C113.339 708.97 114.11 709.74 114.11 710.691C114.11 711.642 113.339 712.413 112.388 712.413C111.437 712.413 110.667 711.642 110.667 710.691C110.667 709.74 111.437 708.97 112.388 708.97Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.379 703.021C113.33 703.021 114.101 703.792 114.101 704.743C114.101 705.694 113.33 706.465 112.379 706.465C111.429 706.465 110.658 705.694 110.658 704.743C110.658 703.792 111.429 703.021 112.379 703.021Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.379 697.064C113.33 697.064 114.101 697.834 114.101 698.785C114.101 699.736 113.33 700.507 112.379 700.507C111.429 700.507 110.658 699.736 110.658 698.785C110.658 697.834 111.429 697.064 112.379 697.064Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 691.066C113.339 691.066 114.11 691.837 114.11 692.788C114.11 693.739 113.339 694.51 112.388 694.51C111.437 694.51 110.667 693.739 110.667 692.788C110.667 691.837 111.437 691.066 112.388 691.066Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 685.108C113.339 685.108 114.11 685.879 114.11 686.83C114.11 687.781 113.339 688.552 112.388 688.552C111.437 688.552 110.667 687.781 110.667 686.83C110.667 685.879 111.437 685.108 112.388 685.108Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 679.144C113.339 679.144 114.11 679.915 114.11 680.866C114.11 681.817 113.339 682.587 112.388 682.587C111.437 682.587 110.667 681.817 110.667 680.866C110.667 679.915 111.437 679.144 112.388 679.144Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 673.186C113.339 673.186 114.11 673.957 114.11 674.908C114.11 675.859 113.339 676.629 112.388 676.629C111.437 676.629 110.667 675.859 110.667 674.908C110.667 673.957 111.437 673.186 112.388 673.186Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 667.189C113.339 667.189 114.11 667.96 114.11 668.911C114.11 669.861 113.339 670.632 112.388 670.632C111.437 670.632 110.667 669.861 110.667 668.911C110.667 667.96 111.437 667.189 112.388 667.189Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.388 661.231C113.339 661.231 114.11 662.002 114.11 662.953C114.11 663.903 113.339 664.674 112.388 664.674C111.437 664.674 110.667 663.903 110.667 662.953C110.667 662.002 111.437 661.231 112.388 661.231Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 655.261C113.344 655.261 114.115 656.032 114.115 656.983C114.115 657.934 113.344 658.705 112.393 658.705C111.442 658.705 110.671 657.934 110.671 656.983C110.671 656.032 111.442 655.261 112.393 655.261Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 649.305C113.344 649.305 114.115 650.075 114.115 651.026C114.115 651.977 113.344 652.748 112.393 652.748C111.442 652.748 110.671 651.977 110.671 651.026C110.671 650.075 111.442 649.305 112.393 649.305Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 643.291C113.344 643.291 114.115 644.062 114.115 645.013C114.115 645.964 113.344 646.735 112.393 646.735C111.442 646.735 110.671 645.964 110.671 645.013C110.671 644.062 111.442 643.291 112.393 643.291Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 637.333C113.344 637.333 114.115 638.104 114.115 639.055C114.115 640.006 113.344 640.777 112.393 640.777C111.442 640.777 110.671 640.006 110.671 639.055C110.671 638.104 111.442 637.333 112.393 637.333Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.385 631.384C113.335 631.384 114.106 632.155 114.106 633.105C114.106 634.056 113.335 634.827 112.385 634.827C111.434 634.827 110.663 634.056 110.663 633.105C110.663 632.155 111.434 631.384 112.385 631.384Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.385 625.427C113.335 625.427 114.106 626.198 114.106 627.149C114.106 628.1 113.335 628.87 112.385 628.87C111.434 628.87 110.663 628.1 110.663 627.149C110.663 626.198 111.434 625.427 112.385 625.427Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 619.429C113.344 619.429 114.115 620.199 114.115 621.15C114.115 622.101 113.344 622.872 112.393 622.872C111.442 622.872 110.671 622.101 110.671 621.15C110.671 620.199 111.442 619.429 112.393 619.429Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 613.471C113.344 613.471 114.115 614.241 114.115 615.192C114.115 616.143 113.344 616.914 112.393 616.914C111.442 616.914 110.671 616.143 110.671 615.192C110.671 614.241 111.442 613.471 112.393 613.471Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 607.506C113.344 607.506 114.115 608.277 114.115 609.228C114.115 610.179 113.344 610.95 112.393 610.95C111.442 610.95 110.671 610.179 110.671 609.228C110.671 608.277 111.442 607.506 112.393 607.506Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 601.549C113.344 601.549 114.115 602.32 114.115 603.271C114.115 604.222 113.344 604.993 112.393 604.993C111.442 604.993 110.671 604.222 110.671 603.271C110.671 602.32 111.442 601.549 112.393 601.549Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 595.551C113.344 595.551 114.115 596.322 114.115 597.273C114.115 598.224 113.344 598.994 112.393 598.994C111.442 598.994 110.671 598.224 110.671 597.273C110.671 596.322 111.442 595.551 112.393 595.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.393 589.593C113.344 589.593 114.115 590.364 114.115 591.315C114.115 592.266 113.344 593.036 112.393 593.036C111.442 593.036 110.671 592.266 110.671 591.315C110.671 590.364 111.442 589.593 112.393 589.593Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.123 732.862C103.074 732.862 103.845 733.633 103.845 734.584C103.845 735.535 103.074 736.306 102.123 736.306C101.172 736.306 100.402 735.535 100.402 734.584C100.402 733.633 101.172 732.862 102.123 732.862Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.325 732.874C113.276 732.874 114.047 733.644 114.047 734.595C114.047 735.546 113.276 736.317 112.325 736.317C111.375 736.317 110.604 735.546 110.604 734.595C110.604 733.644 111.375 732.874 112.325 732.874Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 586.95C98.4006 586.95 99.1714 587.721 99.1714 588.672C99.1714 589.622 98.4006 590.393 97.4497 590.393C96.4989 590.393 95.728 589.622 95.728 588.672C95.728 587.721 96.4989 586.95 97.4497 586.95Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 580.992C98.4006 580.992 99.1714 581.763 99.1714 582.713C99.1714 583.664 98.4006 584.435 97.4497 584.435C96.4989 584.435 95.728 583.664 95.728 582.713C95.728 581.763 96.4989 580.992 97.4497 580.992Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 574.978C98.4006 574.978 99.1714 575.749 99.1714 576.7C99.1714 577.651 98.4006 578.422 97.4497 578.422C96.4989 578.422 95.728 577.651 95.728 576.7C95.728 575.749 96.4989 574.978 97.4497 574.978Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 569.02C98.4006 569.02 99.1714 569.791 99.1714 570.742C99.1714 571.693 98.4006 572.464 97.4497 572.464C96.4989 572.464 95.728 571.693 95.728 570.742C95.728 569.791 96.4989 569.02 97.4497 569.02Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4409 563.072C98.3918 563.072 99.1626 563.843 99.1626 564.794C99.1626 565.745 98.3918 566.516 97.4409 566.516C96.4901 566.516 95.7192 565.745 95.7192 564.794C95.7192 563.843 96.4901 563.072 97.4409 563.072Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4409 557.114C98.3918 557.114 99.1626 557.885 99.1626 558.836C99.1626 559.787 98.3918 560.558 97.4409 560.558C96.4901 560.558 95.7192 559.787 95.7192 558.836C95.7192 557.885 96.4901 557.114 97.4409 557.114Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 551.117C98.4006 551.117 99.1714 551.888 99.1714 552.839C99.1714 553.79 98.4006 554.561 97.4497 554.561C96.4989 554.561 95.728 553.79 95.728 552.839C95.728 551.888 96.4989 551.117 97.4497 551.117Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 545.159C98.4006 545.159 99.1714 545.93 99.1714 546.881C99.1714 547.831 98.4006 548.602 97.4497 548.602C96.4989 548.602 95.728 547.831 95.728 546.881C95.728 545.93 96.4989 545.159 97.4497 545.159Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 539.195C98.4006 539.195 99.1714 539.965 99.1714 540.916C99.1714 541.867 98.4006 542.638 97.4497 542.638C96.4989 542.638 95.728 541.867 95.728 540.916C95.728 539.965 96.4989 539.195 97.4497 539.195Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 533.237C98.4006 533.237 99.1714 534.007 99.1714 534.958C99.1714 535.909 98.4006 536.68 97.4497 536.68C96.4989 536.68 95.728 535.909 95.728 534.958C95.728 534.007 96.4989 533.237 97.4497 533.237Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 527.24C98.4006 527.24 99.1714 528.01 99.1714 528.961C99.1714 529.912 98.4006 530.683 97.4497 530.683C96.4989 530.683 95.728 529.912 95.728 528.961C95.728 528.01 96.4989 527.24 97.4497 527.24Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4497 521.281C98.4006 521.281 99.1714 522.052 99.1714 523.003C99.1714 523.954 98.4006 524.725 97.4497 524.725C96.4989 524.725 95.728 523.954 95.728 523.003C95.728 522.052 96.4989 521.281 97.4497 521.281Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 515.313C98.4057 515.313 99.1766 516.084 99.1766 517.035C99.1766 517.986 98.4057 518.757 97.4549 518.757C96.504 518.757 95.7332 517.986 95.7332 517.035C95.7332 516.084 96.504 515.313 97.4549 515.313Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 509.355C98.4057 509.355 99.1766 510.126 99.1766 511.077C99.1766 512.028 98.4057 512.799 97.4549 512.799C96.504 512.799 95.7332 512.028 95.7332 511.077C95.7332 510.126 96.504 509.355 97.4549 509.355Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 503.342C98.4057 503.342 99.1766 504.113 99.1766 505.063C99.1766 506.014 98.4057 506.785 97.4549 506.785C96.504 506.785 95.7332 506.014 95.7332 505.063C95.7332 504.113 96.504 503.342 97.4549 503.342Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 497.384C98.4057 497.384 99.1766 498.154 99.1766 499.105C99.1766 500.056 98.4057 500.827 97.4549 500.827C96.504 500.827 95.7332 500.056 95.7332 499.105C95.7332 498.154 96.504 497.384 97.4549 497.384Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4446 491.436C98.3955 491.436 99.1663 492.207 99.1663 493.157C99.1663 494.108 98.3955 494.879 97.4446 494.879C96.4937 494.879 95.7229 494.108 95.7229 493.157C95.7229 492.207 96.4937 491.436 97.4446 491.436Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4446 485.478C98.3955 485.478 99.1663 486.248 99.1663 487.199C99.1663 488.15 98.3955 488.921 97.4446 488.921C96.4937 488.921 95.7229 488.15 95.7229 487.199C95.7229 486.248 96.4937 485.478 97.4446 485.478Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 479.479C98.4057 479.479 99.1766 480.25 99.1766 481.201C99.1766 482.152 98.4057 482.923 97.4549 482.923C96.504 482.923 95.7332 482.152 95.7332 481.201C95.7332 480.25 96.504 479.479 97.4549 479.479Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 473.521C98.4057 473.521 99.1766 474.292 99.1766 475.243C99.1766 476.194 98.4057 476.965 97.4549 476.965C96.504 476.965 95.7332 476.194 95.7332 475.243C95.7332 474.292 96.504 473.521 97.4549 473.521Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 467.558C98.4057 467.558 99.1766 468.329 99.1766 469.28C99.1766 470.231 98.4057 471.002 97.4549 471.002C96.504 471.002 95.7332 470.231 95.7332 469.28C95.7332 468.329 96.504 467.558 97.4549 467.558Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 461.6C98.4057 461.6 99.1766 462.371 99.1766 463.322C99.1766 464.273 98.4057 465.043 97.4549 465.043C96.504 465.043 95.7332 464.273 95.7332 463.322C95.7332 462.371 96.504 461.6 97.4549 461.6Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 455.602C98.4057 455.602 99.1766 456.372 99.1766 457.323C99.1766 458.274 98.4057 459.045 97.4549 459.045C96.504 459.045 95.7332 458.274 95.7332 457.323C95.7332 456.372 96.504 455.602 97.4549 455.602Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4549 449.644C98.4057 449.644 99.1766 450.414 99.1766 451.365C99.1766 452.316 98.4057 453.087 97.4549 453.087C96.504 453.087 95.7332 452.316 95.7332 451.365C95.7332 450.414 96.504 449.644 97.4549 449.644Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 589.923C103.415 589.923 104.186 590.694 104.186 591.645C104.186 592.596 103.415 593.367 102.464 593.367C101.513 593.367 100.742 592.596 100.742 591.645C100.742 590.694 101.513 589.923 102.464 589.923Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 583.966C103.415 583.966 104.186 584.737 104.186 585.688C104.186 586.639 103.415 587.41 102.464 587.41C101.513 587.41 100.742 586.639 100.742 585.688C100.742 584.737 101.513 583.966 102.464 583.966Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 577.953C103.415 577.953 104.186 578.724 104.186 579.675C104.186 580.625 103.415 581.396 102.464 581.396C101.513 581.396 100.742 580.625 100.742 579.675C100.742 578.724 101.513 577.953 102.464 577.953Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 571.995C103.415 571.995 104.186 572.766 104.186 573.717C104.186 574.667 103.415 575.438 102.464 575.438C101.513 575.438 100.742 574.667 100.742 573.717C100.742 572.766 101.513 571.995 102.464 571.995Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.455 566.045C103.406 566.045 104.177 566.816 104.177 567.767C104.177 568.718 103.406 569.489 102.455 569.489C101.504 569.489 100.733 568.718 100.733 567.767C100.733 566.816 101.504 566.045 102.455 566.045Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.455 560.089C103.406 560.089 104.177 560.86 104.177 561.81C104.177 562.761 103.406 563.532 102.455 563.532C101.504 563.532 100.733 562.761 100.733 561.81C100.733 560.86 101.504 560.089 102.455 560.089Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 554.09C103.415 554.09 104.186 554.861 104.186 555.812C104.186 556.763 103.415 557.534 102.464 557.534C101.513 557.534 100.742 556.763 100.742 555.812C100.742 554.861 101.513 554.09 102.464 554.09Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 548.132C103.415 548.132 104.186 548.903 104.186 549.854C104.186 550.805 103.415 551.576 102.464 551.576C101.513 551.576 100.742 550.805 100.742 549.854C100.742 548.903 101.513 548.132 102.464 548.132Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 542.168C103.415 542.168 104.186 542.939 104.186 543.89C104.186 544.841 103.415 545.611 102.464 545.611C101.513 545.611 100.742 544.841 100.742 543.89C100.742 542.939 101.513 542.168 102.464 542.168Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 536.211C103.415 536.211 104.186 536.982 104.186 537.933C104.186 538.884 103.415 539.655 102.464 539.655C101.513 539.655 100.742 538.884 100.742 537.933C100.742 536.982 101.513 536.211 102.464 536.211Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 530.213C103.415 530.213 104.186 530.984 104.186 531.935C104.186 532.886 103.415 533.656 102.464 533.656C101.513 533.656 100.742 532.886 100.742 531.935C100.742 530.984 101.513 530.213 102.464 530.213Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.464 524.255C103.415 524.255 104.186 525.026 104.186 525.976C104.186 526.927 103.415 527.698 102.464 527.698C101.513 527.698 100.742 526.927 100.742 525.976C100.742 525.026 101.513 524.255 102.464 524.255Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 518.287C103.42 518.287 104.191 519.058 104.191 520.008C104.191 520.959 103.42 521.73 102.469 521.73C101.518 521.73 100.747 520.959 100.747 520.008C100.747 519.058 101.518 518.287 102.469 518.287Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 512.329C103.42 512.329 104.191 513.099 104.191 514.05C104.191 515.001 103.42 515.772 102.469 515.772C101.518 515.772 100.747 515.001 100.747 514.05C100.747 513.099 101.518 512.329 102.469 512.329Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 506.315C103.42 506.315 104.191 507.086 104.191 508.037C104.191 508.988 103.42 509.758 102.469 509.758C101.518 509.758 100.747 508.988 100.747 508.037C100.747 507.086 101.518 506.315 102.469 506.315Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 500.357C103.42 500.357 104.191 501.128 104.191 502.079C104.191 503.03 103.42 503.801 102.469 503.801C101.518 503.801 100.747 503.03 100.747 502.079C100.747 501.128 101.518 500.357 102.469 500.357Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.459 494.409C103.41 494.409 104.181 495.18 104.181 496.131C104.181 497.082 103.41 497.853 102.459 497.853C101.508 497.853 100.737 497.082 100.737 496.131C100.737 495.18 101.508 494.409 102.459 494.409Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.459 488.451C103.41 488.451 104.181 489.222 104.181 490.173C104.181 491.124 103.41 491.894 102.459 491.894C101.508 491.894 100.737 491.124 100.737 490.173C100.737 489.222 101.508 488.451 102.459 488.451Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 482.453C103.42 482.453 104.191 483.224 104.191 484.174C104.191 485.125 103.42 485.896 102.469 485.896C101.518 485.896 100.747 485.125 100.747 484.174C100.747 483.224 101.518 482.453 102.469 482.453Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 476.494C103.42 476.494 104.191 477.265 104.191 478.216C104.191 479.167 103.42 479.938 102.469 479.938C101.518 479.938 100.747 479.167 100.747 478.216C100.747 477.265 101.518 476.494 102.469 476.494Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 470.531C103.42 470.531 104.191 471.302 104.191 472.253C104.191 473.204 103.42 473.975 102.469 473.975C101.518 473.975 100.747 473.204 100.747 472.253C100.747 471.302 101.518 470.531 102.469 470.531Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 464.573C103.42 464.573 104.191 465.344 104.191 466.295C104.191 467.246 103.42 468.017 102.469 468.017C101.518 468.017 100.747 467.246 100.747 466.295C100.747 465.344 101.518 464.573 102.469 464.573Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 458.575C103.42 458.575 104.191 459.346 104.191 460.297C104.191 461.248 103.42 462.018 102.469 462.018C101.518 462.018 100.747 461.248 100.747 460.297C100.747 459.346 101.518 458.575 102.469 458.575Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 452.617C103.42 452.617 104.191 453.388 104.191 454.339C104.191 455.29 103.42 456.06 102.469 456.06C101.518 456.06 100.747 455.29 100.747 454.339C100.747 453.388 101.518 452.617 102.469 452.617Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 586.94C108.544 586.94 109.315 587.71 109.315 588.661C109.315 589.612 108.544 590.383 107.593 590.383C106.642 590.383 105.871 589.612 105.871 588.661C105.871 587.71 106.642 586.94 107.593 586.94Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 580.982C108.544 580.982 109.315 581.752 109.315 582.703C109.315 583.654 108.544 584.425 107.593 584.425C106.642 584.425 105.871 583.654 105.871 582.703C105.871 581.752 106.642 580.982 107.593 580.982Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 574.968C108.544 574.968 109.315 575.739 109.315 576.69C109.315 577.641 108.544 578.412 107.593 578.412C106.642 578.412 105.871 577.641 105.871 576.69C105.871 575.739 106.642 574.968 107.593 574.968Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 569.01C108.544 569.01 109.315 569.781 109.315 570.732C109.315 571.683 108.544 572.454 107.593 572.454C106.642 572.454 105.871 571.683 105.871 570.732C105.871 569.781 106.642 569.01 107.593 569.01Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.584 563.062C108.535 563.062 109.306 563.833 109.306 564.784C109.306 565.735 108.535 566.505 107.584 566.505C106.633 566.505 105.862 565.735 105.862 564.784C105.862 563.833 106.633 563.062 107.584 563.062Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.584 557.104C108.535 557.104 109.306 557.875 109.306 558.826C109.306 559.777 108.535 560.547 107.584 560.547C106.633 560.547 105.862 559.777 105.862 558.826C105.862 557.875 106.633 557.104 107.584 557.104Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 551.106C108.544 551.106 109.315 551.877 109.315 552.827C109.315 553.778 108.544 554.549 107.593 554.549C106.642 554.549 105.871 553.778 105.871 552.827C105.871 551.877 106.642 551.106 107.593 551.106Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 545.148C108.544 545.148 109.315 545.919 109.315 546.869C109.315 547.82 108.544 548.591 107.593 548.591C106.642 548.591 105.871 547.82 105.871 546.869C105.871 545.919 106.642 545.148 107.593 545.148Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 539.184C108.544 539.184 109.315 539.955 109.315 540.906C109.315 541.857 108.544 542.628 107.593 542.628C106.642 542.628 105.871 541.857 105.871 540.906C105.871 539.955 106.642 539.184 107.593 539.184Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 533.226C108.544 533.226 109.315 533.997 109.315 534.948C109.315 535.899 108.544 536.67 107.593 536.67C106.642 536.67 105.871 535.899 105.871 534.948C105.871 533.997 106.642 533.226 107.593 533.226Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 527.228C108.543 527.228 109.314 527.999 109.314 528.95C109.314 529.901 108.543 530.672 107.593 530.672C106.642 530.672 105.871 529.901 105.871 528.95C105.871 527.999 106.642 527.228 107.593 527.228Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.593 521.27C108.544 521.27 109.315 522.041 109.315 522.992C109.315 523.943 108.544 524.714 107.593 524.714C106.642 524.714 105.871 523.943 105.871 522.992C105.871 522.041 106.642 521.27 107.593 521.27Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 515.302C108.547 515.302 109.318 516.073 109.318 517.024C109.318 517.975 108.547 518.745 107.596 518.745C106.646 518.745 105.875 517.975 105.875 517.024C105.875 516.073 106.646 515.302 107.596 515.302Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 509.344C108.547 509.344 109.318 510.115 109.318 511.066C109.318 512.017 108.547 512.787 107.596 512.787C106.646 512.787 105.875 512.017 105.875 511.066C105.875 510.115 106.646 509.344 107.596 509.344Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 503.33C108.547 503.33 109.318 504.101 109.318 505.052C109.318 506.003 108.547 506.774 107.596 506.774C106.646 506.774 105.875 506.003 105.875 505.052C105.875 504.101 106.646 503.33 107.596 503.33Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 497.372C108.547 497.372 109.318 498.143 109.318 499.094C109.318 500.045 108.547 500.816 107.596 500.816C106.646 500.816 105.875 500.045 105.875 499.094C105.875 498.143 106.646 497.372 107.596 497.372Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.588 491.424C108.539 491.424 109.309 492.195 109.309 493.146C109.309 494.097 108.539 494.868 107.588 494.868C106.637 494.868 105.866 494.097 105.866 493.146C105.866 492.195 106.637 491.424 107.588 491.424Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.588 485.466C108.539 485.466 109.309 486.237 109.309 487.188C109.309 488.139 108.539 488.91 107.588 488.91C106.637 488.91 105.866 488.139 105.866 487.188C105.866 486.237 106.637 485.466 107.588 485.466Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 479.469C108.547 479.469 109.318 480.24 109.318 481.191C109.318 482.142 108.547 482.913 107.596 482.913C106.646 482.913 105.875 482.142 105.875 481.191C105.875 480.24 106.646 479.469 107.596 479.469Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 473.511C108.547 473.511 109.318 474.282 109.318 475.233C109.318 476.184 108.547 476.955 107.596 476.955C106.646 476.955 105.875 476.184 105.875 475.233C105.875 474.282 106.646 473.511 107.596 473.511Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 467.547C108.547 467.547 109.318 468.318 109.318 469.268C109.318 470.219 108.547 470.99 107.596 470.99C106.646 470.99 105.875 470.219 105.875 469.268C105.875 468.318 106.646 467.547 107.596 467.547Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 461.589C108.547 461.589 109.318 462.36 109.318 463.31C109.318 464.261 108.547 465.032 107.596 465.032C106.646 465.032 105.875 464.261 105.875 463.31C105.875 462.36 106.646 461.589 107.596 461.589Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 455.592C108.547 455.592 109.318 456.362 109.318 457.313C109.318 458.264 108.547 459.035 107.596 459.035C106.646 459.035 105.875 458.264 105.875 457.313C105.875 456.362 106.646 455.592 107.596 455.592Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 449.634C108.547 449.634 109.318 450.404 109.318 451.355C109.318 452.306 108.547 453.077 107.596 453.077C106.646 453.077 105.875 452.306 105.875 451.355C105.875 450.404 106.646 449.634 107.596 449.634Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 589.923C113.617 589.923 114.388 590.694 114.388 591.645C114.388 592.596 113.617 593.367 112.666 593.367C111.715 593.367 110.944 592.596 110.944 591.645C110.944 590.694 111.715 589.923 112.666 589.923Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 583.966C113.617 583.966 114.388 584.737 114.388 585.688C114.388 586.639 113.617 587.41 112.666 587.41C111.715 587.41 110.944 586.639 110.944 585.688C110.944 584.737 111.715 583.966 112.666 583.966Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 577.953C113.617 577.953 114.388 578.724 114.388 579.675C114.388 580.625 113.617 581.396 112.666 581.396C111.715 581.396 110.944 580.625 110.944 579.675C110.944 578.724 111.715 577.953 112.666 577.953Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 571.995C113.617 571.995 114.388 572.766 114.388 573.717C114.388 574.667 113.617 575.438 112.666 575.438C111.715 575.438 110.944 574.667 110.944 573.717C110.944 572.766 111.715 571.995 112.666 571.995Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.657 566.045C113.608 566.045 114.379 566.816 114.379 567.767C114.379 568.718 113.608 569.489 112.657 569.489C111.706 569.489 110.936 568.718 110.936 567.767C110.936 566.816 111.706 566.045 112.657 566.045Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.657 560.089C113.608 560.089 114.379 560.86 114.379 561.81C114.379 562.761 113.608 563.532 112.657 563.532C111.706 563.532 110.936 562.761 110.936 561.81C110.936 560.86 111.706 560.089 112.657 560.089Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 554.09C113.617 554.09 114.388 554.861 114.388 555.812C114.388 556.763 113.617 557.534 112.666 557.534C111.715 557.534 110.944 556.763 110.944 555.812C110.944 554.861 111.715 554.09 112.666 554.09Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 548.132C113.617 548.132 114.388 548.903 114.388 549.854C114.388 550.805 113.617 551.576 112.666 551.576C111.715 551.576 110.944 550.805 110.944 549.854C110.944 548.903 111.715 548.132 112.666 548.132Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 542.168C113.617 542.168 114.388 542.939 114.388 543.89C114.388 544.841 113.617 545.611 112.666 545.611C111.715 545.611 110.944 544.841 110.944 543.89C110.944 542.939 111.715 542.168 112.666 542.168Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 536.211C113.617 536.211 114.388 536.982 114.388 537.933C114.388 538.884 113.617 539.655 112.666 539.655C111.715 539.655 110.944 538.884 110.944 537.933C110.944 536.982 111.715 536.211 112.666 536.211Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 530.213C113.617 530.213 114.388 530.984 114.388 531.935C114.388 532.886 113.617 533.656 112.666 533.656C111.715 533.656 110.944 532.886 110.944 531.935C110.944 530.984 111.715 530.213 112.666 530.213Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.666 524.255C113.617 524.255 114.388 525.026 114.388 525.977C114.388 526.927 113.617 527.698 112.666 527.698C111.715 527.698 110.944 526.927 110.944 525.977C110.944 525.026 111.715 524.255 112.666 524.255Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 518.287C113.622 518.287 114.393 519.058 114.393 520.008C114.393 520.959 113.622 521.73 112.671 521.73C111.72 521.73 110.949 520.959 110.949 520.008C110.949 519.058 111.72 518.287 112.671 518.287Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 512.329C113.622 512.329 114.393 513.1 114.393 514.05C114.393 515.001 113.622 515.772 112.671 515.772C111.72 515.772 110.949 515.001 110.949 514.05C110.949 513.1 111.72 512.329 112.671 512.329Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 506.315C113.622 506.315 114.393 507.086 114.393 508.037C114.393 508.988 113.622 509.758 112.671 509.758C111.72 509.758 110.949 508.988 110.949 508.037C110.949 507.086 111.72 506.315 112.671 506.315Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 500.357C113.622 500.357 114.393 501.128 114.393 502.079C114.393 503.03 113.622 503.8 112.671 503.8C111.72 503.8 110.949 503.03 110.949 502.079C110.949 501.128 111.72 500.357 112.671 500.357Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.661 494.409C113.612 494.409 114.383 495.18 114.383 496.131C114.383 497.082 113.612 497.853 112.661 497.853C111.71 497.853 110.939 497.082 110.939 496.131C110.939 495.18 111.71 494.409 112.661 494.409Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.661 488.451C113.612 488.451 114.383 489.222 114.383 490.173C114.383 491.124 113.612 491.894 112.661 491.894C111.71 491.894 110.939 491.124 110.939 490.173C110.939 489.222 111.71 488.451 112.661 488.451Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 482.453C113.622 482.453 114.393 483.224 114.393 484.174C114.393 485.125 113.622 485.896 112.671 485.896C111.72 485.896 110.949 485.125 110.949 484.174C110.949 483.224 111.72 482.453 112.671 482.453Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 476.494C113.622 476.494 114.393 477.265 114.393 478.216C114.393 479.167 113.622 479.938 112.671 479.938C111.72 479.938 110.949 479.167 110.949 478.216C110.949 477.265 111.72 476.494 112.671 476.494Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 470.531C113.622 470.531 114.393 471.302 114.393 472.253C114.393 473.204 113.622 473.975 112.671 473.975C111.72 473.975 110.949 473.204 110.949 472.253C110.949 471.302 111.72 470.531 112.671 470.531Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 464.573C113.622 464.573 114.393 465.344 114.393 466.295C114.393 467.246 113.622 468.017 112.671 468.017C111.72 468.017 110.949 467.246 110.949 466.295C110.949 465.344 111.72 464.573 112.671 464.573Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 458.575C113.622 458.575 114.393 459.346 114.393 460.297C114.393 461.248 113.622 462.018 112.671 462.018C111.72 462.018 110.949 461.248 110.949 460.297C110.949 459.346 111.72 458.575 112.671 458.575Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 452.617C113.622 452.617 114.393 453.388 114.393 454.339C114.393 455.29 113.622 456.06 112.671 456.06C111.72 456.06 110.949 455.29 110.949 454.339C110.949 453.388 111.72 452.617 112.671 452.617Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 437.712C98.4697 437.712 99.2405 438.483 99.2405 439.434C99.2405 440.385 98.4697 441.156 97.5188 441.156C96.5679 441.156 95.7971 440.385 95.7971 439.434C95.7971 438.483 96.5679 437.712 97.5188 437.712Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 431.754C98.4697 431.754 99.2405 432.525 99.2405 433.476C99.2405 434.427 98.4697 435.198 97.5188 435.198C96.5679 435.198 95.7971 434.427 95.7971 433.476C95.7971 432.525 96.5679 431.754 97.5188 431.754Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 425.741C98.4697 425.741 99.2405 426.512 99.2405 427.463C99.2405 428.413 98.4697 429.184 97.5188 429.184C96.5679 429.184 95.7971 428.413 95.7971 427.463C95.7971 426.512 96.5679 425.741 97.5188 425.741Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 419.783C98.4697 419.783 99.2405 420.554 99.2405 421.504C99.2405 422.455 98.4697 423.226 97.5188 423.226C96.5679 423.226 95.7971 422.455 95.7971 421.504C95.7971 420.554 96.5679 419.783 97.5188 419.783Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5088 413.835C98.4597 413.835 99.2305 414.606 99.2305 415.557C99.2305 416.507 98.4597 417.278 97.5088 417.278C96.5579 417.278 95.7871 416.507 95.7871 415.557C95.7871 414.606 96.5579 413.835 97.5088 413.835Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5088 407.877C98.4597 407.877 99.2305 408.647 99.2305 409.598C99.2305 410.549 98.4597 411.32 97.5088 411.32C96.5579 411.32 95.7871 410.549 95.7871 409.598C95.7871 408.647 96.5579 407.877 97.5088 407.877Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 401.878C98.4697 401.878 99.2405 402.649 99.2405 403.6C99.2405 404.551 98.4697 405.322 97.5188 405.322C96.5679 405.322 95.7971 404.551 95.7971 403.6C95.7971 402.649 96.5679 401.878 97.5188 401.878Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 395.92C98.4697 395.92 99.2405 396.691 99.2405 397.642C99.2405 398.593 98.4697 399.364 97.5188 399.364C96.5679 399.364 95.7971 398.593 95.7971 397.642C95.7971 396.691 96.5679 395.92 97.5188 395.92Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 389.957C98.4697 389.957 99.2405 390.728 99.2405 391.679C99.2405 392.63 98.4697 393.401 97.5188 393.401C96.5679 393.401 95.7971 392.63 95.7971 391.679C95.7971 390.728 96.5679 389.957 97.5188 389.957Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 383.999C98.4697 383.999 99.2405 384.77 99.2405 385.721C99.2405 386.672 98.4697 387.443 97.5188 387.443C96.5679 387.443 95.7971 386.672 95.7971 385.721C95.7971 384.77 96.5679 383.999 97.5188 383.999Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 378.001C98.4697 378.001 99.2405 378.772 99.2405 379.723C99.2405 380.673 98.4697 381.444 97.5188 381.444C96.5679 381.444 95.7971 380.673 95.7971 379.723C95.7971 378.772 96.5679 378.001 97.5188 378.001Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5188 372.043C98.4697 372.043 99.2405 372.814 99.2405 373.764C99.2405 374.715 98.4697 375.486 97.5188 375.486C96.5679 375.486 95.7971 374.715 95.7971 373.764C95.7971 372.814 96.5679 372.043 97.5188 372.043Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5227 366.074C98.4736 366.074 99.2444 366.845 99.2444 367.796C99.2444 368.747 98.4736 369.518 97.5227 369.518C96.5719 369.518 95.801 368.747 95.801 367.796C95.801 366.845 96.5719 366.074 97.5227 366.074Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 360.116C98.4733 360.116 99.2442 360.887 99.2442 361.838C99.2442 362.789 98.4733 363.56 97.5225 363.56C96.5716 363.56 95.8008 362.789 95.8008 361.838C95.8008 360.887 96.5716 360.116 97.5225 360.116Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 354.103C98.4733 354.103 99.2442 354.874 99.2442 355.825C99.2442 356.776 98.4733 357.547 97.5225 357.547C96.5716 357.547 95.8008 356.776 95.8008 355.825C95.8008 354.874 96.5716 354.103 97.5225 354.103Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 348.145C98.4733 348.145 99.2442 348.916 99.2442 349.867C99.2442 350.818 98.4733 351.589 97.5225 351.589C96.5716 351.589 95.8008 350.818 95.8008 349.867C95.8008 348.916 96.5716 348.145 97.5225 348.145Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5139 342.197C98.4648 342.197 99.2356 342.968 99.2356 343.919C99.2356 344.869 98.4648 345.64 97.5139 345.64C96.5631 345.64 95.7922 344.869 95.7922 343.919C95.7922 342.968 96.5631 342.197 97.5139 342.197Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5139 336.239C98.4648 336.239 99.2356 337.01 99.2356 337.961C99.2356 338.911 98.4648 339.682 97.5139 339.682C96.5631 339.682 95.7922 338.911 95.7922 337.961C95.7922 337.01 96.5631 336.239 97.5139 336.239Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 330.242C98.4733 330.242 99.2442 331.013 99.2442 331.964C99.2442 332.914 98.4733 333.685 97.5225 333.685C96.5716 333.685 95.8008 332.914 95.8008 331.964C95.8008 331.013 96.5716 330.242 97.5225 330.242Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 324.284C98.4733 324.284 99.2442 325.055 99.2442 326.006C99.2442 326.956 98.4733 327.727 97.5225 327.727C96.5716 327.727 95.8008 326.956 95.8008 326.006C95.8008 325.055 96.5716 324.284 97.5225 324.284Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 318.319C98.4733 318.319 99.2442 319.09 99.2442 320.041C99.2442 320.992 98.4733 321.763 97.5225 321.763C96.5716 321.763 95.8008 320.992 95.8008 320.041C95.8008 319.09 96.5716 318.319 97.5225 318.319Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 312.361C98.4733 312.361 99.2442 313.132 99.2442 314.083C99.2442 315.034 98.4733 315.805 97.5225 315.805C96.5716 315.805 95.8008 315.034 95.8008 314.083C95.8008 313.132 96.5716 312.361 97.5225 312.361Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.5225 306.364C98.4733 306.364 99.2442 307.135 99.2442 308.086C99.2442 309.037 98.4733 309.808 97.5225 309.808C96.5716 309.808 95.8008 309.037 95.8008 308.086C95.8008 307.135 96.5716 306.364 97.5225 306.364Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 440.696C103.543 440.696 104.314 441.467 104.314 442.418C104.314 443.369 103.543 444.139 102.592 444.139C101.641 444.139 100.87 443.369 100.87 442.418C100.87 441.467 101.641 440.696 102.592 440.696Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 434.739C103.543 434.739 104.314 435.51 104.314 436.461C104.314 437.412 103.543 438.182 102.592 438.182C101.641 438.182 100.87 437.412 100.87 436.461C100.87 435.51 101.641 434.739 102.592 434.739Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 428.726C103.543 428.726 104.314 429.496 104.314 430.447C104.314 431.398 103.543 432.169 102.592 432.169C101.641 432.169 100.87 431.398 100.87 430.447C100.87 429.496 101.641 428.726 102.592 428.726Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 422.768C103.543 422.768 104.314 423.538 104.314 424.489C104.314 425.44 103.543 426.211 102.592 426.211C101.641 426.211 100.87 425.44 100.87 424.489C100.87 423.538 101.641 422.768 102.592 422.768Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.582 416.818C103.533 416.818 104.304 417.589 104.304 418.54C104.304 419.491 103.533 420.262 102.582 420.262C101.631 420.262 100.86 419.491 100.86 418.54C100.86 417.589 101.631 416.818 102.582 416.818Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.582 410.861C103.533 410.861 104.304 411.632 104.304 412.583C104.304 413.534 103.533 414.305 102.582 414.305C101.631 414.305 100.86 413.534 100.86 412.583C100.86 411.632 101.631 410.861 102.582 410.861Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 404.863C103.543 404.863 104.314 405.634 104.314 406.585C104.314 407.536 103.543 408.307 102.592 408.307C101.641 408.307 100.87 407.536 100.87 406.585C100.87 405.634 101.641 404.863 102.592 404.863Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 398.905C103.543 398.905 104.314 399.676 104.314 400.627C104.314 401.577 103.543 402.348 102.592 402.348C101.641 402.348 100.87 401.577 100.87 400.627C100.87 399.676 101.641 398.905 102.592 398.905Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 392.941C103.543 392.941 104.314 393.712 104.314 394.662C104.314 395.613 103.543 396.384 102.592 396.384C101.641 396.384 100.87 395.613 100.87 394.662C100.87 393.712 101.641 392.941 102.592 392.941Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 386.984C103.543 386.984 104.314 387.755 104.314 388.706C104.314 389.656 103.543 390.427 102.592 390.427C101.641 390.427 100.87 389.656 100.87 388.706C100.87 387.755 101.641 386.984 102.592 386.984Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 380.986C103.543 380.986 104.314 381.756 104.314 382.707C104.314 383.658 103.543 384.429 102.592 384.429C101.641 384.429 100.87 383.658 100.87 382.707C100.87 381.756 101.641 380.986 102.592 380.986Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.592 375.027C103.543 375.027 104.314 375.798 104.314 376.749C104.314 377.7 103.543 378.471 102.592 378.471C101.641 378.471 100.87 377.7 100.87 376.749C100.87 375.798 101.641 375.027 102.592 375.027Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 369.059C103.547 369.059 104.318 369.83 104.318 370.781C104.318 371.732 103.547 372.503 102.596 372.503C101.645 372.503 100.874 371.732 100.874 370.781C100.874 369.83 101.645 369.059 102.596 369.059Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 363.101C103.547 363.101 104.318 363.872 104.318 364.823C104.318 365.774 103.547 366.545 102.596 366.545C101.645 366.545 100.874 365.774 100.874 364.823C100.874 363.872 101.645 363.101 102.596 363.101Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 357.088C103.547 357.088 104.318 357.859 104.318 358.81C104.318 359.76 103.547 360.531 102.596 360.531C101.645 360.531 100.874 359.76 100.874 358.81C100.874 357.859 101.645 357.088 102.596 357.088Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 351.13C103.547 351.13 104.318 351.901 104.318 352.852C104.318 353.802 103.547 354.573 102.596 354.573C101.645 354.573 100.874 353.802 100.874 352.852C100.874 351.901 101.645 351.13 102.596 351.13Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.587 345.182C103.538 345.182 104.309 345.952 104.309 346.903C104.309 347.854 103.538 348.625 102.587 348.625C101.636 348.625 100.865 347.854 100.865 346.903C100.865 345.952 101.636 345.182 102.587 345.182Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.587 339.224C103.538 339.224 104.309 339.994 104.309 340.945C104.309 341.896 103.538 342.667 102.587 342.667C101.636 342.667 100.865 341.896 100.865 340.945C100.865 339.994 101.636 339.224 102.587 339.224Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 333.225C103.547 333.225 104.318 333.996 104.318 334.947C104.318 335.898 103.547 336.669 102.596 336.669C101.645 336.669 100.874 335.898 100.874 334.947C100.874 333.996 101.645 333.225 102.596 333.225Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 327.267C103.547 327.267 104.318 328.038 104.318 328.989C104.318 329.94 103.547 330.711 102.596 330.711C101.645 330.711 100.874 329.94 100.874 328.989C100.874 328.038 101.645 327.267 102.596 327.267Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 321.304C103.547 321.304 104.318 322.075 104.318 323.026C104.318 323.977 103.547 324.747 102.596 324.747C101.645 324.747 100.874 323.977 100.874 323.026C100.874 322.075 101.645 321.304 102.596 321.304Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 315.346C103.547 315.346 104.318 316.117 104.318 317.068C104.318 318.019 103.547 318.789 102.596 318.789C101.645 318.789 100.874 318.019 100.874 317.068C100.874 316.117 101.645 315.346 102.596 315.346Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.596 309.348C103.547 309.348 104.318 310.119 104.318 311.069C104.318 312.02 103.547 312.791 102.596 312.791C101.645 312.791 100.874 312.02 100.874 311.069C100.874 310.119 101.645 309.348 102.596 309.348Z"
              fill="#B8B7B7"
            />
            <path
              d="M97.4912 443.67C98.4421 443.67 99.2129 444.441 99.2129 445.392C99.2129 446.343 98.4421 447.114 97.4912 447.114C96.5404 447.114 95.7695 446.343 95.7695 445.392C95.7695 444.441 96.5404 443.67 97.4912 443.67Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 443.67C108.557 443.67 109.328 444.441 109.328 445.392C109.328 446.343 108.557 447.114 107.606 447.114C106.656 447.114 105.885 446.343 105.885 445.392C105.885 444.441 106.656 443.67 107.606 443.67Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 437.712C108.557 437.712 109.328 438.483 109.328 439.434C109.328 440.385 108.557 441.156 107.606 441.156C106.656 441.156 105.885 440.385 105.885 439.434C105.885 438.483 106.656 437.712 107.606 437.712Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 431.699C108.557 431.699 109.328 432.47 109.328 433.421C109.328 434.371 108.557 435.142 107.606 435.142C106.656 435.142 105.885 434.371 105.885 433.421C105.885 432.47 106.656 431.699 107.606 431.699Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 425.741C108.557 425.741 109.328 426.512 109.328 427.463C109.328 428.413 108.557 429.184 107.606 429.184C106.656 429.184 105.885 428.413 105.885 427.463C105.885 426.512 106.656 425.741 107.606 425.741Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 419.793C108.547 419.793 109.318 420.564 109.318 421.515C109.318 422.465 108.547 423.236 107.596 423.236C106.646 423.236 105.875 422.465 105.875 421.515C105.875 420.564 106.646 419.793 107.596 419.793Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.596 413.835C108.547 413.835 109.318 414.606 109.318 415.557C109.318 416.507 108.547 417.278 107.596 417.278C106.646 417.278 105.875 416.507 105.875 415.557C105.875 414.606 106.646 413.835 107.596 413.835Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 407.836C108.557 407.836 109.328 408.607 109.328 409.558C109.328 410.509 108.557 411.28 107.606 411.28C106.656 411.28 105.885 410.509 105.885 409.558C105.885 408.607 106.656 407.836 107.606 407.836Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 401.878C108.557 401.878 109.328 402.649 109.328 403.6C109.328 404.551 108.557 405.322 107.606 405.322C106.656 405.322 105.885 404.551 105.885 403.6C105.885 402.649 106.656 401.878 107.606 401.878Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 395.915C108.557 395.915 109.328 396.686 109.328 397.637C109.328 398.588 108.557 399.359 107.606 399.359C106.656 399.359 105.885 398.588 105.885 397.637C105.885 396.686 106.656 395.915 107.606 395.915Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 389.957C108.557 389.957 109.328 390.728 109.328 391.679C109.328 392.63 108.557 393.401 107.606 393.401C106.656 393.401 105.885 392.63 105.885 391.679C105.885 390.728 106.656 389.957 107.606 389.957Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 383.959C108.557 383.959 109.328 384.73 109.328 385.681C109.328 386.632 108.557 387.402 107.606 387.402C106.656 387.402 105.885 386.632 105.885 385.681C105.885 384.73 106.656 383.959 107.606 383.959Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.606 378.001C108.557 378.001 109.328 378.772 109.328 379.723C109.328 380.673 108.557 381.444 107.606 381.444C106.656 381.444 105.885 380.673 105.885 379.723C105.885 378.772 106.656 378.001 107.606 378.001Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 372.033C108.561 372.033 109.332 372.804 109.332 373.754C109.332 374.705 108.561 375.476 107.61 375.476C106.659 375.476 105.889 374.705 105.889 373.754C105.889 372.804 106.659 372.033 107.61 372.033Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 366.074C108.561 366.074 109.332 366.845 109.332 367.796C109.332 368.747 108.561 369.518 107.61 369.518C106.659 369.518 105.889 368.747 105.889 367.796C105.889 366.845 106.659 366.074 107.61 366.074Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 360.061C108.561 360.061 109.332 360.832 109.332 361.783C109.332 362.734 108.561 363.505 107.61 363.505C106.659 363.505 105.889 362.734 105.889 361.783C105.889 360.832 106.659 360.061 107.61 360.061Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 354.103C108.561 354.103 109.332 354.874 109.332 355.825C109.332 356.776 108.561 357.547 107.61 357.547C106.659 357.547 105.889 356.776 105.889 355.825C105.889 354.874 106.659 354.103 107.61 354.103Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.601 348.155C108.552 348.155 109.323 348.926 109.323 349.877C109.323 350.828 108.552 351.598 107.601 351.598C106.65 351.598 105.88 350.828 105.88 349.877C105.88 348.926 106.65 348.155 107.601 348.155Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.601 342.197C108.552 342.197 109.323 342.968 109.323 343.919C109.323 344.869 108.552 345.64 107.601 345.64C106.65 345.64 105.88 344.869 105.88 343.919C105.88 342.968 106.65 342.197 107.601 342.197Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 336.2C108.561 336.2 109.332 336.971 109.332 337.922C109.332 338.872 108.561 339.643 107.61 339.643C106.659 339.643 105.889 338.872 105.889 337.922C105.889 336.971 106.659 336.2 107.61 336.2Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 330.242C108.561 330.242 109.332 331.013 109.332 331.964C109.332 332.914 108.561 333.685 107.61 333.685C106.659 333.685 105.889 332.914 105.889 331.964C105.889 331.013 106.659 330.242 107.61 330.242Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 324.278C108.561 324.278 109.332 325.048 109.332 325.999C109.332 326.95 108.561 327.721 107.61 327.721C106.659 327.721 105.889 326.95 105.889 325.999C105.889 325.048 106.659 324.278 107.61 324.278Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 318.319C108.561 318.319 109.332 319.09 109.332 320.041C109.332 320.992 108.561 321.763 107.61 321.763C106.659 321.763 105.889 320.992 105.889 320.041C105.889 319.09 106.659 318.319 107.61 318.319Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 312.322C108.561 312.322 109.332 313.093 109.332 314.044C109.332 314.995 108.561 315.766 107.61 315.766C106.659 315.766 105.889 314.995 105.889 314.044C105.889 313.093 106.659 312.322 107.61 312.322Z"
              fill="#B8B7B7"
            />
            <path
              d="M107.61 306.364C108.561 306.364 109.332 307.135 109.332 308.086C109.332 309.037 108.561 309.808 107.61 309.808C106.659 309.808 105.889 309.037 105.889 308.086C105.889 307.135 106.659 306.364 107.61 306.364Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 440.686C113.685 440.686 114.456 441.457 114.456 442.407C114.456 443.358 113.685 444.129 112.734 444.129C111.783 444.129 111.012 443.358 111.012 442.407C111.012 441.457 111.783 440.686 112.734 440.686Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 434.728C113.685 434.728 114.456 435.499 114.456 436.449C114.456 437.4 113.685 438.171 112.734 438.171C111.783 438.171 111.012 437.4 111.012 436.449C111.012 435.499 111.783 434.728 112.734 434.728Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 428.714C113.685 428.714 114.456 429.485 114.456 430.436C114.456 431.387 113.685 432.158 112.734 432.158C111.783 432.158 111.012 431.387 111.012 430.436C111.012 429.485 111.783 428.714 112.734 428.714Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 422.756C113.685 422.756 114.456 423.527 114.456 424.478C114.456 425.429 113.685 426.2 112.734 426.2C111.783 426.2 111.012 425.429 111.012 424.478C111.012 423.527 111.783 422.756 112.734 422.756Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.725 416.808C113.676 416.808 114.447 417.579 114.447 418.53C114.447 419.481 113.676 420.252 112.725 420.252C111.774 420.252 111.003 419.481 111.003 418.53C111.003 417.579 111.774 416.808 112.725 416.808Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.725 410.85C113.676 410.85 114.447 411.621 114.447 412.572C114.447 413.523 113.676 414.294 112.725 414.294C111.774 414.294 111.003 413.523 111.003 412.572C111.003 411.621 111.774 410.85 112.725 410.85Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 404.853C113.685 404.853 114.456 405.624 114.456 406.575C114.456 407.526 113.685 408.296 112.734 408.296C111.783 408.296 111.012 407.526 111.012 406.575C111.012 405.624 111.783 404.853 112.734 404.853Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 398.895C113.685 398.895 114.456 399.666 114.456 400.617C114.456 401.567 113.685 402.338 112.734 402.338C111.783 402.338 111.012 401.568 111.012 400.617C111.012 399.666 111.783 398.895 112.734 398.895Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 392.931C113.685 392.931 114.456 393.701 114.456 394.652C114.456 395.603 113.685 396.374 112.734 396.374C111.783 396.374 111.012 395.603 111.012 394.652C111.012 393.701 111.783 392.931 112.734 392.931Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 386.973C113.685 386.973 114.456 387.743 114.456 388.694C114.456 389.645 113.685 390.416 112.734 390.416C111.783 390.416 111.012 389.645 111.012 388.694C111.012 387.743 111.783 386.973 112.734 386.973Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 380.975C113.685 380.975 114.456 381.746 114.456 382.697C114.456 383.648 113.685 384.419 112.734 384.419C111.783 384.419 111.012 383.648 111.012 382.697C111.012 381.746 111.783 380.975 112.734 380.975Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.734 375.017C113.685 375.017 114.456 375.788 114.456 376.739C114.456 377.69 113.685 378.461 112.734 378.461C111.783 378.461 111.012 377.69 111.012 376.739C111.012 375.788 111.783 375.017 112.734 375.017Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 369.048C113.69 369.048 114.461 369.819 114.461 370.77C114.461 371.721 113.69 372.491 112.739 372.491C111.788 372.491 111.017 371.721 111.017 370.77C111.017 369.819 111.788 369.048 112.739 369.048Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 363.091C113.69 363.091 114.461 363.862 114.461 364.813C114.461 365.764 113.69 366.535 112.739 366.535C111.788 366.535 111.017 365.764 111.017 364.813C111.017 363.862 111.788 363.091 112.739 363.091Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 357.078C113.69 357.078 114.461 357.849 114.461 358.799C114.461 359.75 113.69 360.521 112.739 360.521C111.788 360.521 111.017 359.75 111.017 358.799C111.017 357.849 111.788 357.078 112.739 357.078Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 351.12C113.69 351.12 114.461 351.891 114.461 352.841C114.461 353.792 113.69 354.563 112.739 354.563C111.788 354.563 111.017 353.792 111.017 352.841C111.017 351.891 111.788 351.12 112.739 351.12Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.73 345.17C113.681 345.17 114.452 345.941 114.452 346.892C114.452 347.843 113.681 348.614 112.73 348.614C111.779 348.614 111.009 347.843 111.009 346.892C111.009 345.941 111.779 345.17 112.73 345.17Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.73 339.214C113.681 339.214 114.452 339.984 114.452 340.935C114.452 341.886 113.681 342.657 112.73 342.657C111.779 342.657 111.009 341.886 111.009 340.935C111.009 339.984 111.779 339.214 112.73 339.214Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 333.215C113.69 333.215 114.461 333.986 114.461 334.937C114.461 335.888 113.69 336.659 112.739 336.659C111.788 336.659 111.017 335.888 111.017 334.937C111.017 333.986 111.788 333.215 112.739 333.215Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 327.257C113.69 327.257 114.46 328.028 114.46 328.979C114.46 329.93 113.69 330.701 112.739 330.701C111.788 330.701 111.017 329.93 111.017 328.979C111.017 328.028 111.788 327.257 112.739 327.257Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 321.293C113.69 321.293 114.461 322.064 114.461 323.015C114.461 323.965 113.69 324.736 112.739 324.736C111.788 324.736 111.017 323.965 111.017 323.015C111.017 322.064 111.788 321.293 112.739 321.293Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 315.336C113.69 315.336 114.461 316.107 114.461 317.058C114.461 318.009 113.69 318.779 112.739 318.779C111.788 318.779 111.017 318.009 111.017 317.058C111.017 316.107 111.788 315.336 112.739 315.336Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.739 309.338C113.69 309.338 114.461 310.108 114.461 311.059C114.461 312.01 113.69 312.781 112.739 312.781C111.788 312.781 111.017 312.01 111.017 311.059C111.017 310.108 111.788 309.338 112.739 309.338Z"
              fill="#B8B7B7"
            />
            <path
              d="M102.469 446.649C103.42 446.649 104.191 447.42 104.191 448.371C104.191 449.322 103.42 450.092 102.469 450.092C101.518 450.092 100.747 449.322 100.747 448.371C100.747 447.42 101.518 446.649 102.469 446.649Z"
              fill="#B8B7B7"
            />
            <path
              d="M112.671 446.66C113.622 446.66 114.393 447.431 114.393 448.382C114.393 449.333 113.622 450.104 112.671 450.104C111.72 450.104 110.949 449.333 110.949 448.382C110.949 447.431 111.72 446.66 112.671 446.66Z"
              fill="#B8B7B7"
            />
          </g>
          <mask
            id="mask1_16_5373"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="60"
            y="328"
            style={{ width: "19", height: "555" }}
            width="19"
            height="555"
          >
            <path
              d="M78.7637 338.806C78.7637 333.07 74.7044 328.415 69.6998 328.415C64.6953 328.415 60.636 333.063 60.636 338.806"
              fill="#CFCFCF"
            />
            <path
              d="M78.7646 338.802L78.7646 882.165H60.637L60.637 338.802H78.7646Z"
              fill="#CFCFCF"
            />
          </mask>
          <g mask="url(#mask1_16_5373)">
            <path
              d="M61.9785 880.021C62.9294 880.021 63.7002 880.792 63.7002 881.743C63.7002 882.694 62.9294 883.464 61.9785 883.464C61.0277 883.464 60.2568 882.694 60.2568 881.743C60.2568 880.792 61.0277 880.021 61.9785 880.021Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 874.064C62.9292 874.064 63.7 874.835 63.7 875.786C63.7 876.737 62.9292 877.507 61.9783 877.507C61.0274 877.507 60.2566 876.737 60.2566 875.786C60.2566 874.835 61.0274 874.064 61.9783 874.064Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 868.051C62.9292 868.051 63.7 868.822 63.7 869.772C63.7 870.723 62.9292 871.494 61.9783 871.494C61.0274 871.494 60.2566 870.723 60.2566 869.772C60.2566 868.822 61.0274 868.051 61.9783 868.051Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 862.093C62.9292 862.093 63.7 862.863 63.7 863.814C63.7 864.765 62.9292 865.536 61.9783 865.536C61.0274 865.536 60.2566 864.765 60.2566 863.814C60.2566 862.863 61.0274 862.093 61.9783 862.093Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9695 856.143C62.9204 856.143 63.6912 856.914 63.6912 857.865C63.6912 858.816 62.9204 859.587 61.9695 859.587C61.0186 859.587 60.2478 858.816 60.2478 857.865C60.2478 856.914 61.0186 856.143 61.9695 856.143Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9695 850.187C62.9204 850.187 63.6912 850.957 63.6912 851.908C63.6912 852.859 62.9204 853.63 61.9695 853.63C61.0186 853.63 60.2478 852.859 60.2478 851.908C60.2478 850.957 61.0186 850.187 61.9695 850.187Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 844.188C62.9292 844.188 63.7 844.959 63.7 845.91C63.7 846.861 62.9292 847.632 61.9783 847.632C61.0274 847.632 60.2566 846.861 60.2566 845.91C60.2566 844.959 61.0274 844.188 61.9783 844.188Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 838.23C62.9292 838.23 63.7 839.001 63.7 839.952C63.7 840.903 62.9292 841.674 61.9783 841.674C61.0274 841.674 60.2566 840.903 60.2566 839.952C60.2566 839.001 61.0274 838.23 61.9783 838.23Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 832.266C62.9292 832.266 63.7 833.037 63.7 833.987C63.7 834.938 62.9292 835.709 61.9783 835.709C61.0274 835.709 60.2566 834.938 60.2566 833.987C60.2566 833.037 61.0274 832.266 61.9783 832.266Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 826.309C62.9292 826.309 63.7 827.08 63.7 828.031C63.7 828.982 62.9292 829.752 61.9783 829.752C61.0274 829.752 60.2566 828.982 60.2566 828.031C60.2566 827.08 61.0274 826.309 61.9783 826.309Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 820.311C62.9292 820.311 63.7 821.082 63.7 822.032C63.7 822.983 62.9292 823.754 61.9783 823.754C61.0274 823.754 60.2566 822.983 60.2566 822.032C60.2566 821.082 61.0274 820.311 61.9783 820.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9783 814.353C62.9292 814.353 63.7 815.124 63.7 816.074C63.7 817.025 62.9292 817.796 61.9783 817.796C61.0274 817.796 60.2566 817.025 60.2566 816.074C60.2566 815.124 61.0274 814.353 61.9783 814.353Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 808.384C62.9343 808.384 63.7051 809.155 63.7051 810.106C63.7051 811.057 62.9343 811.828 61.9834 811.828C61.0326 811.828 60.2617 811.057 60.2617 810.106C60.2617 809.155 61.0326 808.384 61.9834 808.384Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 802.426C62.9343 802.426 63.7051 803.197 63.7051 804.148C63.7051 805.099 62.9343 805.87 61.9834 805.87C61.0326 805.87 60.2617 805.099 60.2617 804.148C60.2617 803.197 61.0326 802.426 61.9834 802.426Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 796.413C62.9343 796.413 63.7051 797.184 63.7051 798.135C63.7051 799.085 62.9343 799.856 61.9834 799.856C61.0326 799.856 60.2617 799.085 60.2617 798.135C60.2617 797.184 61.0326 796.413 61.9834 796.413Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 790.455C62.9343 790.455 63.7051 791.226 63.7051 792.177C63.7051 793.127 62.9343 793.898 61.9834 793.898C61.0326 793.898 60.2617 793.127 60.2617 792.177C60.2617 791.226 61.0326 790.455 61.9834 790.455Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9734 784.507C62.9243 784.507 63.6951 785.278 63.6951 786.229C63.6951 787.18 62.9243 787.95 61.9734 787.95C61.0225 787.95 60.2517 787.18 60.2517 786.229C60.2517 785.278 61.0225 784.507 61.9734 784.507Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9734 778.549C62.9243 778.549 63.6951 779.32 63.6951 780.271C63.6951 781.221 62.9243 781.992 61.9734 781.992C61.0225 781.992 60.2517 781.221 60.2517 780.271C60.2517 779.32 61.0225 778.549 61.9734 778.549Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 772.551C62.9343 772.551 63.7051 773.321 63.7051 774.272C63.7051 775.223 62.9343 775.994 61.9834 775.994C61.0326 775.994 60.2617 775.223 60.2617 774.272C60.2617 773.321 61.0326 772.551 61.9834 772.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 766.592C62.9343 766.592 63.7051 767.363 63.7051 768.314C63.7051 769.265 62.9343 770.036 61.9834 770.036C61.0326 770.036 60.2617 769.265 60.2617 768.314C60.2617 767.363 61.0326 766.592 61.9834 766.592Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 760.629C62.9343 760.629 63.7051 761.4 63.7051 762.351C63.7051 763.302 62.9343 764.073 61.9834 764.073C61.0326 764.073 60.2617 763.302 60.2617 762.351C60.2617 761.4 61.0326 760.629 61.9834 760.629Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 754.671C62.9343 754.671 63.7051 755.442 63.7051 756.393C63.7051 757.344 62.9343 758.115 61.9834 758.115C61.0326 758.115 60.2617 757.344 60.2617 756.393C60.2617 755.442 61.0326 754.671 61.9834 754.671Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 748.673C62.9343 748.673 63.7051 749.444 63.7051 750.395C63.7051 751.345 62.9343 752.116 61.9834 752.116C61.0326 752.116 60.2617 751.345 60.2617 750.395C60.2617 749.444 61.0326 748.673 61.9834 748.673Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 742.715C62.9343 742.715 63.7051 743.486 63.7051 744.436C63.7051 745.387 62.9343 746.158 61.9834 746.158C61.0326 746.158 60.2617 745.387 60.2617 744.436C60.2617 743.486 61.0326 742.715 61.9834 742.715Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 877.037C68.0581 877.037 68.8289 877.808 68.8289 878.759C68.8289 879.71 68.0581 880.481 67.1072 880.481C66.1563 880.481 65.3855 879.71 65.3855 878.759C65.3855 877.808 66.1563 877.037 67.1072 877.037Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 871.08C68.0581 871.08 68.8289 871.85 68.8289 872.801C68.8289 873.752 68.0581 874.523 67.1072 874.523C66.1563 874.523 65.3855 873.752 65.3855 872.801C65.3855 871.85 66.1563 871.08 67.1072 871.08Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 865.066C68.0581 865.066 68.8289 865.837 68.8289 866.788C68.8289 867.739 68.0581 868.509 67.1072 868.509C66.1563 868.509 65.3855 867.739 65.3855 866.788C65.3855 865.837 66.1563 865.066 67.1072 865.066Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 859.108C68.0581 859.108 68.8289 859.879 68.8289 860.83C68.8289 861.78 68.0581 862.551 67.1072 862.551C66.1563 862.551 65.3855 861.78 65.3855 860.83C65.3855 859.879 66.1563 859.108 67.1072 859.108Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.0982 853.16C68.049 853.16 68.8199 853.931 68.8199 854.881C68.8199 855.832 68.049 856.603 67.0982 856.603C66.1473 856.603 65.3765 855.832 65.3765 854.881C65.3765 853.931 66.1473 853.16 67.0982 853.16Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.0984 847.202C68.0493 847.202 68.8201 847.973 68.8201 848.924C68.8201 849.874 68.0493 850.645 67.0984 850.645C66.1475 850.645 65.3767 849.874 65.3767 848.924C65.3767 847.973 66.1475 847.202 67.0984 847.202Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 841.204C68.0581 841.204 68.8289 841.974 68.8289 842.925C68.8289 843.876 68.0581 844.647 67.1072 844.647C66.1563 844.647 65.3855 843.876 65.3855 842.925C65.3855 841.974 66.1563 841.204 67.1072 841.204Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 835.246C68.0581 835.246 68.8289 836.016 68.8289 836.967C68.8289 837.918 68.0581 838.689 67.1072 838.689C66.1563 838.689 65.3855 837.918 65.3855 836.967C65.3855 836.016 66.1563 835.246 67.1072 835.246Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 829.282C68.0581 829.282 68.8289 830.053 68.8289 831.004C68.8289 831.955 68.0581 832.726 67.1072 832.726C66.1563 832.726 65.3855 831.955 65.3855 831.004C65.3855 830.053 66.1563 829.282 67.1072 829.282Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 823.324C68.0581 823.324 68.8289 824.095 68.8289 825.046C68.8289 825.997 68.0581 826.768 67.1072 826.768C66.1563 826.768 65.3855 825.997 65.3855 825.046C65.3855 824.095 66.1563 823.324 67.1072 823.324Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.107 817.326C68.0578 817.326 68.8287 818.097 68.8287 819.048C68.8287 819.999 68.0578 820.769 67.107 820.769C66.1561 820.769 65.3853 819.999 65.3853 819.048C65.3853 818.097 66.1561 817.326 67.107 817.326Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1072 811.368C68.0581 811.368 68.8289 812.139 68.8289 813.09C68.8289 814.04 68.0581 814.811 67.1072 814.811C66.1563 814.811 65.3855 814.04 65.3855 813.09C65.3855 812.139 66.1563 811.368 67.1072 811.368Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 805.4C68.0617 805.4 68.8326 806.171 68.8326 807.121C68.8326 808.072 68.0617 808.843 67.1109 808.843C66.16 808.843 65.3892 808.072 65.3892 807.121C65.3892 806.171 66.16 805.4 67.1109 805.4Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 799.442C68.0617 799.442 68.8326 800.213 68.8326 801.163C68.8326 802.114 68.0617 802.885 67.1109 802.885C66.16 802.885 65.3892 802.114 65.3892 801.163C65.3892 800.213 66.16 799.442 67.1109 799.442Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 793.428C68.0617 793.428 68.8326 794.199 68.8326 795.15C68.8326 796.101 68.0617 796.871 67.1109 796.871C66.16 796.871 65.3892 796.101 65.3892 795.15C65.3892 794.199 66.16 793.428 67.1109 793.428Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 787.47C68.0617 787.47 68.8326 788.241 68.8326 789.192C68.8326 790.143 68.0617 790.914 67.1109 790.914C66.16 790.914 65.3892 790.143 65.3892 789.192C65.3892 788.241 66.16 787.47 67.1109 787.47Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1021 781.522C68.0529 781.522 68.8238 782.293 68.8238 783.244C68.8238 784.195 68.0529 784.966 67.1021 784.966C66.1512 784.966 65.3804 784.195 65.3804 783.244C65.3804 782.293 66.1512 781.522 67.1021 781.522Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1021 775.564C68.0529 775.564 68.8238 776.335 68.8238 777.286C68.8238 778.237 68.0529 779.008 67.1021 779.008C66.1512 779.008 65.3804 778.237 65.3804 777.286C65.3804 776.335 66.1512 775.564 67.1021 775.564Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 769.567C68.0617 769.567 68.8326 770.338 68.8326 771.289C68.8326 772.24 68.0617 773.01 67.1109 773.01C66.16 773.01 65.3892 772.24 65.3892 771.289C65.3892 770.338 66.16 769.567 67.1109 769.567Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 763.609C68.0617 763.609 68.8326 764.38 68.8326 765.331C68.8326 766.282 68.0617 767.052 67.1109 767.052C66.16 767.052 65.3892 766.282 65.3892 765.331C65.3892 764.38 66.16 763.609 67.1109 763.609Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 757.645C68.0617 757.645 68.8326 758.416 68.8326 759.366C68.8326 760.317 68.0617 761.088 67.1109 761.088C66.16 761.088 65.3892 760.317 65.3892 759.366C65.3892 758.416 66.16 757.645 67.1109 757.645Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 751.687C68.0617 751.687 68.8326 752.457 68.8326 753.408C68.8326 754.359 68.0617 755.13 67.1109 755.13C66.16 755.13 65.3892 754.359 65.3892 753.408C65.3892 752.457 66.16 751.687 67.1109 751.687Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 745.69C68.0617 745.69 68.8326 746.46 68.8326 747.411C68.8326 748.362 68.0617 749.133 67.1109 749.133C66.16 749.133 65.3892 748.362 65.3892 747.411C65.3892 746.46 66.16 745.69 67.1109 745.69Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 739.731C68.0617 739.731 68.8326 740.502 68.8326 741.453C68.8326 742.404 68.0617 743.175 67.1109 743.175C66.16 743.175 65.3892 742.404 65.3892 741.453C65.3892 740.502 66.16 739.731 67.1109 739.731Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 880.021C73.1313 880.021 73.9021 880.792 73.9021 881.743C73.9021 882.694 73.1313 883.464 72.1804 883.464C71.2296 883.464 70.4587 882.694 70.4587 881.743C70.4587 880.792 71.2296 880.021 72.1804 880.021Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 874.064C73.1313 874.064 73.9021 874.835 73.9021 875.786C73.9021 876.737 73.1313 877.507 72.1804 877.507C71.2296 877.507 70.4587 876.737 70.4587 875.786C70.4587 874.835 71.2296 874.064 72.1804 874.064Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 868.051C73.1313 868.051 73.9021 868.822 73.9021 869.772C73.9021 870.723 73.1313 871.494 72.1804 871.494C71.2296 871.494 70.4587 870.723 70.4587 869.772C70.4587 868.822 71.2296 868.051 72.1804 868.051Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 862.093C73.1313 862.093 73.9021 862.863 73.9021 863.814C73.9021 864.765 73.1313 865.536 72.1804 865.536C71.2296 865.536 70.4587 864.765 70.4587 863.814C70.4587 862.863 71.2296 862.093 72.1804 862.093Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1717 856.143C73.1225 856.143 73.8934 856.914 73.8934 857.865C73.8934 858.816 73.1225 859.587 72.1717 859.587C71.2208 859.587 70.45 858.816 70.45 857.865C70.45 856.914 71.2208 856.143 72.1717 856.143Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1717 850.187C73.1225 850.187 73.8934 850.957 73.8934 851.908C73.8934 852.859 73.1225 853.63 72.1717 853.63C71.2208 853.63 70.45 852.859 70.45 851.908C70.45 850.957 71.2208 850.187 72.1717 850.187Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 844.188C73.1313 844.188 73.9021 844.959 73.9021 845.91C73.9021 846.861 73.1313 847.632 72.1804 847.632C71.2296 847.632 70.4587 846.861 70.4587 845.91C70.4587 844.959 71.2296 844.188 72.1804 844.188Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 838.23C73.1313 838.23 73.9021 839.001 73.9021 839.952C73.9021 840.903 73.1313 841.674 72.1804 841.674C71.2296 841.674 70.4587 840.903 70.4587 839.952C70.4587 839.001 71.2296 838.23 72.1804 838.23Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 832.266C73.1313 832.266 73.9021 833.037 73.9021 833.987C73.9021 834.938 73.1313 835.709 72.1804 835.709C71.2296 835.709 70.4587 834.938 70.4587 833.987C70.4587 833.037 71.2296 832.266 72.1804 832.266Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 826.309C73.1313 826.309 73.9021 827.08 73.9021 828.031C73.9021 828.982 73.1313 829.752 72.1804 829.752C71.2296 829.752 70.4587 828.982 70.4587 828.031C70.4587 827.08 71.2296 826.309 72.1804 826.309Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 820.311C73.1313 820.311 73.9021 821.082 73.9021 822.032C73.9021 822.983 73.1313 823.754 72.1804 823.754C71.2296 823.754 70.4587 822.983 70.4587 822.032C70.4587 821.082 71.2296 820.311 72.1804 820.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1804 814.353C73.1313 814.353 73.9021 815.124 73.9021 816.074C73.9021 817.025 73.1313 817.796 72.1804 817.796C71.2296 817.796 70.4587 817.025 70.4587 816.074C70.4587 815.124 71.2296 814.353 72.1804 814.353Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1856 808.384C73.1364 808.384 73.9073 809.155 73.9073 810.106C73.9073 811.057 73.1364 811.828 72.1856 811.828C71.2347 811.828 70.4639 811.057 70.4639 810.106C70.4639 809.155 71.2347 808.384 72.1856 808.384Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1856 802.426C73.1364 802.426 73.9073 803.197 73.9073 804.148C73.9073 805.099 73.1364 805.87 72.1856 805.87C71.2347 805.87 70.4639 805.099 70.4639 804.148C70.4639 803.197 71.2347 802.426 72.1856 802.426Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1856 796.413C73.1364 796.413 73.9073 797.184 73.9073 798.135C73.9073 799.085 73.1364 799.856 72.1856 799.856C71.2347 799.856 70.4639 799.085 70.4639 798.135C70.4639 797.184 71.2347 796.413 72.1856 796.413Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 790.455C73.1362 790.455 73.907 791.226 73.907 792.177C73.907 793.127 73.1362 793.898 72.1853 793.898C71.2345 793.898 70.4636 793.127 70.4636 792.177C70.4636 791.226 71.2345 790.455 72.1853 790.455Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1753 784.507C73.1262 784.507 73.897 785.278 73.897 786.229C73.897 787.18 73.1262 787.95 72.1753 787.95C71.2244 787.95 70.4536 787.18 70.4536 786.229C70.4536 785.278 71.2244 784.507 72.1753 784.507Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1753 778.549C73.1262 778.549 73.897 779.32 73.897 780.271C73.897 781.221 73.1262 781.992 72.1753 781.992C71.2244 781.992 70.4536 781.221 70.4536 780.271C70.4536 779.32 71.2244 778.549 72.1753 778.549Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 772.551C73.1362 772.551 73.907 773.321 73.907 774.272C73.907 775.223 73.1362 775.994 72.1853 775.994C71.2345 775.994 70.4636 775.223 70.4636 774.272C70.4636 773.321 71.2345 772.551 72.1853 772.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 766.592C73.1362 766.592 73.907 767.363 73.907 768.314C73.907 769.265 73.1362 770.036 72.1853 770.036C71.2345 770.036 70.4636 769.265 70.4636 768.314C70.4636 767.363 71.2345 766.592 72.1853 766.592Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 760.629C73.1362 760.629 73.907 761.4 73.907 762.351C73.907 763.302 73.1362 764.073 72.1853 764.073C71.2345 764.073 70.4636 763.302 70.4636 762.351C70.4636 761.4 71.2345 760.629 72.1853 760.629Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 754.671C73.1362 754.671 73.907 755.442 73.907 756.393C73.907 757.344 73.1362 758.115 72.1853 758.115C71.2345 758.115 70.4636 757.344 70.4636 756.393C70.4636 755.442 71.2345 754.671 72.1853 754.671Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 748.673C73.1362 748.673 73.907 749.444 73.907 750.395C73.907 751.345 73.1362 752.116 72.1853 752.116C71.2345 752.116 70.4636 751.345 70.4636 750.395C70.4636 749.444 71.2345 748.673 72.1853 748.673Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 742.715C73.1362 742.715 73.907 743.486 73.907 744.436C73.907 745.387 73.1362 746.158 72.1853 746.158C71.2345 746.158 70.4636 745.387 70.4636 744.436C70.4636 743.486 71.2345 742.715 72.1853 742.715Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 877.048C78.2485 877.048 79.0194 877.818 79.0194 878.769C79.0194 879.72 78.2485 880.491 77.2976 880.491C76.3468 880.491 75.5759 879.72 75.5759 878.769C75.5759 877.818 76.3468 877.048 77.2976 877.048Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 871.09C78.2485 871.09 79.0194 871.86 79.0194 872.811C79.0194 873.762 78.2485 874.533 77.2976 874.533C76.3468 874.533 75.5759 873.762 75.5759 872.811C75.5759 871.86 76.3468 871.09 77.2976 871.09Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 865.076C78.2485 865.076 79.0194 865.847 79.0194 866.798C79.0194 867.749 78.2485 868.519 77.2976 868.519C76.3468 868.519 75.5759 867.749 75.5759 866.798C75.5759 865.847 76.3468 865.076 77.2976 865.076Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 859.118C78.2485 859.118 79.0194 859.889 79.0194 860.84C79.0194 861.791 78.2485 862.561 77.2976 862.561C76.3468 862.561 75.5759 861.791 75.5759 860.84C75.5759 859.889 76.3468 859.118 77.2976 859.118Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2888 853.17C78.2397 853.17 79.0105 853.941 79.0105 854.892C79.0105 855.843 78.2397 856.613 77.2888 856.613C76.338 856.613 75.5671 855.843 75.5671 854.892C75.5671 853.941 76.338 853.17 77.2888 853.17Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2888 847.212C78.2397 847.212 79.0105 847.983 79.0105 848.934C79.0105 849.884 78.2397 850.655 77.2888 850.655C76.338 850.655 75.5671 849.884 75.5671 848.934C75.5671 847.983 76.338 847.212 77.2888 847.212Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 841.215C78.2485 841.215 79.0193 841.986 79.0193 842.937C79.0193 843.887 78.2485 844.658 77.2976 844.658C76.3468 844.658 75.5759 843.887 75.5759 842.937C75.5759 841.986 76.3468 841.215 77.2976 841.215Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 835.257C78.2485 835.257 79.0193 836.028 79.0193 836.979C79.0193 837.929 78.2485 838.7 77.2976 838.7C76.3468 838.7 75.5759 837.929 75.5759 836.979C75.5759 836.028 76.3468 835.257 77.2976 835.257Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 829.292C78.2485 829.292 79.0193 830.063 79.0193 831.014C79.0193 831.965 78.2485 832.736 77.2976 832.736C76.3468 832.736 75.5759 831.965 75.5759 831.014C75.5759 830.063 76.3468 829.292 77.2976 829.292Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 823.334C78.2485 823.334 79.0194 824.105 79.0194 825.056C79.0194 826.007 78.2485 826.778 77.2976 826.778C76.3468 826.778 75.5759 826.007 75.5759 825.056C75.5759 824.105 76.3468 823.334 77.2976 823.334Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 817.337C78.2485 817.337 79.0194 818.108 79.0194 819.059C79.0194 820.01 78.2485 820.781 77.2976 820.781C76.3468 820.781 75.5759 820.01 75.5759 819.059C75.5759 818.108 76.3468 817.337 77.2976 817.337Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.2976 811.379C78.2485 811.379 79.0194 812.15 79.0194 813.101C79.0194 814.052 78.2485 814.823 77.2976 814.823C76.3468 814.823 75.5759 814.052 75.5759 813.101C75.5759 812.15 76.3468 811.379 77.2976 811.379Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 805.411C78.2536 805.411 79.0245 806.182 79.0245 807.133C79.0245 808.084 78.2536 808.855 77.3028 808.855C76.3519 808.855 75.5811 808.084 75.5811 807.133C75.5811 806.182 76.3519 805.411 77.3028 805.411Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 799.453C78.2536 799.453 79.0245 800.224 79.0245 801.175C79.0245 802.126 78.2536 802.896 77.3028 802.896C76.3519 802.896 75.5811 802.126 75.5811 801.175C75.5811 800.224 76.3519 799.453 77.3028 799.453Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 793.44C78.2536 793.44 79.0245 794.21 79.0245 795.161C79.0245 796.112 78.2536 796.883 77.3028 796.883C76.3519 796.883 75.5811 796.112 75.5811 795.161C75.5811 794.21 76.3519 793.44 77.3028 793.44Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 787.481C78.2536 787.481 79.0245 788.252 79.0245 789.203C79.0245 790.154 78.2536 790.925 77.3028 790.925C76.3519 790.925 75.5811 790.154 75.5811 789.203C75.5811 788.252 76.3519 787.481 77.3028 787.481Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.294 781.534C78.2448 781.534 79.0157 782.304 79.0157 783.255C79.0157 784.206 78.2448 784.977 77.294 784.977C76.3431 784.977 75.5723 784.206 75.5723 783.255C75.5723 782.304 76.3431 781.534 77.294 781.534Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.294 775.575C78.2448 775.575 79.0157 776.346 79.0157 777.297C79.0157 778.248 78.2448 779.019 77.294 779.019C76.3431 779.019 75.5723 778.248 75.5723 777.297C75.5723 776.346 76.3431 775.575 77.294 775.575Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 769.577C78.2536 769.577 79.0245 770.348 79.0245 771.299C79.0245 772.25 78.2536 773.02 77.3028 773.02C76.3519 773.02 75.5811 772.25 75.5811 771.299C75.5811 770.348 76.3519 769.577 77.3028 769.577Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 763.619C78.2536 763.619 79.0245 764.39 79.0245 765.341C79.0245 766.292 78.2536 767.062 77.3028 767.062C76.3519 767.062 75.5811 766.292 75.5811 765.341C75.5811 764.39 76.3519 763.619 77.3028 763.619Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 757.656C78.2536 757.656 79.0245 758.427 79.0245 759.378C79.0245 760.329 78.2536 761.099 77.3028 761.099C76.3519 761.099 75.5811 760.329 75.5811 759.378C75.5811 758.427 76.3519 757.656 77.3028 757.656Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 751.698C78.2536 751.698 79.0245 752.469 79.0245 753.42C79.0245 754.37 78.2536 755.141 77.3028 755.141C76.3519 755.141 75.5811 754.37 75.5811 753.42C75.5811 752.469 76.3519 751.698 77.3028 751.698Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 745.699C78.2536 745.699 79.0245 746.47 79.0245 747.421C79.0245 748.372 78.2536 749.143 77.3028 749.143C76.3519 749.143 75.5811 748.372 75.5811 747.421C75.5811 746.47 76.3519 745.699 77.3028 745.699Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3028 739.741C78.2536 739.741 79.0245 740.512 79.0245 741.463C79.0245 742.414 78.2536 743.185 77.3028 743.185C76.3519 743.185 75.5811 742.414 75.5811 741.463C75.5811 740.512 76.3519 739.741 77.3028 739.741Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 730.794C63.0573 730.794 63.8281 731.565 63.8281 732.515C63.8281 733.466 63.0573 734.237 62.1065 734.237C61.1556 734.237 60.3848 733.466 60.3848 732.515C60.3848 731.565 61.1556 730.794 62.1065 730.794Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 724.837C63.0573 724.837 63.8281 725.608 63.8281 726.558C63.8281 727.509 63.0573 728.28 62.1065 728.28C61.1556 728.28 60.3848 727.509 60.3848 726.558C60.3848 725.608 61.1556 724.837 62.1065 724.837Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 718.823C63.0573 718.823 63.8281 719.594 63.8281 720.545C63.8281 721.496 63.0573 722.267 62.1065 722.267C61.1556 722.267 60.3848 721.496 60.3848 720.545C60.3848 719.594 61.1556 718.823 62.1065 718.823Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 712.865C63.0573 712.865 63.8282 713.636 63.8282 714.587C63.8282 715.538 63.0573 716.309 62.1065 716.309C61.1556 716.309 60.3848 715.538 60.3848 714.587C60.3848 713.636 61.1556 712.865 62.1065 712.865Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.0964 706.916C63.0473 706.916 63.8181 707.687 63.8181 708.638C63.8181 709.589 63.0473 710.359 62.0964 710.359C61.1456 710.359 60.3748 709.589 60.3748 708.638C60.3748 707.687 61.1456 706.916 62.0964 706.916Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.0964 700.959C63.0473 700.959 63.8181 701.73 63.8181 702.681C63.8181 703.632 63.0473 704.402 62.0964 704.402C61.1456 704.402 60.3748 703.632 60.3748 702.681C60.3748 701.73 61.1456 700.959 62.0964 700.959Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 694.961C63.0573 694.961 63.8281 695.732 63.8281 696.683C63.8281 697.633 63.0573 698.404 62.1065 698.404C61.1556 698.404 60.3848 697.633 60.3848 696.683C60.3848 695.732 61.1556 694.961 62.1065 694.961Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 689.003C63.0573 689.003 63.8281 689.774 63.8281 690.724C63.8281 691.675 63.0573 692.446 62.1065 692.446C61.1556 692.446 60.3848 691.675 60.3848 690.724C60.3848 689.774 61.1556 689.003 62.1065 689.003Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 683.039C63.0573 683.039 63.8281 683.809 63.8281 684.76C63.8281 685.711 63.0573 686.482 62.1065 686.482C61.1556 686.482 60.3848 685.711 60.3848 684.76C60.3848 683.809 61.1556 683.039 62.1065 683.039Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 677.082C63.0573 677.082 63.8281 677.852 63.8281 678.803C63.8281 679.754 63.0573 680.525 62.1065 680.525C61.1556 680.525 60.3848 679.754 60.3848 678.803C60.3848 677.852 61.1556 677.082 62.1065 677.082Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 671.083C63.0573 671.083 63.8281 671.854 63.8281 672.805C63.8281 673.756 63.0573 674.527 62.1065 674.527C61.1556 674.527 60.3848 673.756 60.3848 672.805C60.3848 671.854 61.1556 671.083 62.1065 671.083Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1065 665.125C63.0573 665.125 63.8281 665.896 63.8281 666.847C63.8281 667.798 63.0573 668.569 62.1065 668.569C61.1556 668.569 60.3848 667.798 60.3848 666.847C60.3848 665.896 61.1556 665.125 62.1065 665.125Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 659.157C63.0612 659.157 63.8321 659.928 63.8321 660.879C63.8321 661.83 63.0612 662.6 62.1104 662.6C61.1595 662.6 60.3887 661.83 60.3887 660.879C60.3887 659.928 61.1595 659.157 62.1104 659.157Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 653.199C63.0613 653.199 63.8321 653.97 63.8321 654.921C63.8321 655.871 63.0613 656.642 62.1104 656.642C61.1595 656.642 60.3887 655.871 60.3887 654.921C60.3887 653.97 61.1595 653.199 62.1104 653.199Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 647.186C63.0612 647.186 63.8321 647.956 63.8321 648.907C63.8321 649.858 63.0612 650.629 62.1104 650.629C61.1595 650.629 60.3887 649.858 60.3887 648.907C60.3887 647.956 61.1595 647.186 62.1104 647.186Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 641.228C63.0612 641.228 63.8321 641.998 63.8321 642.949C63.8321 643.9 63.0612 644.671 62.1104 644.671C61.1595 644.671 60.3887 643.9 60.3887 642.949C60.3887 641.998 61.1595 641.228 62.1104 641.228Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1016 635.279C63.0524 635.279 63.8233 636.05 63.8233 637.001C63.8233 637.952 63.0524 638.723 62.1016 638.723C61.1507 638.723 60.3799 637.952 60.3799 637.001C60.3799 636.05 61.1507 635.279 62.1016 635.279Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1016 629.321C63.0524 629.321 63.8233 630.092 63.8233 631.043C63.8233 631.994 63.0524 632.765 62.1016 632.765C61.1507 632.765 60.3799 631.994 60.3799 631.043C60.3799 630.092 61.1507 629.321 62.1016 629.321Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 623.323C63.0612 623.323 63.8321 624.094 63.8321 625.045C63.8321 625.996 63.0612 626.767 62.1104 626.767C61.1595 626.767 60.3887 625.996 60.3887 625.045C60.3887 624.094 61.1595 623.323 62.1104 623.323Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 617.365C63.0612 617.365 63.8321 618.136 63.8321 619.087C63.8321 620.038 63.0612 620.808 62.1104 620.808C61.1595 620.808 60.3887 620.038 60.3887 619.087C60.3887 618.136 61.1595 617.365 62.1104 617.365Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 611.402C63.0612 611.402 63.8321 612.173 63.8321 613.124C63.8321 614.074 63.0612 614.845 62.1104 614.845C61.1595 614.845 60.3887 614.074 60.3887 613.124C60.3887 612.173 61.1595 611.402 62.1104 611.402Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 605.444C63.0612 605.444 63.8321 606.215 63.8321 607.166C63.8321 608.116 63.0612 608.887 62.1104 608.887C61.1595 608.887 60.3887 608.116 60.3887 607.166C60.3887 606.215 61.1595 605.444 62.1104 605.444Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 599.446C63.0613 599.446 63.8321 600.216 63.8321 601.167C63.8321 602.118 63.0613 602.889 62.1104 602.889C61.1595 602.889 60.3887 602.118 60.3887 601.167C60.3887 600.216 61.1595 599.446 62.1104 599.446Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.1104 593.488C63.0613 593.488 63.8321 594.258 63.8321 595.209C63.8321 596.16 63.0613 596.931 62.1104 596.931C61.1595 596.931 60.3887 596.16 60.3887 595.209C60.3887 594.258 61.1595 593.488 62.1104 593.488Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 733.768C68.0717 733.768 68.8426 734.539 68.8426 735.49C68.8426 736.441 68.0717 737.212 67.1209 737.212C66.17 737.212 65.3992 736.441 65.3992 735.49C65.3992 734.539 66.17 733.768 67.1209 733.768Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 727.81C68.0717 727.81 68.8426 728.581 68.8426 729.532C68.8426 730.483 68.0717 731.254 67.1209 731.254C66.17 731.254 65.3992 730.483 65.3992 729.532C65.3992 728.581 66.17 727.81 67.1209 727.81Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 721.797C68.0717 721.797 68.8426 722.567 68.8426 723.518C68.8426 724.469 68.0717 725.24 67.1209 725.24C66.17 725.24 65.3992 724.469 65.3992 723.518C65.3992 722.567 66.17 721.797 67.1209 721.797Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 715.839C68.0717 715.839 68.8426 716.609 68.8426 717.56C68.8426 718.511 68.0717 719.282 67.1209 719.282C66.17 719.282 65.3992 718.511 65.3992 717.56C65.3992 716.609 66.17 715.839 67.1209 715.839Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 709.891C68.0617 709.891 68.8326 710.661 68.8326 711.612C68.8326 712.563 68.0617 713.334 67.1109 713.334C66.16 713.334 65.3892 712.563 65.3892 711.612C65.3892 710.661 66.16 709.891 67.1109 709.891Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1109 703.933C68.0617 703.933 68.8326 704.704 68.8326 705.654C68.8326 706.605 68.0617 707.376 67.1109 707.376C66.16 707.376 65.3892 706.605 65.3892 705.654C65.3892 704.704 66.16 703.933 67.1109 703.933Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 697.934C68.0717 697.934 68.8426 698.705 68.8426 699.656C68.8426 700.607 68.0717 701.378 67.1209 701.378C66.17 701.378 65.3992 700.607 65.3992 699.656C65.3992 698.705 66.17 697.934 67.1209 697.934Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 691.976C68.0717 691.976 68.8426 692.747 68.8426 693.698C68.8426 694.649 68.0717 695.42 67.1209 695.42C66.17 695.42 65.3992 694.649 65.3992 693.698C65.3992 692.747 66.17 691.976 67.1209 691.976Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 686.013C68.0717 686.013 68.8426 686.784 68.8426 687.735C68.8426 688.686 68.0717 689.457 67.1209 689.457C66.17 689.457 65.3992 688.686 65.3992 687.735C65.3992 686.784 66.17 686.013 67.1209 686.013Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 680.055C68.0717 680.055 68.8426 680.826 68.8426 681.777C68.8426 682.728 68.0717 683.498 67.1209 683.498C66.17 683.498 65.3992 682.728 65.3992 681.777C65.3992 680.826 66.17 680.055 67.1209 680.055Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 674.057C68.0717 674.057 68.8426 674.828 68.8426 675.778C68.8426 676.729 68.0717 677.5 67.1209 677.5C66.17 677.5 65.3992 676.729 65.3992 675.778C65.3992 674.828 66.17 674.057 67.1209 674.057Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1209 668.099C68.0717 668.099 68.8426 668.869 68.8426 669.82C68.8426 670.771 68.0717 671.542 67.1209 671.542C66.17 671.542 65.3992 670.771 65.3992 669.82C65.3992 668.869 66.17 668.099 67.1209 668.099Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 662.131C68.0754 662.131 68.8462 662.901 68.8462 663.852C68.8462 664.803 68.0754 665.574 67.1245 665.574C66.1737 665.574 65.4028 664.803 65.4028 663.852C65.4028 662.901 66.1737 662.131 67.1245 662.131Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 656.172C68.0754 656.172 68.8462 656.943 68.8462 657.894C68.8462 658.845 68.0754 659.616 67.1245 659.616C66.1737 659.616 65.4028 658.845 65.4028 657.894C65.4028 656.943 66.1737 656.172 67.1245 656.172Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 650.159C68.0754 650.159 68.8462 650.93 68.8462 651.881C68.8462 652.832 68.0754 653.602 67.1245 653.602C66.1737 653.602 65.4028 652.832 65.4028 651.881C65.4028 650.93 66.1737 650.159 67.1245 650.159Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 644.201C68.0754 644.201 68.8462 644.972 68.8462 645.923C68.8462 646.873 68.0754 647.644 67.1245 647.644C66.1737 647.644 65.4028 646.873 65.4028 645.923C65.4028 644.972 66.1737 644.201 67.1245 644.201Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1157 638.253C68.0666 638.253 68.8375 639.024 68.8375 639.975C68.8375 640.925 68.0666 641.696 67.1157 641.696C66.1649 641.696 65.394 640.925 65.394 639.975C65.394 639.024 66.1649 638.253 67.1157 638.253Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1157 632.295C68.0666 632.295 68.8375 633.066 68.8375 634.016C68.8375 634.967 68.0666 635.738 67.1157 635.738C66.1649 635.738 65.394 634.967 65.394 634.016C65.394 633.066 66.1649 632.295 67.1157 632.295Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 626.298C68.0754 626.298 68.8462 627.068 68.8462 628.019C68.8462 628.97 68.0754 629.741 67.1245 629.741C66.1737 629.741 65.4028 628.97 65.4028 628.019C65.4028 627.068 66.1737 626.298 67.1245 626.298Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 620.34C68.0754 620.34 68.8462 621.11 68.8462 622.061C68.8462 623.012 68.0754 623.783 67.1245 623.783C66.1737 623.783 65.4028 623.012 65.4028 622.061C65.4028 621.11 66.1737 620.34 67.1245 620.34Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 614.375C68.0754 614.375 68.8462 615.146 68.8462 616.097C68.8462 617.048 68.0754 617.819 67.1245 617.819C66.1737 617.819 65.4028 617.048 65.4028 616.097C65.4028 615.146 66.1737 614.375 67.1245 614.375Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 608.417C68.0754 608.417 68.8462 609.188 68.8462 610.139C68.8462 611.09 68.0754 611.861 67.1245 611.861C66.1737 611.861 65.4028 611.09 65.4028 610.139C65.4028 609.188 66.1737 608.417 67.1245 608.417Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 602.42C68.0754 602.42 68.8462 603.191 68.8462 604.142C68.8462 605.093 68.0754 605.864 67.1245 605.864C66.1737 605.864 65.4028 605.093 65.4028 604.142C65.4028 603.191 66.1737 602.42 67.1245 602.42Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.1245 596.462C68.0754 596.462 68.8462 597.233 68.8462 598.184C68.8462 599.135 68.0754 599.906 67.1245 599.906C66.1737 599.906 65.4028 599.135 65.4028 598.184C65.4028 597.233 66.1737 596.462 67.1245 596.462Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 730.784C73.1992 730.784 73.97 731.554 73.97 732.505C73.97 733.456 73.1992 734.227 72.2483 734.227C71.2974 734.227 70.5266 733.456 70.5266 732.505C70.5266 731.554 71.2974 730.784 72.2483 730.784Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 724.826C73.1992 724.826 73.97 725.596 73.97 726.547C73.97 727.498 73.1992 728.269 72.2483 728.269C71.2974 728.269 70.5266 727.498 70.5266 726.547C70.5266 725.596 71.2974 724.826 72.2483 724.826Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 718.812C73.1992 718.812 73.97 719.583 73.97 720.534C73.97 721.484 73.1992 722.255 72.2483 722.255C71.2974 722.255 70.5266 721.485 70.5266 720.534C70.5266 719.583 71.2974 718.812 72.2483 718.812Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 712.854C73.1992 712.854 73.97 713.625 73.97 714.576C73.97 715.527 73.1992 716.297 72.2483 716.297C71.2974 716.297 70.5266 715.527 70.5266 714.576C70.5266 713.625 71.2974 712.854 72.2483 712.854Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2395 706.906C73.1904 706.906 73.9612 707.677 73.9612 708.628C73.9612 709.579 73.1904 710.349 72.2395 710.349C71.2887 710.349 70.5178 709.579 70.5178 708.628C70.5178 707.677 71.2887 706.906 72.2395 706.906Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2395 700.948C73.1904 700.948 73.9612 701.719 73.9612 702.67C73.9612 703.62 73.1904 704.391 72.2395 704.391C71.2887 704.391 70.5178 703.62 70.5178 702.67C70.5178 701.719 71.2887 700.948 72.2395 700.948Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 694.951C73.1992 694.951 73.97 695.722 73.97 696.672C73.97 697.623 73.1992 698.394 72.2483 698.394C71.2974 698.394 70.5266 697.623 70.5266 696.672C70.5266 695.722 71.2974 694.951 72.2483 694.951Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 688.993C73.1992 688.993 73.97 689.764 73.97 690.714C73.97 691.665 73.1992 692.436 72.2483 692.436C71.2974 692.436 70.5266 691.665 70.5266 690.714C70.5266 689.764 71.2974 688.993 72.2483 688.993Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 683.028C73.1992 683.028 73.97 683.799 73.97 684.75C73.97 685.701 73.1992 686.472 72.2483 686.472C71.2974 686.472 70.5266 685.701 70.5266 684.75C70.5266 683.799 71.2974 683.028 72.2483 683.028Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 677.07C73.1992 677.07 73.97 677.841 73.97 678.792C73.97 679.743 73.1992 680.514 72.2483 680.514C71.2974 680.514 70.5266 679.743 70.5266 678.792C70.5266 677.841 71.2974 677.07 72.2483 677.07Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 671.073C73.1992 671.073 73.97 671.844 73.97 672.795C73.97 673.746 73.1992 674.517 72.2483 674.517C71.2974 674.517 70.5266 673.746 70.5266 672.795C70.5266 671.844 71.2974 671.073 72.2483 671.073Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2483 665.115C73.1992 665.115 73.97 665.886 73.97 666.837C73.97 667.788 73.1992 668.559 72.2483 668.559C71.2974 668.559 70.5266 667.788 70.5266 666.837C70.5266 665.886 71.2974 665.115 72.2483 665.115Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 659.146C73.2041 659.146 73.9749 659.917 73.9749 660.868C73.9749 661.818 73.2041 662.589 72.2532 662.589C71.3023 662.589 70.5315 661.818 70.5315 660.868C70.5315 659.917 71.3023 659.146 72.2532 659.146Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 653.189C73.2041 653.189 73.9749 653.96 73.9749 654.911C73.9749 655.862 73.2041 656.632 72.2532 656.632C71.3023 656.632 70.5315 655.862 70.5315 654.911C70.5315 653.96 71.3023 653.189 72.2532 653.189Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 647.176C73.2041 647.176 73.9749 647.946 73.9749 648.897C73.9749 649.848 73.2041 650.619 72.2532 650.619C71.3023 650.619 70.5315 649.848 70.5315 648.897C70.5315 647.946 71.3023 647.176 72.2532 647.176Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 641.218C73.2041 641.218 73.9749 641.988 73.9749 642.939C73.9749 643.89 73.2041 644.661 72.2532 644.661C71.3023 644.661 70.5315 643.89 70.5315 642.939C70.5315 641.988 71.3023 641.218 72.2532 641.218Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2444 635.268C73.1953 635.268 73.9661 636.039 73.9661 636.99C73.9661 637.941 73.1953 638.712 72.2444 638.712C71.2935 638.712 70.5227 637.941 70.5227 636.99C70.5227 636.039 71.2935 635.268 72.2444 635.268Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2444 629.311C73.1953 629.311 73.9661 630.082 73.9661 631.033C73.9661 631.984 73.1953 632.755 72.2444 632.755C71.2935 632.755 70.5227 631.984 70.5227 631.033C70.5227 630.082 71.2935 629.311 72.2444 629.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 623.313C73.2041 623.313 73.9749 624.084 73.9749 625.035C73.9749 625.986 73.2041 626.756 72.2532 626.756C71.3023 626.756 70.5315 625.986 70.5315 625.035C70.5315 624.084 71.3023 623.313 72.2532 623.313Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 617.355C73.2041 617.355 73.9749 618.126 73.9749 619.077C73.9749 620.027 73.2041 620.798 72.2532 620.798C71.3023 620.798 70.5315 620.027 70.5315 619.077C70.5315 618.126 71.3023 617.355 72.2532 617.355Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 611.391C73.2041 611.391 73.9749 612.161 73.9749 613.112C73.9749 614.063 73.2041 614.834 72.2532 614.834C71.3023 614.834 70.5315 614.063 70.5315 613.112C70.5315 612.161 71.3023 611.391 72.2532 611.391Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 605.434C73.2041 605.434 73.9749 606.205 73.9749 607.156C73.9749 608.106 73.2041 608.877 72.2532 608.877C71.3023 608.877 70.5315 608.106 70.5315 607.156C70.5315 606.205 71.3023 605.434 72.2532 605.434Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 599.435C73.2041 599.435 73.9749 600.206 73.9749 601.157C73.9749 602.108 73.2041 602.879 72.2532 602.879C71.3023 602.879 70.5315 602.108 70.5315 601.157C70.5315 600.206 71.3023 599.435 72.2532 599.435Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.2532 593.477C73.2041 593.477 73.9749 594.248 73.9749 595.199C73.9749 596.15 73.2041 596.921 72.2532 596.921C71.3023 596.921 70.5315 596.15 70.5315 595.199C70.5315 594.248 71.3023 593.477 72.2532 593.477Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.323 733.768C78.2739 733.768 79.0447 734.539 79.0447 735.49C79.0447 736.441 78.2739 737.212 77.323 737.212C76.3721 737.212 75.6013 736.441 75.6013 735.49C75.6013 734.539 76.3721 733.768 77.323 733.768Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.323 727.81C78.2739 727.81 79.0447 728.581 79.0447 729.532C79.0447 730.483 78.2739 731.254 77.323 731.254C76.3721 731.254 75.6013 730.483 75.6013 729.532C75.6013 728.581 76.3721 727.81 77.323 727.81Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.323 721.797C78.2739 721.797 79.0447 722.567 79.0447 723.518C79.0447 724.469 78.2739 725.24 77.323 725.24C76.3721 725.24 75.6013 724.469 75.6013 723.518C75.6013 722.567 76.3721 721.797 77.323 721.797Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.323 715.839C78.2739 715.839 79.0447 716.609 79.0447 717.56C79.0447 718.511 78.2739 719.282 77.323 719.282C76.3721 719.282 75.6013 718.511 75.6013 717.56C75.6013 716.609 76.3721 715.839 77.323 715.839Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3128 709.891C78.2636 709.891 79.0345 710.661 79.0345 711.612C79.0345 712.563 78.2636 713.334 77.3128 713.334C76.3619 713.334 75.5911 712.563 75.5911 711.612C75.5911 710.661 76.3619 709.891 77.3128 709.891Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3128 703.933C78.2636 703.933 79.0345 704.704 79.0345 705.654C79.0345 706.605 78.2636 707.376 77.3128 707.376C76.3619 707.376 75.5911 706.605 75.5911 705.654C75.5911 704.704 76.3619 703.933 77.3128 703.933Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 697.934C78.2736 697.934 79.0445 698.705 79.0445 699.656C79.0445 700.607 78.2736 701.378 77.3228 701.378C76.3719 701.378 75.6011 700.607 75.6011 699.656C75.6011 698.705 76.3719 697.934 77.3228 697.934Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 691.976C78.2736 691.976 79.0445 692.747 79.0445 693.698C79.0445 694.649 78.2736 695.42 77.3228 695.42C76.3719 695.42 75.6011 694.649 75.6011 693.698C75.6011 692.747 76.3719 691.976 77.3228 691.976Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 686.013C78.2736 686.013 79.0445 686.784 79.0445 687.735C79.0445 688.686 78.2736 689.457 77.3228 689.457C76.3719 689.457 75.6011 688.686 75.6011 687.735C75.6011 686.784 76.3719 686.013 77.3228 686.013Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 680.055C78.2736 680.055 79.0445 680.826 79.0445 681.777C79.0445 682.728 78.2736 683.498 77.3228 683.498C76.3719 683.498 75.6011 682.728 75.6011 681.777C75.6011 680.826 76.3719 680.055 77.3228 680.055Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 674.057C78.2736 674.057 79.0445 674.828 79.0445 675.778C79.0445 676.729 78.2736 677.5 77.3228 677.5C76.3719 677.5 75.6011 676.729 75.6011 675.778C75.6011 674.828 76.3719 674.057 77.3228 674.057Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3228 668.099C78.2736 668.099 79.0445 668.869 79.0445 669.82C79.0445 670.771 78.2736 671.542 77.3228 671.542C76.3719 671.542 75.6011 670.771 75.6011 669.82C75.6011 668.869 76.3719 668.099 77.3228 668.099Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 662.131C78.2775 662.131 79.0484 662.901 79.0484 663.852C79.0484 664.803 78.2775 665.574 77.3267 665.574C76.3758 665.574 75.605 664.803 75.605 663.852C75.605 662.901 76.3758 662.131 77.3267 662.131Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 656.172C78.2775 656.172 79.0484 656.943 79.0484 657.894C79.0484 658.845 78.2775 659.616 77.3267 659.616C76.3758 659.616 75.605 658.845 75.605 657.894C75.605 656.943 76.3758 656.172 77.3267 656.172Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 650.159C78.2775 650.159 79.0484 650.93 79.0484 651.881C79.0484 652.832 78.2775 653.602 77.3267 653.602C76.3758 653.602 75.605 652.832 75.605 651.881C75.605 650.93 76.3758 650.159 77.3267 650.159Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 644.201C78.2775 644.201 79.0484 644.972 79.0484 645.923C79.0484 646.873 78.2775 647.644 77.3267 647.644C76.3758 647.644 75.605 646.873 75.605 645.923C75.605 644.972 76.3758 644.201 77.3267 644.201Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3179 638.253C78.2688 638.253 79.0396 639.024 79.0396 639.975C79.0396 640.925 78.2688 641.696 77.3179 641.696C76.367 641.696 75.5962 640.925 75.5962 639.975C75.5962 639.024 76.367 638.253 77.3179 638.253Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3179 632.295C78.2688 632.295 79.0396 633.066 79.0396 634.016C79.0396 634.967 78.2688 635.738 77.3179 635.738C76.367 635.738 75.5962 634.967 75.5962 634.016C75.5962 633.066 76.367 632.295 77.3179 632.295Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 626.298C78.2775 626.298 79.0484 627.068 79.0484 628.019C79.0484 628.97 78.2775 629.741 77.3267 629.741C76.3758 629.741 75.605 628.97 75.605 628.019C75.605 627.068 76.3758 626.298 77.3267 626.298Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 620.34C78.2775 620.34 79.0484 621.11 79.0484 622.061C79.0484 623.012 78.2775 623.783 77.3267 623.783C76.3758 623.783 75.605 623.012 75.605 622.061C75.605 621.11 76.3758 620.34 77.3267 620.34Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 614.375C78.2775 614.375 79.0484 615.146 79.0484 616.097C79.0484 617.048 78.2775 617.819 77.3267 617.819C76.3758 617.819 75.605 617.048 75.605 616.097C75.605 615.146 76.3758 614.375 77.3267 614.375Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 608.417C78.2775 608.417 79.0484 609.188 79.0484 610.139C79.0484 611.09 78.2775 611.861 77.3267 611.861C76.3758 611.861 75.605 611.09 75.605 610.139C75.605 609.188 76.3758 608.417 77.3267 608.417Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 602.42C78.2775 602.42 79.0484 603.191 79.0484 604.142C79.0484 605.093 78.2775 605.864 77.3267 605.864C76.3758 605.864 75.605 605.093 75.605 604.142C75.605 603.191 76.3758 602.42 77.3267 602.42Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.3267 596.462C78.2775 596.462 79.0484 597.233 79.0484 598.184C79.0484 599.135 78.2775 599.906 77.3267 599.906C76.3758 599.906 75.605 599.135 75.605 598.184C75.605 597.233 76.3758 596.462 77.3267 596.462Z"
              fill="#B8B7B7"
            />
            <path
              d="M61.9834 736.747C62.9343 736.747 63.7051 737.518 63.7051 738.468C63.7051 739.419 62.9343 740.19 61.9834 740.19C61.0326 740.19 60.2617 739.419 60.2617 738.468C60.2617 737.518 61.0326 736.747 61.9834 736.747Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.1853 736.758C73.1362 736.758 73.907 737.529 73.907 738.48C73.907 739.43 73.1362 740.201 72.1853 740.201C71.2345 740.201 70.4636 739.43 70.4636 738.48C70.4636 737.529 71.2345 736.758 72.1853 736.758Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 593.808C63.2753 593.808 64.0462 594.579 64.0462 595.529C64.0462 596.48 63.2753 597.251 62.3245 597.251C61.3736 597.251 60.6028 596.48 60.6028 595.529C60.6028 594.579 61.3736 593.808 62.3245 593.808Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 587.851C63.2753 587.851 64.0462 588.622 64.0462 589.573C64.0462 590.523 63.2753 591.294 62.3245 591.294C61.3736 591.294 60.6028 590.523 60.6028 589.573C60.6028 588.622 61.3736 587.851 62.3245 587.851Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 581.837C63.2753 581.837 64.0462 582.608 64.0462 583.559C64.0462 584.51 63.2753 585.281 62.3245 585.281C61.3736 585.281 60.6028 584.51 60.6028 583.559C60.6028 582.608 61.3736 581.837 62.3245 581.837Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 575.879C63.2753 575.879 64.0462 576.65 64.0462 577.601C64.0462 578.552 63.2753 579.323 62.3245 579.323C61.3736 579.323 60.6028 578.552 60.6028 577.601C60.6028 576.65 61.3736 575.879 62.3245 575.879Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3155 569.93C63.2663 569.93 64.0372 570.701 64.0372 571.652C64.0372 572.603 63.2663 573.374 62.3155 573.374C61.3646 573.374 60.5937 572.603 60.5937 571.652C60.5937 570.701 61.3646 569.93 62.3155 569.93Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3155 563.973C63.2663 563.973 64.0372 564.744 64.0372 565.695C64.0372 566.646 63.2663 567.417 62.3155 567.417C61.3646 567.417 60.5937 566.646 60.5937 565.695C60.5937 564.744 61.3646 563.973 62.3155 563.973Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 557.975C63.2753 557.975 64.0462 558.746 64.0462 559.697C64.0462 560.648 63.2753 561.418 62.3245 561.418C61.3736 561.418 60.6028 560.648 60.6028 559.697C60.6028 558.746 61.3736 557.975 62.3245 557.975Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 552.017C63.2753 552.017 64.0462 552.788 64.0462 553.739C64.0462 554.69 63.2753 555.46 62.3245 555.46C61.3736 555.46 60.6028 554.69 60.6028 553.739C60.6028 552.788 61.3736 552.017 62.3245 552.017Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 546.053C63.2753 546.053 64.0462 546.823 64.0462 547.774C64.0462 548.725 63.2753 549.496 62.3245 549.496C61.3736 549.496 60.6028 548.725 60.6028 547.774C60.6028 546.823 61.3736 546.053 62.3245 546.053Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 540.096C63.2753 540.096 64.0462 540.867 64.0462 541.817C64.0462 542.768 63.2753 543.539 62.3245 543.539C61.3736 543.539 60.6028 542.768 60.6028 541.817C60.6028 540.867 61.3736 540.096 62.3245 540.096Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 534.098C63.2753 534.098 64.0462 534.868 64.0462 535.819C64.0462 536.77 63.2753 537.541 62.3245 537.541C61.3736 537.541 60.6028 536.77 60.6028 535.819C60.6028 534.868 61.3736 534.098 62.3245 534.098Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3245 528.139C63.2753 528.139 64.0462 528.91 64.0462 529.861C64.0462 530.812 63.2753 531.583 62.3245 531.583C61.3736 531.583 60.6028 530.812 60.6028 529.861C60.6028 528.91 61.3736 528.139 62.3245 528.139Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 522.171C63.2802 522.171 64.0511 522.942 64.0511 523.893C64.0511 524.844 63.2802 525.615 62.3294 525.615C61.3785 525.615 60.6077 524.844 60.6077 523.893C60.6077 522.942 61.3785 522.171 62.3294 522.171Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 516.213C63.2802 516.213 64.0511 516.984 64.0511 517.935C64.0511 518.886 63.2802 519.657 62.3294 519.657C61.3785 519.657 60.6077 518.886 60.6077 517.935C60.6077 516.984 61.3785 516.213 62.3294 516.213Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 510.2C63.2802 510.2 64.0511 510.97 64.0511 511.921C64.0511 512.872 63.2802 513.643 62.3294 513.643C61.3785 513.643 60.6077 512.872 60.6077 511.921C60.6077 510.97 61.3785 510.2 62.3294 510.2Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 504.242C63.2802 504.242 64.0511 505.012 64.0511 505.963C64.0511 506.914 63.2802 507.685 62.3294 507.685C61.3785 507.685 60.6077 506.914 60.6077 505.963C60.6077 505.012 61.3785 504.242 62.3294 504.242Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3194 498.294C63.2702 498.294 64.041 499.065 64.041 500.015C64.041 500.966 63.2702 501.737 62.3194 501.737C61.3685 501.737 60.5977 500.966 60.5977 500.015C60.5977 499.065 61.3685 498.294 62.3194 498.294Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3194 492.336C63.2702 492.336 64.041 493.107 64.041 494.057C64.041 495.008 63.2702 495.779 62.3194 495.779C61.3685 495.779 60.5977 495.008 60.5977 494.057C60.5977 493.107 61.3685 492.336 62.3194 492.336Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 486.337C63.2802 486.337 64.0511 487.108 64.0511 488.059C64.0511 489.01 63.2802 489.781 62.3294 489.781C61.3785 489.781 60.6077 489.01 60.6077 488.059C60.6077 487.108 61.3785 486.337 62.3294 486.337Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 480.379C63.2802 480.379 64.0511 481.15 64.0511 482.101C64.0511 483.052 63.2802 483.822 62.3294 483.822C61.3785 483.822 60.6077 483.052 60.6077 482.101C60.6077 481.15 61.3785 480.379 62.3294 480.379Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 474.416C63.2802 474.416 64.0511 475.187 64.0511 476.138C64.0511 477.089 63.2802 477.859 62.3294 477.859C61.3785 477.859 60.6077 477.089 60.6077 476.138C60.6077 475.187 61.3785 474.416 62.3294 474.416Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 468.458C63.2802 468.458 64.0511 469.229 64.0511 470.18C64.0511 471.131 63.2802 471.901 62.3294 471.901C61.3785 471.901 60.6077 471.131 60.6077 470.18C60.6077 469.229 61.3785 468.458 62.3294 468.458Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 462.46C63.2802 462.46 64.0511 463.23 64.0511 464.181C64.0511 465.132 63.2802 465.903 62.3294 465.903C61.3785 465.903 60.6077 465.132 60.6077 464.181C60.6077 463.23 61.3785 462.46 62.3294 462.46Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 456.502C63.2802 456.502 64.0511 457.272 64.0511 458.223C64.0511 459.174 63.2802 459.945 62.3294 459.945C61.3785 459.945 60.6077 459.174 60.6077 458.223C60.6077 457.272 61.3785 456.502 62.3294 456.502Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 590.824C68.404 590.824 69.1748 591.595 69.1748 592.546C69.1748 593.497 68.404 594.268 67.4531 594.268C66.5023 594.268 65.7314 593.497 65.7314 592.546C65.7314 591.595 66.5023 590.824 67.4531 590.824Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 584.866C68.404 584.866 69.1748 585.637 69.1748 586.588C69.1748 587.539 68.404 588.31 67.4531 588.31C66.5023 588.31 65.7314 587.539 65.7314 586.588C65.7314 585.637 66.5023 584.866 67.4531 584.866Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 578.853C68.404 578.853 69.1748 579.624 69.1748 580.575C69.1748 581.525 68.404 582.296 67.4531 582.296C66.5023 582.296 65.7314 581.525 65.7314 580.575C65.7314 579.624 66.5023 578.853 67.4531 578.853Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 572.895C68.404 572.895 69.1748 573.666 69.1748 574.616C69.1748 575.567 68.404 576.338 67.4531 576.338C66.5023 576.338 65.7314 575.567 65.7314 574.616C65.7314 573.666 66.5023 572.895 67.4531 572.895Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4444 566.947C68.3952 566.947 69.166 567.717 69.166 568.668C69.166 569.619 68.3952 570.39 67.4444 570.39C66.4935 570.39 65.7227 569.619 65.7227 568.668C65.7227 567.717 66.4935 566.947 67.4444 566.947Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4444 560.989C68.3952 560.989 69.166 561.759 69.166 562.71C69.166 563.661 68.3952 564.432 67.4444 564.432C66.4935 564.432 65.7227 563.661 65.7227 562.71C65.7227 561.759 66.4935 560.989 67.4444 560.989Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 554.99C68.404 554.99 69.1748 555.761 69.1748 556.712C69.1748 557.663 68.404 558.434 67.4531 558.434C66.5023 558.434 65.7314 557.663 65.7314 556.712C65.7314 555.761 66.5023 554.99 67.4531 554.99Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 549.032C68.404 549.032 69.1748 549.803 69.1748 550.754C69.1748 551.705 68.404 552.476 67.4531 552.476C66.5023 552.476 65.7314 551.705 65.7314 550.754C65.7314 549.803 66.5023 549.032 67.4531 549.032Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 543.069C68.404 543.069 69.1748 543.84 69.1748 544.791C69.1748 545.742 68.404 546.512 67.4531 546.512C66.5023 546.512 65.7314 545.742 65.7314 544.791C65.7314 543.84 66.5023 543.069 67.4531 543.069Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 537.111C68.404 537.111 69.1748 537.882 69.1748 538.833C69.1748 539.784 68.404 540.554 67.4531 540.554C66.5023 540.554 65.7314 539.784 65.7314 538.833C65.7314 537.882 66.5023 537.111 67.4531 537.111Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 531.113C68.404 531.113 69.1748 531.884 69.1748 532.835C69.1748 533.785 68.404 534.556 67.4531 534.556C66.5023 534.556 65.7314 533.785 65.7314 532.835C65.7314 531.884 66.5023 531.113 67.4531 531.113Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4531 525.155C68.404 525.155 69.1748 525.926 69.1748 526.876C69.1748 527.827 68.404 528.598 67.4531 528.598C66.5023 528.598 65.7314 527.827 65.7314 526.876C65.7314 525.926 66.5023 525.155 67.4531 525.155Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 519.187C68.4077 519.187 69.1785 519.957 69.1785 520.908C69.1785 521.859 68.4077 522.63 67.4568 522.63C66.5059 522.63 65.7351 521.859 65.7351 520.908C65.7351 519.957 66.5059 519.187 67.4568 519.187Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 513.229C68.4077 513.229 69.1785 513.999 69.1785 514.95C69.1785 515.901 68.4077 516.672 67.4568 516.672C66.5059 516.672 65.7351 515.901 65.7351 514.95C65.7351 513.999 66.5059 513.229 67.4568 513.229Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 507.215C68.4077 507.215 69.1785 507.986 69.1785 508.937C69.1785 509.888 68.4077 510.658 67.4568 510.658C66.5059 510.658 65.7351 509.888 65.7351 508.937C65.7351 507.986 66.5059 507.215 67.4568 507.215Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 501.257C68.4077 501.257 69.1785 502.028 69.1785 502.979C69.1785 503.93 68.4077 504.7 67.4568 504.7C66.5059 504.7 65.7351 503.93 65.7351 502.979C65.7351 502.028 66.5059 501.257 67.4568 501.257Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.448 495.309C68.3989 495.309 69.1697 496.08 69.1697 497.031C69.1697 497.982 68.3989 498.752 67.448 498.752C66.4971 498.752 65.7263 497.982 65.7263 497.031C65.7263 496.08 66.4971 495.309 67.448 495.309Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.448 489.351C68.3989 489.351 69.1697 490.122 69.1697 491.073C69.1697 492.024 68.3989 492.794 67.448 492.794C66.4971 492.794 65.7263 492.024 65.7263 491.073C65.7263 490.122 66.4971 489.351 67.448 489.351Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 483.354C68.4077 483.354 69.1785 484.125 69.1785 485.075C69.1785 486.026 68.4077 486.797 67.4568 486.797C66.5059 486.797 65.7351 486.026 65.7351 485.075C65.7351 484.125 66.5059 483.354 67.4568 483.354Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 477.396C68.4077 477.396 69.1785 478.167 69.1785 479.117C69.1785 480.068 68.4077 480.839 67.4568 480.839C66.5059 480.839 65.7351 480.068 65.7351 479.117C65.7351 478.167 66.5059 477.396 67.4568 477.396Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 471.431C68.4077 471.431 69.1785 472.202 69.1785 473.153C69.1785 474.104 68.4077 474.875 67.4568 474.875C66.5059 474.875 65.7351 474.104 65.7351 473.153C65.7351 472.202 66.5059 471.431 67.4568 471.431Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 465.473C68.4077 465.473 69.1785 466.244 69.1785 467.195C69.1785 468.146 68.4077 468.917 67.4568 468.917C66.5059 468.917 65.7351 468.146 65.7351 467.195C65.7351 466.244 66.5059 465.473 67.4568 465.473Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 459.476C68.4077 459.476 69.1785 460.247 69.1785 461.198C69.1785 462.149 68.4077 462.92 67.4568 462.92C66.5059 462.92 65.7351 462.149 65.7351 461.198C65.7351 460.247 66.5059 459.476 67.4568 459.476Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 453.518C68.4077 453.518 69.1785 454.289 69.1785 455.24C69.1785 456.191 68.4077 456.962 67.4568 456.962C66.5059 456.962 65.7351 456.191 65.7351 455.24C65.7351 454.289 66.5059 453.518 67.4568 453.518Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 593.808C73.4773 593.808 74.2481 594.579 74.2481 595.529C74.2481 596.48 73.4773 597.251 72.5264 597.251C71.5755 597.251 70.8047 596.48 70.8047 595.529C70.8047 594.579 71.5755 593.808 72.5264 593.808Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 587.851C73.4773 587.851 74.2481 588.622 74.2481 589.573C74.2481 590.523 73.4773 591.294 72.5264 591.294C71.5755 591.294 70.8047 590.523 70.8047 589.573C70.8047 588.622 71.5755 587.851 72.5264 587.851Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 581.837C73.4773 581.837 74.2481 582.608 74.2481 583.559C74.2481 584.51 73.4773 585.281 72.5264 585.281C71.5755 585.281 70.8047 584.51 70.8047 583.559C70.8047 582.608 71.5755 581.837 72.5264 581.837Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 575.879C73.4773 575.879 74.2481 576.65 74.2481 577.601C74.2481 578.552 73.4773 579.323 72.5264 579.323C71.5755 579.323 70.8047 578.552 70.8047 577.601C70.8047 576.65 71.5755 575.879 72.5264 575.879Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5176 569.93C73.4685 569.93 74.2393 570.701 74.2393 571.652C74.2393 572.603 73.4685 573.374 72.5176 573.374C71.5667 573.374 70.7959 572.603 70.7959 571.652C70.7959 570.701 71.5667 569.93 72.5176 569.93Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5176 563.973C73.4685 563.973 74.2393 564.744 74.2393 565.695C74.2393 566.646 73.4685 567.417 72.5176 567.417C71.5667 567.417 70.7959 566.646 70.7959 565.695C70.7959 564.744 71.5667 563.973 72.5176 563.973Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 557.975C73.4773 557.975 74.2481 558.746 74.2481 559.697C74.2481 560.648 73.4773 561.418 72.5264 561.418C71.5755 561.418 70.8047 560.648 70.8047 559.697C70.8047 558.746 71.5755 557.975 72.5264 557.975Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 552.017C73.4773 552.017 74.2481 552.788 74.2481 553.739C74.2481 554.69 73.4773 555.46 72.5264 555.46C71.5755 555.46 70.8047 554.69 70.8047 553.739C70.8047 552.788 71.5755 552.017 72.5264 552.017Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 546.053C73.4773 546.053 74.2481 546.823 74.2481 547.774C74.2481 548.725 73.4773 549.496 72.5264 549.496C71.5755 549.496 70.8047 548.725 70.8047 547.774C70.8047 546.823 71.5755 546.053 72.5264 546.053Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 540.096C73.4773 540.096 74.2481 540.867 74.2481 541.817C74.2481 542.768 73.4773 543.539 72.5264 543.539C71.5755 543.539 70.8047 542.768 70.8047 541.817C70.8047 540.867 71.5755 540.096 72.5264 540.096Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 534.098C73.4773 534.098 74.2481 534.868 74.2481 535.819C74.2481 536.77 73.4773 537.541 72.5264 537.541C71.5755 537.541 70.8047 536.77 70.8047 535.819C70.8047 534.868 71.5755 534.098 72.5264 534.098Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5264 528.139C73.4773 528.139 74.2481 528.91 74.2481 529.861C74.2481 530.812 73.4773 531.583 72.5264 531.583C71.5755 531.583 70.8047 530.812 70.8047 529.861C70.8047 528.91 71.5755 528.139 72.5264 528.139Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 522.171C73.4824 522.171 74.2532 522.942 74.2532 523.893C74.2532 524.844 73.4824 525.615 72.5315 525.615C71.5806 525.615 70.8098 524.844 70.8098 523.893C70.8098 522.942 71.5806 522.171 72.5315 522.171Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 516.213C73.4824 516.213 74.2532 516.984 74.2532 517.935C74.2532 518.886 73.4824 519.657 72.5315 519.657C71.5806 519.657 70.8098 518.886 70.8098 517.935C70.8098 516.984 71.5806 516.213 72.5315 516.213Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 510.2C73.4824 510.2 74.2532 510.97 74.2532 511.921C74.2532 512.872 73.4824 513.643 72.5315 513.643C71.5806 513.643 70.8098 512.872 70.8098 511.921C70.8098 510.97 71.5806 510.2 72.5315 510.2Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 504.242C73.4824 504.242 74.2532 505.012 74.2532 505.963C74.2532 506.914 73.4824 507.685 72.5315 507.685C71.5806 507.685 70.8098 506.914 70.8098 505.963C70.8098 505.012 71.5806 504.242 72.5315 504.242Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5213 498.294C73.4721 498.294 74.243 499.065 74.243 500.015C74.243 500.966 73.4721 501.737 72.5213 501.737C71.5704 501.737 70.7996 500.966 70.7996 500.015C70.7996 499.065 71.5704 498.294 72.5213 498.294Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5213 492.336C73.4721 492.336 74.243 493.107 74.243 494.057C74.243 495.008 73.4721 495.779 72.5213 495.779C71.5704 495.779 70.7996 495.008 70.7996 494.057C70.7996 493.107 71.5704 492.336 72.5213 492.336Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 486.337C73.4824 486.337 74.2532 487.108 74.2532 488.059C74.2532 489.01 73.4824 489.781 72.5315 489.781C71.5806 489.781 70.8098 489.01 70.8098 488.059C70.8098 487.108 71.5806 486.337 72.5315 486.337Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 480.379C73.4824 480.379 74.2532 481.15 74.2532 482.101C74.2532 483.052 73.4824 483.822 72.5315 483.822C71.5806 483.822 70.8098 483.052 70.8098 482.101C70.8098 481.15 71.5806 480.379 72.5315 480.379Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 474.416C73.4824 474.416 74.2532 475.187 74.2532 476.138C74.2532 477.089 73.4824 477.859 72.5315 477.859C71.5806 477.859 70.8098 477.089 70.8098 476.138C70.8098 475.187 71.5806 474.416 72.5315 474.416Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 468.458C73.4824 468.458 74.2532 469.229 74.2532 470.18C74.2532 471.131 73.4824 471.901 72.5315 471.901C71.5806 471.901 70.8098 471.131 70.8098 470.18C70.8098 469.229 71.5806 468.458 72.5315 468.458Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 462.46C73.4824 462.46 74.2532 463.23 74.2532 464.181C74.2532 465.132 73.4824 465.903 72.5315 465.903C71.5806 465.903 70.8098 465.132 70.8098 464.181C70.8098 463.23 71.5806 462.46 72.5315 462.46Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 456.502C73.4824 456.502 74.2532 457.272 74.2532 458.223C74.2532 459.174 73.4824 459.945 72.5315 459.945C71.5806 459.945 70.8098 459.174 70.8098 458.223C70.8098 457.272 71.5806 456.502 72.5315 456.502Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 590.834C78.5947 590.834 79.3655 591.605 79.3655 592.556C79.3655 593.507 78.5947 594.278 77.6438 594.278C76.693 594.278 75.9221 593.507 75.9221 592.556C75.9221 591.605 76.693 590.834 77.6438 590.834Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 584.876C78.5947 584.876 79.3655 585.647 79.3655 586.598C79.3655 587.549 78.5947 588.32 77.6438 588.32C76.693 588.32 75.9221 587.549 75.9221 586.598C75.9221 585.647 76.693 584.876 77.6438 584.876Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 578.863C78.5947 578.863 79.3655 579.634 79.3655 580.585C79.3655 581.536 78.5947 582.306 77.6438 582.306C76.693 582.306 75.9221 581.536 75.9221 580.585C75.9221 579.634 76.693 578.863 77.6438 578.863Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 572.905C78.5947 572.905 79.3655 573.676 79.3655 574.626C79.3655 575.577 78.5947 576.348 77.6438 576.348C76.693 576.348 75.9221 575.577 75.9221 574.626C75.9221 573.676 76.693 572.905 77.6438 572.905Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.635 566.957C78.5859 566.957 79.3567 567.728 79.3567 568.678C79.3567 569.629 78.5859 570.4 77.635 570.4C76.6842 570.4 75.9133 569.629 75.9133 568.678C75.9133 567.728 76.6842 566.957 77.635 566.957Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.635 560.999C78.5859 560.999 79.3567 561.77 79.3567 562.72C79.3567 563.671 78.5859 564.442 77.635 564.442C76.6842 564.442 75.9133 563.671 75.9133 562.72C75.9133 561.77 76.6842 560.999 77.635 560.999Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 555.002C78.5947 555.002 79.3655 555.773 79.3655 556.723C79.3655 557.674 78.5947 558.445 77.6438 558.445C76.693 558.445 75.9221 557.674 75.9221 556.723C75.9221 555.773 76.693 555.002 77.6438 555.002Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 549.044C78.5947 549.044 79.3655 549.814 79.3655 550.765C79.3655 551.716 78.5947 552.487 77.6438 552.487C76.693 552.487 75.9221 551.716 75.9221 550.765C75.9221 549.814 76.693 549.044 77.6438 549.044Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 543.079C78.5947 543.079 79.3655 543.85 79.3655 544.801C79.3655 545.752 78.5947 546.523 77.6438 546.523C76.693 546.523 75.9221 545.752 75.9221 544.801C75.9221 543.85 76.693 543.079 77.6438 543.079Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 537.121C78.5947 537.121 79.3655 537.892 79.3655 538.843C79.3655 539.794 78.5947 540.565 77.6438 540.565C76.693 540.565 75.9221 539.794 75.9221 538.843C75.9221 537.892 76.693 537.121 77.6438 537.121Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 531.124C78.5947 531.124 79.3655 531.895 79.3655 532.846C79.3655 533.797 78.5947 534.568 77.6438 534.568C76.693 534.568 75.9221 533.797 75.9221 532.846C75.9221 531.895 76.693 531.124 77.6438 531.124Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6438 525.166C78.5947 525.166 79.3655 525.937 79.3655 526.888C79.3655 527.838 78.5947 528.609 77.6438 528.609C76.693 528.609 75.9221 527.838 75.9221 526.888C75.9221 525.937 76.693 525.166 77.6438 525.166Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 519.198C78.5996 519.198 79.3704 519.969 79.3704 520.92C79.3704 521.87 78.5996 522.641 77.6487 522.641C76.6978 522.641 75.927 521.87 75.927 520.92C75.927 519.969 76.6978 519.198 77.6487 519.198Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 513.24C78.5996 513.24 79.3704 514.011 79.3704 514.961C79.3704 515.912 78.5996 516.683 77.6487 516.683C76.6978 516.683 75.927 515.912 75.927 514.961C75.927 514.011 76.6978 513.24 77.6487 513.24Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 507.226C78.5996 507.226 79.3704 507.997 79.3704 508.948C79.3704 509.899 78.5996 510.67 77.6487 510.67C76.6978 510.67 75.927 509.899 75.927 508.948C75.927 507.997 76.6978 507.226 77.6487 507.226Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 501.268C78.5996 501.268 79.3704 502.039 79.3704 502.99C79.3704 503.941 78.5996 504.712 77.6487 504.712C76.6978 504.712 75.927 503.941 75.927 502.99C75.927 502.039 76.6978 501.268 77.6487 501.268Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6399 495.32C78.5908 495.32 79.3616 496.091 79.3616 497.042C79.3616 497.993 78.5908 498.764 77.6399 498.764C76.689 498.764 75.9182 497.993 75.9182 497.042C75.9182 496.091 76.689 495.32 77.6399 495.32Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6399 489.362C78.5908 489.362 79.3616 490.133 79.3616 491.084C79.3616 492.035 78.5908 492.806 77.6399 492.806C76.689 492.806 75.9182 492.035 75.9182 491.084C75.9182 490.133 76.689 489.362 77.6399 489.362Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 483.364C78.5996 483.364 79.3704 484.135 79.3704 485.085C79.3704 486.036 78.5996 486.807 77.6487 486.807C76.6978 486.807 75.927 486.036 75.927 485.085C75.927 484.135 76.6978 483.364 77.6487 483.364Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 477.406C78.5996 477.406 79.3704 478.177 79.3704 479.127C79.3704 480.078 78.5996 480.849 77.6487 480.849C76.6978 480.849 75.927 480.078 75.927 479.127C75.927 478.177 76.6978 477.406 77.6487 477.406Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 471.443C78.5996 471.443 79.3704 472.214 79.3704 473.164C79.3704 474.115 78.5996 474.886 77.6487 474.886C76.6978 474.886 75.927 474.115 75.927 473.164C75.927 472.214 76.6978 471.443 77.6487 471.443Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 465.485C78.5996 465.485 79.3704 466.255 79.3704 467.206C79.3704 468.157 78.5996 468.928 77.6487 468.928C76.6978 468.928 75.927 468.157 75.927 467.206C75.927 466.255 76.6978 465.485 77.6487 465.485Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 459.486C78.5996 459.486 79.3704 460.257 79.3704 461.208C79.3704 462.159 78.5996 462.93 77.6487 462.93C76.6978 462.93 75.927 462.159 75.927 461.208C75.927 460.257 76.6978 459.486 77.6487 459.486Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6487 453.528C78.5996 453.528 79.3704 454.299 79.3704 455.25C79.3704 456.201 78.5996 456.971 77.6487 456.971C76.6978 456.971 75.927 456.201 75.927 455.25C75.927 454.299 76.6978 453.528 77.6487 453.528Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 444.581C63.4035 444.581 64.1743 445.351 64.1743 446.302C64.1743 447.253 63.4035 448.024 62.4526 448.024C61.5018 448.024 60.731 447.253 60.731 446.302C60.731 445.351 61.5018 444.581 62.4526 444.581Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 438.623C63.4035 438.623 64.1743 439.394 64.1743 440.345C64.1743 441.296 63.4035 442.067 62.4526 442.067C61.5018 442.067 60.731 441.296 60.731 440.345C60.731 439.394 61.5018 438.623 62.4526 438.623Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 432.61C63.4035 432.61 64.1743 433.381 64.1743 434.332C64.1743 435.283 63.4035 436.054 62.4526 436.054C61.5018 436.054 60.731 435.283 60.731 434.332C60.731 433.381 61.5018 432.61 62.4526 432.61Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4524 426.652C63.4033 426.652 64.1741 427.423 64.1741 428.374C64.1741 429.325 63.4033 430.096 62.4524 430.096C61.5015 430.096 60.7307 429.325 60.7307 428.374C60.7307 427.423 61.5015 426.652 62.4524 426.652Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4424 420.703C63.3933 420.703 64.1641 421.474 64.1641 422.425C64.1641 423.375 63.3933 424.146 62.4424 424.146C61.4915 424.146 60.7207 423.375 60.7207 422.425C60.7207 421.474 61.4915 420.703 62.4424 420.703Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4424 414.746C63.3933 414.746 64.1641 415.517 64.1641 416.468C64.1641 417.419 63.3933 418.189 62.4424 418.189C61.4915 418.189 60.7207 417.419 60.7207 416.468C60.7207 415.517 61.4915 414.746 62.4424 414.746Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 408.748C63.4035 408.748 64.1743 409.519 64.1743 410.469C64.1743 411.42 63.4035 412.191 62.4526 412.191C61.5018 412.191 60.731 411.42 60.731 410.469C60.731 409.519 61.5018 408.748 62.4526 408.748Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 402.79C63.4035 402.79 64.1743 403.56 64.1743 404.511C64.1743 405.462 63.4035 406.233 62.4526 406.233C61.5018 406.233 60.731 405.462 60.731 404.511C60.731 403.56 61.5018 402.79 62.4526 402.79Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 396.825C63.4035 396.825 64.1743 397.596 64.1743 398.547C64.1743 399.498 63.4035 400.269 62.4526 400.269C61.5018 400.269 60.731 399.498 60.731 398.547C60.731 397.596 61.5018 396.825 62.4526 396.825Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 390.868C63.4035 390.868 64.1743 391.639 64.1743 392.59C64.1743 393.541 63.4035 394.312 62.4526 394.312C61.5018 394.312 60.731 393.541 60.731 392.59C60.731 391.639 61.5018 390.868 62.4526 390.868Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 384.87C63.4035 384.87 64.1743 385.641 64.1743 386.592C64.1743 387.543 63.4035 388.314 62.4526 388.314C61.5018 388.314 60.731 387.543 60.731 386.592C60.731 385.641 61.5018 384.87 62.4526 384.87Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4526 378.912C63.4035 378.912 64.1743 379.683 64.1743 380.634C64.1743 381.585 63.4035 382.355 62.4526 382.355C61.5018 382.355 60.731 381.585 60.731 380.634C60.731 379.683 61.5018 378.912 62.4526 378.912Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 372.944C63.4072 372.944 64.178 373.715 64.178 374.665C64.178 375.616 63.4072 376.387 62.4563 376.387C61.5055 376.387 60.7346 375.616 60.7346 374.665C60.7346 373.715 61.5055 372.944 62.4563 372.944Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 366.986C63.4072 366.986 64.178 367.757 64.178 368.707C64.178 369.658 63.4072 370.429 62.4563 370.429C61.5055 370.429 60.7346 369.658 60.7346 368.707C60.7346 367.757 61.5055 366.986 62.4563 366.986Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 360.972C63.4072 360.972 64.178 361.743 64.178 362.694C64.178 363.645 63.4072 364.416 62.4563 364.416C61.5055 364.416 60.7346 363.645 60.7346 362.694C60.7346 361.743 61.5055 360.972 62.4563 360.972Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 355.014C63.4072 355.014 64.178 355.785 64.178 356.736C64.178 357.687 63.4072 358.458 62.4563 358.458C61.5055 358.458 60.7346 357.687 60.7346 356.736C60.7346 355.785 61.5055 355.014 62.4563 355.014Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4475 349.066C63.3984 349.066 64.1692 349.837 64.1692 350.788C64.1692 351.739 63.3984 352.51 62.4475 352.51C61.4967 352.51 60.7258 351.739 60.7258 350.788C60.7258 349.837 61.4967 349.066 62.4475 349.066Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4475 343.108C63.3984 343.108 64.1692 343.879 64.1692 344.83C64.1692 345.781 63.3984 346.552 62.4475 346.552C61.4967 346.552 60.7258 345.781 60.7258 344.83C60.7258 343.879 61.4967 343.108 62.4475 343.108Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 337.11C63.4072 337.11 64.178 337.881 64.178 338.832C64.178 339.783 63.4072 340.553 62.4563 340.553C61.5055 340.553 60.7346 339.783 60.7346 338.832C60.7346 337.881 61.5055 337.11 62.4563 337.11Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 331.152C63.4072 331.152 64.178 331.923 64.178 332.874C64.178 333.824 63.4072 334.595 62.4563 334.595C61.5055 334.595 60.7346 333.824 60.7346 332.874C60.7346 331.923 61.5055 331.152 62.4563 331.152Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.4563 325.189C63.4072 325.189 64.178 325.959 64.178 326.91C64.178 327.861 63.4072 328.632 62.4563 328.632C61.5055 328.632 60.7346 327.861 60.7346 326.91C60.7346 325.959 61.5055 325.189 62.4563 325.189Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 447.555C68.4177 447.555 69.1885 448.326 69.1885 449.277C69.1885 450.228 68.4177 450.998 67.4668 450.998C66.5159 450.998 65.7451 450.228 65.7451 449.277C65.7451 448.326 66.5159 447.555 67.4668 447.555Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 441.597C68.4177 441.597 69.1885 442.368 69.1885 443.319C69.1885 444.27 68.4177 445.04 67.4668 445.04C66.5159 445.04 65.7451 444.27 65.7451 443.319C65.7451 442.368 66.5159 441.597 67.4668 441.597Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 435.583C68.4177 435.583 69.1885 436.354 69.1885 437.305C69.1885 438.256 68.4177 439.027 67.4668 439.027C66.5159 439.027 65.7451 438.256 65.7451 437.305C65.7451 436.354 66.5159 435.583 67.4668 435.583Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 429.625C68.4177 429.625 69.1885 430.396 69.1885 431.347C69.1885 432.298 68.4177 433.069 67.4668 433.069C66.5159 433.069 65.7451 432.298 65.7451 431.347C65.7451 430.396 66.5159 429.625 67.4668 429.625Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 423.677C68.4077 423.677 69.1785 424.448 69.1785 425.399C69.1785 426.35 68.4077 427.121 67.4568 427.121C66.5059 427.121 65.7351 426.35 65.7351 425.399C65.7351 424.448 66.5059 423.677 67.4568 423.677Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4568 417.719C68.4077 417.719 69.1785 418.49 69.1785 419.441C69.1785 420.392 68.4077 421.163 67.4568 421.163C66.5059 421.163 65.7351 420.392 65.7351 419.441C65.7351 418.49 66.5059 417.719 67.4568 417.719Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 411.721C68.4177 411.721 69.1885 412.492 69.1885 413.443C69.1885 414.394 68.4177 415.164 67.4668 415.164C66.5159 415.164 65.7451 414.394 65.7451 413.443C65.7451 412.492 66.5159 411.721 67.4668 411.721Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 405.763C68.4177 405.763 69.1885 406.534 69.1885 407.485C69.1885 408.436 68.4177 409.206 67.4668 409.206C66.5159 409.206 65.7451 408.436 65.7451 407.485C65.7451 406.534 66.5159 405.763 67.4668 405.763Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 399.8C68.4177 399.8 69.1885 400.571 69.1885 401.522C69.1885 402.473 68.4177 403.243 67.4668 403.243C66.5159 403.243 65.7451 402.473 65.7451 401.522C65.7451 400.571 66.5159 399.8 67.4668 399.8Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 393.842C68.4177 393.842 69.1885 394.613 69.1885 395.564C69.1885 396.514 68.4177 397.285 67.4668 397.285C66.5159 397.285 65.7451 396.514 65.7451 395.564C65.7451 394.613 66.5159 393.842 67.4668 393.842Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 387.844C68.4177 387.844 69.1885 388.614 69.1885 389.565C69.1885 390.516 68.4177 391.287 67.4668 391.287C66.5159 391.287 65.7451 390.516 65.7451 389.565C65.7451 388.614 66.5159 387.844 67.4668 387.844Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4668 381.885C68.4177 381.885 69.1885 382.656 69.1885 383.607C69.1885 384.558 68.4177 385.329 67.4668 385.329C66.5159 385.329 65.7451 384.558 65.7451 383.607C65.7451 382.656 66.5159 381.885 67.4668 381.885Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 375.917C68.4216 375.917 69.1924 376.688 69.1924 377.639C69.1924 378.59 68.4216 379.361 67.4707 379.361C66.5198 379.361 65.749 378.59 65.749 377.639C65.749 376.688 66.5198 375.917 67.4707 375.917Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 369.959C68.4216 369.959 69.1924 370.73 69.1924 371.681C69.1924 372.632 68.4216 373.402 67.4707 373.402C66.5198 373.402 65.749 372.632 65.749 371.681C65.749 370.73 66.5198 369.959 67.4707 369.959Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 363.946C68.4216 363.946 69.1924 364.717 69.1924 365.668C69.1924 366.618 68.4216 367.389 67.4707 367.389C66.5198 367.389 65.749 366.618 65.749 365.668C65.749 364.717 66.5198 363.946 67.4707 363.946Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 357.988C68.4216 357.988 69.1924 358.759 69.1924 359.709C69.1924 360.66 68.4216 361.431 67.4707 361.431C66.5198 361.431 65.749 360.66 65.749 359.709C65.749 358.759 66.5198 357.988 67.4707 357.988Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4617 352.04C68.4126 352.04 69.1834 352.81 69.1834 353.761C69.1834 354.712 68.4126 355.483 67.4617 355.483C66.5108 355.483 65.74 354.712 65.74 353.761C65.74 352.81 66.5108 352.04 67.4617 352.04Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4617 346.081C68.4126 346.081 69.1834 346.852 69.1834 347.803C69.1834 348.754 68.4126 349.525 67.4617 349.525C66.5108 349.525 65.74 348.754 65.74 347.803C65.74 346.852 66.5108 346.081 67.4617 346.081Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 340.084C68.4216 340.084 69.1924 340.855 69.1924 341.806C69.1924 342.757 68.4216 343.528 67.4707 343.528C66.5198 343.528 65.749 342.757 65.749 341.806C65.749 340.855 66.5198 340.084 67.4707 340.084Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 334.126C68.4216 334.126 69.1924 334.897 69.1924 335.848C69.1924 336.799 68.4216 337.57 67.4707 337.57C66.5198 337.57 65.749 336.799 65.749 335.848C65.749 334.897 66.5198 334.126 67.4707 334.126Z"
              fill="#B8B7B7"
            />
            <path
              d="M67.4707 328.162C68.4216 328.162 69.1924 328.933 69.1924 329.884C69.1924 330.835 68.4216 331.606 67.4707 331.606C66.5198 331.606 65.749 330.835 65.749 329.884C65.749 328.933 66.5198 328.162 67.4707 328.162Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 444.57C73.5451 444.57 74.316 445.341 74.316 446.292C74.316 447.243 73.5451 448.014 72.5943 448.014C71.6434 448.014 70.8726 447.243 70.8726 446.292C70.8726 445.341 71.6434 444.57 72.5943 444.57Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 438.612C73.5451 438.612 74.316 439.383 74.316 440.334C74.316 441.285 73.5451 442.056 72.5943 442.056C71.6434 442.056 70.8726 441.285 70.8726 440.334C70.8726 439.383 71.6434 438.612 72.5943 438.612Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 432.599C73.5451 432.599 74.316 433.37 74.316 434.32C74.316 435.271 73.5451 436.042 72.5943 436.042C71.6434 436.042 70.8726 435.271 70.8726 434.32C70.8726 433.37 71.6434 432.599 72.5943 432.599Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 426.641C73.5451 426.641 74.316 427.412 74.316 428.362C74.316 429.313 73.5451 430.084 72.5943 430.084C71.6434 430.084 70.8726 429.313 70.8726 428.362C70.8726 427.412 71.6434 426.641 72.5943 426.641Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5855 420.693C73.5363 420.693 74.3071 421.464 74.3071 422.414C74.3071 423.365 73.5363 424.136 72.5855 424.136C71.6346 424.136 70.8638 423.365 70.8638 422.414C70.8638 421.464 71.6346 420.693 72.5855 420.693Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5855 414.735C73.5363 414.735 74.3071 415.506 74.3071 416.456C74.3071 417.407 73.5363 418.178 72.5855 418.178C71.6346 418.178 70.8638 417.407 70.8638 416.456C70.8638 415.506 71.6346 414.735 72.5855 414.735Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 408.738C73.5451 408.738 74.316 409.508 74.316 410.459C74.316 411.41 73.5451 412.181 72.5943 412.181C71.6434 412.181 70.8726 411.41 70.8726 410.459C70.8726 409.508 71.6434 408.738 72.5943 408.738Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 402.78C73.5451 402.78 74.316 403.55 74.316 404.501C74.316 405.452 73.5451 406.223 72.5943 406.223C71.6434 406.223 70.8726 405.452 70.8726 404.501C70.8726 403.55 71.6434 402.78 72.5943 402.78Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 396.815C73.5451 396.815 74.316 397.586 74.316 398.537C74.316 399.488 73.5451 400.259 72.5943 400.259C71.6434 400.259 70.8726 399.488 70.8726 398.537C70.8726 397.586 71.6434 396.815 72.5943 396.815Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 390.857C73.5451 390.857 74.316 391.628 74.316 392.579C74.316 393.53 73.5451 394.301 72.5943 394.301C71.6434 394.301 70.8726 393.53 70.8726 392.579C70.8726 391.628 71.6434 390.857 72.5943 390.857Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 384.86C73.5451 384.86 74.316 385.631 74.316 386.582C74.316 387.533 73.5451 388.303 72.5943 388.303C71.6434 388.303 70.8726 387.533 70.8726 386.582C70.8726 385.631 71.6434 384.86 72.5943 384.86Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5943 378.902C73.5451 378.902 74.316 379.673 74.316 380.624C74.316 381.575 73.5451 382.345 72.5943 382.345C71.6434 382.345 70.8726 381.575 70.8726 380.624C70.8726 379.673 71.6434 378.902 72.5943 378.902Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5994 372.933C73.5503 372.933 74.3211 373.703 74.3211 374.654C74.3211 375.605 73.5503 376.376 72.5994 376.376C71.6485 376.376 70.8777 375.605 70.8777 374.654C70.8777 373.703 71.6485 372.933 72.5994 372.933Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5994 366.976C73.5503 366.976 74.3211 367.747 74.3211 368.697C74.3211 369.648 73.5503 370.419 72.5994 370.419C71.6485 370.419 70.8777 369.648 70.8777 368.697C70.8777 367.747 71.6485 366.976 72.5994 366.976Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5994 360.962C73.5503 360.962 74.3211 361.733 74.3211 362.684C74.3211 363.635 73.5503 364.406 72.5994 364.406C71.6485 364.406 70.8777 363.635 70.8777 362.684C70.8777 361.733 71.6485 360.962 72.5994 360.962Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5994 355.004C73.5503 355.004 74.3211 355.775 74.3211 356.726C74.3211 357.677 73.5503 358.448 72.5994 358.448C71.6485 358.448 70.8777 357.677 70.8777 356.726C70.8777 355.775 71.6485 355.004 72.5994 355.004Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5906 349.055C73.5415 349.055 74.3123 349.826 74.3123 350.777C74.3123 351.728 73.5415 352.498 72.5906 352.498C71.6397 352.498 70.8689 351.728 70.8689 350.777C70.8689 349.826 71.6397 349.055 72.5906 349.055Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5906 343.098C73.5415 343.098 74.3123 343.869 74.3123 344.82C74.3123 345.771 73.5415 346.542 72.5906 346.542C71.6397 346.542 70.8689 345.771 70.8689 344.82C70.8689 343.869 71.6397 343.098 72.5906 343.098Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5992 337.1C73.55 337.1 74.3209 337.871 74.3209 338.821C74.3209 339.772 73.55 340.543 72.5992 340.543C71.6483 340.543 70.8774 339.772 70.8774 338.821C70.8774 337.871 71.6483 337.1 72.5992 337.1Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5991 331.142C73.55 331.142 74.3208 331.913 74.3208 332.863C74.3208 333.814 73.55 334.585 72.5991 334.585C71.6483 334.585 70.8774 333.814 70.8774 332.863C70.8774 331.913 71.6483 331.142 72.5991 331.142Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5992 325.177C73.55 325.177 74.3209 325.948 74.3209 326.899C74.3209 327.85 73.55 328.621 72.5992 328.621C71.6483 328.621 70.8774 327.85 70.8774 326.899C70.8774 325.948 71.6483 325.177 72.5992 325.177Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 447.555C78.6198 447.555 79.3907 448.326 79.3907 449.277C79.3907 450.228 78.6198 450.998 77.669 450.998C76.7181 450.998 75.9473 450.228 75.9473 449.277C75.9473 448.326 76.7181 447.555 77.669 447.555Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 441.597C78.6198 441.597 79.3907 442.368 79.3907 443.319C79.3907 444.27 78.6198 445.04 77.669 445.04C76.7181 445.04 75.9473 444.27 75.9473 443.319C75.9473 442.368 76.7181 441.597 77.669 441.597Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 435.583C78.6198 435.583 79.3907 436.354 79.3907 437.305C79.3907 438.256 78.6198 439.027 77.669 439.027C76.7181 439.027 75.9473 438.256 75.9473 437.305C75.9473 436.354 76.7181 435.583 77.669 435.583Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 429.625C78.6198 429.625 79.3907 430.396 79.3907 431.347C79.3907 432.298 78.6198 433.069 77.669 433.069C76.7181 433.069 75.9473 432.298 75.9473 431.347C75.9473 430.396 76.7181 429.625 77.669 429.625Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6587 423.677C78.6096 423.677 79.3804 424.448 79.3804 425.399C79.3804 426.35 78.6096 427.121 77.6587 427.121C76.7078 427.121 75.937 426.35 75.937 425.399C75.937 424.448 76.7078 423.677 77.6587 423.677Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6587 417.719C78.6096 417.719 79.3804 418.49 79.3804 419.441C79.3804 420.392 78.6096 421.163 77.6587 421.163C76.7078 421.163 75.937 420.392 75.937 419.441C75.937 418.49 76.7078 417.719 77.6587 417.719Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 411.721C78.6198 411.721 79.3907 412.492 79.3907 413.443C79.3907 414.394 78.6198 415.164 77.669 415.164C76.7181 415.164 75.9473 414.394 75.9473 413.443C75.9473 412.492 76.7181 411.721 77.669 411.721Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 405.763C78.6198 405.763 79.3907 406.534 79.3907 407.485C79.3907 408.436 78.6198 409.206 77.669 409.206C76.7181 409.206 75.9473 408.436 75.9473 407.485C75.9473 406.534 76.7181 405.763 77.669 405.763Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 399.8C78.6198 399.8 79.3907 400.571 79.3907 401.522C79.3907 402.473 78.6198 403.243 77.669 403.243C76.7181 403.243 75.9473 402.473 75.9473 401.522C75.9473 400.571 76.7181 399.8 77.669 399.8Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 393.842C78.6198 393.842 79.3907 394.613 79.3907 395.564C79.3907 396.514 78.6198 397.285 77.669 397.285C76.7181 397.285 75.9473 396.514 75.9473 395.564C75.9473 394.613 76.7181 393.842 77.669 393.842Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 387.844C78.6198 387.844 79.3907 388.614 79.3907 389.565C79.3907 390.516 78.6198 391.287 77.669 391.287C76.7181 391.287 75.9473 390.516 75.9473 389.565C75.9473 388.614 76.7181 387.844 77.669 387.844Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.669 381.885C78.6198 381.885 79.3907 382.656 79.3907 383.607C79.3907 384.558 78.6198 385.329 77.669 385.329C76.7181 385.329 75.9473 384.558 75.9473 383.607C75.9473 382.656 76.7181 381.885 77.669 381.885Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 375.917C78.6235 375.917 79.3943 376.688 79.3943 377.639C79.3943 378.59 78.6235 379.361 77.6726 379.361C76.7218 379.361 75.9509 378.59 75.9509 377.639C75.9509 376.688 76.7218 375.917 77.6726 375.917Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 369.959C78.6235 369.959 79.3943 370.73 79.3943 371.681C79.3943 372.632 78.6235 373.402 77.6726 373.402C76.7218 373.402 75.9509 372.632 75.9509 371.681C75.9509 370.73 76.7218 369.959 77.6726 369.959Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 363.946C78.6235 363.946 79.3943 364.717 79.3943 365.668C79.3943 366.618 78.6235 367.389 77.6726 367.389C76.7218 367.389 75.9509 366.618 75.9509 365.668C75.9509 364.717 76.7218 363.946 77.6726 363.946Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 357.988C78.6235 357.988 79.3943 358.759 79.3943 359.709C79.3943 360.66 78.6235 361.431 77.6726 361.431C76.7218 361.431 75.9509 360.66 75.9509 359.709C75.9509 358.759 76.7218 357.988 77.6726 357.988Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6639 352.04C78.6147 352.04 79.3856 352.81 79.3856 353.761C79.3856 354.712 78.6147 355.483 77.6639 355.483C76.713 355.483 75.9421 354.712 75.9421 353.761C75.9421 352.81 76.713 352.04 77.6639 352.04Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6639 346.081C78.6147 346.081 79.3856 346.852 79.3856 347.803C79.3856 348.754 78.6147 349.525 77.6639 349.525C76.713 349.525 75.9421 348.754 75.9421 347.803C75.9421 346.852 76.713 346.081 77.6639 346.081Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 340.084C78.6235 340.084 79.3943 340.855 79.3943 341.806C79.3943 342.757 78.6235 343.528 77.6726 343.528C76.7218 343.528 75.9509 342.757 75.9509 341.806C75.9509 340.855 76.7218 340.084 77.6726 340.084Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 334.126C78.6235 334.126 79.3943 334.897 79.3943 335.848C79.3943 336.799 78.6235 337.57 77.6726 337.57C76.7218 337.57 75.9509 336.799 75.9509 335.848C75.9509 334.897 76.7218 334.126 77.6726 334.126Z"
              fill="#B8B7B7"
            />
            <path
              d="M77.6726 328.162C78.6235 328.162 79.3943 328.933 79.3943 329.884C79.3943 330.835 78.6235 331.606 77.6726 331.606C76.7218 331.606 75.9509 330.835 75.9509 329.884C75.9509 328.933 76.7218 328.162 77.6726 328.162Z"
              fill="#B8B7B7"
            />
            <path
              d="M62.3294 450.534C63.2802 450.534 64.0511 451.304 64.0511 452.255C64.0511 453.206 63.2802 453.977 62.3294 453.977C61.3785 453.977 60.6077 453.206 60.6077 452.255C60.6077 451.304 61.3785 450.534 62.3294 450.534Z"
              fill="#B8B7B7"
            />
            <path
              d="M72.5315 450.545C73.4824 450.545 74.2532 451.316 74.2532 452.266C74.2532 453.217 73.4824 453.988 72.5315 453.988C71.5806 453.988 70.8098 453.217 70.8098 452.266C70.8098 451.316 71.5806 450.545 72.5315 450.545Z"
              fill="#B8B7B7"
            />
          </g>
          <mask
            id="mask2_16_5373"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="25"
            y="341"
            style={{ width: "19", height: "542" }}
            width="19"
            height="542"
          >
            <path
              d="M43.5442 352.162C43.5442 346.426 39.4849 341.771 34.4804 341.771C29.4758 341.771 25.4165 346.42 25.4165 352.162"
              fill="#CFCFCF"
            />
            <path
              d="M43.5493 352.16L43.5493 882.167H25.4217L25.4217 352.16H43.5493Z"
              fill="#CFCFCF"
            />
          </mask>
          <g mask="url(#mask2_16_5373)">
            <path
              d="M28.304 877.508C29.2548 877.508 30.0257 878.279 30.0257 879.23C30.0257 880.181 29.2548 880.952 28.304 880.952C27.3531 880.952 26.5823 880.181 26.5823 879.23C26.5823 878.279 27.3531 877.508 28.304 877.508Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.304 871.551C29.2548 871.551 30.0257 872.322 30.0257 873.273C30.0257 874.224 29.2548 874.995 28.304 874.995C27.3531 874.995 26.5823 874.224 26.5823 873.273C26.5823 872.322 27.3531 871.551 28.304 871.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 865.553C29.2636 865.553 30.0345 866.324 30.0345 867.275C30.0345 868.226 29.2636 868.996 28.3128 868.996C27.3619 868.996 26.5911 868.226 26.5911 867.275C26.5911 866.324 27.3619 865.553 28.3128 865.553Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 859.595C29.2636 859.595 30.0345 860.366 30.0345 861.317C30.0345 862.268 29.2636 863.038 28.3128 863.038C27.3619 863.038 26.5911 862.268 26.5911 861.317C26.5911 860.366 27.3619 859.595 28.3128 859.595Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 853.63C29.2636 853.63 30.0345 854.401 30.0345 855.352C30.0345 856.303 29.2636 857.074 28.3128 857.074C27.3619 857.074 26.5911 856.303 26.5911 855.352C26.5911 854.401 27.3619 853.63 28.3128 853.63Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 847.674C29.2636 847.674 30.0345 848.445 30.0345 849.395C30.0345 850.346 29.2636 851.117 28.3128 851.117C27.3619 851.117 26.5911 850.346 26.5911 849.395C26.5911 848.445 27.3619 847.674 28.3128 847.674Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 841.676C29.2636 841.676 30.0345 842.446 30.0345 843.397C30.0345 844.348 29.2636 845.119 28.3128 845.119C27.3619 845.119 26.5911 844.348 26.5911 843.397C26.5911 842.446 27.3619 841.676 28.3128 841.676Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3128 835.717C29.2636 835.717 30.0345 836.488 30.0345 837.439C30.0345 838.39 29.2636 839.161 28.3128 839.161C27.3619 839.161 26.5911 838.39 26.5911 837.439C26.5911 836.488 27.3619 835.717 28.3128 835.717Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 829.749C29.2685 829.749 30.0393 830.52 30.0393 831.471C30.0393 832.422 29.2685 833.193 28.3176 833.193C27.3668 833.193 26.5959 832.422 26.5959 831.471C26.5959 830.52 27.3668 829.749 28.3176 829.749Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 823.791C29.2685 823.791 30.0393 824.562 30.0393 825.513C30.0393 826.464 29.2685 827.235 28.3176 827.235C27.3668 827.235 26.5959 826.464 26.5959 825.513C26.5959 824.562 27.3668 823.791 28.3176 823.791Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 817.778C29.2685 817.778 30.0393 818.548 30.0393 819.499C30.0393 820.45 29.2685 821.221 28.3176 821.221C27.3668 821.221 26.5959 820.45 26.5959 819.499C26.5959 818.548 27.3668 817.778 28.3176 817.778Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 811.82C29.2685 811.82 30.0393 812.59 30.0393 813.541C30.0393 814.492 29.2685 815.263 28.3176 815.263C27.3668 815.263 26.5959 814.492 26.5959 813.541C26.5959 812.59 27.3668 811.82 28.3176 811.82Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3076 805.872C29.2585 805.872 30.0294 806.643 30.0294 807.593C30.0294 808.544 29.2585 809.315 28.3076 809.315C27.3568 809.315 26.5859 808.544 26.5859 807.593C26.5859 806.643 27.3568 805.872 28.3076 805.872Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3076 799.914C29.2585 799.914 30.0294 800.684 30.0294 801.635C30.0294 802.586 29.2585 803.357 28.3076 803.357C27.3568 803.357 26.5859 802.586 26.5859 801.635C26.5859 800.684 27.3568 799.914 28.3076 799.914Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 793.915C29.2685 793.915 30.0393 794.686 30.0393 795.637C30.0393 796.588 29.2685 797.359 28.3176 797.359C27.3668 797.359 26.5959 796.588 26.5959 795.637C26.5959 794.686 27.3668 793.915 28.3176 793.915Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 787.957C29.2685 787.957 30.0393 788.728 30.0393 789.679C30.0393 790.63 29.2685 791.401 28.3176 791.401C27.3668 791.401 26.5959 790.63 26.5959 789.679C26.5959 788.728 27.3668 787.957 28.3176 787.957Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 781.994C29.2685 781.994 30.0393 782.765 30.0393 783.716C30.0393 784.667 29.2685 785.437 28.3176 785.437C27.3668 785.437 26.5959 784.667 26.5959 783.716C26.5959 782.765 27.3668 781.994 28.3176 781.994Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 776.036C29.2685 776.036 30.0393 776.807 30.0393 777.758C30.0393 778.709 29.2685 779.479 28.3176 779.479C27.3668 779.479 26.5959 778.709 26.5959 777.758C26.5959 776.807 27.3668 776.036 28.3176 776.036Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 770.038C29.2685 770.038 30.0393 770.808 30.0393 771.759C30.0393 772.71 29.2685 773.481 28.3176 773.481C27.3668 773.481 26.5959 772.71 26.5959 771.759C26.5959 770.808 27.3668 770.038 28.3176 770.038Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 764.08C29.2685 764.08 30.0393 764.85 30.0393 765.801C30.0393 766.752 29.2685 767.523 28.3176 767.523C27.3668 767.523 26.5959 766.752 26.5959 765.801C26.5959 764.85 27.3668 764.08 28.3176 764.08Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 880.473C34.3923 880.473 35.1631 881.243 35.1631 882.194C35.1631 883.145 34.3923 883.916 33.4414 883.916C32.4906 883.916 31.7197 883.145 31.7197 882.194C31.7197 881.243 32.4906 880.473 33.4414 880.473Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4326 874.525C34.3835 874.525 35.1544 875.295 35.1544 876.246C35.1544 877.197 34.3835 877.968 33.4326 877.968C32.4818 877.968 31.7109 877.197 31.7109 876.246C31.7109 875.295 32.4818 874.525 33.4326 874.525Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4326 868.567C34.3835 868.567 35.1543 869.337 35.1543 870.288C35.1543 871.239 34.3835 872.01 33.4326 872.01C32.4818 872.01 31.7109 871.239 31.7109 870.288C31.7109 869.337 32.4818 868.567 33.4326 868.567Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 862.568C34.3923 862.568 35.1631 863.339 35.1631 864.29C35.1631 865.241 34.3923 866.012 33.4414 866.012C32.4906 866.012 31.7197 865.241 31.7197 864.29C31.7197 863.339 32.4906 862.568 33.4414 862.568Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 856.61C34.3923 856.61 35.1631 857.381 35.1631 858.332C35.1631 859.283 34.3923 860.054 33.4414 860.054C32.4906 860.054 31.7197 859.283 31.7197 858.332C31.7197 857.381 32.4906 856.61 33.4414 856.61Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 850.647C34.3923 850.647 35.1631 851.418 35.1631 852.369C35.1631 853.32 34.3923 854.09 33.4414 854.09C32.4906 854.09 31.7197 853.32 31.7197 852.369C31.7197 851.418 32.4906 850.647 33.4414 850.647Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 844.689C34.3923 844.689 35.1631 845.46 35.1631 846.411C35.1631 847.362 34.3923 848.132 33.4414 848.132C32.4906 848.132 31.7197 847.362 31.7197 846.411C31.7197 845.46 32.4906 844.689 33.4414 844.689Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 838.691C34.3923 838.691 35.1631 839.462 35.1631 840.412C35.1631 841.363 34.3923 842.134 33.4414 842.134C32.4906 842.134 31.7197 841.363 31.7197 840.412C31.7197 839.462 32.4906 838.691 33.4414 838.691Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4414 832.733C34.3923 832.733 35.1631 833.503 35.1631 834.454C35.1631 835.405 34.3923 836.176 33.4414 836.176C32.4906 836.176 31.7197 835.405 31.7197 834.454C31.7197 833.503 32.4906 832.733 33.4414 832.733Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 826.765C34.396 826.765 35.1668 827.535 35.1668 828.486C35.1668 829.437 34.396 830.208 33.4451 830.208C32.4942 830.208 31.7234 829.437 31.7234 828.486C31.7234 827.535 32.4942 826.765 33.4451 826.765Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 820.807C34.396 820.807 35.1668 821.577 35.1668 822.528C35.1668 823.479 34.396 824.25 33.4451 824.25C32.4942 824.25 31.7234 823.479 31.7234 822.528C31.7234 821.577 32.4942 820.807 33.4451 820.807Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 814.793C34.396 814.793 35.1668 815.564 35.1668 816.515C35.1668 817.465 34.396 818.236 33.4451 818.236C32.4942 818.236 31.7234 817.465 31.7234 816.515C31.7234 815.564 32.4942 814.793 33.4451 814.793Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 808.835C34.396 808.835 35.1668 809.606 35.1668 810.557C35.1668 811.507 34.396 812.278 33.4451 812.278C32.4942 812.278 31.7234 811.507 31.7234 810.557C31.7234 809.606 32.4942 808.835 33.4451 808.835Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4363 802.887C34.3871 802.887 35.158 803.658 35.158 804.609C35.158 805.56 34.3871 806.33 33.4363 806.33C32.4854 806.33 31.7146 805.56 31.7146 804.609C31.7146 803.658 32.4854 802.887 33.4363 802.887Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4363 796.929C34.3871 796.929 35.158 797.7 35.158 798.651C35.158 799.602 34.3871 800.372 33.4363 800.372C32.4854 800.372 31.7146 799.602 31.7146 798.651C31.7146 797.7 32.4854 796.929 33.4363 796.929Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 790.932C34.396 790.932 35.1668 791.703 35.1668 792.653C35.1668 793.604 34.396 794.375 33.4451 794.375C32.4942 794.375 31.7234 793.604 31.7234 792.653C31.7234 791.703 32.4942 790.932 33.4451 790.932Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 784.974C34.396 784.974 35.1668 785.745 35.1668 786.696C35.1668 787.646 34.396 788.417 33.4451 788.417C32.4942 788.417 31.7234 787.646 31.7234 786.696C31.7234 785.745 32.4942 784.974 33.4451 784.974Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 779.01C34.396 779.01 35.1668 779.78 35.1668 780.731C35.1668 781.682 34.396 782.453 33.4451 782.453C32.4942 782.453 31.7234 781.682 31.7234 780.731C31.7234 779.78 32.4942 779.01 33.4451 779.01Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 773.051C34.396 773.051 35.1668 773.822 35.1668 774.773C35.1668 775.724 34.396 776.495 33.4451 776.495C32.4942 776.495 31.7234 775.724 31.7234 774.773C31.7234 773.822 32.4942 773.051 33.4451 773.051Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 767.054C34.396 767.054 35.1668 767.825 35.1668 768.776C35.1668 769.727 34.396 770.498 33.4451 770.498C32.4942 770.498 31.7234 769.727 31.7234 768.776C31.7234 767.825 32.4942 767.054 33.4451 767.054Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 761.096C34.396 761.096 35.1668 761.867 35.1668 762.818C35.1668 763.769 34.396 764.54 33.4451 764.54C32.4942 764.54 31.7234 763.769 31.7234 762.818C31.7234 761.867 32.4942 761.096 33.4451 761.096Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5059 877.508C39.4568 877.508 40.2276 878.279 40.2276 879.23C40.2276 880.181 39.4568 880.951 38.5059 880.951C37.555 880.951 36.7842 880.181 36.7842 879.23C36.7842 878.279 37.555 877.508 38.5059 877.508Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5059 871.551C39.4568 871.551 40.2276 872.322 40.2276 873.273C40.2276 874.224 39.4568 874.995 38.5059 874.995C37.555 874.995 36.7842 874.224 36.7842 873.273C36.7842 872.322 37.555 871.551 38.5059 871.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 865.553C39.4655 865.553 40.2364 866.324 40.2364 867.275C40.2364 868.226 39.4655 868.997 38.5147 868.997C37.5638 868.997 36.793 868.226 36.793 867.275C36.793 866.324 37.5638 865.553 38.5147 865.553Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 859.595C39.4655 859.595 40.2364 860.366 40.2364 861.317C40.2364 862.268 39.4655 863.038 38.5147 863.038C37.5638 863.038 36.793 862.268 36.793 861.317C36.793 860.366 37.5638 859.595 38.5147 859.595Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 853.63C39.4655 853.63 40.2364 854.401 40.2364 855.352C40.2364 856.303 39.4655 857.074 38.5147 857.074C37.5638 857.074 36.793 856.303 36.793 855.352C36.793 854.401 37.5638 853.63 38.5147 853.63Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 847.674C39.4655 847.674 40.2364 848.445 40.2364 849.395C40.2364 850.346 39.4655 851.117 38.5147 851.117C37.5638 851.117 36.793 850.346 36.793 849.395C36.793 848.445 37.5638 847.674 38.5147 847.674Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 841.676C39.4655 841.676 40.2364 842.446 40.2364 843.397C40.2364 844.348 39.4655 845.119 38.5147 845.119C37.5638 845.119 36.793 844.348 36.793 843.397C36.793 842.446 37.5638 841.676 38.5147 841.676Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5147 835.717C39.4655 835.717 40.2364 836.488 40.2364 837.439C40.2364 838.39 39.4655 839.161 38.5147 839.161C37.5638 839.161 36.793 838.39 36.793 837.439C36.793 836.488 37.5638 835.717 38.5147 835.717Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 829.749C39.4707 829.749 40.2415 830.52 40.2415 831.471C40.2415 832.422 39.4707 833.193 38.5198 833.193C37.5689 833.193 36.7981 832.422 36.7981 831.471C36.7981 830.52 37.5689 829.749 38.5198 829.749Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 823.791C39.4707 823.791 40.2415 824.562 40.2415 825.513C40.2415 826.464 39.4707 827.235 38.5198 827.235C37.5689 827.235 36.7981 826.464 36.7981 825.513C36.7981 824.562 37.5689 823.791 38.5198 823.791Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 817.778C39.4707 817.778 40.2415 818.548 40.2415 819.499C40.2415 820.45 39.4707 821.221 38.5198 821.221C37.5689 821.221 36.7981 820.45 36.7981 819.499C36.7981 818.548 37.5689 817.778 38.5198 817.778Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 811.82C39.4707 811.82 40.2415 812.59 40.2415 813.541C40.2415 814.492 39.4707 815.263 38.5198 815.263C37.5689 815.263 36.7981 814.492 36.7981 813.541C36.7981 812.59 37.5689 811.82 38.5198 811.82Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5096 805.872C39.4604 805.872 40.2313 806.643 40.2313 807.593C40.2313 808.544 39.4604 809.315 38.5096 809.315C37.5587 809.315 36.7878 808.544 36.7878 807.593C36.7878 806.643 37.5587 805.872 38.5096 805.872Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5096 799.914C39.4604 799.914 40.2313 800.684 40.2313 801.635C40.2313 802.586 39.4604 803.357 38.5096 803.357C37.5587 803.357 36.7878 802.586 36.7878 801.635C36.7878 800.684 37.5587 799.914 38.5096 799.914Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 793.915C39.4707 793.915 40.2415 794.686 40.2415 795.637C40.2415 796.588 39.4707 797.359 38.5198 797.359C37.5689 797.359 36.7981 796.588 36.7981 795.637C36.7981 794.686 37.5689 793.915 38.5198 793.915Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 787.957C39.4707 787.957 40.2415 788.728 40.2415 789.679C40.2415 790.63 39.4707 791.401 38.5198 791.401C37.5689 791.401 36.7981 790.63 36.7981 789.679C36.7981 788.728 37.5689 787.957 38.5198 787.957Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 781.994C39.4707 781.994 40.2415 782.765 40.2415 783.716C40.2415 784.667 39.4707 785.437 38.5198 785.437C37.5689 785.437 36.7981 784.667 36.7981 783.716C36.7981 782.765 37.5689 781.994 38.5198 781.994Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 776.036C39.4707 776.036 40.2415 776.807 40.2415 777.758C40.2415 778.709 39.4707 779.479 38.5198 779.479C37.5689 779.479 36.7981 778.709 36.7981 777.758C36.7981 776.807 37.5689 776.036 38.5198 776.036Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 770.038C39.4707 770.038 40.2415 770.808 40.2415 771.759C40.2415 772.71 39.4707 773.481 38.5198 773.481C37.5689 773.481 36.7981 772.71 36.7981 771.759C36.7981 770.808 37.5689 770.038 38.5198 770.038Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 764.08C39.4707 764.08 40.2415 764.85 40.2415 765.801C40.2415 766.752 39.4707 767.523 38.5198 767.523C37.5689 767.523 36.7981 766.752 36.7981 765.801C36.7981 764.85 37.5689 764.08 38.5198 764.08Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 880.483C44.583 880.483 45.3538 881.254 45.3538 882.204C45.3538 883.155 44.583 883.926 43.6321 883.926C42.6812 883.926 41.9104 883.155 41.9104 882.204C41.9104 881.254 42.6812 880.483 43.6321 880.483Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6233 874.535C44.5742 874.535 45.345 875.306 45.345 876.257C45.345 877.207 44.5742 877.978 43.6233 877.978C42.6724 877.978 41.9016 877.207 41.9016 876.257C41.9016 875.306 42.6724 874.535 43.6233 874.535Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6233 868.577C44.5742 868.577 45.345 869.348 45.345 870.298C45.345 871.249 44.5742 872.02 43.6233 872.02C42.6724 872.02 41.9016 871.249 41.9016 870.298C41.9016 869.348 42.6724 868.577 43.6233 868.577Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 862.58C44.5829 862.58 45.3538 863.351 45.3538 864.301C45.3538 865.252 44.5829 866.023 43.6321 866.023C42.6812 866.023 41.9104 865.252 41.9104 864.301C41.9104 863.351 42.6812 862.58 43.6321 862.58Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 856.622C44.5829 856.622 45.3538 857.392 45.3538 858.343C45.3538 859.294 44.5829 860.065 43.6321 860.065C42.6812 860.065 41.9104 859.294 41.9104 858.343C41.9104 857.392 42.6812 856.622 43.6321 856.622Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 850.657C44.5829 850.657 45.3538 851.428 45.3538 852.379C45.3538 853.33 44.5829 854.101 43.6321 854.101C42.6812 854.101 41.9104 853.33 41.9104 852.379C41.9104 851.428 42.6812 850.657 43.6321 850.657Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 844.699C44.583 844.699 45.3538 845.47 45.3538 846.421C45.3538 847.372 44.583 848.142 43.6321 848.142C42.6812 848.142 41.9104 847.372 41.9104 846.421C41.9104 845.47 42.6812 844.699 43.6321 844.699Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 838.702C44.583 838.702 45.3538 839.473 45.3538 840.424C45.3538 841.375 44.583 842.145 43.6321 842.145C42.6812 842.145 41.9104 841.375 41.9104 840.424C41.9104 839.473 42.6812 838.702 43.6321 838.702Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6321 832.744C44.583 832.744 45.3538 833.515 45.3538 834.466C45.3538 835.417 44.583 836.187 43.6321 836.187C42.6812 836.187 41.9104 835.417 41.9104 834.466C41.9104 833.515 42.6812 832.744 43.6321 832.744Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 826.776C44.5878 826.776 45.3587 827.547 45.3587 828.498C45.3587 829.448 44.5878 830.219 43.637 830.219C42.6861 830.219 41.9153 829.448 41.9153 828.498C41.9153 827.547 42.6861 826.776 43.637 826.776Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 820.818C44.5878 820.818 45.3587 821.589 45.3587 822.539C45.3587 823.49 44.5878 824.261 43.637 824.261C42.6861 824.261 41.9153 823.49 41.9153 822.539C41.9153 821.589 42.6861 820.818 43.637 820.818Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 814.804C44.5878 814.804 45.3587 815.575 45.3587 816.526C45.3587 817.477 44.5878 818.248 43.637 818.248C42.6861 818.248 41.9153 817.477 41.9153 816.526C41.9153 815.575 42.6861 814.804 43.637 814.804Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 808.846C44.5878 808.846 45.3587 809.617 45.3587 810.568C45.3587 811.519 44.5878 812.29 43.637 812.29C42.6861 812.29 41.9153 811.519 41.9153 810.568C41.9153 809.617 42.6861 808.846 43.637 808.846Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6282 802.898C44.5791 802.898 45.3499 803.669 45.3499 804.62C45.3499 805.571 44.5791 806.342 43.6282 806.342C42.6773 806.342 41.9065 805.571 41.9065 804.62C41.9065 803.669 42.6773 802.898 43.6282 802.898Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6282 796.94C44.5791 796.94 45.3499 797.711 45.3499 798.662C45.3499 799.613 44.5791 800.384 43.6282 800.384C42.6773 800.384 41.9065 799.613 41.9065 798.662C41.9065 797.711 42.6773 796.94 43.6282 796.94Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 790.942C44.5878 790.942 45.3587 791.713 45.3587 792.664C45.3587 793.614 44.5878 794.385 43.637 794.385C42.6861 794.385 41.9153 793.614 41.9153 792.664C41.9153 791.713 42.6861 790.942 43.637 790.942Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 784.984C44.5878 784.984 45.3587 785.755 45.3587 786.705C45.3587 787.656 44.5878 788.427 43.637 788.427C42.6861 788.427 41.9153 787.656 41.9153 786.705C41.9153 785.755 42.6861 784.984 43.637 784.984Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 779.021C44.5878 779.021 45.3587 779.792 45.3587 780.742C45.3587 781.693 44.5878 782.464 43.637 782.464C42.6861 782.464 41.9153 781.693 41.9153 780.742C41.9153 779.792 42.6861 779.021 43.637 779.021Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 773.063C44.5878 773.063 45.3587 773.833 45.3587 774.784C45.3587 775.735 44.5878 776.506 43.637 776.506C42.6861 776.506 41.9153 775.735 41.9153 774.784C41.9153 773.833 42.6861 773.063 43.637 773.063Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 767.064C44.5878 767.064 45.3587 767.835 45.3587 768.786C45.3587 769.737 44.5878 770.508 43.637 770.508C42.6861 770.508 41.9153 769.737 41.9153 768.786C41.9153 767.835 42.6861 767.064 43.637 767.064Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.637 761.106C44.5878 761.106 45.3587 761.877 45.3587 762.828C45.3587 763.779 44.5878 764.549 43.637 764.549C42.6861 764.549 41.9153 763.779 41.9153 762.828C41.9153 761.877 42.6861 761.106 43.637 761.106Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 752.158C29.3918 752.158 30.1626 752.929 30.1626 753.88C30.1626 754.831 29.3918 755.602 28.4409 755.602C27.4901 755.602 26.7192 754.831 26.7192 753.88C26.7192 752.929 27.4901 752.158 28.4409 752.158Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 746.201C29.3918 746.201 30.1626 746.972 30.1626 747.923C30.1626 748.874 29.3918 749.645 28.4409 749.645C27.4901 749.645 26.7192 748.874 26.7192 747.923C26.7192 746.972 27.4901 746.201 28.4409 746.201Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 740.188C29.3918 740.188 30.1626 740.959 30.1626 741.91C30.1626 742.861 29.3918 743.632 28.4409 743.632C27.4901 743.632 26.7192 742.861 26.7192 741.91C26.7192 740.959 27.4901 740.188 28.4409 740.188Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 734.23C29.3918 734.23 30.1626 735.001 30.1626 735.952C30.1626 736.903 29.3918 737.673 28.4409 737.673C27.4901 737.673 26.7192 736.903 26.7192 735.952C26.7192 735.001 27.4901 734.23 28.4409 734.23Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4307 728.281C29.3815 728.281 30.1524 729.052 30.1524 730.003C30.1524 730.953 29.3815 731.724 28.4307 731.724C27.4798 731.724 26.709 730.953 26.709 730.003C26.709 729.052 27.4798 728.281 28.4307 728.281Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4307 722.324C29.3815 722.324 30.1524 723.095 30.1524 724.046C30.1524 724.996 29.3815 725.767 28.4307 725.767C27.4798 725.767 26.709 724.996 26.709 724.046C26.709 723.095 27.4798 722.324 28.4307 722.324Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 716.326C29.3918 716.326 30.1626 717.096 30.1626 718.047C30.1626 718.998 29.3918 719.769 28.4409 719.769C27.4901 719.769 26.7192 718.998 26.7192 718.047C26.7192 717.096 27.4901 716.326 28.4409 716.326Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 710.367C29.3918 710.367 30.1626 711.138 30.1626 712.089C30.1626 713.04 29.3918 713.811 28.4409 713.811C27.4901 713.811 26.7192 713.04 26.7192 712.089C26.7192 711.138 27.4901 710.367 28.4409 710.367Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 704.403C29.3918 704.403 30.1626 705.174 30.1626 706.125C30.1626 707.076 29.3918 707.847 28.4409 707.847C27.4901 707.847 26.7192 707.076 26.7192 706.125C26.7192 705.174 27.4901 704.403 28.4409 704.403Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 698.446C29.3918 698.446 30.1626 699.217 30.1626 700.168C30.1626 701.119 29.3918 701.89 28.4409 701.89C27.4901 701.89 26.7192 701.119 26.7192 700.168C26.7192 699.217 27.4901 698.446 28.4409 698.446Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 692.448C29.3918 692.448 30.1626 693.219 30.1626 694.17C30.1626 695.121 29.3918 695.892 28.4409 695.892C27.4901 695.892 26.7192 695.121 26.7192 694.17C26.7192 693.219 27.4901 692.448 28.4409 692.448Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4409 686.49C29.3918 686.49 30.1626 687.261 30.1626 688.212C30.1626 689.163 29.3918 689.933 28.4409 689.933C27.4901 689.933 26.7192 689.163 26.7192 688.212C26.7192 687.261 27.4901 686.49 28.4409 686.49Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 680.522C29.3955 680.522 30.1663 681.293 30.1663 682.243C30.1663 683.194 29.3955 683.965 28.4446 683.965C27.4937 683.965 26.7229 683.194 26.7229 682.243C26.7229 681.293 27.4937 680.522 28.4446 680.522Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 674.564C29.3955 674.564 30.1663 675.335 30.1663 676.285C30.1663 677.236 29.3955 678.007 28.4446 678.007C27.4937 678.007 26.7229 677.236 26.7229 676.285C26.7229 675.335 27.4937 674.564 28.4446 674.564Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 668.55C29.3955 668.55 30.1663 669.321 30.1663 670.272C30.1663 671.223 29.3955 671.994 28.4446 671.994C27.4937 671.994 26.7229 671.223 26.7229 670.272C26.7229 669.321 27.4937 668.55 28.4446 668.55Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 662.592C29.3955 662.592 30.1663 663.363 30.1663 664.314C30.1663 665.265 29.3955 666.036 28.4446 666.036C27.4937 666.036 26.7229 665.265 26.7229 664.314C26.7229 663.363 27.4937 662.592 28.4446 662.592Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4358 656.644C29.3867 656.644 30.1575 657.415 30.1575 658.366C30.1575 659.317 29.3867 660.088 28.4358 660.088C27.4849 660.088 26.7141 659.317 26.7141 658.366C26.7141 657.415 27.4849 656.644 28.4358 656.644Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4358 650.686C29.3867 650.686 30.1575 651.457 30.1575 652.408C30.1575 653.359 29.3867 654.13 28.4358 654.13C27.4849 654.13 26.7141 653.359 26.7141 652.408C26.7141 651.457 27.4849 650.686 28.4358 650.686Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 644.688C29.3955 644.688 30.1663 645.459 30.1663 646.41C30.1663 647.36 29.3955 648.131 28.4446 648.131C27.4937 648.131 26.7229 647.36 26.7229 646.41C26.7229 645.459 27.4937 644.688 28.4446 644.688Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 638.73C29.3955 638.73 30.1663 639.501 30.1663 640.452C30.1663 641.402 29.3955 642.173 28.4446 642.173C27.4937 642.173 26.7229 641.402 26.7229 640.452C26.7229 639.501 27.4937 638.73 28.4446 638.73Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 632.767C29.3955 632.767 30.1663 633.537 30.1663 634.488C30.1663 635.439 29.3955 636.21 28.4446 636.21C27.4937 636.21 26.7229 635.439 26.7229 634.488C26.7229 633.537 27.4937 632.767 28.4446 632.767Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 626.809C29.3955 626.809 30.1663 627.579 30.1663 628.53C30.1663 629.481 29.3955 630.252 28.4446 630.252C27.4937 630.252 26.7229 629.481 26.7229 628.53C26.7229 627.579 27.4937 626.809 28.4446 626.809Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 620.81C29.3955 620.81 30.1663 621.581 30.1663 622.532C30.1663 623.483 29.3955 624.254 28.4446 624.254C27.4937 624.254 26.7229 623.483 26.7229 622.532C26.7229 621.581 27.4937 620.81 28.4446 620.81Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.4446 614.852C29.3955 614.852 30.1663 615.623 30.1663 616.574C30.1663 617.525 29.3955 618.296 28.4446 618.296C27.4937 618.296 26.7229 617.525 26.7229 616.574C26.7229 615.623 27.4937 614.852 28.4446 614.852Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 755.133C34.406 755.133 35.1768 755.904 35.1768 756.855C35.1768 757.806 34.406 758.576 33.4551 758.576C32.5042 758.576 31.7334 757.806 31.7334 756.855C31.7334 755.904 32.5042 755.133 33.4551 755.133Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 749.175C34.406 749.175 35.1768 749.946 35.1768 750.897C35.1768 751.848 34.406 752.618 33.4551 752.618C32.5042 752.618 31.7334 751.848 31.7334 750.897C31.7334 749.946 32.5042 749.175 33.4551 749.175Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 743.161C34.406 743.161 35.1768 743.932 35.1768 744.883C35.1768 745.834 34.406 746.605 33.4551 746.605C32.5042 746.605 31.7334 745.834 31.7334 744.883C31.7334 743.932 32.5042 743.161 33.4551 743.161Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 737.203C34.406 737.203 35.1768 737.974 35.1768 738.925C35.1768 739.876 34.406 740.647 33.4551 740.647C32.5042 740.647 31.7334 739.876 31.7334 738.925C31.7334 737.974 32.5042 737.203 33.4551 737.203Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 731.255C34.396 731.255 35.1668 732.026 35.1668 732.977C35.1668 733.928 34.396 734.699 33.4451 734.699C32.4942 734.699 31.7234 733.928 31.7234 732.977C31.7234 732.026 32.4942 731.255 33.4451 731.255Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4451 725.297C34.396 725.297 35.1668 726.068 35.1668 727.019C35.1668 727.97 34.396 728.741 33.4451 728.741C32.4942 728.741 31.7234 727.97 31.7234 727.019C31.7234 726.068 32.4942 725.297 33.4451 725.297Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 719.299C34.406 719.299 35.1768 720.07 35.1768 721.021C35.1768 721.972 34.406 722.742 33.4551 722.742C32.5042 722.742 31.7334 721.972 31.7334 721.021C31.7334 720.07 32.5042 719.299 33.4551 719.299Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 713.341C34.406 713.341 35.1768 714.112 35.1768 715.063C35.1768 716.014 34.406 716.784 33.4551 716.784C32.5042 716.784 31.7334 716.014 31.7334 715.063C31.7334 714.112 32.5042 713.341 33.4551 713.341Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 707.378C34.406 707.378 35.1768 708.149 35.1768 709.1C35.1768 710.05 34.406 710.821 33.4551 710.821C32.5042 710.821 31.7334 710.05 31.7334 709.1C31.7334 708.149 32.5042 707.378 33.4551 707.378Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 701.42C34.406 701.42 35.1768 702.191 35.1768 703.142C35.1768 704.092 34.406 704.863 33.4551 704.863C32.5042 704.863 31.7334 704.092 31.7334 703.142C31.7334 702.191 32.5042 701.42 33.4551 701.42Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 695.421C34.406 695.421 35.1768 696.192 35.1768 697.143C35.1768 698.094 34.406 698.865 33.4551 698.865C32.5042 698.865 31.7334 698.094 31.7334 697.143C31.7334 696.192 32.5042 695.421 33.4551 695.421Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.4551 689.463C34.406 689.463 35.1768 690.234 35.1768 691.185C35.1768 692.136 34.406 692.907 33.4551 692.907C32.5042 692.907 31.7334 692.136 31.7334 691.185C31.7334 690.234 32.5042 689.463 33.4551 689.463Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 683.495C34.4099 683.495 35.1807 684.266 35.1807 685.217C35.1807 686.168 34.4099 686.939 33.459 686.939C32.5081 686.939 31.7373 686.168 31.7373 685.217C31.7373 684.266 32.5081 683.495 33.459 683.495Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 677.537C34.4099 677.537 35.1807 678.308 35.1807 679.259C35.1807 680.21 34.4099 680.98 33.459 680.98C32.5081 680.98 31.7373 680.21 31.7373 679.259C31.7373 678.308 32.5081 677.537 33.459 677.537Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 671.524C34.4099 671.524 35.1807 672.295 35.1807 673.246C35.1807 674.196 34.4099 674.967 33.459 674.967C32.5081 674.967 31.7373 674.196 31.7373 673.246C31.7373 672.295 32.5081 671.524 33.459 671.524Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 665.566C34.4099 665.566 35.1807 666.336 35.1807 667.287C35.1807 668.238 34.4099 669.009 33.459 669.009C32.5081 669.009 31.7373 668.238 31.7373 667.287C31.7373 666.336 32.5081 665.566 33.459 665.566Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.45 659.618C34.4008 659.618 35.1717 660.389 35.1717 661.339C35.1717 662.29 34.4008 663.061 33.45 663.061C32.4991 663.061 31.7283 662.29 31.7283 661.339C31.7283 660.389 32.4991 659.618 33.45 659.618Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.45 653.659C34.4008 653.659 35.1717 654.43 35.1717 655.381C35.1717 656.332 34.4008 657.103 33.45 657.103C32.4991 657.103 31.7283 656.332 31.7283 655.381C31.7283 654.43 32.4991 653.659 33.45 653.659Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 647.662C34.4099 647.662 35.1807 648.433 35.1807 649.384C35.1807 650.335 34.4099 651.106 33.459 651.106C32.5081 651.106 31.7373 650.335 31.7373 649.384C31.7373 648.433 32.5081 647.662 33.459 647.662Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 641.704C34.4099 641.704 35.1807 642.475 35.1807 643.426C35.1807 644.377 34.4099 645.148 33.459 645.148C32.5081 645.148 31.7373 644.377 31.7373 643.426C31.7373 642.475 32.5081 641.704 33.459 641.704Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 635.74C34.4099 635.74 35.1807 636.511 35.1807 637.462C35.1807 638.413 34.4099 639.184 33.459 639.184C32.5081 639.184 31.7373 638.413 31.7373 637.462C31.7373 636.511 32.5081 635.74 33.459 635.74Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 629.782C34.4099 629.782 35.1807 630.553 35.1807 631.504C35.1807 632.454 34.4099 633.225 33.459 633.225C32.5081 633.225 31.7373 632.454 31.7373 631.504C31.7373 630.553 32.5081 629.782 33.459 629.782Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 623.785C34.4099 623.785 35.1807 624.556 35.1807 625.507C35.1807 626.457 34.4099 627.228 33.459 627.228C32.5081 627.228 31.7373 626.457 31.7373 625.507C31.7373 624.556 32.5081 623.785 33.459 623.785Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.459 617.827C34.4099 617.827 35.1807 618.598 35.1807 619.549C35.1807 620.499 34.4099 621.27 33.459 621.27C32.5081 621.27 31.7373 620.499 31.7373 619.549C31.7373 618.598 32.5081 617.827 33.459 617.827Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 752.148C39.5334 752.148 40.3042 752.919 40.3042 753.87C40.3042 754.821 39.5334 755.592 38.5825 755.592C37.6317 755.592 36.8608 754.821 36.8608 753.87C36.8608 752.919 37.6317 752.148 38.5825 752.148Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 746.19C39.5334 746.19 40.3042 746.961 40.3042 747.912C40.3042 748.863 39.5334 749.634 38.5825 749.634C37.6317 749.634 36.8608 748.863 36.8608 747.912C36.8608 746.961 37.6317 746.19 38.5825 746.19Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 740.177C39.5334 740.177 40.3042 740.948 40.3042 741.898C40.3042 742.849 39.5334 743.62 38.5825 743.62C37.6317 743.62 36.8608 742.849 36.8608 741.898C36.8608 740.948 37.6317 740.177 38.5825 740.177Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 734.219C39.5334 734.219 40.3042 734.989 40.3042 735.94C40.3042 736.891 39.5334 737.662 38.5825 737.662C37.6317 737.662 36.8608 736.891 36.8608 735.94C36.8608 734.989 37.6317 734.219 38.5825 734.219Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5737 728.271C39.5246 728.271 40.2954 729.042 40.2954 729.992C40.2954 730.943 39.5246 731.714 38.5737 731.714C37.6229 731.714 36.8521 730.943 36.8521 729.992C36.8521 729.042 37.6229 728.271 38.5737 728.271Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5737 722.313C39.5246 722.313 40.2954 723.084 40.2954 724.034C40.2954 724.985 39.5246 725.756 38.5737 725.756C37.6229 725.756 36.8521 724.985 36.8521 724.034C36.8521 723.084 37.6229 722.313 38.5737 722.313Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 716.316C39.5334 716.316 40.3042 717.086 40.3042 718.037C40.3042 718.988 39.5334 719.759 38.5825 719.759C37.6317 719.759 36.8608 718.988 36.8608 718.037C36.8608 717.086 37.6317 716.316 38.5825 716.316Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 710.357C39.5334 710.357 40.3042 711.128 40.3042 712.079C40.3042 713.03 39.5334 713.801 38.5825 713.801C37.6317 713.801 36.8608 713.03 36.8608 712.079C36.8608 711.128 37.6317 710.357 38.5825 710.357Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 704.393C39.5334 704.393 40.3042 705.164 40.3042 706.115C40.3042 707.066 39.5334 707.837 38.5825 707.837C37.6317 707.837 36.8608 707.066 36.8608 706.115C36.8608 705.164 37.6317 704.393 38.5825 704.393Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 698.435C39.5334 698.435 40.3042 699.206 40.3042 700.157C40.3042 701.108 39.5334 701.879 38.5825 701.879C37.6317 701.879 36.8608 701.108 36.8608 700.157C36.8608 699.206 37.6317 698.435 38.5825 698.435Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 692.438C39.5334 692.438 40.3042 693.209 40.3042 694.16C40.3042 695.111 39.5334 695.881 38.5825 695.881C37.6317 695.881 36.8608 695.111 36.8608 694.16C36.8608 693.209 37.6317 692.438 38.5825 692.438Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5825 686.48C39.5334 686.48 40.3042 687.251 40.3042 688.202C40.3042 689.153 39.5334 689.923 38.5825 689.923C37.6317 689.923 36.8608 689.153 36.8608 688.202C36.8608 687.251 37.6317 686.48 38.5825 686.48Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 680.511C39.5385 680.511 40.3094 681.281 40.3094 682.232C40.3094 683.183 39.5385 683.954 38.5877 683.954C37.6368 683.954 36.866 683.183 36.866 682.232C36.866 681.281 37.6368 680.511 38.5877 680.511Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 674.554C39.5385 674.554 40.3094 675.325 40.3094 676.275C40.3094 677.226 39.5385 677.997 38.5877 677.997C37.6368 677.997 36.866 677.226 36.866 676.275C36.866 675.325 37.6368 674.554 38.5877 674.554Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 668.54C39.5385 668.54 40.3094 669.311 40.3094 670.262C40.3094 671.213 39.5385 671.984 38.5877 671.984C37.6368 671.984 36.866 671.213 36.866 670.262C36.866 669.311 37.6368 668.54 38.5877 668.54Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 662.582C39.5385 662.582 40.3094 663.353 40.3094 664.304C40.3094 665.255 39.5385 666.026 38.5877 666.026C37.6368 666.026 36.866 665.255 36.866 664.304C36.866 663.353 37.6368 662.582 38.5877 662.582Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5789 656.633C39.5298 656.633 40.3006 657.404 40.3006 658.355C40.3006 659.306 39.5298 660.076 38.5789 660.076C37.628 660.076 36.8572 659.306 36.8572 658.355C36.8572 657.404 37.628 656.633 38.5789 656.633Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5789 650.676C39.5298 650.676 40.3006 651.447 40.3006 652.398C40.3006 653.349 39.5298 654.12 38.5789 654.12C37.628 654.12 36.8572 653.349 36.8572 652.398C36.8572 651.447 37.628 650.676 38.5789 650.676Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 644.678C39.5385 644.678 40.3094 645.449 40.3094 646.399C40.3094 647.35 39.5385 648.121 38.5877 648.121C37.6368 648.121 36.866 647.35 36.866 646.399C36.866 645.449 37.6368 644.678 38.5877 644.678Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 638.72C39.5385 638.72 40.3093 639.491 40.3093 640.441C40.3093 641.392 39.5385 642.163 38.5877 642.163C37.6368 642.163 36.866 641.392 36.866 640.441C36.866 639.491 37.6368 638.72 38.5877 638.72Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 632.755C39.5385 632.755 40.3093 633.526 40.3093 634.477C40.3093 635.428 39.5385 636.199 38.5877 636.199C37.6368 636.199 36.866 635.428 36.866 634.477C36.866 633.526 37.6368 632.755 38.5877 632.755Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 626.799C39.5385 626.799 40.3094 627.569 40.3094 628.52C40.3094 629.471 39.5385 630.242 38.5877 630.242C37.6368 630.242 36.866 629.471 36.866 628.52C36.866 627.569 37.6368 626.799 38.5877 626.799Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 620.8C39.5385 620.8 40.3094 621.571 40.3094 622.522C40.3094 623.473 39.5385 624.244 38.5877 624.244C37.6368 624.244 36.866 623.473 36.866 622.522C36.866 621.571 37.6368 620.8 38.5877 620.8Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5877 614.842C39.5385 614.842 40.3094 615.613 40.3094 616.564C40.3094 617.515 39.5385 618.286 38.5877 618.286C37.6368 618.286 36.866 617.515 36.866 616.564C36.866 615.613 37.6368 614.842 38.5877 614.842Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 755.133C44.6081 755.133 45.3789 755.904 45.3789 756.855C45.3789 757.806 44.6081 758.576 43.6572 758.576C42.7064 758.576 41.9355 757.806 41.9355 756.855C41.9355 755.904 42.7064 755.133 43.6572 755.133Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 749.175C44.6081 749.175 45.3789 749.946 45.3789 750.897C45.3789 751.848 44.6081 752.618 43.6572 752.618C42.7064 752.618 41.9355 751.848 41.9355 750.897C41.9355 749.946 42.7064 749.175 43.6572 749.175Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 743.161C44.6081 743.161 45.3789 743.932 45.3789 744.883C45.3789 745.834 44.6081 746.605 43.6572 746.605C42.7064 746.605 41.9355 745.834 41.9355 744.883C41.9355 743.932 42.7064 743.161 43.6572 743.161Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 737.203C44.6081 737.203 45.3789 737.974 45.3789 738.925C45.3789 739.876 44.6081 740.647 43.6572 740.647C42.7064 740.647 41.9355 739.876 41.9355 738.925C41.9355 737.974 42.7064 737.203 43.6572 737.203Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.647 731.255C44.5979 731.255 45.3687 732.026 45.3687 732.977C45.3687 733.928 44.5979 734.699 43.647 734.699C42.6961 734.699 41.9253 733.928 41.9253 732.977C41.9253 732.026 42.6961 731.255 43.647 731.255Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.647 725.297C44.5979 725.297 45.3687 726.068 45.3687 727.019C45.3687 727.97 44.5979 728.741 43.647 728.741C42.6961 728.741 41.9253 727.97 41.9253 727.019C41.9253 726.068 42.6961 725.297 43.647 725.297Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 719.299C44.6081 719.299 45.3789 720.07 45.3789 721.021C45.3789 721.972 44.6081 722.742 43.6572 722.742C42.7064 722.742 41.9355 721.972 41.9355 721.021C41.9355 720.07 42.7064 719.299 43.6572 719.299Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 713.341C44.6081 713.341 45.3789 714.112 45.3789 715.063C45.3789 716.014 44.6081 716.784 43.6572 716.784C42.7064 716.784 41.9355 716.014 41.9355 715.063C41.9355 714.112 42.7064 713.341 43.6572 713.341Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 707.378C44.6081 707.378 45.3789 708.149 45.3789 709.1C45.3789 710.05 44.6081 710.821 43.6572 710.821C42.7064 710.821 41.9355 710.05 41.9355 709.1C41.9355 708.149 42.7064 707.378 43.6572 707.378Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 701.42C44.6081 701.42 45.3789 702.191 45.3789 703.142C45.3789 704.092 44.6081 704.863 43.6572 704.863C42.7064 704.863 41.9355 704.092 41.9355 703.142C41.9355 702.191 42.7064 701.42 43.6572 701.42Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 695.421C44.6081 695.421 45.3789 696.192 45.3789 697.143C45.3789 698.094 44.6081 698.865 43.6572 698.865C42.7064 698.865 41.9355 698.094 41.9355 697.143C41.9355 696.192 42.7064 695.421 43.6572 695.421Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6572 689.463C44.6081 689.463 45.3789 690.234 45.3789 691.185C45.3789 692.136 44.6081 692.907 43.6572 692.907C42.7064 692.907 41.9355 692.136 41.9355 691.185C41.9355 690.234 42.7064 689.463 43.6572 689.463Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 683.495C44.6118 683.495 45.3826 684.266 45.3826 685.217C45.3826 686.168 44.6118 686.939 43.6609 686.939C42.71 686.939 41.9392 686.168 41.9392 685.217C41.9392 684.266 42.71 683.495 43.6609 683.495Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 677.537C44.6118 677.537 45.3826 678.308 45.3826 679.259C45.3826 680.21 44.6118 680.98 43.6609 680.98C42.71 680.98 41.9392 680.21 41.9392 679.259C41.9392 678.308 42.71 677.537 43.6609 677.537Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 671.524C44.6118 671.524 45.3826 672.295 45.3826 673.246C45.3826 674.196 44.6118 674.967 43.6609 674.967C42.71 674.967 41.9392 674.196 41.9392 673.246C41.9392 672.295 42.71 671.524 43.6609 671.524Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 665.566C44.6118 665.566 45.3826 666.336 45.3826 667.287C45.3826 668.238 44.6118 669.009 43.6609 669.009C42.71 669.009 41.9392 668.238 41.9392 667.287C41.9392 666.336 42.71 665.566 43.6609 665.566Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6521 659.618C44.603 659.618 45.3738 660.389 45.3738 661.339C45.3738 662.29 44.603 663.061 43.6521 663.061C42.7013 663.061 41.9304 662.29 41.9304 661.339C41.9304 660.389 42.7013 659.618 43.6521 659.618Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6521 653.659C44.603 653.659 45.3738 654.43 45.3738 655.381C45.3738 656.332 44.603 657.103 43.6521 657.103C42.7013 657.103 41.9304 656.332 41.9304 655.381C41.9304 654.43 42.7013 653.659 43.6521 653.659Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 647.662C44.6118 647.662 45.3826 648.433 45.3826 649.384C45.3826 650.335 44.6118 651.106 43.6609 651.106C42.71 651.106 41.9392 650.335 41.9392 649.384C41.9392 648.433 42.71 647.662 43.6609 647.662Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 641.704C44.6118 641.704 45.3826 642.475 45.3826 643.426C45.3826 644.377 44.6118 645.148 43.6609 645.148C42.71 645.148 41.9392 644.377 41.9392 643.426C41.9392 642.475 42.71 641.704 43.6609 641.704Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 635.74C44.6118 635.74 45.3826 636.511 45.3826 637.462C45.3826 638.413 44.6118 639.184 43.6609 639.184C42.71 639.184 41.9392 638.413 41.9392 637.462C41.9392 636.511 42.71 635.74 43.6609 635.74Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 629.782C44.6118 629.782 45.3826 630.553 45.3826 631.504C45.3826 632.454 44.6118 633.225 43.6609 633.225C42.71 633.225 41.9392 632.454 41.9392 631.504C41.9392 630.553 42.71 629.782 43.6609 629.782Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 623.785C44.6118 623.785 45.3826 624.556 45.3826 625.507C45.3826 626.457 44.6118 627.228 43.6609 627.228C42.71 627.228 41.9392 626.457 41.9392 625.507C41.9392 624.556 42.71 623.785 43.6609 623.785Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.6609 617.827C44.6118 617.827 45.3826 618.598 45.3826 619.549C45.3826 620.499 44.6118 621.27 43.6609 621.27C42.71 621.27 41.9392 620.499 41.9392 619.549C41.9392 618.598 42.71 617.827 43.6609 617.827Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.3176 758.112C29.2685 758.112 30.0393 758.882 30.0393 759.833C30.0393 760.784 29.2685 761.555 28.3176 761.555C27.3668 761.555 26.5959 760.784 26.5959 759.833C26.5959 758.882 27.3668 758.112 28.3176 758.112Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.5198 758.123C39.4707 758.123 40.2415 758.894 40.2415 759.844C40.2415 760.795 39.4707 761.566 38.5198 761.566C37.5689 761.566 36.7981 760.795 36.7981 759.844C36.7981 758.894 37.5689 758.123 38.5198 758.123Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 615.172C29.6096 615.172 30.3804 615.943 30.3804 616.894C30.3804 617.845 29.6096 618.616 28.6587 618.616C27.7078 618.616 26.937 617.845 26.937 616.894C26.937 615.943 27.7078 615.172 28.6587 615.172Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 609.216C29.6096 609.216 30.3804 609.986 30.3804 610.937C30.3804 611.888 29.6096 612.659 28.6587 612.659C27.7078 612.659 26.937 611.888 26.937 610.937C26.937 609.986 27.7078 609.216 28.6587 609.216Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 603.202C29.6096 603.202 30.3804 603.973 30.3804 604.924C30.3804 605.875 29.6096 606.646 28.6587 606.646C27.7078 606.646 26.937 605.875 26.937 604.924C26.937 603.973 27.7078 603.202 28.6587 603.202Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 597.244C29.6096 597.244 30.3804 598.015 30.3804 598.966C30.3804 599.917 29.6096 600.687 28.6587 600.687C27.7078 600.687 26.937 599.917 26.937 598.966C26.937 598.015 27.7078 597.244 28.6587 597.244Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6499 591.295C29.6008 591.295 30.3716 592.066 30.3716 593.016C30.3716 593.967 29.6008 594.738 28.6499 594.738C27.6991 594.738 26.9282 593.967 26.9282 593.016C26.9282 592.066 27.6991 591.295 28.6499 591.295Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6499 585.338C29.6008 585.338 30.3716 586.109 30.3716 587.06C30.3716 588.01 29.6008 588.781 28.6499 588.781C27.6991 588.781 26.9282 588.01 26.9282 587.06C26.9282 586.109 27.6991 585.338 28.6499 585.338Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 579.34C29.6096 579.34 30.3804 580.111 30.3804 581.061C30.3804 582.012 29.6096 582.783 28.6587 582.783C27.7078 582.783 26.937 582.012 26.937 581.061C26.937 580.111 27.7078 579.34 28.6587 579.34Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 573.382C29.6096 573.382 30.3804 574.152 30.3804 575.103C30.3804 576.054 29.6096 576.825 28.6587 576.825C27.7078 576.825 26.937 576.054 26.937 575.103C26.937 574.152 27.7078 573.382 28.6587 573.382Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 567.417C29.6096 567.417 30.3804 568.188 30.3804 569.139C30.3804 570.09 29.6096 570.861 28.6587 570.861C27.7078 570.861 26.937 570.09 26.937 569.139C26.937 568.188 27.7078 567.417 28.6587 567.417Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 561.46C29.6096 561.46 30.3804 562.231 30.3804 563.182C30.3804 564.133 29.6096 564.904 28.6587 564.904C27.7078 564.904 26.937 564.133 26.937 563.182C26.937 562.231 27.7078 561.46 28.6587 561.46Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 555.462C29.6096 555.462 30.3804 556.233 30.3804 557.184C30.3804 558.135 29.6096 558.906 28.6587 558.906C27.7078 558.906 26.937 558.135 26.937 557.184C26.937 556.233 27.7078 555.462 28.6587 555.462Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6587 549.504C29.6096 549.504 30.3804 550.275 30.3804 551.226C30.3804 552.177 29.6096 552.947 28.6587 552.947C27.7078 552.947 26.937 552.177 26.937 551.226C26.937 550.275 27.7078 549.504 28.6587 549.504Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 543.536C29.6145 543.536 30.3853 544.307 30.3853 545.258C30.3853 546.209 29.6145 546.979 28.6636 546.979C27.7127 546.979 26.9419 546.209 26.9419 545.258C26.9419 544.307 27.7127 543.536 28.6636 543.536Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 537.578C29.6145 537.578 30.3853 538.349 30.3853 539.3C30.3853 540.25 29.6145 541.021 28.6636 541.021C27.7127 541.021 26.9419 540.25 26.9419 539.3C26.9419 538.349 27.7127 537.578 28.6636 537.578Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 531.564C29.6145 531.564 30.3853 532.335 30.3853 533.286C30.3853 534.237 29.6145 535.008 28.6636 535.008C27.7127 535.008 26.9419 534.237 26.9419 533.286C26.9419 532.335 27.7127 531.564 28.6636 531.564Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 525.606C29.6145 525.606 30.3853 526.377 30.3853 527.328C30.3853 528.279 29.6145 529.05 28.6636 529.05C27.7127 529.05 26.9419 528.279 26.9419 527.328C26.9419 526.377 27.7127 525.606 28.6636 525.606Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6536 519.658C29.6045 519.658 30.3753 520.429 30.3753 521.38C30.3753 522.331 29.6045 523.102 28.6536 523.102C27.7027 523.102 26.9319 522.331 26.9319 521.38C26.9319 520.429 27.7027 519.658 28.6536 519.658Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6536 513.7C29.6045 513.7 30.3753 514.471 30.3753 515.422C30.3753 516.373 29.6045 517.144 28.6536 517.144C27.7027 517.144 26.9319 516.373 26.9319 515.422C26.9319 514.471 27.7027 513.7 28.6536 513.7Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 507.702C29.6145 507.702 30.3853 508.473 30.3853 509.424C30.3853 510.374 29.6145 511.145 28.6636 511.145C27.7127 511.145 26.9419 510.374 26.9419 509.424C26.9419 508.473 27.7127 507.702 28.6636 507.702Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 501.744C29.6145 501.744 30.3853 502.515 30.3853 503.465C30.3853 504.416 29.6145 505.187 28.6636 505.187C27.7127 505.187 26.9419 504.416 26.9419 503.465C26.9419 502.515 27.7127 501.744 28.6636 501.744Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 495.781C29.6145 495.781 30.3853 496.552 30.3853 497.502C30.3853 498.453 29.6145 499.224 28.6636 499.224C27.7127 499.224 26.9419 498.453 26.9419 497.502C26.9419 496.552 27.7127 495.781 28.6636 495.781Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 489.823C29.6145 489.823 30.3853 490.594 30.3853 491.544C30.3853 492.495 29.6145 493.266 28.6636 493.266C27.7127 493.266 26.9419 492.495 26.9419 491.544C26.9419 490.594 27.7127 489.823 28.6636 489.823Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 483.824C29.6145 483.824 30.3853 484.595 30.3853 485.546C30.3853 486.497 29.6145 487.268 28.6636 487.268C27.7127 487.268 26.9419 486.497 26.9419 485.546C26.9419 484.595 27.7127 483.824 28.6636 483.824Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 477.866C29.6145 477.866 30.3853 478.637 30.3853 479.588C30.3853 480.539 29.6145 481.31 28.6636 481.31C27.7127 481.31 26.9419 480.539 26.9419 479.588C26.9419 478.637 27.7127 477.866 28.6636 477.866Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 612.189C34.7382 612.189 35.5091 612.96 35.5091 613.911C35.5091 614.861 34.7382 615.632 33.7874 615.632C32.8365 615.632 32.0657 614.861 32.0657 613.911C32.0657 612.96 32.8365 612.189 33.7874 612.189Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 606.231C34.7382 606.231 35.5091 607.002 35.5091 607.953C35.5091 608.903 34.7382 609.674 33.7874 609.674C32.8365 609.674 32.0657 608.903 32.0657 607.953C32.0657 607.002 32.8365 606.231 33.7874 606.231Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 600.217C34.7382 600.217 35.5091 600.988 35.5091 601.939C35.5091 602.89 34.7382 603.661 33.7874 603.661C32.8365 603.661 32.0657 602.89 32.0657 601.939C32.0657 600.988 32.8365 600.217 33.7874 600.217Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 594.259C34.7382 594.259 35.5091 595.03 35.5091 595.981C35.5091 596.932 34.7382 597.703 33.7874 597.703C32.8365 597.703 32.0657 596.932 32.0657 595.981C32.0657 595.03 32.8365 594.259 33.7874 594.259Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7786 588.311C34.7295 588.311 35.5003 589.082 35.5003 590.033C35.5003 590.984 34.7295 591.755 33.7786 591.755C32.8277 591.755 32.0569 590.984 32.0569 590.033C32.0569 589.082 32.8277 588.311 33.7786 588.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7786 582.353C34.7295 582.353 35.5003 583.124 35.5003 584.075C35.5003 585.026 34.7295 585.797 33.7786 585.797C32.8277 585.797 32.0569 585.026 32.0569 584.075C32.0569 583.124 32.8277 582.353 33.7786 582.353Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 576.355C34.7382 576.355 35.5091 577.126 35.5091 578.077C35.5091 579.028 34.7382 579.798 33.7874 579.798C32.8365 579.798 32.0657 579.028 32.0657 578.077C32.0657 577.126 32.8365 576.355 33.7874 576.355Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 570.397C34.7382 570.397 35.5091 571.168 35.5091 572.119C35.5091 573.07 34.7382 573.84 33.7874 573.84C32.8365 573.84 32.0657 573.07 32.0657 572.119C32.0657 571.168 32.8365 570.397 33.7874 570.397Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 564.434C34.7382 564.434 35.5091 565.205 35.5091 566.155C35.5091 567.106 34.7382 567.877 33.7874 567.877C32.8365 567.877 32.0657 567.106 32.0657 566.155C32.0657 565.205 32.8365 564.434 33.7874 564.434Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 558.476C34.7382 558.476 35.5091 559.247 35.5091 560.197C35.5091 561.148 34.7382 561.919 33.7874 561.919C32.8365 561.919 32.0657 561.148 32.0657 560.197C32.0657 559.247 32.8365 558.476 33.7874 558.476Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 552.477C34.7382 552.477 35.5091 553.248 35.5091 554.199C35.5091 555.15 34.7382 555.921 33.7874 555.921C32.8365 555.921 32.0657 555.15 32.0657 554.199C32.0657 553.248 32.8365 552.477 33.7874 552.477Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7874 546.519C34.7382 546.519 35.5091 547.29 35.5091 548.241C35.5091 549.192 34.7382 549.963 33.7874 549.963C32.8365 549.963 32.0657 549.192 32.0657 548.241C32.0657 547.29 32.8365 546.519 33.7874 546.519Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 540.551C34.7419 540.551 35.5127 541.322 35.5127 542.273C35.5127 543.224 34.7419 543.995 33.791 543.995C32.8402 543.995 32.0693 543.224 32.0693 542.273C32.0693 541.322 32.8402 540.551 33.791 540.551Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 534.593C34.7419 534.593 35.5127 535.364 35.5127 536.315C35.5127 537.266 34.7419 538.037 33.791 538.037C32.8402 538.037 32.0693 537.266 32.0693 536.315C32.0693 535.364 32.8402 534.593 33.791 534.593Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 528.58C34.7419 528.58 35.5127 529.35 35.5127 530.301C35.5127 531.252 34.7419 532.023 33.791 532.023C32.8402 532.023 32.0693 531.252 32.0693 530.301C32.0693 529.35 32.8402 528.58 33.791 528.58Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 522.622C34.7419 522.622 35.5127 523.392 35.5127 524.343C35.5127 525.294 34.7419 526.065 33.791 526.065C32.8402 526.065 32.0693 525.294 32.0693 524.343C32.0693 523.392 32.8402 522.622 33.791 522.622Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7822 516.674C34.7331 516.674 35.5039 517.444 35.5039 518.395C35.5039 519.346 34.7331 520.117 33.7822 520.117C32.8314 520.117 32.0605 519.346 32.0605 518.395C32.0605 517.444 32.8314 516.674 33.7822 516.674Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7822 510.716C34.7331 510.716 35.5039 511.486 35.5039 512.437C35.5039 513.388 34.7331 514.159 33.7822 514.159C32.8314 514.159 32.0605 513.388 32.0605 512.437C32.0605 511.486 32.8314 510.716 33.7822 510.716Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 504.718C34.7419 504.718 35.5127 505.489 35.5127 506.44C35.5127 507.391 34.7419 508.162 33.791 508.162C32.8402 508.162 32.0693 507.391 32.0693 506.44C32.0693 505.489 32.8402 504.718 33.791 504.718Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 498.76C34.7419 498.76 35.5127 499.531 35.5127 500.482C35.5127 501.433 34.7419 502.204 33.791 502.204C32.8402 502.204 32.0693 501.433 32.0693 500.482C32.0693 499.531 32.8402 498.76 33.791 498.76Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 492.796C34.7419 492.796 35.5127 493.567 35.5127 494.518C35.5127 495.469 34.7419 496.239 33.791 496.239C32.8402 496.239 32.0693 495.469 32.0693 494.518C32.0693 493.567 32.8402 492.796 33.791 492.796Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 486.838C34.7419 486.838 35.5127 487.609 35.5127 488.56C35.5127 489.511 34.7419 490.281 33.791 490.281C32.8402 490.281 32.0693 489.511 32.0693 488.56C32.0693 487.609 32.8402 486.838 33.791 486.838Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 480.841C34.7419 480.841 35.5127 481.612 35.5127 482.563C35.5127 483.513 34.7419 484.284 33.791 484.284C32.8402 484.284 32.0693 483.513 32.0693 482.563C32.0693 481.612 32.8402 480.841 33.791 480.841Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 474.883C34.7419 474.883 35.5127 475.654 35.5127 476.605C35.5127 477.555 34.7419 478.326 33.791 478.326C32.8402 478.326 32.0693 477.555 32.0693 476.605C32.0693 475.654 32.8402 474.883 33.791 474.883Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 615.172C39.8115 615.172 40.5823 615.943 40.5823 616.894C40.5823 617.845 39.8115 618.616 38.8606 618.616C37.9097 618.616 37.1389 617.845 37.1389 616.894C37.1389 615.943 37.9097 615.172 38.8606 615.172Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 609.216C39.8115 609.216 40.5823 609.986 40.5823 610.937C40.5823 611.888 39.8115 612.659 38.8606 612.659C37.9097 612.659 37.1389 611.888 37.1389 610.937C37.1389 609.986 37.9097 609.216 38.8606 609.216Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 603.202C39.8115 603.202 40.5823 603.973 40.5823 604.924C40.5823 605.875 39.8115 606.646 38.8606 606.646C37.9097 606.646 37.1389 605.875 37.1389 604.924C37.1389 603.973 37.9097 603.202 38.8606 603.202Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 597.244C39.8115 597.244 40.5823 598.015 40.5823 598.966C40.5823 599.917 39.8115 600.687 38.8606 600.687C37.9097 600.687 37.1389 599.917 37.1389 598.966C37.1389 598.015 37.9097 597.244 38.8606 597.244Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8518 591.295C39.8027 591.295 40.5735 592.066 40.5735 593.016C40.5735 593.967 39.8027 594.738 38.8518 594.738C37.901 594.738 37.1301 593.967 37.1301 593.016C37.1301 592.066 37.901 591.295 38.8518 591.295Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8518 585.338C39.8027 585.338 40.5735 586.109 40.5735 587.06C40.5735 588.01 39.8027 588.781 38.8518 588.781C37.901 588.781 37.1301 588.01 37.1301 587.06C37.1301 586.109 37.901 585.338 38.8518 585.338Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 579.34C39.8115 579.34 40.5823 580.111 40.5823 581.061C40.5823 582.012 39.8115 582.783 38.8606 582.783C37.9097 582.783 37.1389 582.012 37.1389 581.061C37.1389 580.111 37.9097 579.34 38.8606 579.34Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 573.382C39.8115 573.382 40.5823 574.152 40.5823 575.103C40.5823 576.054 39.8115 576.825 38.8606 576.825C37.9097 576.825 37.1389 576.054 37.1389 575.103C37.1389 574.152 37.9097 573.382 38.8606 573.382Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 567.417C39.8115 567.417 40.5823 568.188 40.5823 569.139C40.5823 570.09 39.8115 570.861 38.8606 570.861C37.9097 570.861 37.1389 570.09 37.1389 569.139C37.1389 568.188 37.9097 567.417 38.8606 567.417Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 561.46C39.8115 561.46 40.5823 562.231 40.5823 563.182C40.5823 564.133 39.8115 564.904 38.8606 564.904C37.9097 564.904 37.1389 564.133 37.1389 563.182C37.1389 562.231 37.9097 561.46 38.8606 561.46Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 555.462C39.8115 555.462 40.5823 556.233 40.5823 557.184C40.5823 558.135 39.8115 558.906 38.8606 558.906C37.9097 558.906 37.1389 558.135 37.1389 557.184C37.1389 556.233 37.9097 555.462 38.8606 555.462Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8606 549.504C39.8115 549.504 40.5823 550.275 40.5823 551.226C40.5823 552.177 39.8115 552.947 38.8606 552.947C37.9097 552.947 37.1389 552.177 37.1389 551.226C37.1389 550.275 37.9097 549.504 38.8606 549.504Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 543.536C39.8166 543.536 40.5874 544.307 40.5874 545.258C40.5874 546.209 39.8166 546.979 38.8657 546.979C37.9149 546.979 37.144 546.209 37.144 545.258C37.144 544.307 37.9149 543.536 38.8657 543.536Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 537.578C39.8166 537.578 40.5874 538.349 40.5874 539.3C40.5874 540.251 39.8166 541.021 38.8657 541.021C37.9149 541.021 37.144 540.251 37.144 539.3C37.144 538.349 37.9149 537.578 38.8657 537.578Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 531.564C39.8166 531.564 40.5874 532.335 40.5874 533.286C40.5874 534.237 39.8166 535.008 38.8657 535.008C37.9149 535.008 37.144 534.237 37.144 533.286C37.144 532.335 37.9149 531.564 38.8657 531.564Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 525.606C39.8166 525.606 40.5874 526.377 40.5874 527.328C40.5874 528.279 39.8166 529.05 38.8657 529.05C37.9149 529.05 37.144 528.279 37.144 527.328C37.144 526.377 37.9149 525.606 38.8657 525.606Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8555 519.658C39.8064 519.658 40.5772 520.429 40.5772 521.38C40.5772 522.331 39.8064 523.102 38.8555 523.102C37.9046 523.102 37.1338 522.331 37.1338 521.38C37.1338 520.429 37.9046 519.658 38.8555 519.658Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8555 513.7C39.8064 513.7 40.5772 514.471 40.5772 515.422C40.5772 516.373 39.8064 517.144 38.8555 517.144C37.9046 517.144 37.1338 516.373 37.1338 515.422C37.1338 514.471 37.9046 513.7 38.8555 513.7Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 507.702C39.8166 507.702 40.5874 508.473 40.5874 509.424C40.5874 510.374 39.8166 511.145 38.8657 511.145C37.9149 511.145 37.144 510.374 37.144 509.424C37.144 508.473 37.9149 507.702 38.8657 507.702Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 501.744C39.8166 501.744 40.5874 502.515 40.5874 503.465C40.5874 504.416 39.8166 505.187 38.8657 505.187C37.9149 505.187 37.144 504.416 37.144 503.465C37.144 502.515 37.9149 501.744 38.8657 501.744Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 495.781C39.8166 495.781 40.5874 496.552 40.5874 497.502C40.5874 498.453 39.8166 499.224 38.8657 499.224C37.9149 499.224 37.144 498.453 37.144 497.502C37.144 496.552 37.9149 495.781 38.8657 495.781Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 489.823C39.8166 489.823 40.5874 490.594 40.5874 491.544C40.5874 492.495 39.8166 493.266 38.8657 493.266C37.9149 493.266 37.144 492.495 37.144 491.544C37.144 490.594 37.9149 489.823 38.8657 489.823Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 483.824C39.8166 483.824 40.5874 484.595 40.5874 485.546C40.5874 486.497 39.8166 487.268 38.8657 487.268C37.9149 487.268 37.144 486.497 37.144 485.546C37.144 484.595 37.9149 483.824 38.8657 483.824Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 477.866C39.8166 477.866 40.5874 478.637 40.5874 479.588C40.5874 480.539 39.8166 481.31 38.8657 481.31C37.9149 481.31 37.144 480.539 37.144 479.588C37.144 478.637 37.9149 477.866 38.8657 477.866Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 612.199C44.9289 612.199 45.6998 612.97 45.6998 613.921C45.6998 614.872 44.9289 615.642 43.9781 615.642C43.0272 615.642 42.2563 614.872 42.2563 613.921C42.2563 612.97 43.0272 612.199 43.9781 612.199Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 606.241C44.9289 606.241 45.6998 607.012 45.6998 607.963C45.6998 608.914 44.9289 609.684 43.9781 609.684C43.0272 609.684 42.2563 608.914 42.2563 607.963C42.2563 607.012 43.0272 606.241 43.9781 606.241Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 600.228C44.9289 600.228 45.6998 600.998 45.6998 601.949C45.6998 602.9 44.9289 603.671 43.9781 603.671C43.0272 603.671 42.2563 602.9 42.2563 601.949C42.2563 600.998 43.0272 600.228 43.9781 600.228Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 594.269C44.9289 594.269 45.6998 595.04 45.6998 595.991C45.6998 596.942 44.9289 597.713 43.9781 597.713C43.0272 597.713 42.2563 596.942 42.2563 595.991C42.2563 595.04 43.0272 594.269 43.9781 594.269Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9693 588.321C44.9201 588.321 45.691 589.092 45.691 590.043C45.691 590.994 44.9201 591.765 43.9693 591.765C43.0184 591.765 42.2476 590.994 42.2476 590.043C42.2476 589.092 43.0184 588.321 43.9693 588.321Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9693 582.363C44.9201 582.363 45.691 583.134 45.691 584.085C45.691 585.036 44.9201 585.807 43.9693 585.807C43.0184 585.807 42.2476 585.036 42.2476 584.085C42.2476 583.134 43.0184 582.363 43.9693 582.363Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.978 576.366C44.9289 576.366 45.6997 577.137 45.6997 578.088C45.6997 579.039 44.9289 579.81 43.978 579.81C43.0272 579.81 42.2563 579.039 42.2563 578.088C42.2563 577.137 43.0272 576.366 43.978 576.366Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 570.408C44.9289 570.408 45.6998 571.179 45.6998 572.13C45.6998 573.081 44.9289 573.852 43.9781 573.852C43.0272 573.852 42.2563 573.081 42.2563 572.13C42.2563 571.179 43.0272 570.408 43.9781 570.408Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 564.444C44.9289 564.444 45.6998 565.215 45.6998 566.166C45.6998 567.116 44.9289 567.887 43.9781 567.887C43.0272 567.887 42.2563 567.116 42.2563 566.166C42.2563 565.215 43.0272 564.444 43.9781 564.444Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 558.486C44.9289 558.486 45.6998 559.257 45.6998 560.207C45.6998 561.158 44.9289 561.929 43.9781 561.929C43.0272 561.929 42.2563 561.158 42.2563 560.207C42.2563 559.257 43.0272 558.486 43.9781 558.486Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 552.489C44.9289 552.489 45.6998 553.26 45.6998 554.21C45.6998 555.161 44.9289 555.932 43.9781 555.932C43.0272 555.932 42.2563 555.161 42.2563 554.21C42.2563 553.26 43.0272 552.489 43.9781 552.489Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9781 546.531C44.9289 546.531 45.6998 547.301 45.6998 548.252C45.6998 549.203 44.9289 549.974 43.9781 549.974C43.0272 549.974 42.2563 549.203 42.2563 548.252C42.2563 547.301 43.0272 546.531 43.9781 546.531Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 540.563C44.9338 540.563 45.7046 541.333 45.7046 542.284C45.7046 543.235 44.9338 544.006 43.9829 544.006C43.0321 544.006 42.2612 543.235 42.2612 542.284C42.2612 541.333 43.0321 540.563 43.9829 540.563Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 534.604C44.9338 534.604 45.7046 535.375 45.7046 536.326C45.7046 537.277 44.9338 538.048 43.9829 538.048C43.0321 538.048 42.2612 537.277 42.2612 536.326C42.2612 535.375 43.0321 534.604 43.9829 534.604Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 528.591C44.9338 528.591 45.7046 529.362 45.7046 530.313C45.7046 531.264 44.9338 532.034 43.9829 532.034C43.0321 532.034 42.2612 531.264 42.2612 530.313C42.2612 529.362 43.0321 528.591 43.9829 528.591Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 522.633C44.9338 522.633 45.7046 523.404 45.7046 524.355C45.7046 525.305 44.9338 526.076 43.9829 526.076C43.0321 526.076 42.2612 525.305 42.2612 524.355C42.2612 523.404 43.0321 522.633 43.9829 522.633Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9741 516.685C44.925 516.685 45.6958 517.456 45.6958 518.407C45.6958 519.358 44.925 520.128 43.9741 520.128C43.0233 520.128 42.2524 519.358 42.2524 518.407C42.2524 517.456 43.0233 516.685 43.9741 516.685Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9741 510.727C44.925 510.727 45.6958 511.498 45.6958 512.449C45.6958 513.399 44.925 514.17 43.9741 514.17C43.0233 514.17 42.2524 513.399 42.2524 512.449C42.2524 511.498 43.0233 510.727 43.9741 510.727Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 504.728C44.9338 504.728 45.7046 505.499 45.7046 506.45C45.7046 507.401 44.9338 508.172 43.9829 508.172C43.0321 508.172 42.2612 507.401 42.2612 506.45C42.2612 505.499 43.0321 504.728 43.9829 504.728Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 498.77C44.9338 498.77 45.7046 499.541 45.7046 500.492C45.7046 501.443 44.9338 502.214 43.9829 502.214C43.0321 502.214 42.2612 501.443 42.2612 500.492C42.2612 499.541 43.0321 498.77 43.9829 498.77Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 492.807C44.9338 492.807 45.7046 493.578 45.7046 494.529C45.7046 495.48 44.9338 496.251 43.9829 496.251C43.0321 496.251 42.2612 495.48 42.2612 494.529C42.2612 493.578 43.0321 492.807 43.9829 492.807Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 486.849C44.9338 486.849 45.7046 487.62 45.7046 488.571C45.7046 489.522 44.9338 490.293 43.9829 490.293C43.0321 490.293 42.2612 489.522 42.2612 488.571C42.2612 487.62 43.0321 486.849 43.9829 486.849Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 480.851C44.9338 480.851 45.7046 481.622 45.7046 482.573C45.7046 483.523 44.9338 484.294 43.9829 484.294C43.0321 484.294 42.2612 483.523 42.2612 482.573C42.2612 481.622 43.0321 480.851 43.9829 480.851Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9829 474.893C44.9338 474.893 45.7046 475.664 45.7046 476.614C45.7046 477.565 44.9338 478.336 43.9829 478.336C43.0321 478.336 42.2612 477.565 42.2612 476.614C42.2612 475.664 43.0321 474.893 43.9829 474.893Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 462.962C24.6643 462.962 25.4351 463.733 25.4351 464.683C25.4351 465.634 24.6643 466.405 23.7134 466.405C22.7625 466.405 21.9917 465.634 21.9917 464.683C21.9917 463.733 22.7625 462.962 23.7134 462.962Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 457.003C24.6643 457.003 25.4351 457.774 25.4351 458.725C25.4351 459.676 24.6643 460.447 23.7134 460.447C22.7625 460.447 21.9917 459.676 21.9917 458.725C21.9917 457.774 22.7625 457.003 23.7134 457.003Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 450.99C24.6643 450.99 25.4351 451.761 25.4351 452.712C25.4351 453.663 24.6643 454.434 23.7134 454.434C22.7625 454.434 21.9917 453.663 21.9917 452.712C21.9917 451.761 22.7625 450.99 23.7134 450.99Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 445.032C24.6643 445.032 25.4351 445.803 25.4351 446.754C25.4351 447.705 24.6643 448.475 23.7134 448.475C22.7625 448.475 21.9917 447.705 21.9917 446.754C21.9917 445.803 22.7625 445.032 23.7134 445.032Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7034 439.084C24.6543 439.084 25.4251 439.855 25.4251 440.806C25.4251 441.757 24.6543 442.527 23.7034 442.527C22.7525 442.527 21.9817 441.757 21.9817 440.806C21.9817 439.855 22.7525 439.084 23.7034 439.084Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7034 433.126C24.6543 433.126 25.4251 433.897 25.4251 434.848C25.4251 435.798 24.6543 436.569 23.7034 436.569C22.7525 436.569 21.9817 435.798 21.9817 434.848C21.9817 433.897 22.7525 433.126 23.7034 433.126Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 427.128C24.6643 427.128 25.4351 427.899 25.4351 428.849C25.4351 429.8 24.6643 430.571 23.7134 430.571C22.7625 430.571 21.9917 429.8 21.9917 428.849C21.9917 427.899 22.7625 427.128 23.7134 427.128Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 421.169C24.6643 421.169 25.4351 421.94 25.4351 422.891C25.4351 423.842 24.6643 424.613 23.7134 424.613C22.7625 424.613 21.9917 423.842 21.9917 422.891C21.9917 421.94 22.7625 421.169 23.7134 421.169Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 415.207C24.6643 415.207 25.4351 415.977 25.4351 416.928C25.4351 417.879 24.6643 418.65 23.7134 418.65C22.7625 418.65 21.9917 417.879 21.9917 416.928C21.9917 415.977 22.7625 415.207 23.7134 415.207Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 409.248C24.6643 409.248 25.4351 410.019 25.4351 410.97C25.4351 411.921 24.6643 412.692 23.7134 412.692C22.7625 412.692 21.9917 411.921 21.9917 410.97C21.9917 410.019 22.7625 409.248 23.7134 409.248Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 403.25C24.6643 403.25 25.4351 404.021 25.4351 404.972C25.4351 405.923 24.6643 406.694 23.7134 406.694C22.7625 406.694 21.9917 405.923 21.9917 404.972C21.9917 404.021 22.7625 403.25 23.7134 403.25Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7134 397.292C24.6643 397.292 25.4351 398.063 25.4351 399.014C25.4351 399.964 24.6643 400.735 23.7134 400.735C22.7625 400.735 21.9917 399.964 21.9917 399.014C21.9917 398.063 22.7625 397.292 23.7134 397.292Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7173 391.324C24.6682 391.324 25.439 392.095 25.439 393.045C25.439 393.996 24.6682 394.767 23.7173 394.767C22.7664 394.767 21.9956 393.996 21.9956 393.045C21.9956 392.095 22.7664 391.324 23.7173 391.324Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7173 385.366C24.6682 385.366 25.439 386.137 25.439 387.087C25.439 388.038 24.6682 388.809 23.7173 388.809C22.7664 388.809 21.9956 388.038 21.9956 387.087C21.9956 386.137 22.7664 385.366 23.7173 385.366Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7173 379.352C24.6682 379.352 25.439 380.123 25.439 381.074C25.439 382.025 24.6682 382.796 23.7173 382.796C22.7664 382.796 21.9956 382.025 21.9956 381.074C21.9956 380.123 22.7664 379.352 23.7173 379.352Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7173 373.394C24.6682 373.394 25.439 374.165 25.439 375.116C25.439 376.067 24.6682 376.838 23.7173 376.838C22.7664 376.838 21.9956 376.067 21.9956 375.116C21.9956 374.165 22.7664 373.394 23.7173 373.394Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7085 367.446C24.6594 367.446 25.4302 368.217 25.4302 369.168C25.4302 370.119 24.6594 370.89 23.7085 370.89C22.7576 370.89 21.9868 370.119 21.9868 369.168C21.9868 368.217 22.7576 367.446 23.7085 367.446Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7085 361.488C24.6594 361.488 25.4302 362.259 25.4302 363.21C25.4302 364.161 24.6594 364.932 23.7085 364.932C22.7576 364.932 21.9868 364.161 21.9868 363.21C21.9868 362.259 22.7576 361.488 23.7085 361.488Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7171 355.491C24.6679 355.491 25.4388 356.262 25.4388 357.213C25.4388 358.164 24.6679 358.935 23.7171 358.935C22.7662 358.935 21.9954 358.164 21.9954 357.213C21.9954 356.262 22.7662 355.491 23.7171 355.491Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7171 349.533C24.6679 349.533 25.4388 350.304 25.4388 351.255C25.4388 352.206 24.6679 352.976 23.7171 352.976C22.7662 352.976 21.9954 352.206 21.9954 351.255C21.9954 350.304 22.7662 349.533 23.7171 349.533Z"
              fill="#B8B7B7"
            />
            <path
              d="M23.7171 343.569C24.6679 343.569 25.4388 344.339 25.4388 345.29C25.4388 346.241 24.6679 347.012 23.7171 347.012C22.7662 347.012 21.9954 346.241 21.9954 345.29C21.9954 344.339 22.7662 343.569 23.7171 343.569Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 465.945C29.7377 465.945 30.5086 466.716 30.5086 467.667C30.5086 468.618 29.7377 469.389 28.7869 469.389C27.836 469.389 27.0652 468.618 27.0652 467.667C27.0652 466.716 27.836 465.945 28.7869 465.945Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 459.988C29.7377 459.988 30.5086 460.759 30.5086 461.71C30.5086 462.661 29.7377 463.432 28.7869 463.432C27.836 463.432 27.0652 462.661 27.0652 461.71C27.0652 460.759 27.836 459.988 28.7869 459.988Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 453.975C29.7377 453.975 30.5086 454.746 30.5086 455.696C30.5086 456.647 29.7377 457.418 28.7869 457.418C27.836 457.418 27.0652 456.647 27.0652 455.696C27.0652 454.746 27.836 453.975 28.7869 453.975Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 448.017C29.7378 448.017 30.5086 448.788 30.5086 449.738C30.5086 450.689 29.7378 451.46 28.7869 451.46C27.836 451.46 27.0652 450.689 27.0652 449.738C27.0652 448.788 27.836 448.017 28.7869 448.017Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7769 442.068C29.7278 442.068 30.4986 442.838 30.4986 443.789C30.4986 444.74 29.7278 445.511 28.7769 445.511C27.826 445.511 27.0552 444.74 27.0552 443.789C27.0552 442.838 27.826 442.068 28.7769 442.068Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7769 436.111C29.7278 436.111 30.4986 436.881 30.4986 437.832C30.4986 438.783 29.7278 439.554 28.7769 439.554C27.826 439.554 27.0552 438.783 27.0552 437.832C27.0552 436.881 27.826 436.111 28.7769 436.111Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 430.112C29.7377 430.112 30.5086 430.883 30.5086 431.834C30.5086 432.785 29.7377 433.556 28.7869 433.556C27.836 433.556 27.0652 432.785 27.0652 431.834C27.0652 430.883 27.836 430.112 28.7869 430.112Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 424.154C29.7377 424.154 30.5086 424.925 30.5086 425.876C30.5086 426.827 29.7377 427.598 28.7869 427.598C27.836 427.598 27.0652 426.827 27.0652 425.876C27.0652 424.925 27.836 424.154 28.7869 424.154Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 418.19C29.7377 418.19 30.5086 418.961 30.5086 419.912C30.5086 420.863 29.7377 421.633 28.7869 421.633C27.836 421.633 27.0652 420.863 27.0652 419.912C27.0652 418.961 27.836 418.19 28.7869 418.19Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 412.233C29.7377 412.233 30.5086 413.004 30.5086 413.955C30.5086 414.906 29.7377 415.676 28.7869 415.676C27.836 415.676 27.0652 414.906 27.0652 413.955C27.0652 413.004 27.836 412.233 28.7869 412.233Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 406.235C29.7377 406.235 30.5086 407.006 30.5086 407.957C30.5086 408.907 29.7377 409.678 28.7869 409.678C27.836 409.678 27.0652 408.907 27.0652 407.957C27.0652 407.006 27.836 406.235 28.7869 406.235Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7869 400.277C29.7377 400.277 30.5086 401.047 30.5086 401.998C30.5086 402.949 29.7377 403.72 28.7869 403.72C27.836 403.72 27.0652 402.949 27.0652 401.998C27.0652 401.047 27.836 400.277 28.7869 400.277Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 394.308C29.7414 394.308 30.5122 395.079 30.5122 396.03C30.5122 396.981 29.7414 397.752 28.7905 397.752C27.8397 397.752 27.0688 396.981 27.0688 396.03C27.0688 395.079 27.8397 394.308 28.7905 394.308Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 388.35C29.7414 388.35 30.5122 389.121 30.5122 390.072C30.5122 391.023 29.7414 391.794 28.7905 391.794C27.8397 391.794 27.0688 391.023 27.0688 390.072C27.0688 389.121 27.8397 388.35 28.7905 388.35Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 382.337C29.7414 382.337 30.5122 383.108 30.5122 384.059C30.5122 385.01 29.7414 385.781 28.7905 385.781C27.8397 385.781 27.0688 385.01 27.0688 384.059C27.0688 383.108 27.8397 382.337 28.7905 382.337Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 376.379C29.7414 376.379 30.5122 377.15 30.5122 378.101C30.5122 379.052 29.7414 379.822 28.7905 379.822C27.8397 379.822 27.0688 379.052 27.0688 378.101C27.0688 377.15 27.8397 376.379 28.7905 376.379Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7818 370.431C29.7326 370.431 30.5034 371.202 30.5034 372.153C30.5034 373.103 29.7326 373.874 28.7818 373.874C27.8309 373.874 27.0601 373.103 27.0601 372.153C27.0601 371.202 27.8309 370.431 28.7818 370.431Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7818 364.473C29.7326 364.473 30.5034 365.244 30.5034 366.195C30.5034 367.145 29.7326 367.916 28.7818 367.916C27.8309 367.916 27.0601 367.145 27.0601 366.195C27.0601 365.244 27.8309 364.473 28.7818 364.473Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 358.475C29.7414 358.475 30.5122 359.245 30.5122 360.196C30.5122 361.147 29.7414 361.918 28.7905 361.918C27.8397 361.918 27.0688 361.147 27.0688 360.196C27.0688 359.245 27.8397 358.475 28.7905 358.475Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 352.517C29.7414 352.517 30.5122 353.287 30.5122 354.238C30.5122 355.189 29.7414 355.96 28.7905 355.96C27.8397 355.96 27.0688 355.189 27.0688 354.238C27.0688 353.287 27.8397 352.517 28.7905 352.517Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 346.553C29.7414 346.553 30.5122 347.324 30.5122 348.275C30.5122 349.226 29.7414 349.997 28.7905 349.997C27.8397 349.997 27.0688 349.226 27.0688 348.275C27.0688 347.324 27.8397 346.553 28.7905 346.553Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.7905 340.595C29.7414 340.595 30.5122 341.366 30.5122 342.317C30.5122 343.268 29.7414 344.039 28.7905 344.039C27.8397 344.039 27.0688 343.268 27.0688 342.317C27.0688 341.366 27.8397 340.595 28.7905 340.595Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 468.92C34.7519 468.92 35.5227 469.691 35.5227 470.641C35.5227 471.592 34.7519 472.363 33.801 472.363C32.8502 472.363 32.0793 471.592 32.0793 470.641C32.0793 469.691 32.8502 468.92 33.801 468.92Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 462.962C34.7519 462.962 35.5227 463.733 35.5227 464.683C35.5227 465.634 34.7519 466.405 33.801 466.405C32.8502 466.405 32.0793 465.634 32.0793 464.683C32.0793 463.733 32.8502 462.962 33.801 462.962Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 456.948C34.7519 456.948 35.5227 457.719 35.5227 458.67C35.5227 459.621 34.7519 460.392 33.801 460.392C32.8502 460.392 32.0793 459.621 32.0793 458.67C32.0793 457.719 32.8502 456.948 33.801 456.948Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 450.99C34.7519 450.99 35.5227 451.761 35.5227 452.712C35.5227 453.663 34.7519 454.434 33.801 454.434C32.8502 454.434 32.0793 453.663 32.0793 452.712C32.0793 451.761 32.8502 450.99 33.801 450.99Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 445.042C34.7419 445.042 35.5127 445.813 35.5127 446.764C35.5127 447.715 34.7419 448.485 33.791 448.485C32.8402 448.485 32.0693 447.715 32.0693 446.764C32.0693 445.813 32.8402 445.042 33.791 445.042Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.791 439.084C34.7419 439.084 35.5127 439.855 35.5127 440.806C35.5127 441.757 34.7419 442.527 33.791 442.527C32.8402 442.527 32.0693 441.757 32.0693 440.806C32.0693 439.855 32.8402 439.084 33.791 439.084Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 433.086C34.7519 433.086 35.5227 433.857 35.5227 434.807C35.5227 435.758 34.7519 436.529 33.801 436.529C32.8502 436.529 32.0793 435.758 32.0793 434.807C32.0793 433.857 32.8502 433.086 33.801 433.086Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 427.128C34.7519 427.128 35.5227 427.899 35.5227 428.849C35.5227 429.8 34.7519 430.571 33.801 430.571C32.8502 430.571 32.0793 429.8 32.0793 428.849C32.0793 427.899 32.8502 427.128 33.801 427.128Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 421.165C34.7519 421.165 35.5227 421.935 35.5227 422.886C35.5227 423.837 34.7519 424.608 33.801 424.608C32.8502 424.608 32.0793 423.837 32.0793 422.886C32.0793 421.935 32.8502 421.165 33.801 421.165Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 415.207C34.7519 415.207 35.5227 415.977 35.5227 416.928C35.5227 417.879 34.7519 418.65 33.801 418.65C32.8502 418.65 32.0793 417.879 32.0793 416.928C32.0793 415.977 32.8502 415.207 33.801 415.207Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 409.208C34.7519 409.208 35.5227 409.979 35.5227 410.93C35.5227 411.881 34.7519 412.652 33.801 412.652C32.8502 412.652 32.0793 411.881 32.0793 410.93C32.0793 409.979 32.8502 409.208 33.801 409.208Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.801 403.25C34.7519 403.25 35.5227 404.021 35.5227 404.972C35.5227 405.923 34.7519 406.694 33.801 406.694C32.8502 406.694 32.0793 405.923 32.0793 404.972C32.0793 404.021 32.8502 403.25 33.801 403.25Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 397.282C34.7558 397.282 35.5266 398.053 35.5266 399.004C35.5266 399.955 34.7558 400.725 33.8049 400.725C32.8541 400.725 32.0833 399.955 32.0833 399.004C32.0833 398.053 32.8541 397.282 33.8049 397.282Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 391.324C34.7558 391.324 35.5266 392.095 35.5266 393.045C35.5266 393.996 34.7558 394.767 33.8049 394.767C32.8541 394.767 32.0833 393.996 32.0833 393.045C32.0833 392.095 32.8541 391.324 33.8049 391.324Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 385.311C34.7558 385.311 35.5266 386.081 35.5266 387.032C35.5266 387.983 34.7558 388.754 33.8049 388.754C32.8541 388.754 32.0833 387.983 32.0833 387.032C32.0833 386.081 32.8541 385.311 33.8049 385.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 379.352C34.7558 379.352 35.5266 380.123 35.5266 381.074C35.5266 382.025 34.7558 382.796 33.8049 382.796C32.8541 382.796 32.0833 382.025 32.0833 381.074C32.0833 380.123 32.8541 379.352 33.8049 379.352Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7959 373.404C34.7468 373.404 35.5176 374.175 35.5176 375.126C35.5176 376.077 34.7468 376.848 33.7959 376.848C32.8451 376.848 32.0742 376.077 32.0742 375.126C32.0742 374.175 32.8451 373.404 33.7959 373.404Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.7959 367.446C34.7468 367.446 35.5176 368.217 35.5176 369.168C35.5176 370.119 34.7468 370.89 33.7959 370.89C32.8451 370.89 32.0742 370.119 32.0742 369.168C32.0742 368.217 32.8451 367.446 33.7959 367.446Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 361.449C34.7558 361.449 35.5266 362.22 35.5266 363.171C35.5266 364.122 34.7558 364.893 33.8049 364.893C32.8541 364.893 32.0833 364.122 32.0833 363.171C32.0833 362.22 32.8541 361.449 33.8049 361.449Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 355.491C34.7558 355.491 35.5266 356.262 35.5266 357.213C35.5266 358.164 34.7558 358.935 33.8049 358.935C32.8541 358.935 32.0833 358.164 32.0833 357.213C32.0833 356.262 32.8541 355.491 33.8049 355.491Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 349.527C34.7558 349.527 35.5266 350.298 35.5266 351.249C35.5266 352.199 34.7558 352.97 33.8049 352.97C32.8541 352.97 32.0833 352.199 32.0833 351.249C32.0833 350.298 32.8541 349.527 33.8049 349.527Z"
              fill="#B8B7B7"
            />
            <path
              d="M33.8049 343.569C34.7558 343.569 35.5266 344.339 35.5266 345.29C35.5266 346.241 34.7558 347.012 33.8049 347.012C32.8541 347.012 32.0833 346.241 32.0833 345.29C32.0833 344.339 32.8541 343.569 33.8049 343.569Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 465.935C39.8793 465.935 40.6502 466.706 40.6502 467.657C40.6502 468.608 39.8793 469.378 38.9285 469.378C37.9776 469.378 37.2068 468.608 37.2068 467.657C37.2068 466.706 37.9776 465.935 38.9285 465.935Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 459.977C39.8793 459.977 40.6502 460.748 40.6502 461.699C40.6502 462.65 39.8793 463.42 38.9285 463.42C37.9776 463.42 37.2068 462.65 37.2068 461.699C37.2068 460.748 37.9776 459.977 38.9285 459.977Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 453.963C39.8793 453.963 40.6502 454.734 40.6502 455.685C40.6502 456.636 39.8793 457.407 38.9285 457.407C37.9776 457.407 37.2068 456.636 37.2068 455.685C37.2068 454.734 37.9776 453.963 38.9285 453.963Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 448.005C39.8794 448.005 40.6502 448.776 40.6502 449.727C40.6502 450.678 39.8794 451.449 38.9285 451.449C37.9776 451.449 37.2068 450.678 37.2068 449.727C37.2068 448.776 37.9776 448.005 38.9285 448.005Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9197 442.057C39.8706 442.057 40.6414 442.828 40.6414 443.779C40.6414 444.73 39.8706 445.501 38.9197 445.501C37.9688 445.501 37.198 444.73 37.198 443.779C37.198 442.828 37.9688 442.057 38.9197 442.057Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9197 436.099C39.8706 436.099 40.6414 436.87 40.6414 437.821C40.6414 438.772 39.8706 439.543 38.9197 439.543C37.9688 439.543 37.198 438.772 37.198 437.821C37.198 436.87 37.9688 436.099 38.9197 436.099Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 430.102C39.8794 430.102 40.6502 430.873 40.6502 431.824C40.6502 432.775 39.8794 433.546 38.9285 433.546C37.9776 433.546 37.2068 432.775 37.2068 431.824C37.2068 430.873 37.9776 430.102 38.9285 430.102Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 424.144C39.8794 424.144 40.6502 424.915 40.6502 425.866C40.6502 426.817 39.8794 427.588 38.9285 427.588C37.9776 427.588 37.2068 426.817 37.2068 425.866C37.2068 424.915 37.9776 424.144 38.9285 424.144Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 418.18C39.8794 418.18 40.6502 418.951 40.6502 419.902C40.6502 420.852 39.8794 421.623 38.9285 421.623C37.9776 421.623 37.2068 420.852 37.2068 419.902C37.2068 418.951 37.9776 418.18 38.9285 418.18Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 412.222C39.8793 412.222 40.6502 412.993 40.6502 413.944C40.6502 414.894 39.8793 415.665 38.9285 415.665C37.9776 415.665 37.2068 414.894 37.2068 413.944C37.2068 412.993 37.9776 412.222 38.9285 412.222Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 406.225C39.8793 406.225 40.6502 406.996 40.6502 407.946C40.6502 408.897 39.8793 409.668 38.9285 409.668C37.9776 409.668 37.2068 408.897 37.2068 407.946C37.2068 406.996 37.9776 406.225 38.9285 406.225Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9285 400.267C39.8793 400.267 40.6502 401.037 40.6502 401.988C40.6502 402.939 39.8793 403.71 38.9285 403.71C37.9776 403.71 37.2068 402.939 37.2068 401.988C37.2068 401.037 37.9776 400.267 38.9285 400.267Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 394.297C39.8845 394.297 40.6553 395.068 40.6553 396.019C40.6553 396.97 39.8845 397.741 38.9336 397.741C37.9827 397.741 37.2119 396.97 37.2119 396.019C37.2119 395.068 37.9827 394.297 38.9336 394.297Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 388.34C39.8845 388.34 40.6553 389.111 40.6553 390.062C40.6553 391.013 39.8845 391.784 38.9336 391.784C37.9827 391.784 37.2119 391.013 37.2119 390.062C37.2119 389.111 37.9827 388.34 38.9336 388.34Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 382.327C39.8845 382.327 40.6553 383.098 40.6553 384.049C40.6553 385 39.8845 385.77 38.9336 385.77C37.9827 385.77 37.2119 385 37.2119 384.049C37.2119 383.098 37.9827 382.327 38.9336 382.327Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 376.369C39.8845 376.369 40.6553 377.14 40.6553 378.091C40.6553 379.042 39.8845 379.812 38.9336 379.812C37.9827 379.812 37.2119 379.042 37.2119 378.091C37.2119 377.14 37.9827 376.369 38.9336 376.369Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9248 370.42C39.8757 370.42 40.6465 371.19 40.6465 372.141C40.6465 373.092 39.8757 373.863 38.9248 373.863C37.974 373.863 37.2031 373.092 37.2031 372.141C37.2031 371.19 37.974 370.42 38.9248 370.42Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9248 364.463C39.8757 364.463 40.6465 365.234 40.6465 366.185C40.6465 367.135 39.8757 367.906 38.9248 367.906C37.974 367.906 37.2031 367.135 37.2031 366.185C37.2031 365.234 37.974 364.463 38.9248 364.463Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 358.464C39.8845 358.464 40.6553 359.235 40.6553 360.186C40.6553 361.137 39.8845 361.908 38.9336 361.908C37.9827 361.908 37.2119 361.137 37.2119 360.186C37.2119 359.235 37.9827 358.464 38.9336 358.464Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 352.506C39.8845 352.506 40.6553 353.277 40.6553 354.228C40.6553 355.179 39.8845 355.95 38.9336 355.95C37.9827 355.95 37.2119 355.179 37.2119 354.228C37.2119 353.277 37.9827 352.506 38.9336 352.506Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 346.542C39.8845 346.542 40.6553 347.313 40.6553 348.264C40.6553 349.215 39.8845 349.986 38.9336 349.986C37.9827 349.986 37.2119 349.215 37.2119 348.264C37.2119 347.313 37.9827 346.542 38.9336 346.542Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.9336 340.585C39.8845 340.585 40.6553 341.356 40.6553 342.307C40.6553 343.258 39.8845 344.029 38.9336 344.029C37.9827 344.029 37.2119 343.258 37.2119 342.307C37.2119 341.356 37.9827 340.585 38.9336 340.585Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 468.92C44.9541 468.92 45.7249 469.691 45.7249 470.641C45.7249 471.592 44.9541 472.363 44.0032 472.363C43.0523 472.363 42.2815 471.592 42.2815 470.641C42.2815 469.691 43.0523 468.92 44.0032 468.92Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 462.962C44.9541 462.962 45.7249 463.733 45.7249 464.683C45.7249 465.634 44.9541 466.405 44.0032 466.405C43.0523 466.405 42.2815 465.634 42.2815 464.683C42.2815 463.733 43.0523 462.962 44.0032 462.962Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 456.948C44.9541 456.948 45.7249 457.719 45.7249 458.67C45.7249 459.621 44.9541 460.392 44.0032 460.392C43.0523 460.392 42.2815 459.621 42.2815 458.67C42.2815 457.719 43.0523 456.948 44.0032 456.948Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 450.99C44.9541 450.99 45.7249 451.761 45.7249 452.712C45.7249 453.663 44.9541 454.434 44.0032 454.434C43.0523 454.434 42.2815 453.663 42.2815 452.712C42.2815 451.761 43.0523 450.99 44.0032 450.99Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.993 445.042C44.9438 445.042 45.7147 445.813 45.7147 446.764C45.7147 447.715 44.9438 448.485 43.993 448.485C43.0421 448.485 42.2712 447.715 42.2712 446.764C42.2712 445.813 43.0421 445.042 43.993 445.042Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.993 439.084C44.9438 439.084 45.7147 439.855 45.7147 440.806C45.7147 441.757 44.9438 442.527 43.993 442.527C43.0421 442.527 42.2712 441.757 42.2712 440.806C42.2712 439.855 43.0421 439.084 43.993 439.084Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 433.086C44.9541 433.086 45.7249 433.857 45.7249 434.807C45.7249 435.758 44.9541 436.529 44.0032 436.529C43.0523 436.529 42.2815 435.758 42.2815 434.807C42.2815 433.857 43.0523 433.086 44.0032 433.086Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 427.128C44.9541 427.128 45.7249 427.899 45.7249 428.849C45.7249 429.8 44.9541 430.571 44.0032 430.571C43.0523 430.571 42.2815 429.8 42.2815 428.849C42.2815 427.899 43.0523 427.128 44.0032 427.128Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 421.165C44.9541 421.165 45.7249 421.935 45.7249 422.886C45.7249 423.837 44.9541 424.608 44.0032 424.608C43.0523 424.608 42.2815 423.837 42.2815 422.886C42.2815 421.935 43.0523 421.165 44.0032 421.165Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 415.207C44.9541 415.207 45.7249 415.977 45.7249 416.928C45.7249 417.879 44.9541 418.65 44.0032 418.65C43.0523 418.65 42.2815 417.879 42.2815 416.928C42.2815 415.977 43.0523 415.207 44.0032 415.207Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 409.208C44.9541 409.208 45.7249 409.979 45.7249 410.93C45.7249 411.881 44.9541 412.652 44.0032 412.652C43.0523 412.652 42.2815 411.881 42.2815 410.93C42.2815 409.979 43.0523 409.208 44.0032 409.208Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0032 403.25C44.9541 403.25 45.7249 404.021 45.7249 404.972C45.7249 405.923 44.9541 406.694 44.0032 406.694C43.0523 406.694 42.2815 405.923 42.2815 404.972C42.2815 404.021 43.0523 403.25 44.0032 403.25Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 397.282C44.9577 397.282 45.7285 398.053 45.7285 399.004C45.7285 399.955 44.9577 400.725 44.0069 400.725C43.056 400.725 42.2852 399.955 42.2852 399.004C42.2852 398.053 43.056 397.282 44.0069 397.282Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 391.324C44.9577 391.324 45.7285 392.095 45.7285 393.045C45.7285 393.996 44.9577 394.767 44.0069 394.767C43.056 394.767 42.2852 393.996 42.2852 393.045C42.2852 392.095 43.056 391.324 44.0069 391.324Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 385.311C44.9577 385.311 45.7285 386.081 45.7285 387.032C45.7285 387.983 44.9577 388.754 44.0069 388.754C43.056 388.754 42.2852 387.983 42.2852 387.032C42.2852 386.081 43.056 385.311 44.0069 385.311Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 379.352C44.9577 379.352 45.7285 380.123 45.7285 381.074C45.7285 382.025 44.9577 382.796 44.0069 382.796C43.056 382.796 42.2852 382.025 42.2852 381.074C42.2852 380.123 43.056 379.352 44.0069 379.352Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9981 373.404C44.949 373.404 45.7198 374.175 45.7198 375.126C45.7198 376.077 44.949 376.848 43.9981 376.848C43.0472 376.848 42.2764 376.077 42.2764 375.126C42.2764 374.175 43.0472 373.404 43.9981 373.404Z"
              fill="#B8B7B7"
            />
            <path
              d="M43.9981 367.446C44.949 367.446 45.7198 368.217 45.7198 369.168C45.7198 370.119 44.949 370.89 43.9981 370.89C43.0472 370.89 42.2764 370.119 42.2764 369.168C42.2764 368.217 43.0472 367.446 43.9981 367.446Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 361.449C44.9577 361.449 45.7285 362.22 45.7285 363.171C45.7285 364.122 44.9577 364.893 44.0069 364.893C43.056 364.893 42.2852 364.122 42.2852 363.171C42.2852 362.22 43.056 361.449 44.0069 361.449Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 355.491C44.9577 355.491 45.7285 356.262 45.7285 357.213C45.7285 358.164 44.9577 358.935 44.0069 358.935C43.056 358.935 42.2852 358.164 42.2852 357.213C42.2852 356.262 43.056 355.491 44.0069 355.491Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 349.527C44.9577 349.527 45.7285 350.298 45.7285 351.249C45.7285 352.199 44.9577 352.97 44.0069 352.97C43.056 352.97 42.2852 352.199 42.2852 351.249C42.2852 350.298 43.056 349.527 44.0069 349.527Z"
              fill="#B8B7B7"
            />
            <path
              d="M44.0069 343.569C44.9577 343.569 45.7285 344.339 45.7285 345.29C45.7285 346.241 44.9577 347.012 44.0069 347.012C43.056 347.012 42.2852 346.241 42.2852 345.29C42.2852 344.339 43.056 343.569 44.0069 343.569Z"
              fill="#B8B7B7"
            />
            <path
              d="M28.6636 471.898C29.6145 471.898 30.3853 472.669 30.3853 473.62C30.3853 474.571 29.6145 475.342 28.6636 475.342C27.7127 475.342 26.9419 474.571 26.9419 473.62C26.9419 472.669 27.7127 471.898 28.6636 471.898Z"
              fill="#B8B7B7"
            />
            <path
              d="M38.8657 471.909C39.8166 471.909 40.5874 472.68 40.5874 473.631C40.5874 474.582 39.8166 475.353 38.8657 475.353C37.9149 475.353 37.144 474.582 37.144 473.631C37.144 472.68 37.9149 471.909 38.8657 471.909Z"
              fill="#B8B7B7"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_16_5373"
          x="0"
          y="0"
          width="304.698"
          height="890.169"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16_5373"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_16_5373"
            result="effect2_dropShadow_16_5373"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_16_5373"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_16_5373">
          <rect
            width="296.698"
            height="882.169"
            fill="white"
            transform="matrix(-1 0 0 1 300.698 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
