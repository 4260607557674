import React from "react";

export const Laptop = () => {
  return (
    <svg
      width="187"
      height="207"
      viewBox="0 0 187 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: "100", width: "100" }}
    >
      <g opacity="0.5">
        <path
          d="M20.7386 128.892L115.906 172.358L146.123 106.197L50.9564 62.7314L20.7386 128.892Z"
          fill="#03684D"
        />
        <mask
          id="mask0_67_13425"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="73"
          y="82"
          style={{ widht: "38", height: "33" }}
          width="38"
          height="33"
        >
          <path
            d="M73.3617 102.258L101.197 114.971L110.226 95.2021L82.3907 82.489L73.3617 102.258Z"
            fill="#B8B7B7"
          />
        </mask>
        <g mask="url(#mask0_67_13425)">
          <path
            d="M74.0221 103.503C74.1468 103.23 74.0266 102.907 73.7535 102.783C73.4805 102.658 73.1581 102.778 73.0334 103.051C72.9087 103.324 73.0289 103.647 73.3019 103.772C73.575 103.896 73.8974 103.776 74.0221 103.503Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.733 104.284C75.8577 104.011 75.7375 103.689 75.4644 103.564C75.1914 103.439 74.8689 103.56 74.7442 103.833C74.6195 104.106 74.7398 104.428 75.0128 104.553C75.2859 104.678 75.6083 104.557 75.733 104.284Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.4474 105.065C77.5721 104.792 77.4519 104.47 77.1788 104.345C76.9058 104.221 76.5834 104.341 76.4587 104.614C76.3339 104.887 76.4542 105.209 76.7272 105.334C77.0003 105.459 77.3227 105.338 77.4474 105.065Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.1583 105.847C79.283 105.574 79.1628 105.251 78.8897 105.127C78.6167 105.002 78.2942 105.122 78.1695 105.395C78.0448 105.668 78.1651 105.991 78.4381 106.116C78.7112 106.24 79.0336 106.12 79.1583 105.847Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.885 106.636C81.0097 106.363 80.8894 106.04 80.6164 105.915C80.3434 105.791 80.0209 105.911 79.8962 106.184C79.7715 106.457 79.8918 106.78 80.1648 106.904C80.4378 107.029 80.7603 106.909 80.885 106.636Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.5959 107.417C82.7206 107.144 82.6003 106.822 82.3273 106.697C82.0542 106.572 81.7318 106.692 81.6071 106.965C81.4824 107.238 81.6026 107.561 81.8757 107.686C82.1487 107.81 82.4712 107.69 82.5959 107.417Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.3026 108.2C84.4273 107.927 84.307 107.604 84.034 107.479C83.761 107.355 83.4385 107.475 83.3138 107.748C83.1891 108.021 83.3094 108.344 83.5824 108.468C83.8554 108.593 84.1779 108.473 84.3026 108.2Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.0135 108.981C86.1382 108.708 86.018 108.386 85.7449 108.261C85.4719 108.136 85.1495 108.256 85.0248 108.529C84.9 108.803 85.0203 109.125 85.2933 109.25C85.5664 109.374 85.8888 109.254 86.0135 108.981Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.7371 109.765C87.8618 109.492 87.7416 109.17 87.4685 109.045C87.1955 108.92 86.873 109.04 86.7483 109.314C86.6236 109.587 86.7439 109.909 87.0169 110.034C87.29 110.158 87.6124 110.038 87.7371 109.765Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.448 110.547C89.5727 110.274 89.4524 109.951 89.1794 109.826C88.9063 109.702 88.5839 109.822 88.4592 110.095C88.3345 110.368 88.4547 110.69 88.7278 110.815C89.0008 110.94 89.3233 110.82 89.448 110.547Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.1603 111.329C91.285 111.056 91.1647 110.733 90.8917 110.608C90.6186 110.484 90.2962 110.604 90.1715 110.877C90.0468 111.15 90.167 111.473 90.4401 111.597C90.7131 111.722 91.0355 111.602 91.1603 111.329Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.8712 112.11C92.9959 111.837 92.8756 111.515 92.6026 111.39C92.3296 111.265 92.0071 111.385 91.8824 111.658C91.7577 111.931 91.878 112.254 92.151 112.379C92.424 112.503 92.7465 112.383 92.8712 112.11Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.5937 112.897C94.7184 112.624 94.5981 112.301 94.3251 112.176C94.052 112.052 93.7296 112.172 93.6049 112.445C93.4802 112.718 93.6004 113.041 93.8735 113.165C94.1465 113.29 94.469 113.17 94.5937 112.897Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.3044 113.678C96.4291 113.405 96.3089 113.083 96.0358 112.958C95.7628 112.833 95.4404 112.953 95.3156 113.226C95.1909 113.5 95.3112 113.822 95.5842 113.947C95.8573 114.071 96.1797 113.951 96.3044 113.678Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.8294 101.635C73.9541 101.362 73.8338 101.04 73.5608 100.915C73.2878 100.79 72.9653 100.91 72.8406 101.183C72.7159 101.457 72.8362 101.779 73.1092 101.904C73.3822 102.028 73.7047 101.908 73.8294 101.635Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.5518 102.422C75.6765 102.149 75.5563 101.826 75.2832 101.702C75.0102 101.577 74.6877 101.697 74.563 101.97C74.4383 102.243 74.5586 102.566 74.8316 102.69C75.1046 102.815 75.4271 102.695 75.5518 102.422Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.2627 103.203C77.3874 102.93 77.2671 102.608 76.9941 102.483C76.721 102.358 76.3986 102.478 76.2739 102.751C76.1492 103.025 76.2694 103.347 76.5425 103.472C76.8155 103.596 77.138 103.476 77.2627 103.203Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.977 103.984C79.1017 103.711 78.9815 103.389 78.7084 103.264C78.4354 103.139 78.113 103.26 77.9883 103.533C77.8635 103.806 77.9838 104.128 78.2568 104.253C78.5299 104.378 78.8523 104.257 78.977 103.984Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.6879 104.766C80.8126 104.493 80.6924 104.17 80.4193 104.046C80.1463 103.921 79.8238 104.041 79.6991 104.314C79.5744 104.587 79.6947 104.91 79.9677 105.034C80.2408 105.159 80.5632 105.039 80.6879 104.766Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.4146 105.554C82.5394 105.281 82.4191 104.959 82.1461 104.834C81.873 104.71 81.5506 104.83 81.4259 105.103C81.3012 105.376 81.4214 105.698 81.6945 105.823C81.9675 105.948 82.2899 105.827 82.4146 105.554Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.1254 106.336C84.2501 106.063 84.1299 105.74 83.8568 105.616C83.5838 105.491 83.2613 105.611 83.1366 105.884C83.0119 106.157 83.1322 106.48 83.4052 106.604C83.6783 106.729 84.0007 106.609 84.1254 106.336Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.8323 107.118C85.957 106.845 85.8368 106.523 85.5637 106.398C85.2907 106.274 84.9682 106.394 84.8435 106.667C84.7188 106.94 84.8391 107.262 85.1121 107.387C85.3852 107.512 85.7076 107.391 85.8323 107.118Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.5433 107.9C87.668 107.627 87.5478 107.304 87.2747 107.18C87.0017 107.055 86.6792 107.175 86.5545 107.448C86.4298 107.721 86.5501 108.044 86.8231 108.168C87.0962 108.293 87.4186 108.173 87.5433 107.9Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.2665 108.684C89.3912 108.411 89.2709 108.088 88.9979 107.964C88.7248 107.839 88.4024 107.959 88.2777 108.232C88.153 108.505 88.2732 108.828 88.5463 108.952C88.8193 109.077 89.1417 108.957 89.2665 108.684Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.9773 109.465C91.102 109.192 90.9818 108.87 90.7087 108.745C90.4357 108.62 90.1133 108.741 89.9886 109.014C89.8639 109.287 89.9841 109.609 90.2571 109.734C90.5302 109.859 90.8526 109.738 90.9773 109.465Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.6899 110.248C92.8146 109.974 92.6943 109.652 92.4213 109.527C92.1482 109.403 91.8258 109.523 91.7011 109.796C91.5764 110.069 91.6966 110.391 91.9697 110.516C92.2427 110.641 92.5651 110.521 92.6899 110.248Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.4009 111.029C94.5256 110.756 94.4053 110.433 94.1323 110.309C93.8592 110.184 93.5368 110.304 93.4121 110.577C93.2874 110.85 93.4076 111.173 93.6807 111.297C93.9537 111.422 94.2761 111.302 94.4009 111.029Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.1229 111.815C96.2476 111.542 96.1274 111.22 95.8543 111.095C95.5813 110.971 95.2588 111.091 95.1341 111.364C95.0094 111.637 95.1297 111.959 95.4027 112.084C95.6758 112.209 95.9982 112.088 96.1229 111.815Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.8338 112.597C97.9585 112.324 97.8382 112.001 97.5652 111.877C97.2921 111.752 96.9697 111.872 96.845 112.145C96.7203 112.418 96.8405 112.741 97.1136 112.865C97.3866 112.99 97.7091 112.87 97.8338 112.597Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.638 99.7869C73.7627 99.5138 73.6425 99.1914 73.3695 99.0667C73.0964 98.942 72.774 99.0622 72.6493 99.3353C72.5246 99.6083 72.6448 99.9307 72.9178 100.055C73.1909 100.18 73.5133 100.06 73.638 99.7869Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.3602 100.573C75.4849 100.3 75.3647 99.9779 75.0916 99.8532C74.8186 99.7285 74.4961 99.8487 74.3714 100.122C74.2467 100.395 74.367 100.717 74.64 100.842C74.9131 100.967 75.2355 100.846 75.3602 100.573Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.071 101.355C77.1957 101.082 77.0754 100.759 76.8024 100.635C76.5293 100.51 76.2069 100.63 76.0822 100.903C75.9575 101.176 76.0777 101.499 76.3508 101.623C76.6238 101.748 76.9463 101.628 77.071 101.355Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.7853 102.136C78.91 101.863 78.7898 101.541 78.5167 101.416C78.2437 101.291 77.9212 101.411 77.7965 101.685C77.6718 101.958 77.7921 102.28 78.0651 102.405C78.3382 102.529 78.6606 102.409 78.7853 102.136Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.4963 102.917C80.621 102.644 80.5008 102.322 80.2277 102.197C79.9547 102.073 79.6322 102.193 79.5075 102.466C79.3828 102.739 79.5031 103.061 79.7761 103.186C80.0492 103.311 80.3716 103.191 80.4963 102.917Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.2229 103.706C82.3476 103.433 82.2274 103.111 81.9544 102.986C81.6813 102.861 81.3589 102.982 81.2342 103.255C81.1095 103.528 81.2297 103.85 81.5027 103.975C81.7758 104.099 82.0982 103.979 82.2229 103.706Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.9338 104.488C84.0585 104.215 83.9383 103.892 83.6652 103.767C83.3922 103.643 83.0697 103.763 82.945 104.036C82.8203 104.309 82.9406 104.631 83.2136 104.756C83.4867 104.881 83.8091 104.761 83.9338 104.488Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.6406 105.27C85.7653 104.997 85.6451 104.675 85.372 104.55C85.099 104.425 84.7765 104.545 84.6518 104.818C84.5271 105.092 84.6474 105.414 84.9204 105.539C85.1935 105.663 85.5159 105.543 85.6406 105.27Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.3516 106.052C87.4763 105.778 87.3561 105.456 87.083 105.331C86.81 105.207 86.4875 105.327 86.3628 105.6C86.2381 105.873 86.3584 106.195 86.6314 106.32C86.9045 106.445 87.2269 106.325 87.3516 106.052Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.0751 106.836C89.1998 106.563 89.0796 106.24 88.8065 106.116C88.5335 105.991 88.211 106.111 88.0863 106.384C87.9616 106.657 88.0819 106.98 88.3549 107.104C88.628 107.229 88.9504 107.109 89.0751 106.836Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.786 107.617C90.9107 107.344 90.7904 107.022 90.5174 106.897C90.2444 106.772 89.9219 106.892 89.7972 107.166C89.6725 107.439 89.7928 107.761 90.0658 107.886C90.3388 108.01 90.6613 107.89 90.786 107.617Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.4983 108.399C92.623 108.126 92.5027 107.804 92.2297 107.679C91.9566 107.554 91.6342 107.675 91.5095 107.948C91.3848 108.221 91.505 108.543 91.7781 108.668C92.0511 108.793 92.3736 108.672 92.4983 108.399Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.2091 109.181C94.3339 108.908 94.2136 108.585 93.9406 108.46C93.6675 108.336 93.3451 108.456 93.2204 108.729C93.0957 109.002 93.2159 109.325 93.489 109.449C93.762 109.574 94.0844 109.454 94.2091 109.181Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.9316 109.967C96.0563 109.694 95.9361 109.372 95.663 109.247C95.39 109.122 95.0675 109.243 94.9428 109.516C94.8181 109.789 94.9384 110.111 95.2114 110.236C95.4845 110.361 95.8069 110.24 95.9316 109.967Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.6424 110.749C97.7671 110.476 97.6468 110.153 97.3738 110.029C97.1007 109.904 96.7783 110.024 96.6536 110.297C96.5289 110.57 96.6491 110.893 96.9222 111.017C97.1952 111.142 97.5177 111.022 97.6424 110.749Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.4523 97.9255C73.577 97.6524 73.4568 97.33 73.1837 97.2053C72.9107 97.0806 72.5882 97.2008 72.4635 97.4739C72.3388 97.7469 72.4591 98.0694 72.7321 98.1941C73.0052 98.3188 73.3276 98.1985 73.4523 97.9255Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.1632 98.7069C75.2879 98.4339 75.1676 98.1114 74.8946 97.9867C74.6216 97.862 74.2991 97.9823 74.1744 98.2553C74.0497 98.5284 74.17 98.8508 74.443 98.9755C74.716 99.1002 75.0385 98.98 75.1632 98.7069Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.8852 99.4935C77.0099 99.2204 76.8897 98.898 76.6166 98.7733C76.3436 98.6486 76.0212 98.7688 75.8965 99.0419C75.7717 99.3149 75.892 99.6373 76.165 99.7621C76.4381 99.8868 76.7605 99.7665 76.8852 99.4935Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.5961 100.275C78.7208 100.002 78.6006 99.6794 78.3275 99.5547C78.0545 99.4299 77.732 99.5502 77.6073 99.8232C77.4826 100.096 77.6029 100.419 77.8759 100.543C78.149 100.668 78.4714 100.548 78.5961 100.275Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.3108 101.057C80.4355 100.784 80.3152 100.461 80.0422 100.336C79.7691 100.212 79.4467 100.332 79.322 100.605C79.1973 100.878 79.3175 101.201 79.5906 101.325C79.8636 101.45 80.1861 101.33 80.3108 101.057Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.0216 101.838C82.1464 101.565 82.0261 101.243 81.7531 101.118C81.48 100.993 81.1576 101.113 81.0329 101.386C80.9082 101.659 81.0284 101.982 81.3015 102.107C81.5745 102.231 81.8969 102.111 82.0216 101.838Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.7484 102.627C83.8731 102.354 83.7528 102.031 83.4798 101.906C83.2068 101.782 82.8843 101.902 82.7596 102.175C82.6349 102.448 82.7552 102.77 83.0282 102.895C83.3012 103.02 83.6237 102.9 83.7484 102.627Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.459 103.408C85.5837 103.135 85.4634 102.812 85.1904 102.688C84.9173 102.563 84.5949 102.683 84.4702 102.956C84.3455 103.229 84.4657 103.552 84.7388 103.677C85.0118 103.801 85.3343 103.681 85.459 103.408Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.166 104.191C87.2907 103.918 87.1704 103.595 86.8974 103.471C86.6244 103.346 86.3019 103.466 86.1772 103.739C86.0525 104.012 86.1728 104.335 86.4458 104.459C86.7188 104.584 87.0413 104.464 87.166 104.191Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.8769 104.972C89.0016 104.699 88.8814 104.377 88.6083 104.252C88.3353 104.127 88.0129 104.248 87.8882 104.521C87.7634 104.794 87.8837 105.116 88.1567 105.241C88.4298 105.366 88.7522 105.245 88.8769 104.972Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.6002 105.756C90.7249 105.483 90.6047 105.161 90.3316 105.036C90.0586 104.911 89.7361 105.031 89.6114 105.304C89.4867 105.577 89.607 105.9 89.88 106.025C90.153 106.149 90.4755 106.029 90.6002 105.756Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.3109 106.537C92.4357 106.264 92.3154 105.942 92.0424 105.817C91.7693 105.693 91.4469 105.813 91.3222 106.086C91.1975 106.359 91.3177 106.681 91.5908 106.806C91.8638 106.931 92.1862 106.811 92.3109 106.537Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.0236 107.32C94.1483 107.047 94.0281 106.724 93.755 106.6C93.482 106.475 93.1595 106.595 93.0348 106.868C92.9101 107.141 93.0304 107.464 93.3034 107.588C93.5764 107.713 93.8989 107.593 94.0236 107.32Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.7346 108.101C95.8593 107.828 95.7391 107.506 95.466 107.381C95.193 107.256 94.8705 107.377 94.7458 107.65C94.6211 107.923 94.7414 108.245 95.0144 108.37C95.2874 108.494 95.6099 108.374 95.7346 108.101Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.4566 108.888C97.5813 108.615 97.4611 108.292 97.1881 108.167C96.915 108.043 96.5926 108.163 96.4679 108.436C96.3432 108.709 96.4634 109.031 96.7365 109.156C97.0095 109.281 97.3319 109.161 97.4566 108.888Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.1675 109.669C99.2922 109.396 99.172 109.074 98.8989 108.949C98.6259 108.824 98.3035 108.944 98.1787 109.217C98.054 109.491 98.1743 109.813 98.4473 109.938C98.7204 110.062 99.0428 109.942 99.1675 109.669Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.2692 96.0624C73.3939 95.7894 73.2737 95.467 73.0006 95.3423C72.7276 95.2175 72.4051 95.3378 72.2804 95.6108C72.1557 95.8839 72.276 96.2063 72.549 96.331C72.8221 96.4557 73.1445 96.3355 73.2692 96.0624Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.9818 96.8447C75.1065 96.5717 74.9862 96.2492 74.7132 96.1245C74.4402 95.9998 74.1177 96.1201 73.993 96.3931C73.8683 96.6662 73.9886 96.9886 74.2616 97.1133C74.5346 97.238 74.8571 97.1178 74.9818 96.8447Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.6924 97.6259C76.8171 97.3529 76.6968 97.0304 76.4238 96.9057C76.1507 96.781 75.8283 96.9013 75.7036 97.1743C75.5789 97.4474 75.6991 97.7698 75.9722 97.8945C76.2452 98.0192 76.5677 97.899 76.6924 97.6259Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.4148 98.4126C78.5395 98.1396 78.4193 97.8171 78.1462 97.6924C77.8732 97.5677 77.5508 97.688 77.4261 97.961C77.3014 98.234 77.4216 98.5565 77.6946 98.6812C77.9677 98.8059 78.2901 98.6856 78.4148 98.4126Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.1256 99.194C80.2504 98.9209 80.1301 98.5985 79.8571 98.4738C79.584 98.3491 79.2616 98.4693 79.1369 98.7424C79.0122 99.0154 79.1324 99.3379 79.4055 99.4626C79.6785 99.5873 80.0009 99.467 80.1256 99.194Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.8401 99.9753C81.9648 99.7022 81.8445 99.3798 81.5715 99.2551C81.2984 99.1304 80.976 99.2506 80.8513 99.5237C80.7266 99.7967 80.8468 100.119 81.1199 100.244C81.3929 100.369 81.7154 100.248 81.8401 99.9753Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.5509 100.757C83.6756 100.484 83.5554 100.161 83.2824 100.036C83.0093 99.9118 82.6869 100.032 82.5622 100.305C82.4375 100.578 82.5577 100.901 82.8308 101.025C83.1038 101.15 83.4262 101.03 83.5509 100.757Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.2777 101.545C85.4024 101.272 85.2821 100.95 85.0091 100.825C84.7361 100.7 84.4136 100.821 84.2889 101.094C84.1642 101.367 84.2845 101.689 84.5575 101.814C84.8305 101.939 85.153 101.818 85.2777 101.545Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.9886 102.327C87.1133 102.054 86.9931 101.731 86.72 101.607C86.447 101.482 86.1246 101.602 85.9998 101.875C85.8751 102.148 85.9954 102.471 86.2684 102.595C86.5415 102.72 86.8639 102.6 86.9886 102.327Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.6953 103.109C88.82 102.836 88.6997 102.514 88.4267 102.389C88.1537 102.265 87.8312 102.385 87.7065 102.658C87.5818 102.931 87.7021 103.253 87.9751 103.378C88.2481 103.503 88.5706 103.382 88.6953 103.109Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.4062 103.891C90.5309 103.618 90.4106 103.295 90.1376 103.171C89.8645 103.046 89.5421 103.166 89.4174 103.439C89.2927 103.712 89.4129 104.035 89.686 104.159C89.959 104.284 90.2815 104.164 90.4062 103.891Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.1297 104.675C92.2544 104.402 92.1342 104.079 91.8612 103.955C91.5881 103.83 91.2657 103.95 91.141 104.223C91.0163 104.496 91.1365 104.819 91.4095 104.943C91.6826 105.068 92.005 104.948 92.1297 104.675Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.8407 105.456C93.9654 105.183 93.8451 104.861 93.5721 104.736C93.299 104.611 92.9766 104.732 92.8519 105.005C92.7272 105.278 92.8474 105.6 93.1205 105.725C93.3935 105.85 93.716 105.729 93.8407 105.456Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.553 106.238C95.6777 105.965 95.5574 105.643 95.2844 105.518C95.0113 105.393 94.6889 105.514 94.5642 105.787C94.4395 106.06 94.5597 106.382 94.8328 106.507C95.1058 106.632 95.4282 106.511 95.553 106.238Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.2638 107.02C97.3885 106.747 97.2683 106.424 96.9952 106.3C96.7222 106.175 96.3998 106.295 96.2751 106.568C96.1504 106.841 96.2706 107.164 96.5436 107.288C96.8167 107.413 97.1391 107.293 97.2638 107.02Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.9863 107.806C99.111 107.533 98.9908 107.211 98.7177 107.086C98.4447 106.962 98.1222 107.082 97.9975 107.355C97.8728 107.628 97.9931 107.95 98.2661 108.075C98.5392 108.2 98.8616 108.08 98.9863 107.806Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.697 108.588C100.822 108.315 100.702 107.992 100.428 107.868C100.155 107.743 99.833 107.863 99.7083 108.136C99.5836 108.409 99.7038 108.732 99.9769 108.856C100.25 108.981 100.572 108.861 100.697 108.588Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.7904 94.9962C74.9151 94.7231 74.7948 94.4007 74.5218 94.276C74.2488 94.1513 73.9263 94.2715 73.8016 94.5446C73.6769 94.8176 73.7972 95.14 74.0702 95.2647C74.3432 95.3894 74.6657 95.2692 74.7904 94.9962Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.5011 95.7774C76.6258 95.5044 76.5056 95.1819 76.2326 95.0572C75.9595 94.9325 75.6371 95.0528 75.5124 95.3258C75.3877 95.5988 75.5079 95.9213 75.7809 96.046C76.054 96.1707 76.3764 96.0504 76.5011 95.7774Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.2233 96.564C78.348 96.291 78.2278 95.9685 77.9547 95.8438C77.6817 95.7191 77.3592 95.8394 77.2345 96.1124C77.1098 96.3855 77.2301 96.7079 77.5031 96.8326C77.7762 96.9573 78.0986 96.8371 78.2233 96.564Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.9342 97.3454C80.0589 97.0724 79.9386 96.7499 79.6656 96.6252C79.3926 96.5005 79.0701 96.6208 78.9454 96.8938C78.8207 97.1668 78.9409 97.4893 79.214 97.614C79.487 97.7387 79.8095 97.6184 79.9342 97.3454Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.6487 98.1272C81.7734 97.8542 81.6531 97.5317 81.3801 97.407C81.107 97.2823 80.7846 97.4026 80.6599 97.6756C80.5352 97.9486 80.6554 98.2711 80.9285 98.3958C81.2015 98.5205 81.524 98.4002 81.6487 98.1272Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.3596 98.9086C83.4843 98.6355 83.3641 98.3131 83.091 98.1884C82.818 98.0637 82.4955 98.1839 82.3708 98.457C82.2461 98.73 82.3664 99.0524 82.6394 99.1772C82.9124 99.3019 83.2349 99.1816 83.3596 98.9086Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.0863 99.6973C85.211 99.4242 85.0907 99.1018 84.8177 98.9771C84.5447 98.8524 84.2222 98.9726 84.0975 99.2457C83.9728 99.5187 84.093 99.8411 84.3661 99.9659C84.6391 100.091 84.9616 99.9703 85.0863 99.6973Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.7969 100.479C86.9216 100.205 86.8014 99.883 86.5283 99.7583C86.2553 99.6336 85.9328 99.7539 85.8081 100.027C85.6834 100.3 85.8037 100.622 86.0767 100.747C86.3498 100.872 86.6722 100.752 86.7969 100.479Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.504 101.261C88.6287 100.988 88.5085 100.666 88.2354 100.541C87.9624 100.416 87.6399 100.537 87.5152 100.81C87.3905 101.083 87.5108 101.405 87.7838 101.53C88.0569 101.655 88.3793 101.534 88.504 101.261Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.215 102.043C90.3397 101.77 90.2195 101.447 89.9464 101.322C89.6734 101.198 89.3509 101.318 89.2262 101.591C89.1015 101.864 89.2218 102.186 89.4948 102.311C89.7679 102.436 90.0903 102.316 90.215 102.043Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.938 102.827C92.0627 102.554 91.9425 102.231 91.6694 102.107C91.3964 101.982 91.074 102.102 90.9493 102.375C90.8245 102.648 90.9448 102.971 91.2178 103.095C91.4909 103.22 91.8133 103.1 91.938 102.827Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.6489 103.608C93.7736 103.335 93.6534 103.013 93.3803 102.888C93.1073 102.763 92.7848 102.883 92.6601 103.156C92.5354 103.43 92.6557 103.752 92.9287 103.877C93.2018 104.001 93.5242 103.881 93.6489 103.608Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.3617 104.39C95.4864 104.117 95.3661 103.795 95.0931 103.67C94.82 103.545 94.4976 103.666 94.3729 103.939C94.2482 104.212 94.3684 104.534 94.6415 104.659C94.9145 104.784 95.237 104.663 95.3617 104.39Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.0724 105.172C97.1971 104.899 97.0769 104.576 96.8038 104.452C96.5308 104.327 96.2084 104.447 96.0837 104.72C95.9589 104.993 96.0792 105.316 96.3522 105.44C96.6253 105.565 96.9477 105.445 97.0724 105.172Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.7945 105.958C98.9192 105.685 98.7989 105.363 98.5259 105.238C98.2528 105.113 97.9304 105.234 97.8057 105.507C97.681 105.78 97.8012 106.102 98.0743 106.227C98.3473 106.352 98.6698 106.231 98.7945 105.958Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.505 106.74C100.63 106.467 100.51 106.144 100.237 106.019C99.9637 105.895 99.6413 106.015 99.5166 106.288C99.3919 106.561 99.5121 106.884 99.7852 107.008C100.058 107.133 100.381 107.013 100.505 106.74Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.6155 93.1158C74.7402 92.8428 74.6199 92.5204 74.3469 92.3957C74.0738 92.271 73.7514 92.3912 73.6267 92.6642C73.502 92.9373 73.6222 93.2597 73.8953 93.3844C74.1683 93.5091 74.4907 93.3889 74.6155 93.1158Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.3259 93.897C76.4506 93.624 76.3304 93.3016 76.0573 93.1768C75.7843 93.0521 75.4618 93.1724 75.3371 93.4454C75.2124 93.7185 75.3327 94.0409 75.6057 94.1656C75.8788 94.2903 76.2012 94.1701 76.3259 93.897Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.0484 94.6838C78.1731 94.4107 78.0528 94.0883 77.7798 93.9636C77.5067 93.8389 77.1843 93.9591 77.0596 94.2322C76.9349 94.5052 77.0551 94.8277 77.3282 94.9524C77.6012 95.0771 77.9237 94.9568 78.0484 94.6838Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.7593 95.4652C79.884 95.1921 79.7637 94.8697 79.4907 94.745C79.2176 94.6203 78.8952 94.7405 78.7705 95.0135C78.6458 95.2866 78.766 95.609 79.0391 95.7337C79.3121 95.8584 79.6345 95.7382 79.7593 95.4652Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.4736 96.2463C81.5983 95.9733 81.4781 95.6509 81.205 95.5262C80.932 95.4014 80.6095 95.5217 80.4848 95.7947C80.3601 96.0678 80.4804 96.3902 80.7534 96.5149C81.0265 96.6396 81.3489 96.5194 81.4736 96.2463Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.1845 97.0277C83.3092 96.7546 83.1889 96.4322 82.9159 96.3075C82.6429 96.1828 82.3204 96.303 82.1957 96.576C82.071 96.8491 82.1913 97.1715 82.4643 97.2962C82.7373 97.4209 83.0598 97.3007 83.1845 97.0277Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.9113 97.8164C85.036 97.5434 84.9157 97.2209 84.6427 97.0962C84.3697 96.9715 84.0472 97.0918 83.9225 97.3648C83.7978 97.6378 83.9181 97.9603 84.1911 98.085C84.4641 98.2097 84.7866 98.0895 84.9113 97.8164Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.6222 98.5978C86.7469 98.3248 86.6266 98.0024 86.3536 97.8777C86.0805 97.7529 85.7581 97.8732 85.6334 98.1462C85.5087 98.4193 85.6289 98.7417 85.902 98.8664C86.175 98.9911 86.4975 98.8709 86.6222 98.5978Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.3288 99.3808C88.4535 99.1078 88.3332 98.7853 88.0602 98.6606C87.7871 98.5359 87.4647 98.6562 87.34 98.9292C87.2153 99.2022 87.3355 99.5247 87.6086 99.6494C87.8816 99.7741 88.2041 99.6538 88.3288 99.3808Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.0396 100.162C90.1644 99.8891 90.0441 99.5667 89.7711 99.442C89.498 99.3173 89.1756 99.4375 89.0509 99.7106C88.9262 99.9836 89.0464 100.306 89.3195 100.431C89.5925 100.555 89.9149 100.435 90.0396 100.162Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.7634 100.946C91.8881 100.673 91.7679 100.351 91.4948 100.226C91.2218 100.101 90.8993 100.221 90.7746 100.494C90.6499 100.767 90.7702 101.09 91.0432 101.215C91.3163 101.339 91.6387 101.219 91.7634 100.946Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.4743 101.727C93.599 101.454 93.4787 101.132 93.2057 101.007C92.9327 100.882 92.6102 101.003 92.4855 101.276C92.3608 101.549 92.481 101.871 92.7541 101.996C93.0271 102.121 93.3496 102 93.4743 101.727Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.1866 102.509C95.3113 102.236 95.1911 101.914 94.918 101.789C94.645 101.665 94.3226 101.785 94.1978 102.058C94.0731 102.331 94.1934 102.653 94.4664 102.778C94.7395 102.903 95.0619 102.783 95.1866 102.509Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.8974 103.291C97.0221 103.018 96.9018 102.695 96.6288 102.571C96.3557 102.446 96.0333 102.566 95.9086 102.839C95.7839 103.112 95.9041 103.435 96.1772 103.559C96.4502 103.684 96.7727 103.564 96.8974 103.291Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.62 104.078C98.7447 103.805 98.6244 103.482 98.3514 103.357C98.0783 103.233 97.7559 103.353 97.6312 103.626C97.5065 103.899 97.6267 104.222 97.8998 104.346C98.1728 104.471 98.4953 104.351 98.62 104.078Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.331 104.859C100.455 104.586 100.335 104.264 100.062 104.139C99.7891 104.014 99.4667 104.134 99.342 104.407C99.2172 104.68 99.3375 105.003 99.6105 105.128C99.8836 105.252 100.206 105.132 100.331 104.859Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.4324 91.2523C74.5571 90.9792 74.4369 90.6568 74.1638 90.5321C73.8908 90.4074 73.5683 90.5276 73.4436 90.8007C73.3189 91.0737 73.4392 91.3961 73.7122 91.5208C73.9853 91.6456 74.3077 91.5253 74.4324 91.2523Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.1448 92.0344C76.2695 91.7613 76.1493 91.4389 75.8762 91.3142C75.6032 91.1895 75.2807 91.3097 75.156 91.5828C75.0313 91.8558 75.1516 92.1782 75.4246 92.303C75.6977 92.4277 76.0201 92.3074 76.1448 92.0344Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.8557 92.8159C77.9804 92.5428 77.8601 92.2204 77.5871 92.0957C77.3141 91.971 76.9916 92.0912 76.8669 92.3643C76.7422 92.6373 76.8625 92.9597 77.1355 93.0844C77.4085 93.2092 77.731 93.0889 77.8557 92.8159Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.578 93.6025C79.7027 93.3295 79.5824 93.0071 79.3094 92.8824C79.0364 92.7576 78.7139 92.8779 78.5892 93.1509C78.4645 93.424 78.5847 93.7464 78.8578 93.8711C79.1308 93.9958 79.4533 93.8756 79.578 93.6025Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.2888 94.3839C81.4135 94.1109 81.2933 93.7884 81.0202 93.6637C80.7472 93.539 80.4247 93.6593 80.3 93.9323C80.1753 94.2054 80.2956 94.5278 80.5686 94.6525C80.8416 94.7772 81.1641 94.657 81.2888 94.3839Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.003 95.1656C83.1277 94.8925 83.0075 94.5701 82.7344 94.4454C82.4614 94.3207 82.139 94.4409 82.0143 94.714C81.8895 94.987 82.0098 95.3095 82.2828 95.4342C82.5559 95.5589 82.8783 95.4386 83.003 95.1656Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.7139 95.9469C84.8386 95.6739 84.7184 95.3514 84.4453 95.2267C84.1723 95.102 83.8498 95.2223 83.7251 95.4953C83.6004 95.7683 83.7207 96.0908 83.9937 96.2155C84.2668 96.3402 84.5892 96.2199 84.7139 95.9469Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.4408 96.7355C86.5655 96.4625 86.4452 96.1401 86.1722 96.0153C85.8991 95.8906 85.5767 96.0109 85.452 96.2839C85.3273 96.557 85.4475 96.8794 85.7206 97.0041C85.9936 97.1288 86.3161 97.0086 86.4408 96.7355Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.1516 97.517C88.2764 97.244 88.1561 96.9216 87.8831 96.7968C87.61 96.6721 87.2876 96.7924 87.1629 97.0654C87.0382 97.3385 87.1584 97.6609 87.4315 97.7856C87.7045 97.9103 88.0269 97.7901 88.1516 97.517Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.8585 98.2995C89.9832 98.0265 89.863 97.704 89.5899 97.5793C89.3169 97.4546 88.9944 97.5749 88.8697 97.8479C88.745 98.1209 88.8653 98.4434 89.1383 98.5681C89.4113 98.6928 89.7338 98.5725 89.8585 98.2995Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.5693 99.0809C91.694 98.8079 91.5738 98.4855 91.3007 98.3607C91.0277 98.236 90.7052 98.3563 90.5805 98.6293C90.4558 98.9024 90.5761 99.2248 90.8491 99.3495C91.1222 99.4742 91.4446 99.354 91.5693 99.0809Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.2926 99.865C93.4173 99.592 93.2971 99.2695 93.0241 99.1448C92.751 99.0201 92.4286 99.1403 92.3039 99.4134C92.1792 99.6864 92.2994 100.009 92.5725 100.134C92.8455 100.258 93.1679 100.138 93.2926 99.865Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.0033 100.646C95.128 100.373 95.0078 100.051 94.7347 99.9262C94.4617 99.8015 94.1393 99.9217 94.0146 100.195C93.8899 100.468 94.0101 100.79 94.2831 100.915C94.5562 101.04 94.8786 100.919 95.0033 100.646Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.7161 101.429C96.8408 101.156 96.7206 100.833 96.4475 100.708C96.1745 100.584 95.852 100.704 95.7273 100.977C95.6026 101.25 95.7229 101.573 95.9959 101.697C96.269 101.822 96.5914 101.702 96.7161 101.429Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.4269 102.21C98.5516 101.937 98.4313 101.615 98.1583 101.49C97.8852 101.365 97.5628 101.485 97.4381 101.758C97.3134 102.032 97.4336 102.354 97.7067 102.479C97.9797 102.603 98.3021 102.483 98.4269 102.21Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.149 102.997C100.274 102.724 100.153 102.401 99.8804 102.276C99.6073 102.152 99.2849 102.272 99.1602 102.545C99.0355 102.818 99.1557 103.141 99.4288 103.265C99.7018 103.39 100.024 103.27 100.149 102.997Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.86 103.778C101.984 103.505 101.864 103.183 101.591 103.058C101.318 102.933 100.996 103.053 100.871 103.326C100.746 103.599 100.867 103.922 101.14 104.047C101.413 104.171 101.735 104.051 101.86 103.778Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.2408 89.404C74.3655 89.131 74.2453 88.8085 73.9722 88.6838C73.6992 88.5591 73.3768 88.6793 73.252 88.9524C73.1273 89.2254 73.2476 89.5479 73.5206 89.6726C73.7937 89.7973 74.1161 89.677 74.2408 89.404Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.9535 90.1863C76.0782 89.9132 75.9579 89.5908 75.6849 89.4661C75.4118 89.3414 75.0894 89.4616 74.9647 89.7347C74.84 90.0077 74.9602 90.3302 75.2333 90.4549C75.5063 90.5796 75.8288 90.4593 75.9535 90.1863Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.664 90.9675C77.7887 90.6944 77.6684 90.372 77.3954 90.2473C77.1224 90.1226 76.7999 90.2428 76.6752 90.5159C76.5505 90.7889 76.6707 91.1113 76.9438 91.2361C77.2168 91.3608 77.5393 91.2405 77.664 90.9675Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.3864 91.7542C79.5111 91.4811 79.3908 91.1587 79.1178 91.034C78.8448 90.9093 78.5223 91.0295 78.3976 91.3025C78.2729 91.5756 78.3932 91.898 78.6662 92.0227C78.9392 92.1474 79.2617 92.0272 79.3864 91.7542Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.0973 92.5355C81.222 92.2625 81.1017 91.94 80.8287 91.8153C80.5556 91.6906 80.2332 91.8109 80.1085 92.0839C79.9838 92.357 80.104 92.6794 80.3771 92.8041C80.6501 92.9288 80.9726 92.8086 81.0973 92.5355Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.8114 93.3172C82.9361 93.0442 82.8159 92.7217 82.5429 92.597C82.2698 92.4723 81.9474 92.5926 81.8227 92.8656C81.698 93.1386 81.8182 93.4611 82.0912 93.5858C82.3643 93.7105 82.6867 93.5902 82.8114 93.3172Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.5223 94.0986C84.647 93.8255 84.5268 93.5031 84.2537 93.3784C83.9807 93.2537 83.6582 93.3739 83.5335 93.647C83.4088 93.92 83.5291 94.2425 83.8021 94.3672C84.0752 94.4919 84.3976 94.3716 84.5223 94.0986Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.2491 94.8873C86.3738 94.6142 86.2535 94.2918 85.9805 94.1671C85.7074 94.0424 85.385 94.1626 85.2603 94.4357C85.1356 94.7087 85.2558 95.0311 85.5289 95.1559C85.8019 95.2806 86.1244 95.1603 86.2491 94.8873Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.9599 95.6686C88.0846 95.3956 87.9644 95.0732 87.6914 94.9485C87.4183 94.8237 87.0959 94.944 86.9712 95.217C86.8465 95.4901 86.9667 95.8125 87.2397 95.9372C87.5128 96.0619 87.8352 95.9417 87.9599 95.6686Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.6668 96.4512C89.7915 96.1782 89.6712 95.8558 89.3982 95.731C89.1252 95.6063 88.8027 95.7266 88.678 95.9996C88.5533 96.2727 88.6736 96.5951 88.9466 96.7198C89.2196 96.8445 89.5421 96.7243 89.6668 96.4512Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.3777 97.2325C91.5024 96.9595 91.3821 96.6371 91.1091 96.5124C90.836 96.3877 90.5136 96.5079 90.3889 96.7809C90.2642 97.054 90.3844 97.3764 90.6575 97.5011C90.9305 97.6258 91.253 97.5056 91.3777 97.2325Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.1012 98.0168C93.2259 97.7437 93.1057 97.4213 92.8326 97.2966C92.5596 97.1719 92.2372 97.2921 92.1125 97.5652C91.9878 97.8382 92.108 98.1607 92.381 98.2854C92.6541 98.4101 92.9765 98.2898 93.1012 98.0168Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.8122 98.7983C94.9369 98.5252 94.8166 98.2028 94.5436 98.0781C94.2705 97.9534 93.9481 98.0736 93.8234 98.3467C93.6987 98.6197 93.8189 98.9422 94.092 99.0669C94.365 99.1916 94.6875 99.0713 94.8122 98.7983Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.5244 99.5803C96.6491 99.3072 96.5288 98.9848 96.2558 98.8601C95.9828 98.7354 95.6603 98.8556 95.5356 99.1287C95.4109 99.4017 95.5312 99.7241 95.8042 99.8488C96.0772 99.9736 96.3997 99.8533 96.5244 99.5803Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.2353 100.362C98.36 100.089 98.2397 99.7662 97.9667 99.6415C97.6936 99.5168 97.3712 99.6371 97.2465 99.9101C97.1218 100.183 97.242 100.506 97.5151 100.63C97.7881 100.755 98.1106 100.635 98.2353 100.362Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.9577 101.148C100.082 100.875 99.9621 100.553 99.6891 100.428C99.4161 100.304 99.0936 100.424 98.9689 100.697C98.8442 100.97 98.9644 101.292 99.2375 101.417C99.5105 101.542 99.833 101.421 99.9577 101.148Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.669 101.93C101.793 101.657 101.673 101.334 101.4 101.21C101.127 101.085 100.804 101.205 100.68 101.478C100.555 101.751 100.675 102.074 100.948 102.198C101.221 102.323 101.544 102.203 101.669 101.93Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.0548 87.5431C74.1795 87.2701 74.0592 86.9476 73.7862 86.8229C73.5132 86.6982 73.1907 86.8184 73.066 87.0915C72.9413 87.3645 73.0616 87.687 73.3346 87.8117C73.6076 87.9364 73.9301 87.8161 74.0548 87.5431Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.7658 88.3246C75.8905 88.0515 75.7702 87.7291 75.4972 87.6044C75.2242 87.4797 74.9017 87.5999 74.777 87.873C74.6523 88.146 74.7726 88.4685 75.0456 88.5932C75.3186 88.7179 75.6411 88.5976 75.7658 88.3246Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.4784 89.1069C77.6031 88.8338 77.4829 88.5114 77.2098 88.3867C76.9368 88.262 76.6144 88.3822 76.4897 88.6553C76.3649 88.9283 76.4852 89.2508 76.7582 89.3755C77.0313 89.5002 77.3537 89.3799 77.4784 89.1069Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.1892 89.8882C79.3139 89.6152 79.1936 89.2928 78.9206 89.1681C78.6476 89.0434 78.3251 89.1636 78.2004 89.4366C78.0757 89.7097 78.196 90.0321 78.469 90.1568C78.742 90.2815 79.0645 90.1613 79.1892 89.8882Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.9112 90.6747C81.0359 90.4017 80.9157 90.0793 80.6426 89.9546C80.3696 89.8299 80.0472 89.9501 79.9225 90.2231C79.7978 90.4962 79.918 90.8186 80.191 90.9433C80.4641 91.068 80.7865 90.9478 80.9112 90.6747Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.6222 91.4561C82.7469 91.1831 82.6267 90.8606 82.3536 90.7359C82.0806 90.6112 81.7582 90.7315 81.6335 91.0045C81.5087 91.2776 81.629 91.6 81.902 91.7247C82.1751 91.8494 82.4975 91.7292 82.6222 91.4561Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.3366 92.2373C84.4613 91.9643 84.341 91.6418 84.068 91.5171C83.795 91.3924 83.4725 91.5127 83.3478 91.7857C83.2231 92.0587 83.3434 92.3812 83.6164 92.5059C83.8894 92.6306 84.2119 92.5103 84.3366 92.2373Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.0475 93.0187C86.1722 92.7457 86.0519 92.4233 85.7789 92.2986C85.5058 92.1738 85.1834 92.2941 85.0587 92.5671C84.934 92.8402 85.0542 93.1626 85.3273 93.2873C85.6003 93.412 85.9228 93.2918 86.0475 93.0187Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.7743 93.8074C87.899 93.5343 87.7788 93.2119 87.5057 93.0872C87.2327 92.9625 86.9103 93.0827 86.7856 93.3558C86.6608 93.6288 86.7811 93.9513 87.0541 94.076C87.3272 94.2007 87.6496 94.0804 87.7743 93.8074Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.4851 94.5888C89.6098 94.3158 89.4895 93.9933 89.2165 93.8686C88.9435 93.7439 88.621 93.8642 88.4963 94.1372C88.3716 94.4102 88.4919 94.7327 88.7649 94.8574C89.0379 94.9821 89.3604 94.8619 89.4851 94.5888Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.1919 95.3716C91.3166 95.0985 91.1963 94.7761 90.9233 94.6514C90.6502 94.5267 90.3278 94.6469 90.2031 94.92C90.0784 95.193 90.1986 95.5155 90.4717 95.6402C90.7447 95.7649 91.0672 95.6446 91.1919 95.3716Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.9026 96.153C93.0273 95.8799 92.9071 95.5575 92.634 95.4328C92.361 95.3081 92.0386 95.4283 91.9139 95.7014C91.7891 95.9744 91.9094 96.2968 92.1824 96.4215C92.4555 96.5462 92.7779 96.426 92.9026 96.153Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.6264 96.937C94.7511 96.6639 94.6308 96.3415 94.3578 96.2168C94.0848 96.0921 93.7623 96.2123 93.6376 96.4853C93.5129 96.7584 93.6331 97.0808 93.9062 97.2055C94.1792 97.3302 94.5017 97.21 94.6264 96.937Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.3373 97.7183C96.462 97.4453 96.3417 97.1228 96.0687 96.9981C95.7956 96.8734 95.4732 96.9937 95.3485 97.2667C95.2238 97.5398 95.344 97.8622 95.6171 97.9869C95.8901 98.1116 96.2125 97.9914 96.3373 97.7183Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.0495 98.5004C98.1742 98.2274 98.054 97.9049 97.781 97.7802C97.5079 97.6555 97.1855 97.7758 97.0608 98.0488C96.9361 98.3219 97.0563 98.6443 97.3293 98.769C97.6024 98.8937 97.9248 98.7735 98.0495 98.5004Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.7604 99.2818C99.8851 99.0088 99.7649 98.6863 99.4918 98.5616C99.2188 98.4369 98.8963 98.5572 98.7716 98.8302C98.6469 99.1032 98.7672 99.4257 99.0402 99.5504C99.3133 99.6751 99.6357 99.5548 99.7604 99.2818Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.483 100.068C101.608 99.7954 101.487 99.473 101.214 99.3483C100.941 99.2236 100.619 99.3438 100.494 99.6169C100.369 99.8899 100.49 100.212 100.763 100.337C101.036 100.462 101.358 100.342 101.483 100.068Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.194 100.85C103.318 100.577 103.198 100.254 102.925 100.13C102.652 100.005 102.33 100.125 102.205 100.398C102.08 100.671 102.2 100.994 102.474 101.118C102.747 101.243 103.069 101.123 103.194 100.85Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.861 85.6777C73.9857 85.4047 73.8655 85.0823 73.5924 84.9575C73.3194 84.8328 72.9969 84.9531 72.8722 85.2261C72.7475 85.4992 72.8678 85.8216 73.1408 85.9463C73.4139 86.071 73.7363 85.9508 73.861 85.6777Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.5846 86.4619C75.7093 86.1889 75.589 85.8664 75.316 85.7417C75.0429 85.617 74.7205 85.7373 74.5958 86.0103C74.4711 86.2834 74.5913 86.6058 74.8644 86.7305C75.1374 86.8552 75.4599 86.735 75.5846 86.4619Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.2955 87.2434C77.4202 86.9703 77.3 86.6479 77.0269 86.5232C76.7539 86.3985 76.4314 86.5187 76.3067 86.7917C76.182 87.0648 76.3023 87.3872 76.5753 87.5119C76.8484 87.6366 77.1708 87.5164 77.2955 87.2434Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.0077 88.0254C79.1324 87.7524 79.0121 87.4299 78.7391 87.3052C78.466 87.1805 78.1436 87.3007 78.0189 87.5738C77.8942 87.8468 78.0144 88.1693 78.2875 88.294C78.5605 88.4187 78.883 88.2984 79.0077 88.0254Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.7185 88.8068C80.8432 88.5338 80.723 88.2113 80.45 88.0866C80.1769 87.9619 79.8545 88.0822 79.7298 88.3552C79.6051 88.6283 79.7253 88.9507 79.9984 89.0754C80.2714 89.2001 80.5938 89.0799 80.7185 88.8068Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.4409 89.5935C82.5656 89.3205 82.4454 88.998 82.1723 88.8733C81.8993 88.7486 81.5768 88.8689 81.4521 89.1419C81.3274 89.4149 81.4477 89.7374 81.7207 89.8621C81.9937 89.9868 82.3162 89.8666 82.4409 89.5935Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.1519 90.3749C84.2766 90.1018 84.1563 89.7794 83.8833 89.6547C83.6103 89.53 83.2878 89.6502 83.1631 89.9233C83.0384 90.1963 83.1587 90.5188 83.4317 90.6435C83.7047 90.7682 84.0272 90.6479 84.1519 90.3749Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.8662 91.1559C85.991 90.8829 85.8707 90.5605 85.5977 90.4358C85.3246 90.3111 85.0022 90.4313 84.8775 90.7043C84.7528 90.9774 84.873 91.2998 85.1461 91.4245C85.4191 91.5492 85.7415 91.429 85.8662 91.1559Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.5771 91.9374C87.7018 91.6644 87.5816 91.342 87.3085 91.2173C87.0355 91.0925 86.7131 91.2128 86.5884 91.4858C86.4636 91.7589 86.5839 92.0813 86.8569 92.206C87.13 92.3307 87.4524 92.2105 87.5771 91.9374Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.3039 92.7261C89.4286 92.4531 89.3084 92.1307 89.0353 92.0059C88.7623 91.8812 88.4399 92.0015 88.3152 92.2745C88.1905 92.5476 88.3107 92.87 88.5837 92.9947C88.8568 93.1194 89.1792 92.9992 89.3039 92.7261Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.0148 93.5075C91.1395 93.2345 91.0193 92.912 90.7462 92.7873C90.4732 92.6626 90.1507 92.7829 90.026 93.0559C89.9013 93.3289 90.0216 93.6514 90.2946 93.7761C90.5677 93.9008 90.8901 93.7806 91.0148 93.5075Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.7213 94.2907C92.846 94.0177 92.7257 93.6952 92.4527 93.5705C92.1797 93.4458 91.8572 93.5661 91.7325 93.8391C91.6078 94.1122 91.7281 94.4346 92.0011 94.5593C92.2741 94.684 92.5966 94.5638 92.7213 94.2907Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.4322 95.072C94.5569 94.7989 94.4366 94.4765 94.1636 94.3518C93.8905 94.2271 93.5681 94.3473 93.4434 94.6204C93.3187 94.8934 93.4389 95.2158 93.712 95.3405C93.985 95.4653 94.3075 95.345 94.4322 95.072Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.1561 95.8558C96.2808 95.5827 96.1606 95.2603 95.8875 95.1356C95.6145 95.0109 95.292 95.1311 95.1673 95.4042C95.0426 95.6772 95.1629 95.9997 95.4359 96.1244C95.709 96.2491 96.0314 96.1288 96.1561 95.8558Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.8666 96.637C97.9913 96.3639 97.8711 96.0415 97.598 95.9168C97.325 95.7921 97.0025 95.9123 96.8778 96.1854C96.7531 96.4584 96.8734 96.7808 97.1464 96.9056C97.4195 97.0303 97.7419 96.91 97.8666 96.637Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.5793 97.4191C99.704 97.1461 99.5837 96.8236 99.3107 96.6989C99.0376 96.5742 98.7152 96.6945 98.5905 96.9675C98.4658 97.2406 98.586 97.563 98.8591 97.6877C99.1321 97.8124 99.4546 97.6922 99.5793 97.4191Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.29 98.2006C101.415 97.9275 101.294 97.6051 101.021 97.4804C100.748 97.3557 100.426 97.4759 100.301 97.749C100.177 98.022 100.297 98.3444 100.57 98.4691C100.843 98.5939 101.165 98.4736 101.29 98.2006Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.012 98.9873C103.137 98.7143 103.017 98.3918 102.744 98.2671C102.471 98.1424 102.148 98.2627 102.024 98.5357C101.899 98.8087 102.019 99.1312 102.292 99.2559C102.565 99.3806 102.888 99.2603 103.012 98.9873Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.723 99.7685C104.848 99.4955 104.728 99.173 104.454 99.0483C104.181 98.9236 103.859 99.0439 103.734 99.3169C103.61 99.5899 103.73 99.9124 104.003 100.037C104.276 100.162 104.598 100.042 104.723 99.7685Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.6696 83.8296C73.7943 83.5566 73.674 83.2341 73.401 83.1094C73.128 82.9847 72.8055 83.1049 72.6808 83.378C72.5561 83.651 72.6764 83.9735 72.9494 84.0982C73.2224 84.2229 73.5449 84.1026 73.6696 83.8296Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.3929 84.6137C75.5176 84.3406 75.3973 84.0182 75.1243 83.8935C74.8512 83.7688 74.5288 83.889 74.4041 84.162C74.2794 84.4351 74.3996 84.7575 74.6727 84.8822C74.9457 85.0069 75.2682 84.8867 75.3929 84.6137Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.1038 85.395C77.2285 85.122 77.1083 84.7995 76.8352 84.6748C76.5622 84.5501 76.2397 84.6704 76.115 84.9434C75.9903 85.2165 76.1106 85.5389 76.3836 85.6636C76.6567 85.7883 76.9791 85.6681 77.1038 85.395Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.8164 86.1773C78.9411 85.9043 78.8208 85.5818 78.5478 85.4571C78.2748 85.3324 77.9523 85.4527 77.8276 85.7257C77.7029 85.9987 77.8232 86.3212 78.0962 86.4459C78.3692 86.5706 78.6917 86.4504 78.8164 86.1773Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.5273 86.9587C80.652 86.6857 80.5317 86.3633 80.2587 86.2386C79.9856 86.1138 79.6632 86.2341 79.5385 86.5071C79.4138 86.7802 79.534 87.1026 79.8071 87.2273C80.0801 87.352 80.4026 87.2318 80.5273 86.9587Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.2493 87.7452C82.374 87.4721 82.2538 87.1497 81.9807 87.025C81.7077 86.9003 81.3852 87.0205 81.2605 87.2936C81.1358 87.5666 81.2561 87.8891 81.5291 88.0138C81.8022 88.1385 82.1246 88.0182 82.2493 87.7452Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.9602 88.5266C84.0849 88.2536 83.9647 87.9311 83.6917 87.8064C83.4186 87.6817 83.0962 87.802 82.9715 88.075C82.8468 88.3481 82.967 88.6705 83.2401 88.7952C83.5131 88.9199 83.8355 88.7997 83.9602 88.5266Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.6745 89.3079C85.7992 89.0348 85.679 88.7124 85.406 88.5877C85.1329 88.463 84.8105 88.5832 84.6858 88.8563C84.5611 89.1293 84.6813 89.4517 84.9543 89.5764C85.2274 89.7012 85.5498 89.5809 85.6745 89.3079Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.3854 90.0894C87.5101 89.8163 87.3899 89.4939 87.1168 89.3692C86.8438 89.2445 86.5213 89.3647 86.3966 89.6378C86.2719 89.9108 86.3922 90.2332 86.6652 90.3579C86.9383 90.4827 87.2607 90.3624 87.3854 90.0894Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.1122 90.8781C89.2369 90.605 89.1166 90.2826 88.8436 90.1579C88.5705 90.0332 88.2481 90.1534 88.1234 90.4265C87.9987 90.6995 88.1189 91.0219 88.392 91.1466C88.665 91.2713 88.9875 91.1511 89.1122 90.8781Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.823 91.6594C90.9477 91.3864 90.8275 91.0639 90.5545 90.9392C90.2814 90.8145 89.959 90.9348 89.8343 91.2078C89.7096 91.4809 89.8298 91.8033 90.1028 91.928C90.3759 92.0527 90.6983 91.9325 90.823 91.6594Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.5297 92.4423C92.6544 92.1693 92.5342 91.8468 92.2611 91.7221C91.9881 91.5974 91.6656 91.7177 91.5409 91.9907C91.4162 92.2638 91.5365 92.5862 91.8095 92.7109C92.0826 92.8356 92.405 92.7154 92.5297 92.4423Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.2406 93.2237C94.3653 92.9507 94.245 92.6282 93.972 92.5035C93.6989 92.3788 93.3765 92.4991 93.2518 92.7721C93.1271 93.0451 93.2473 93.3676 93.5204 93.4923C93.7934 93.617 94.1159 93.4967 94.2406 93.2237Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.9643 94.0076C96.089 93.7345 95.9688 93.4121 95.6957 93.2874C95.4227 93.1627 95.1003 93.2829 94.9756 93.556C94.8509 93.829 94.9711 94.1514 95.2441 94.2762C95.5172 94.4009 95.8396 94.2806 95.9643 94.0076Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.6752 94.7891C97.7999 94.516 97.6797 94.1936 97.4066 94.0689C97.1336 93.9442 96.8111 94.0644 96.6864 94.3375C96.5617 94.6105 96.682 94.9329 96.955 95.0577C97.2281 95.1824 97.5505 95.0621 97.6752 94.7891Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.3875 95.5712C99.5122 95.2981 99.3919 94.9757 99.1189 94.851C98.8459 94.7263 98.5234 94.8465 98.3987 95.1196C98.274 95.3926 98.3943 95.715 98.6673 95.8398C98.9403 95.9645 99.2628 95.8442 99.3875 95.5712Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.098 96.3525C101.223 96.0795 101.103 95.7571 100.83 95.6324C100.557 95.5076 100.234 95.6279 100.11 95.9009C99.9849 96.174 100.105 96.4964 100.378 96.6211C100.651 96.7458 100.974 96.6256 101.098 96.3525Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.821 97.1392C102.945 96.8662 102.825 96.5437 102.552 96.419C102.279 96.2943 101.957 96.4146 101.832 96.6876C101.707 96.9607 101.828 97.2831 102.101 97.4078C102.374 97.5325 102.696 97.4123 102.821 97.1392Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.532 97.9206C104.656 97.6476 104.536 97.3251 104.263 97.2004C103.99 97.0757 103.667 97.196 103.543 97.469C103.418 97.742 103.538 98.0645 103.811 98.1892C104.084 98.3139 104.407 98.1936 104.532 97.9206Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.4731 81.9998C73.5978 81.7267 73.4776 81.4043 73.2045 81.2796C72.9315 81.1549 72.6091 81.2751 72.4843 81.5482C72.3596 81.8212 72.4799 82.1436 72.7529 82.2683C73.026 82.3931 73.3484 82.2728 73.4731 81.9998Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.1966 82.7837C75.3213 82.5107 75.2011 82.1882 74.928 82.0635C74.655 81.9388 74.3326 82.059 74.2079 82.3321C74.0832 82.6051 74.2034 82.9276 74.4764 83.0523C74.7495 83.177 75.0719 83.0567 75.1966 82.7837Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.9075 83.5651C77.0322 83.2921 76.912 82.9696 76.6389 82.8449C76.3659 82.7202 76.0434 82.8405 75.9187 83.1135C75.794 83.3866 75.9143 83.709 76.1873 83.8337C76.4604 83.9584 76.7828 83.8382 76.9075 83.5651Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.6203 84.3474C78.745 84.0744 78.6247 83.7519 78.3517 83.6272C78.0786 83.5025 77.7562 83.6228 77.6315 83.8958C77.5068 84.1689 77.627 84.4913 77.9001 84.616C78.1731 84.7407 78.4956 84.6205 78.6203 84.3474Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.3307 85.1287C80.4554 84.8556 80.3352 84.5332 80.0621 84.4085C79.7891 84.2838 79.4667 84.404 79.342 84.6771C79.2172 84.9501 79.3375 85.2725 79.6105 85.3972C79.8836 85.522 80.206 85.4017 80.3307 85.1287Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.0532 85.9153C82.1779 85.6422 82.0577 85.3198 81.7846 85.1951C81.5116 85.0704 81.1891 85.1906 81.0644 85.4637C80.9397 85.7367 81.06 86.0592 81.333 86.1839C81.6061 86.3086 81.9285 86.1883 82.0532 85.9153Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.764 86.6968C83.8887 86.4237 83.7684 86.1013 83.4954 85.9766C83.2223 85.8519 82.8999 85.9721 82.7752 86.2452C82.6505 86.5182 82.7707 86.8407 83.0438 86.9654C83.3168 87.0901 83.6392 86.9698 83.764 86.6968Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.4784 87.478C85.6031 87.205 85.4829 86.8825 85.2098 86.7578C84.9368 86.6331 84.6144 86.7534 84.4897 87.0264C84.3649 87.2995 84.4852 87.6219 84.7582 87.7466C85.0313 87.8713 85.3537 87.7511 85.4784 87.478Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.1892 88.2595C87.3139 87.9865 87.1936 87.664 86.9206 87.5393C86.6476 87.4146 86.3251 87.5349 86.2004 87.8079C86.0757 88.081 86.196 88.4034 86.469 88.5281C86.742 88.6528 87.0645 88.5326 87.1892 88.2595Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.9161 89.0482C89.0408 88.7752 88.9205 88.4527 88.6475 88.328C88.3744 88.2033 88.052 88.3236 87.9273 88.5966C87.8026 88.8697 87.9228 89.1921 88.1959 89.3168C88.4689 89.4415 88.7913 89.3213 88.9161 89.0482Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.6269 89.8295C90.7516 89.5564 90.6314 89.234 90.3583 89.1093C90.0853 88.9846 89.7629 89.1048 89.6382 89.3779C89.5134 89.6509 89.6337 89.9733 89.9067 90.0981C90.1798 90.2228 90.5022 90.1025 90.6269 89.8295Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.3335 90.6125C92.4582 90.3394 92.338 90.017 92.0649 89.8923C91.7919 89.7676 91.4695 89.8878 91.3448 90.1609C91.2201 90.4339 91.3403 90.7564 91.6133 90.8811C91.8864 91.0058 92.2088 90.8855 92.3335 90.6125Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.0444 91.3939C94.1691 91.1208 94.0489 90.7984 93.7758 90.6737C93.5028 90.549 93.1803 90.6692 93.0556 90.9423C92.9309 91.2153 93.0512 91.5377 93.3242 91.6624C93.5973 91.7872 93.9197 91.6669 94.0444 91.3939Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.7682 92.1777C95.8929 91.9047 95.7727 91.5823 95.4996 91.4575C95.2266 91.3328 94.9042 91.4531 94.7795 91.7261C94.6547 91.9992 94.775 92.3216 95.048 92.4463C95.3211 92.571 95.6435 92.4508 95.7682 92.1777Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.4791 92.9592C97.6038 92.6861 97.4836 92.3637 97.2105 92.239C96.9375 92.1143 96.615 92.2345 96.4903 92.5076C96.3656 92.7806 96.4859 93.103 96.7589 93.2278C97.032 93.3525 97.3544 93.2322 97.4791 92.9592Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.1914 93.7412C99.3161 93.4682 99.1958 93.1457 98.9228 93.021C98.6498 92.8963 98.3273 93.0166 98.2026 93.2896C98.0779 93.5627 98.1982 93.8851 98.4712 94.0098C98.7442 94.1345 99.0667 94.0143 99.1914 93.7412Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.902 94.5226C101.027 94.2496 100.907 93.9272 100.634 93.8025C100.361 93.6778 100.038 93.798 99.9134 94.071C99.7887 94.3441 99.9089 94.6665 100.182 94.7912C100.455 94.9159 100.777 94.7957 100.902 94.5226Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.625 95.3093C102.749 95.0362 102.629 94.7138 102.356 94.5891C102.083 94.4644 101.761 94.5846 101.636 94.8577C101.511 95.1307 101.631 95.4531 101.904 95.5779C102.177 95.7026 102.5 95.5823 102.625 95.3093Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.335 96.0907C104.46 95.8177 104.34 95.4952 104.067 95.3705C103.794 95.2458 103.471 95.3661 103.347 95.6391C103.222 95.9121 103.342 96.2346 103.615 96.3593C103.888 96.484 104.211 96.3637 104.335 96.0907Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.4372 82.484C78.5619 82.2109 78.4417 81.8885 78.1686 81.7638C77.8956 81.6391 77.5732 81.7593 77.4485 82.0323C77.3238 82.3054 77.444 82.6278 77.717 82.7525C77.9901 82.8772 78.3125 82.757 78.4372 82.484Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.1496 83.2661C80.2743 82.993 80.1541 82.6706 79.881 82.5459C79.608 82.4212 79.2856 82.5414 79.1609 82.8145C79.0362 83.0875 79.1564 83.4099 79.4294 83.5346C79.7025 83.6593 80.0249 83.5391 80.1496 83.2661Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.8604 84.0474C81.9851 83.7744 81.8648 83.4519 81.5918 83.3272C81.3188 83.2025 80.9963 83.3228 80.8716 83.5958C80.7469 83.8689 80.8672 84.1913 81.1402 84.316C81.4132 84.4407 81.7357 84.3205 81.8604 84.0474Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.5827 84.8341C83.7074 84.5611 83.5872 84.2386 83.3142 84.1139C83.0411 83.9892 82.7187 84.1095 82.594 84.3825C82.4693 84.6555 82.5895 84.978 82.8626 85.1027C83.1356 85.2274 83.458 85.1072 83.5827 84.8341Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.2937 85.6155C85.4184 85.3424 85.2982 85.02 85.0252 84.8953C84.7521 84.7706 84.4297 84.8908 84.305 85.1639C84.1803 85.4369 84.3005 85.7594 84.5735 85.8841C84.8466 86.0088 85.169 85.8885 85.2937 85.6155Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.0079 86.3972C87.1326 86.1241 87.0124 85.8017 86.7393 85.677C86.4663 85.5523 86.1438 85.6725 86.0191 85.9456C85.8944 86.2186 86.0147 86.541 86.2877 86.6657C86.5608 86.7905 86.8832 86.6702 87.0079 86.3972Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.7188 87.1786C88.8435 86.9055 88.7232 86.5831 88.4502 86.4584C88.1772 86.3337 87.8547 86.4539 87.73 86.727C87.6053 87 87.7256 87.3225 87.9986 87.4472C88.2716 87.5719 88.5941 87.4516 88.7188 87.1786Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.4456 87.9672C90.5703 87.6942 90.4501 87.3717 90.177 87.247C89.904 87.1223 89.5815 87.2426 89.4568 87.5156C89.3321 87.7887 89.4524 88.1111 89.7254 88.2358C89.9984 88.3605 90.3209 88.2403 90.4456 87.9672Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.1565 88.7486C92.2812 88.4756 92.1609 88.1531 91.8879 88.0284C91.6148 87.9037 91.2924 88.024 91.1677 88.297C91.043 88.57 91.1632 88.8925 91.4363 89.0172C91.7093 89.1419 92.0318 89.0216 92.1565 88.7486Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.8631 89.5313C93.9878 89.2583 93.8676 88.9358 93.5946 88.8111C93.3215 88.6864 92.9991 88.8067 92.8744 89.0797C92.7497 89.3528 92.8699 89.6752 93.1429 89.7999C93.416 89.9246 93.7384 89.8044 93.8631 89.5313Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.574 90.3127C95.6987 90.0396 95.5785 89.7172 95.3054 89.5925C95.0324 89.4678 94.7099 89.588 94.5852 89.8611C94.4605 90.1341 94.5808 90.4566 94.8538 90.5813C95.1269 90.706 95.4493 90.5857 95.574 90.3127Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.2974 91.0967C97.4221 90.8236 97.3019 90.5012 97.0288 90.3765C96.7558 90.2518 96.4333 90.372 96.3086 90.6451C96.1839 90.9181 96.3042 91.2406 96.5772 91.3653C96.8503 91.49 97.1727 91.3697 97.2974 91.0967Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.008 91.8781C99.1327 91.605 99.0125 91.2826 98.7394 91.1579C98.4664 91.0332 98.144 91.1534 98.0193 91.4265C97.8946 91.6995 98.0148 92.0219 98.2878 92.1466C98.5609 92.2713 98.8833 92.1511 99.008 91.8781Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.721 92.6603C100.846 92.3872 100.725 92.0648 100.452 91.9401C100.179 91.8154 99.8567 91.9356 99.732 92.2087C99.6073 92.4817 99.7276 92.8042 100.001 92.9289C100.274 93.0536 100.596 92.9333 100.721 92.6603Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.432 93.4417C102.556 93.1686 102.436 92.8462 102.163 92.7215C101.89 92.5968 101.568 92.717 101.443 92.99C101.318 93.2631 101.438 93.5855 101.711 93.7102C101.985 93.8349 102.307 93.7147 102.432 93.4417Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.154 94.2282C104.279 93.9552 104.158 93.6327 103.885 93.508C103.612 93.3833 103.29 93.5036 103.165 93.7766C103.04 94.0497 103.161 94.3721 103.434 94.4968C103.707 94.6215 104.029 94.5013 104.154 94.2282Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.865 95.0096C105.989 94.7366 105.869 94.4142 105.596 94.2895C105.323 94.1648 105.001 94.285 104.876 94.558C104.751 94.8311 104.871 95.1535 105.144 95.2782C105.417 95.4029 105.74 95.2827 105.865 95.0096Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.6687 82.1992C81.7934 81.9262 81.6731 81.6037 81.4001 81.479C81.127 81.3543 80.8046 81.4746 80.6799 81.7476C80.5552 82.0207 80.6754 82.3431 80.9485 82.4678C81.2215 82.5925 81.544 82.4723 81.6687 82.1992Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.391 82.9859C83.5157 82.7129 83.3955 82.3904 83.1224 82.2657C82.8494 82.141 82.527 82.2613 82.4023 82.5343C82.2775 82.8073 82.3978 83.1298 82.6708 83.2545C82.9439 83.3792 83.2663 83.2589 83.391 82.9859Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.1019 83.7673C85.2266 83.4942 85.1064 83.1718 84.8333 83.0471C84.5603 82.9224 84.2378 83.0426 84.1131 83.3157C83.9884 83.5887 84.1087 83.9112 84.3817 84.0359C84.6548 84.1606 84.9772 84.0403 85.1019 83.7673Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.8164 84.5487C86.9411 84.2757 86.8208 83.9532 86.5478 83.8285C86.2748 83.7038 85.9523 83.8241 85.8276 84.0971C85.7029 84.3701 85.8232 84.6926 86.0962 84.8173C86.3692 84.942 86.6917 84.8218 86.8164 84.5487Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.5271 85.3301C88.6518 85.057 88.5316 84.7346 88.2586 84.6099C87.9855 84.4852 87.6631 84.6054 87.5384 84.8785C87.4137 85.1515 87.5339 85.474 87.8069 85.5987C88.08 85.7234 88.4024 85.6031 88.5271 85.3301Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.254 86.1188C90.3787 85.8457 90.2585 85.5233 89.9854 85.3986C89.7124 85.2739 89.3899 85.3941 89.2652 85.6672C89.1405 85.9402 89.2608 86.2627 89.5338 86.3874C89.8069 86.5121 90.1293 86.3918 90.254 86.1188Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.9649 86.9001C92.0896 86.6271 91.9693 86.3047 91.6963 86.18C91.4233 86.0553 91.1008 86.1755 90.9761 86.4485C90.8514 86.7216 90.9716 87.044 91.2447 87.1687C91.5177 87.2934 91.8402 87.1732 91.9649 86.9001Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.6715 87.683C93.7963 87.41 93.676 87.0876 93.403 86.9629C93.1299 86.8382 92.8075 86.9584 92.6828 87.2314C92.5581 87.5045 92.6783 87.8269 92.9514 87.9516C93.2244 88.0763 93.5468 87.9561 93.6715 87.683Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.3824 88.4643C95.5071 88.1913 95.3869 87.8688 95.1138 87.7441C94.8408 87.6194 94.5184 87.7397 94.3936 88.0127C94.2689 88.2857 94.3892 88.6082 94.6622 88.7329C94.9353 88.8576 95.2577 88.7373 95.3824 88.4643Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.1061 89.2484C97.2308 88.9754 97.1105 88.6529 96.8375 88.5282C96.5644 88.4035 96.242 88.5238 96.1173 88.7968C95.9926 89.0699 96.1128 89.3923 96.3859 89.517C96.6589 89.6417 96.9813 89.5215 97.1061 89.2484Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.8169 90.0298C98.9416 89.7567 98.8214 89.4343 98.5483 89.3096C98.2753 89.1849 97.9529 89.3051 97.8282 89.5782C97.7035 89.8512 97.8237 90.1737 98.0967 90.2984C98.3698 90.4231 98.6922 90.3028 98.8169 90.0298Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.529 90.8118C100.654 90.5387 100.534 90.2163 100.261 90.0916C99.9876 89.9669 99.6651 90.0871 99.5404 90.3602C99.4157 90.6332 99.536 90.9556 99.809 91.0804C100.082 91.2051 100.405 91.0848 100.529 90.8118Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.24 91.5931C102.365 91.3201 102.245 90.9977 101.972 90.873C101.698 90.7482 101.376 90.8685 101.251 91.1415C101.127 91.4146 101.247 91.737 101.52 91.8617C101.793 91.9864 102.115 91.8662 102.24 91.5931Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.963 92.3798C104.087 92.1068 103.967 91.7843 103.694 91.6596C103.421 91.5349 103.099 91.6552 102.974 91.9282C102.849 92.2013 102.969 92.5237 103.242 92.6484C103.515 92.7731 103.838 92.6529 103.963 92.3798Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.673 93.1613C105.798 92.8883 105.678 92.5658 105.405 92.4411C105.132 92.3164 104.809 92.4367 104.685 92.7097C104.56 92.9828 104.68 93.3052 104.953 93.4299C105.226 93.5546 105.549 93.4344 105.673 93.1613Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.6271 82.6878C86.7518 82.4148 86.6315 82.0923 86.3585 81.9676C86.0854 81.8429 85.763 81.9632 85.6383 82.2362C85.5136 82.5092 85.6338 82.8317 85.9069 82.9564C86.1799 83.0811 86.5023 82.9609 86.6271 82.6878Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.3413 83.469C88.4661 83.196 88.3458 82.8735 88.0728 82.7488C87.7997 82.6241 87.4773 82.7444 87.3526 83.0174C87.2279 83.2904 87.3481 83.6129 87.6212 83.7376C87.8942 83.8623 88.2166 83.742 88.3413 83.469Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.0522 84.2504C90.1769 83.9773 90.0567 83.6549 89.7836 83.5302C89.5106 83.4055 89.1882 83.5257 89.0634 83.7988C88.9387 84.0718 89.059 84.3942 89.332 84.519C89.6051 84.6437 89.9275 84.5234 90.0522 84.2504Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.779 85.0391C91.9037 84.766 91.7835 84.4436 91.5104 84.3189C91.2374 84.1942 90.915 84.3144 90.7903 84.5875C90.6655 84.8605 90.7858 85.1829 91.0588 85.3077C91.3319 85.4324 91.6543 85.3121 91.779 85.0391Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.4899 85.8204C93.6146 85.5474 93.4944 85.225 93.2213 85.1002C92.9483 84.9755 92.6258 85.0958 92.5011 85.3688C92.3764 85.6419 92.4967 85.9643 92.7697 86.089C93.0428 86.2137 93.3652 86.0935 93.4899 85.8204Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.1967 86.603C95.3214 86.3299 95.2011 86.0075 94.9281 85.8828C94.6551 85.7581 94.3326 85.8783 94.2079 86.1514C94.0832 86.4244 94.2035 86.7468 94.4765 86.8716C94.7495 86.9963 95.072 86.876 95.1967 86.603Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.9074 87.3845C97.0322 87.1114 96.9119 86.789 96.6389 86.6643C96.3658 86.5396 96.0434 86.6598 95.9187 86.9329C95.794 87.2059 95.9142 87.5283 96.1873 87.653C96.4603 87.7778 96.7827 87.6575 96.9074 87.3845Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.6311 88.1686C98.7558 87.8956 98.6356 87.5732 98.3626 87.4485C98.0895 87.3237 97.7671 87.444 97.6424 87.717C97.5177 87.9901 97.6379 88.3125 97.911 88.4372C98.184 88.5619 98.5064 88.4417 98.6311 88.1686Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.342 88.9501C100.467 88.6771 100.346 88.3547 100.073 88.2299C99.8004 88.1052 99.478 88.2255 99.3532 88.4985C99.2285 88.7716 99.3488 89.094 99.6218 89.2187C99.8949 89.3434 100.217 89.2232 100.342 88.9501Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.054 89.7321C102.179 89.4591 102.059 89.1366 101.786 89.0119C101.513 88.8872 101.19 89.0075 101.065 89.2805C100.941 89.5536 101.061 89.876 101.334 90.0007C101.607 90.1254 101.93 90.0052 102.054 89.7321Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.765 90.5135C103.89 90.2404 103.77 89.918 103.497 89.7933C103.223 89.6686 102.901 89.7888 102.776 90.0619C102.652 90.3349 102.772 90.6574 103.045 90.7821C103.318 90.9068 103.64 90.7865 103.765 90.5135Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.488 91.3002C105.612 91.0272 105.492 90.7048 105.219 90.58C104.946 90.4553 104.624 90.5756 104.499 90.8486C104.374 91.1217 104.494 91.4441 104.767 91.5688C105.04 91.6935 105.363 91.5733 105.488 91.3002Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.198 92.0817C107.323 91.8086 107.203 91.4862 106.93 91.3615C106.657 91.2368 106.334 91.357 106.21 91.6301C106.085 91.9031 106.205 92.2255 106.478 92.3503C106.751 92.475 107.074 92.3547 107.198 92.0817Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.8711 82.3878C89.9958 82.1148 89.8755 81.7923 89.6025 81.6676C89.3294 81.5429 89.007 81.6632 88.8823 81.9362C88.7576 82.2093 88.8778 82.5317 89.1509 82.6564C89.4239 82.7811 89.7464 82.6609 89.8711 82.3878Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.5819 83.1691C91.7066 82.8961 91.5863 82.5736 91.3133 82.4489C91.0403 82.3242 90.7178 82.4445 90.5931 82.7175C90.4684 82.9906 90.5887 83.313 90.8617 83.4377C91.1347 83.5624 91.4572 83.4422 91.5819 83.1691Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.3087 83.9578C93.4334 83.6848 93.3131 83.3623 93.0401 83.2376C92.7671 83.1129 92.4446 83.2332 92.3199 83.5062C92.1952 83.7793 92.3155 84.1017 92.5885 84.2264C92.8615 84.3511 93.184 84.2309 93.3087 83.9578Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.0196 84.7393C95.1443 84.4663 95.024 84.1438 94.751 84.0191C94.4779 83.8944 94.1555 84.0147 94.0308 84.2877C93.9061 84.5608 94.0263 84.8832 94.2994 85.0079C94.5724 85.1326 94.8949 85.0124 95.0196 84.7393Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.726 85.5224C96.8508 85.2494 96.7305 84.9269 96.4575 84.8022C96.1844 84.6775 95.862 84.7978 95.7373 85.0708C95.6126 85.3438 95.7328 85.6663 96.0059 85.791C96.2789 85.9157 96.6013 85.7954 96.726 85.5224Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.4369 86.3038C98.5616 86.0307 98.4414 85.7083 98.1683 85.5836C97.8953 85.4589 97.5729 85.5791 97.4482 85.8522C97.3234 86.1252 97.4437 86.4477 97.7167 86.5724C97.9898 86.6971 98.3122 86.5768 98.4369 86.3038Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.161 87.0875C100.286 86.8144 100.165 86.492 99.8923 86.3673C99.6192 86.2426 99.2968 86.3628 99.1721 86.6359C99.0474 86.9089 99.1676 87.2313 99.4407 87.3561C99.7137 87.4808 100.036 87.3605 100.161 87.0875Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.871 87.8687C101.996 87.5957 101.876 87.2732 101.603 87.1485C101.33 87.0238 101.007 87.1441 100.882 87.4171C100.758 87.6902 100.878 88.0126 101.151 88.1373C101.424 88.262 101.747 88.1418 101.871 87.8687Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.584 88.6509C103.709 88.3779 103.588 88.0555 103.315 87.9308C103.042 87.806 102.72 87.9263 102.595 88.1993C102.47 88.4724 102.591 88.7948 102.864 88.9195C103.137 89.0442 103.459 88.924 103.584 88.6509Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.295 89.4323C105.419 89.1593 105.299 88.8368 105.026 88.7121C104.753 88.5874 104.431 88.7077 104.306 88.9807C104.181 89.2538 104.302 89.5762 104.575 89.7009C104.848 89.8256 105.17 89.7054 105.295 89.4323Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.017 90.219C107.142 89.946 107.022 89.6235 106.749 89.4988C106.476 89.3741 106.153 89.4944 106.028 89.7674C105.904 90.0404 106.024 90.3629 106.297 90.4876C106.57 90.6123 106.893 90.492 107.017 90.219Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.728 91.0003C108.853 90.7273 108.732 90.4048 108.459 90.2801C108.186 90.1554 107.864 90.2757 107.739 90.5487C107.614 90.8217 107.735 91.1442 108.008 91.2689C108.281 91.3936 108.603 91.2734 108.728 91.0003Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.117 82.1095C93.2417 81.8365 93.1214 81.514 92.8484 81.3893C92.5754 81.2646 92.2529 81.3849 92.1282 81.6579C92.0035 81.9309 92.1237 82.2534 92.3968 82.3781C92.6698 82.5028 92.9923 82.3825 93.117 82.1095Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.8279 82.8909C94.9526 82.6178 94.8323 82.2954 94.5593 82.1707C94.2862 82.046 93.9638 82.1662 93.8391 82.4393C93.7144 82.7123 93.8346 83.0347 94.1077 83.1595C94.3807 83.2842 94.7031 83.1639 94.8279 82.8909Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.5346 83.6737C96.6594 83.4007 96.5391 83.0782 96.2661 82.9535C95.993 82.8288 95.6706 82.9491 95.5459 83.2221C95.4212 83.4951 95.5414 83.8176 95.8145 83.9423C96.0875 84.067 96.4099 83.9468 96.5346 83.6737Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.2454 84.4551C98.3701 84.182 98.2499 83.8596 97.9768 83.7349C97.7038 83.6102 97.3813 83.7304 97.2566 84.0035C97.1319 84.2765 97.2522 84.599 97.5252 84.7237C97.7983 84.8484 98.1207 84.7281 98.2454 84.4551Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.9692 85.2391C100.094 84.966 99.9736 84.6436 99.7006 84.5189C99.4275 84.3942 99.1051 84.5144 98.9804 84.7875C98.8557 85.0605 98.9759 85.383 99.249 85.5077C99.522 85.6324 99.8444 85.5121 99.9692 85.2391Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.68 86.0204C101.805 85.7474 101.684 85.425 101.411 85.3003C101.138 85.1756 100.816 85.2958 100.691 85.5688C100.567 85.8419 100.687 86.1643 100.96 86.289C101.233 86.4137 101.555 86.2935 101.68 86.0204Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.392 86.8026C103.517 86.5295 103.397 86.2071 103.124 86.0824C102.851 85.9577 102.528 86.0779 102.404 86.351C102.279 86.624 102.399 86.9464 102.672 87.0711C102.945 87.1958 103.268 87.0756 103.392 86.8026Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.103 87.5839C105.228 87.3109 105.108 86.9884 104.835 86.8637C104.562 86.739 104.239 86.8593 104.114 87.1323C103.99 87.4054 104.11 87.7278 104.383 87.8525C104.656 87.9772 104.978 87.857 105.103 87.5839Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.826 88.3707C106.95 88.0977 106.83 87.7752 106.557 87.6505C106.284 87.5258 105.962 87.6461 105.837 87.9191C105.712 88.1922 105.832 88.5146 106.105 88.6393C106.378 88.764 106.701 88.6438 106.826 88.3707Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.536 89.1521C108.661 88.8791 108.541 88.5566 108.268 88.4319C107.995 88.3072 107.672 88.4275 107.548 88.7005C107.423 88.9735 107.543 89.296 107.816 89.4207C108.089 89.5454 108.412 89.4251 108.536 89.1521Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.5472 113.376C99.6719 113.103 99.5517 112.781 99.2786 112.656C99.0056 112.531 98.6832 112.652 98.5584 112.925C98.4337 113.198 98.554 113.52 98.827 113.645C99.1001 113.77 99.4225 113.649 99.5472 113.376Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.258 114.158C101.383 113.885 101.263 113.562 100.99 113.438C100.716 113.313 100.394 113.433 100.269 113.706C100.145 113.979 100.265 114.302 100.538 114.426C100.811 114.551 101.133 114.431 101.258 114.158Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.985 114.946C103.11 114.673 102.989 114.351 102.716 114.226C102.443 114.101 102.121 114.222 101.996 114.495C101.871 114.768 101.992 115.09 102.265 115.215C102.538 115.34 102.86 115.219 102.985 114.946Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.696 115.728C104.82 115.455 104.7 115.132 104.427 115.008C104.154 114.883 103.832 115.003 103.707 115.276C103.582 115.549 103.702 115.872 103.976 115.996C104.249 116.121 104.571 116.001 104.696 115.728Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.077 112.295C101.202 112.022 101.081 111.7 100.808 111.575C100.535 111.45 100.213 111.57 100.088 111.843C99.9634 112.116 100.084 112.439 100.357 112.564C100.63 112.688 100.952 112.568 101.077 112.295Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.788 113.076C102.913 112.803 102.792 112.481 102.519 112.356C102.246 112.232 101.924 112.352 101.799 112.625C101.674 112.898 101.795 113.22 102.068 113.345C102.341 113.47 102.663 113.349 102.788 113.076Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.515 113.865C104.639 113.592 104.519 113.27 104.246 113.145C103.973 113.02 103.65 113.14 103.526 113.414C103.401 113.687 103.521 114.009 103.794 114.134C104.067 114.258 104.39 114.138 104.515 113.865Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.225 114.646C106.35 114.373 106.23 114.051 105.957 113.926C105.684 113.802 105.361 113.922 105.237 114.195C105.112 114.468 105.232 114.79 105.505 114.915C105.778 115.04 106.101 114.92 106.225 114.646Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.932 115.429C108.057 115.156 107.937 114.834 107.664 114.709C107.391 114.584 107.068 114.704 106.943 114.977C106.819 115.251 106.939 115.573 107.212 115.698C107.485 115.822 107.807 115.702 107.932 115.429Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.885 110.447C101.01 110.174 100.89 109.851 100.617 109.727C100.344 109.602 100.021 109.722 99.8964 109.995C99.7717 110.268 99.8919 110.591 100.165 110.715C100.438 110.84 100.76 110.72 100.885 110.447Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.596 111.228C102.721 110.955 102.601 110.633 102.328 110.508C102.055 110.383 101.732 110.504 101.607 110.777C101.483 111.05 101.603 111.372 101.876 111.497C102.149 111.622 102.471 111.501 102.596 111.228Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.323 112.017C104.448 111.744 104.327 111.421 104.054 111.297C103.781 111.172 103.459 111.292 103.334 111.565C103.209 111.838 103.33 112.161 103.603 112.286C103.876 112.41 104.198 112.29 104.323 112.017Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.034 112.798C106.158 112.525 106.038 112.203 105.765 112.078C105.492 111.953 105.17 112.074 105.045 112.347C104.92 112.62 105.04 112.942 105.313 113.067C105.587 113.192 105.909 113.071 106.034 112.798Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.741 113.581C107.865 113.308 107.745 112.985 107.472 112.861C107.199 112.736 106.876 112.856 106.752 113.129C106.627 113.402 106.747 113.725 107.02 113.849C107.293 113.974 107.616 113.854 107.741 113.581Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.451 114.362C109.576 114.089 109.456 113.767 109.183 113.642C108.91 113.517 108.587 113.638 108.463 113.911C108.338 114.184 108.458 114.506 108.731 114.631C109.004 114.756 109.327 114.635 109.451 114.362Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.175 115.146C111.3 114.873 111.179 114.551 110.906 114.426C110.633 114.302 110.311 114.422 110.186 114.695C110.061 114.968 110.182 115.29 110.455 115.415C110.728 115.54 111.05 115.42 111.175 115.146Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.411 109.367C102.535 109.094 102.415 108.772 102.142 108.647C101.869 108.523 101.547 108.643 101.422 108.916C101.297 109.189 101.417 109.511 101.69 109.636C101.963 109.761 102.286 109.64 102.411 109.367Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.121 110.149C104.246 109.876 104.126 109.553 103.853 109.429C103.58 109.304 103.257 109.424 103.133 109.697C103.008 109.97 103.128 110.293 103.401 110.417C103.674 110.542 103.997 110.422 104.121 110.149Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.848 110.938C105.973 110.664 105.853 110.342 105.58 110.217C105.306 110.093 104.984 110.213 104.859 110.486C104.735 110.759 104.855 111.081 105.128 111.206C105.401 111.331 105.723 111.211 105.848 110.938Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.559 111.719C107.684 111.446 107.564 111.123 107.291 110.999C107.017 110.874 106.695 110.994 106.57 111.267C106.446 111.54 106.566 111.863 106.839 111.988C107.112 112.112 107.434 111.992 107.559 111.719Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.266 112.502C109.391 112.228 109.27 111.906 108.997 111.781C108.724 111.657 108.402 111.777 108.277 112.05C108.152 112.323 108.273 112.645 108.546 112.77C108.819 112.895 109.141 112.775 109.266 112.502Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.977 113.283C111.101 113.01 110.981 112.687 110.708 112.563C110.435 112.438 110.113 112.558 109.988 112.831C109.863 113.104 109.984 113.427 110.257 113.551C110.53 113.676 110.852 113.556 110.977 113.283Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.94 108.286C104.065 108.013 103.944 107.69 103.671 107.566C103.398 107.441 103.076 107.561 102.951 107.834C102.826 108.107 102.947 108.43 103.22 108.555C103.493 108.679 103.815 108.559 103.94 108.286Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.651 109.067C105.776 108.794 105.655 108.472 105.382 108.347C105.109 108.222 104.787 108.343 104.662 108.616C104.537 108.889 104.658 109.211 104.931 109.336C105.204 109.461 105.526 109.34 105.651 109.067Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.377 109.856C107.502 109.583 107.382 109.261 107.109 109.136C106.836 109.011 106.513 109.131 106.389 109.404C106.264 109.677 106.384 110 106.657 110.125C106.93 110.249 107.253 110.129 107.377 109.856Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.088 110.637C109.213 110.364 109.093 110.042 108.82 109.917C108.547 109.792 108.224 109.913 108.1 110.186C107.975 110.459 108.095 110.781 108.368 110.906C108.641 111.031 108.964 110.91 109.088 110.637Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.795 111.42C110.92 111.147 110.8 110.825 110.527 110.7C110.254 110.575 109.931 110.696 109.806 110.969C109.682 111.242 109.802 111.564 110.075 111.689C110.348 111.814 110.671 111.693 110.795 111.42Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.749 106.438C103.873 106.165 103.753 105.842 103.48 105.718C103.207 105.593 102.885 105.713 102.76 105.986C102.635 106.259 102.755 106.582 103.028 106.706C103.301 106.831 103.624 106.711 103.749 106.438Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.459 107.219C105.584 106.946 105.464 106.624 105.191 106.499C104.918 106.374 104.595 106.495 104.471 106.768C104.346 107.041 104.466 107.363 104.739 107.488C105.012 107.613 105.335 107.492 105.459 107.219Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.186 108.008C107.311 107.735 107.191 107.412 106.918 107.288C106.645 107.163 106.322 107.283 106.197 107.556C106.073 107.829 106.193 108.152 106.466 108.277C106.739 108.401 107.061 108.281 107.186 108.008Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.897 108.789C109.022 108.516 108.902 108.194 108.628 108.069C108.355 107.945 108.033 108.065 107.908 108.338C107.784 108.611 107.904 108.933 108.177 109.058C108.45 109.183 108.772 109.062 108.897 108.789Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.604 109.572C110.729 109.299 110.608 108.977 110.335 108.852C110.062 108.727 109.74 108.847 109.615 109.12C109.49 109.393 109.611 109.716 109.884 109.841C110.157 109.965 110.479 109.845 110.604 109.572Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.225 107.513C102.35 107.239 102.23 106.917 101.957 106.792C101.684 106.668 101.361 106.788 101.237 107.061C101.112 107.334 101.232 107.656 101.505 107.781C101.778 107.906 102.101 107.786 102.225 107.513Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.574 104.557C103.698 104.284 103.578 103.962 103.305 103.837C103.032 103.712 102.709 103.832 102.585 104.105C102.46 104.379 102.58 104.701 102.853 104.826C103.126 104.95 103.449 104.83 103.574 104.557Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.284 105.338C105.409 105.065 105.289 104.743 105.016 104.618C104.743 104.494 104.42 104.614 104.296 104.887C104.171 105.16 104.291 105.482 104.564 105.607C104.837 105.732 105.16 105.611 105.284 105.338Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.011 106.127C107.136 105.854 107.016 105.532 106.743 105.407C106.47 105.282 106.147 105.402 106.022 105.676C105.898 105.949 106.018 106.271 106.291 106.396C106.564 106.52 106.886 106.4 107.011 106.127Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.722 106.909C108.847 106.636 108.726 106.313 108.453 106.188C108.18 106.064 107.858 106.184 107.733 106.457C107.609 106.73 107.729 107.052 108.002 107.177C108.275 107.302 108.597 107.182 108.722 106.909Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.429 107.692C110.553 107.419 110.433 107.096 110.16 106.971C109.887 106.847 109.564 106.967 109.44 107.24C109.315 107.513 109.435 107.835 109.708 107.96C109.981 108.085 110.304 107.965 110.429 107.692Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.103 103.476C105.228 103.203 105.108 102.881 104.834 102.756C104.561 102.631 104.239 102.751 104.114 103.025C103.99 103.298 104.11 103.62 104.383 103.745C104.656 103.869 104.978 103.749 105.103 103.476Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.814 104.258C106.939 103.984 106.818 103.662 106.545 103.537C106.272 103.413 105.95 103.533 105.825 103.806C105.7 104.079 105.821 104.401 106.094 104.526C106.367 104.651 106.689 104.531 106.814 104.258Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.541 105.046C108.665 104.773 108.545 104.451 108.272 104.326C107.999 104.201 107.677 104.321 107.552 104.595C107.427 104.868 107.547 105.19 107.82 105.315C108.094 105.439 108.416 105.319 108.541 105.046Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.252 105.828C110.376 105.555 110.256 105.232 109.983 105.107C109.71 104.983 109.387 105.103 109.263 105.376C109.138 105.649 109.258 105.971 109.531 106.096C109.804 106.221 110.127 106.101 110.252 105.828Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.911 101.628C105.036 101.355 104.916 101.033 104.643 100.908C104.37 100.783 104.047 100.903 103.923 101.176C103.798 101.449 103.918 101.772 104.191 101.897C104.464 102.021 104.787 101.901 104.911 101.628Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.622 102.409C106.747 102.136 106.627 101.814 106.354 101.689C106.081 101.564 105.758 101.685 105.633 101.958C105.509 102.231 105.629 102.553 105.902 102.678C106.175 102.803 106.497 102.682 106.622 102.409Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.349 103.198C108.474 102.925 108.353 102.603 108.08 102.478C107.807 102.353 107.485 102.473 107.36 102.746C107.235 103.02 107.356 103.342 107.629 103.467C107.902 103.591 108.224 103.471 108.349 103.198Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.06 103.979C110.184 103.706 110.064 103.384 109.791 103.259C109.518 103.135 109.196 103.255 109.071 103.528C108.946 103.801 109.067 104.123 109.34 104.248C109.613 104.373 109.935 104.253 110.06 103.979Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.437 100.548C106.561 100.275 106.441 99.9528 106.168 99.8281C105.895 99.7034 105.573 99.8236 105.448 100.097C105.323 100.37 105.444 100.692 105.717 100.817C105.99 100.942 106.312 100.821 106.437 100.548Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.147 101.329C108.272 101.056 108.152 100.734 107.879 100.609C107.606 100.485 107.283 100.605 107.159 100.878C107.034 101.151 107.154 101.473 107.427 101.598C107.7 101.723 108.023 101.603 108.147 101.329Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.874 102.118C109.999 101.845 109.878 101.523 109.605 101.398C109.332 101.273 109.01 101.394 108.885 101.667C108.761 101.94 108.881 102.262 109.154 102.387C109.427 102.511 109.749 102.391 109.874 102.118Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.966 99.4668C108.091 99.1938 107.971 98.8713 107.698 98.7466C107.424 98.6219 107.102 98.7422 106.977 99.0152C106.853 99.2882 106.973 99.6107 107.246 99.7354C107.519 99.8601 107.841 99.7398 107.966 99.4668Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.677 100.248C109.802 99.9753 109.681 99.6528 109.408 99.5281C109.135 99.4034 108.813 99.5236 108.688 99.7967C108.563 100.07 108.684 100.392 108.957 100.517C109.23 100.642 109.552 100.521 109.677 100.248Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.404 101.037C111.528 100.764 111.408 100.442 111.135 100.317C110.862 100.192 110.54 100.312 110.415 100.585C110.29 100.858 110.411 101.181 110.684 101.306C110.957 101.43 111.279 101.31 111.404 101.037Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.775 97.6187C107.899 97.3457 107.779 97.0232 107.506 96.8985C107.233 96.7738 106.911 96.8941 106.786 97.1671C106.661 97.4402 106.782 97.7626 107.055 97.8873C107.328 98.012 107.65 97.8918 107.775 97.6187Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.485 98.3999C109.61 98.1269 109.49 97.8044 109.217 97.6797C108.944 97.555 108.621 97.6753 108.497 97.9483C108.372 98.2213 108.492 98.5438 108.765 98.6685C109.038 98.7932 109.361 98.6729 109.485 98.3999Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.212 99.1886C111.337 98.9156 111.217 98.5931 110.944 98.4684C110.671 98.3437 110.348 98.464 110.223 98.737C110.099 99.01 110.219 99.3325 110.492 99.4572C110.765 99.5819 111.087 99.4616 111.212 99.1886Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.252 98.6933C106.376 98.4203 106.256 98.0978 105.983 97.9731C105.71 97.8484 105.388 97.9687 105.263 98.2417C105.138 98.5147 105.258 98.8372 105.531 98.9619C105.804 99.0866 106.127 98.9663 106.252 98.6933Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.578 95.7888C107.703 95.5158 107.583 95.1933 107.31 95.0686C107.037 94.9439 106.714 95.0642 106.589 95.3372C106.465 95.6103 106.585 95.9327 106.858 96.0574C107.131 96.1821 107.453 96.0619 107.578 95.7888Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.289 96.5702C109.414 96.2972 109.293 95.9747 109.02 95.85C108.747 95.7253 108.425 95.8455 108.3 96.1186C108.176 96.3916 108.296 96.7141 108.569 96.8388C108.842 96.9635 109.164 96.8432 109.289 96.5702Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.016 97.3589C111.141 97.0858 111.02 96.7634 110.747 96.6387C110.474 96.514 110.152 96.6342 110.027 96.9073C109.902 97.1803 110.023 97.5028 110.296 97.6275C110.569 97.7522 110.891 97.6319 111.016 97.3589Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.108 94.7079C109.233 94.4349 109.112 94.1125 108.839 93.9878C108.566 93.8631 108.244 93.9833 108.119 94.2563C107.994 94.5294 108.115 94.8518 108.388 94.9765C108.661 95.1012 108.983 94.981 109.108 94.7079Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.819 95.4893C110.943 95.2163 110.823 94.8938 110.55 94.7691C110.277 94.6444 109.955 94.7647 109.83 95.0377C109.705 95.3108 109.825 95.6332 110.098 95.7579C110.371 95.8826 110.694 95.7624 110.819 95.4893Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.916 92.8593C109.041 92.5862 108.921 92.2638 108.648 92.1391C108.375 92.0144 108.052 92.1346 107.927 92.4077C107.803 92.6807 107.923 93.0031 108.196 93.1278C108.469 93.2525 108.792 93.1323 108.916 92.8593Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.627 93.6406C110.752 93.3676 110.632 93.0451 110.358 92.9204C110.085 92.7957 109.763 92.916 109.638 93.189C109.514 93.4621 109.634 93.7845 109.907 93.9092C110.18 94.0339 110.502 93.9137 110.627 93.6406Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.442 91.78C110.566 91.5069 110.446 91.1845 110.173 91.0598C109.9 90.9351 109.578 91.0553 109.453 91.3284C109.328 91.6014 109.448 91.9238 109.721 92.0486C109.994 92.1733 110.317 92.053 110.442 91.78Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.256 89.925C110.381 89.6519 110.261 89.3295 109.988 89.2048C109.715 89.0801 109.392 89.2003 109.268 89.4734C109.143 89.7464 109.263 90.0689 109.536 90.1936C109.809 90.3183 110.132 90.198 110.256 89.925Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.0233 114.446C98.148 114.173 98.0277 113.85 97.7547 113.726C97.4817 113.601 97.1592 113.721 97.0345 113.994C96.9098 114.267 97.0301 114.59 97.3031 114.714C97.5761 114.839 97.8986 114.719 98.0233 114.446Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.7342 115.227C99.8589 114.954 99.7387 114.632 99.4656 114.507C99.1926 114.382 98.8702 114.503 98.7455 114.776C98.6207 115.049 98.741 115.371 99.014 115.496C99.2871 115.621 99.6095 115.5 99.7342 115.227Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.2293 111.451C99.354 111.178 99.2337 110.856 98.9607 110.731C98.6877 110.606 98.3652 110.726 98.2405 111C98.1158 111.273 98.2361 111.595 98.5091 111.72C98.7821 111.844 99.1046 111.724 99.2293 111.451Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.044 105.642C102.169 105.369 102.049 105.046 101.776 104.921C101.503 104.797 101.18 104.917 101.056 105.19C100.931 105.463 101.051 105.786 101.324 105.91C101.597 106.035 101.92 105.915 102.044 105.642Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.39 102.697C103.514 102.424 103.394 102.101 103.121 101.977C102.848 101.852 102.525 101.972 102.401 102.245C102.276 102.518 102.396 102.841 102.669 102.965C102.942 103.09 103.265 102.97 103.39 102.697Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.049 96.8734C106.174 96.6003 106.054 96.2779 105.781 96.1532C105.508 96.0285 105.185 96.1487 105.06 96.4218C104.936 96.6948 105.056 97.0172 105.329 97.1419C105.602 97.2666 105.925 97.1464 106.049 96.8734Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.384 93.9425C107.509 93.6695 107.388 93.347 107.115 93.2223C106.842 93.0976 106.52 93.2179 106.395 93.4909C106.27 93.7639 106.391 94.0864 106.664 94.2111C106.937 94.3358 107.259 94.2155 107.384 93.9425Z"
            fill="#B8B7B7"
          />
        </g>
        <path
          d="M20.7383 128.892L115.906 172.358L115.863 206.518L0 153.6L20.7383 128.892Z"
          fill="#03684D"
        />
        <mask
          id="mask1_67_13425"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="18"
          y="89"
          style={{ width: "111", height: "73" }}
          width="111"
          height="73"
        >
          <path
            d="M19.3632 119.007C19.5073 118.691 19.3684 118.319 19.053 118.175C18.7375 118.031 18.3651 118.169 18.221 118.485C18.0769 118.8 18.2158 119.173 18.5313 119.317C18.8467 119.461 19.2192 119.322 19.3632 119.007Z"
            fill="#B8B7B7"
          />
          <path
            d="M21.3396 119.909C21.4837 119.594 21.3448 119.221 21.0293 119.077C20.7139 118.933 20.3414 119.072 20.1974 119.387C20.0533 119.703 20.1922 120.075 20.5076 120.219C20.8231 120.364 21.1956 120.225 21.3396 119.909Z"
            fill="#B8B7B7"
          />
          <path
            d="M23.3344 120.82C23.4785 120.505 23.3396 120.132 23.0242 119.988C22.7087 119.844 22.3362 119.983 22.1922 120.299C22.0481 120.614 22.187 120.986 22.5025 121.131C22.8179 121.275 23.1904 121.136 23.3344 120.82Z"
            fill="#B8B7B7"
          />
          <path
            d="M25.3109 121.723C25.4549 121.407 25.316 121.035 25.0006 120.891C24.6852 120.747 24.3127 120.886 24.1686 121.201C24.0246 121.517 24.1635 121.889 24.4789 122.033C24.7943 122.177 25.1668 122.038 25.3109 121.723Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.2825 122.627C27.4266 122.312 27.2876 121.939 26.9722 121.795C26.6568 121.651 26.2843 121.79 26.1402 122.106C25.9962 122.421 26.1351 122.793 26.4505 122.937C26.7659 123.082 27.1384 122.943 27.2825 122.627Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.259 123.53C29.4031 123.214 29.2641 122.842 28.9487 122.698C28.6333 122.554 28.2608 122.693 28.1167 123.008C27.9727 123.324 28.1116 123.696 28.427 123.84C28.7424 123.984 29.1149 123.845 29.259 123.53Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.2497 124.435C31.3938 124.12 31.2549 123.748 30.9394 123.603C30.624 123.459 30.2515 123.598 30.1075 123.914C29.9634 124.229 30.1023 124.602 30.4177 124.746C30.7332 124.89 31.1057 124.751 31.2497 124.435Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.2262 125.338C33.3702 125.023 33.2313 124.65 32.9159 124.506C32.6005 124.362 32.228 124.501 32.0839 124.816C31.9398 125.132 32.0788 125.504 32.3942 125.648C32.7096 125.792 33.0821 125.654 33.2262 125.338Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.2047 126.242C35.3487 125.926 35.2098 125.554 34.8944 125.41C34.579 125.266 34.2065 125.405 34.0624 125.72C33.9184 126.035 34.0573 126.408 34.3727 126.552C34.6881 126.696 35.0606 126.557 35.2047 126.242Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.1811 127.144C37.3251 126.829 37.1862 126.457 36.8708 126.313C36.5554 126.168 36.1829 126.307 36.0388 126.623C35.8947 126.938 36.0337 127.311 36.3491 127.455C36.6645 127.599 37.037 127.46 37.1811 127.144Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.1704 128.053C39.3144 127.738 39.1755 127.365 38.8601 127.221C38.5447 127.077 38.1722 127.216 38.0281 127.531C37.8841 127.847 38.023 128.219 38.3384 128.363C38.6538 128.507 39.0263 128.368 39.1704 128.053Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.1469 128.956C41.2909 128.64 41.152 128.268 40.8366 128.124C40.5212 127.98 40.1487 128.119 40.0046 128.434C39.8606 128.749 39.9995 129.122 40.3149 129.266C40.6303 129.41 41.0028 129.271 41.1469 128.956Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.1273 129.858C43.2713 129.543 43.1324 129.171 42.817 129.026C42.5016 128.882 42.1291 129.021 41.985 129.337C41.841 129.652 41.9799 130.025 42.2953 130.169C42.6107 130.313 42.9832 130.174 43.1273 129.858Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.1037 130.761C45.2478 130.446 45.1089 130.073 44.7934 129.929C44.478 129.785 44.1055 129.924 43.9615 130.239C43.8174 130.555 43.9563 130.927 44.2717 131.071C44.5872 131.215 44.9597 131.077 45.1037 130.761Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.0984 131.672C47.2425 131.357 47.1036 130.984 46.7881 130.84C46.4727 130.696 46.1002 130.835 45.9562 131.15C45.8121 131.466 45.951 131.838 46.2664 131.982C46.5819 132.127 46.9543 131.988 47.0984 131.672Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.0749 132.575C49.219 132.259 49.0801 131.887 48.7646 131.743C48.4492 131.599 48.0767 131.738 47.9327 132.053C47.7886 132.369 47.9275 132.741 48.2429 132.885C48.5584 133.029 48.9308 132.89 49.0749 132.575Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.0467 133.479C51.1907 133.163 51.0518 132.791 50.7364 132.647C50.421 132.503 50.0485 132.642 49.9044 132.957C49.7603 133.273 49.8993 133.645 50.2147 133.789C50.5301 133.933 50.9026 133.794 51.0467 133.479Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.0231 134.382C53.1672 134.066 53.0282 133.694 52.7128 133.55C52.3974 133.406 52.0249 133.544 51.8808 133.86C51.7368 134.175 51.8757 134.548 52.1911 134.692C52.5065 134.836 52.879 134.697 53.0231 134.382Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.0142 135.288C55.1583 134.972 55.0194 134.6 54.704 134.456C54.3885 134.312 54.0161 134.45 53.872 134.766C53.7279 135.081 53.8668 135.454 54.1823 135.598C54.4977 135.742 54.8702 135.603 55.0142 135.288Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.9906 136.19C57.1346 135.875 56.9957 135.502 56.6803 135.358C56.3649 135.214 55.9924 135.353 55.8483 135.669C55.7042 135.984 55.8432 136.356 56.1586 136.5C56.474 136.645 56.8465 136.506 56.9906 136.19Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.9686 137.094C59.1127 136.778 58.9738 136.406 58.6584 136.262C58.343 136.118 57.9705 136.257 57.8264 136.572C57.6823 136.887 57.8213 137.26 58.1367 137.404C58.4521 137.548 58.8246 137.409 58.9686 137.094Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.9451 137.996C61.0892 137.681 60.9502 137.308 60.6348 137.164C60.3194 137.02 59.9469 137.159 59.8028 137.475C59.6588 137.79 59.7977 138.163 60.1131 138.307C60.4285 138.451 60.801 138.312 60.9451 137.996Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.9349 138.905C63.079 138.59 62.94 138.217 62.6246 138.073C62.3092 137.929 61.9367 138.068 61.7926 138.384C61.6486 138.699 61.7875 139.071 62.1029 139.216C62.4183 139.36 62.7908 139.221 62.9349 138.905Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.9113 139.808C65.0553 139.492 64.9164 139.12 64.601 138.976C64.2856 138.832 63.9131 138.971 63.769 139.286C63.625 139.602 63.7639 139.974 64.0793 140.118C64.3947 140.262 64.7672 140.123 64.9113 139.808Z"
            fill="#B8B7B7"
          />
          <path
            d="M21.1299 117.757C21.274 117.442 21.1351 117.069 20.8196 116.925C20.5042 116.781 20.1317 116.92 19.9877 117.236C19.8436 117.551 19.9825 117.924 20.2979 118.068C20.6134 118.212 20.9858 118.073 21.1299 117.757Z"
            fill="#B8B7B7"
          />
          <path
            d="M23.1064 118.66C23.2505 118.345 23.1116 117.972 22.7961 117.828C22.4807 117.684 22.1082 117.823 21.9642 118.138C21.8201 118.454 21.959 118.826 22.2744 118.97C22.5899 119.114 22.9623 118.975 23.1064 118.66Z"
            fill="#B8B7B7"
          />
          <path
            d="M25.1011 119.571C25.2452 119.256 25.1062 118.883 24.7908 118.739C24.4754 118.595 24.1029 118.734 23.9588 119.049C23.8148 119.365 23.9537 119.737 24.2691 119.881C24.5845 120.025 24.957 119.886 25.1011 119.571Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.0775 120.474C27.2216 120.158 27.0827 119.786 26.7673 119.642C26.4518 119.498 26.0794 119.637 25.9353 119.952C25.7912 120.267 25.9301 120.64 26.2456 120.784C26.561 120.928 26.9335 120.789 27.0775 120.474Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.0493 121.378C29.1933 121.063 29.0544 120.69 28.739 120.546C28.4236 120.402 28.0511 120.541 27.907 120.856C27.763 121.172 27.9019 121.544 28.2173 121.688C28.5327 121.832 28.9052 121.693 29.0493 121.378Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.0257 122.281C31.1697 121.965 31.0308 121.593 30.7154 121.449C30.4 121.305 30.0275 121.444 29.8834 121.759C29.7393 122.074 29.8783 122.447 30.1937 122.591C30.5091 122.735 30.8816 122.596 31.0257 122.281Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.0168 123.186C33.1609 122.871 33.022 122.498 32.7065 122.354C32.3911 122.21 32.0186 122.349 31.8746 122.665C31.7305 122.98 31.8694 123.353 32.1848 123.497C32.5003 123.641 32.8727 123.502 33.0168 123.186Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.9932 124.089C35.1373 123.774 34.9984 123.401 34.683 123.257C34.3676 123.113 33.9951 123.252 33.851 123.567C33.7069 123.883 33.8458 124.255 34.1613 124.399C34.4767 124.543 34.8492 124.404 34.9932 124.089Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.9713 124.993C37.1153 124.677 36.9764 124.305 36.661 124.161C36.3456 124.017 35.9731 124.155 35.829 124.471C35.685 124.786 35.8239 125.159 36.1393 125.303C36.4547 125.447 36.8272 125.308 36.9713 124.993Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.9477 125.895C39.0918 125.58 38.9529 125.207 38.6374 125.063C38.322 124.919 37.9495 125.058 37.8055 125.373C37.6614 125.689 37.8003 126.061 38.1157 126.205C38.4312 126.349 38.8037 126.211 38.9477 125.895Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.9375 126.804C41.0815 126.489 40.9426 126.116 40.6272 125.972C40.3118 125.828 39.9393 125.967 39.7952 126.282C39.6511 126.598 39.7901 126.97 40.1055 127.114C40.4209 127.258 40.7934 127.119 40.9375 126.804Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.914 127.707C43.058 127.391 42.9191 127.019 42.6037 126.875C42.2883 126.731 41.9158 126.87 41.7717 127.185C41.6277 127.5 41.7666 127.873 42.082 128.017C42.3974 128.161 42.7699 128.022 42.914 127.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.8944 128.609C45.0384 128.294 44.8995 127.921 44.5841 127.777C44.2687 127.633 43.8962 127.772 43.7521 128.088C43.6081 128.403 43.747 128.776 44.0624 128.92C44.3778 129.064 44.7503 128.925 44.8944 128.609Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.8708 129.512C47.0149 129.197 46.876 128.824 46.5605 128.68C46.2451 128.536 45.8726 128.675 45.7286 128.99C45.5845 129.306 45.7234 129.678 46.0388 129.822C46.3543 129.966 46.7267 129.828 46.8708 129.512Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.8655 130.423C49.0096 130.108 48.8706 129.735 48.5552 129.591C48.2398 129.447 47.8673 129.586 47.7233 129.901C47.5792 130.217 47.7181 130.589 48.0335 130.733C48.3489 130.877 48.7214 130.739 48.8655 130.423Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.842 131.326C50.9861 131.01 50.8472 130.638 50.5317 130.494C50.2163 130.35 49.8438 130.489 49.6998 130.804C49.5557 131.12 49.6946 131.492 50.01 131.636C50.3255 131.78 50.6979 131.641 50.842 131.326Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.8137 132.23C52.9578 131.914 52.8189 131.542 52.5035 131.398C52.188 131.254 51.8156 131.393 51.6715 131.708C51.5274 132.023 51.6663 132.396 51.9818 132.54C52.2972 132.684 52.6697 132.545 52.8137 132.23Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.7902 133.133C54.9343 132.817 54.7954 132.445 54.48 132.301C54.1645 132.157 53.7921 132.295 53.648 132.611C53.5039 132.926 53.6428 133.299 53.9583 133.443C54.2737 133.587 54.6462 133.448 54.7902 133.133Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.7808 134.038C56.9249 133.723 56.786 133.35 56.4706 133.206C56.1551 133.062 55.7827 133.201 55.6386 133.517C55.4945 133.832 55.6334 134.205 55.9489 134.349C56.2643 134.493 56.6368 134.354 56.7808 134.038Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.7573 134.941C58.9013 134.626 58.7624 134.253 58.447 134.109C58.1316 133.965 57.7591 134.104 57.615 134.419C57.471 134.735 57.6099 135.107 57.9253 135.251C58.2407 135.395 58.6132 135.256 58.7573 134.941Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.7357 135.845C60.8798 135.529 60.7409 135.157 60.4255 135.013C60.11 134.869 59.7376 135.008 59.5935 135.323C59.4494 135.638 59.5883 136.011 59.9038 136.155C60.2192 136.299 60.5917 136.16 60.7357 135.845Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.7122 136.747C62.8562 136.432 62.7173 136.059 62.4019 135.915C62.0865 135.771 61.714 135.91 61.5699 136.226C61.4259 136.541 61.5648 136.914 61.8802 137.058C62.1956 137.202 62.5681 137.063 62.7122 136.747Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.7015 137.656C64.8456 137.341 64.7066 136.968 64.3912 136.824C64.0758 136.68 63.7033 136.819 63.5593 137.134C63.4152 137.45 63.5541 137.822 63.8695 137.966C64.1849 138.11 64.5574 137.971 64.7015 137.656Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.678 138.559C66.8221 138.243 66.6831 137.871 66.3677 137.727C66.0523 137.583 65.6798 137.722 65.5358 138.037C65.3917 138.352 65.5306 138.725 65.846 138.869C66.1614 139.013 66.5339 138.874 66.678 138.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M20.909 115.622C21.053 115.307 20.9141 114.934 20.5987 114.79C20.2833 114.646 19.9108 114.785 19.7667 115.101C19.6226 115.416 19.7616 115.789 20.077 115.933C20.3924 116.077 20.7649 115.938 20.909 115.622Z"
            fill="#B8B7B7"
          />
          <path
            d="M22.8853 116.525C23.0294 116.21 22.8905 115.837 22.5751 115.693C22.2596 115.549 21.8872 115.688 21.7431 116.003C21.599 116.319 21.7379 116.691 22.0534 116.835C22.3688 116.979 22.7413 116.84 22.8853 116.525Z"
            fill="#B8B7B7"
          />
          <path
            d="M24.8801 117.436C25.0242 117.121 24.8852 116.748 24.5698 116.604C24.2544 116.46 23.8819 116.599 23.7378 116.914C23.5938 117.23 23.7327 117.602 24.0481 117.746C24.3635 117.89 24.736 117.752 24.8801 117.436Z"
            fill="#B8B7B7"
          />
          <path
            d="M26.8565 118.339C27.0005 118.023 26.8616 117.651 26.5462 117.507C26.2308 117.363 25.8583 117.502 25.7142 117.817C25.5702 118.133 25.7091 118.505 26.0245 118.649C26.3399 118.793 26.7124 118.654 26.8565 118.339Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.8281 119.243C28.9722 118.928 28.8333 118.555 28.5179 118.411C28.2024 118.267 27.83 118.406 27.6859 118.721C27.5418 119.037 27.6807 119.409 27.9962 119.553C28.3116 119.697 28.6841 119.558 28.8281 119.243Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.8046 120.146C30.9487 119.83 30.8098 119.458 30.4944 119.314C30.179 119.17 29.8065 119.309 29.6624 119.624C29.5183 119.939 29.6573 120.312 29.9727 120.456C30.2881 120.6 30.6606 120.461 30.8046 120.146Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.7954 121.051C32.9394 120.736 32.8005 120.363 32.4851 120.219C32.1697 120.075 31.7972 120.214 31.6531 120.53C31.5091 120.845 31.648 121.217 31.9634 121.362C32.2788 121.506 32.6513 121.367 32.7954 121.051Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.7718 121.954C34.9159 121.639 34.777 121.266 34.4615 121.122C34.1461 120.978 33.7736 121.117 33.6296 121.432C33.4855 121.748 33.6244 122.12 33.9398 122.264C34.2553 122.408 34.6277 122.269 34.7718 121.954Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.7503 122.858C36.8944 122.542 36.7555 122.17 36.4401 122.026C36.1246 121.882 35.7521 122.02 35.6081 122.336C35.464 122.651 35.6029 123.024 35.9184 123.168C36.2338 123.312 36.6063 123.173 36.7503 122.858Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.7267 123.76C38.8708 123.445 38.7319 123.072 38.4164 122.928C38.101 122.784 37.7285 122.923 37.5845 123.239C37.4404 123.554 37.5793 123.926 37.8947 124.071C38.2102 124.215 38.5826 124.076 38.7267 123.76Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.716 124.669C40.8601 124.353 40.7212 123.981 40.4058 123.837C40.0903 123.693 39.7178 123.832 39.5738 124.147C39.4297 124.463 39.5686 124.835 39.8841 124.979C40.1995 125.123 40.572 124.984 40.716 124.669Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.6925 125.572C42.8366 125.256 42.6977 124.884 42.3823 124.74C42.0668 124.596 41.6943 124.734 41.5503 125.05C41.4062 125.365 41.5451 125.738 41.8606 125.882C42.176 126.026 42.5485 125.887 42.6925 125.572Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.6729 126.474C44.817 126.159 44.6781 125.786 44.3627 125.642C44.0472 125.498 43.6748 125.637 43.5307 125.953C43.3866 126.268 43.5255 126.64 43.841 126.785C44.1564 126.929 44.5289 126.79 44.6729 126.474Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.6494 127.377C46.7934 127.062 46.6545 126.689 46.3391 126.545C46.0237 126.401 45.6512 126.54 45.5071 126.855C45.3631 127.171 45.502 127.543 45.8174 127.687C46.1328 127.831 46.5053 127.692 46.6494 127.377Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.6441 128.288C48.7882 127.973 48.6493 127.6 48.3339 127.456C48.0184 127.312 47.6459 127.451 47.5019 127.766C47.3578 128.082 47.4967 128.454 47.8122 128.598C48.1276 128.742 48.5001 128.603 48.6441 128.288Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.6205 129.191C50.7646 128.875 50.6257 128.503 50.3102 128.359C49.9948 128.215 49.6223 128.354 49.4783 128.669C49.3342 128.984 49.4731 129.357 49.7885 129.501C50.104 129.645 50.4764 129.506 50.6205 129.191Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.5924 130.095C52.7364 129.779 52.5975 129.407 52.2821 129.263C51.9667 129.119 51.5942 129.257 51.4501 129.573C51.3061 129.888 51.445 130.261 51.7604 130.405C52.0758 130.549 52.4483 130.41 52.5924 130.095Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.5688 130.997C54.7129 130.682 54.574 130.309 54.2585 130.165C53.9431 130.021 53.5706 130.16 53.4266 130.476C53.2825 130.791 53.4214 131.164 53.7368 131.308C54.0523 131.452 54.4247 131.313 54.5688 130.997Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.5599 131.903C56.704 131.588 56.565 131.215 56.2496 131.071C55.9342 130.927 55.5617 131.066 55.4176 131.382C55.2736 131.697 55.4125 132.07 55.7279 132.214C56.0433 132.358 56.4158 132.219 56.5599 131.903Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.5363 132.806C58.6803 132.491 58.5414 132.118 58.226 131.974C57.9106 131.83 57.5381 131.969 57.394 132.284C57.25 132.6 57.3889 132.972 57.7043 133.116C58.0197 133.26 58.3922 133.122 58.5363 132.806Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.5143 133.71C60.6584 133.394 60.5195 133.022 60.204 132.878C59.8886 132.734 59.5161 132.872 59.3721 133.188C59.228 133.503 59.3669 133.876 59.6823 134.02C59.9978 134.164 60.3702 134.025 60.5143 133.71Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.4907 134.612C62.6347 134.297 62.4958 133.924 62.1804 133.78C61.865 133.636 61.4925 133.775 61.3484 134.09C61.2044 134.406 61.3433 134.778 61.6587 134.922C61.9741 135.067 62.3466 134.928 62.4907 134.612Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.4804 135.521C64.6245 135.206 64.4856 134.833 64.1702 134.689C63.8547 134.545 63.4822 134.684 63.3382 134.999C63.1941 135.315 63.333 135.687 63.6485 135.831C63.9639 135.975 64.3364 135.836 64.4804 135.521Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.4569 136.424C66.6009 136.108 66.462 135.736 66.1466 135.592C65.8312 135.448 65.4587 135.587 65.3146 135.902C65.1706 136.217 65.3095 136.59 65.6249 136.734C65.9403 136.878 66.3128 136.739 66.4569 136.424Z"
            fill="#B8B7B7"
          />
          <path
            d="M22.6707 114.375C22.8147 114.06 22.6758 113.687 22.3604 113.543C22.045 113.399 21.6725 113.538 21.5284 113.853C21.3844 114.169 21.5233 114.541 21.8387 114.685C22.1541 114.829 22.5266 114.69 22.6707 114.375Z"
            fill="#B8B7B7"
          />
          <path
            d="M24.6471 115.278C24.7911 114.962 24.6522 114.59 24.3368 114.446C24.0214 114.302 23.6489 114.441 23.5048 114.756C23.3607 115.071 23.4997 115.444 23.8151 115.588C24.1305 115.732 24.503 115.593 24.6471 115.278Z"
            fill="#B8B7B7"
          />
          <path
            d="M26.6419 116.189C26.7859 115.873 26.647 115.501 26.3316 115.357C26.0162 115.213 25.6437 115.352 25.4996 115.667C25.3556 115.982 25.4945 116.355 25.8099 116.499C26.1253 116.643 26.4978 116.504 26.6419 116.189Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.6183 117.092C28.7624 116.776 28.6235 116.404 28.308 116.26C27.9926 116.115 27.6201 116.254 27.4761 116.57C27.332 116.885 27.4709 117.258 27.7863 117.402C28.1018 117.546 28.4742 117.407 28.6183 117.092Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.5899 117.996C30.734 117.681 30.5951 117.308 30.2797 117.164C29.9642 117.02 29.5917 117.159 29.4477 117.474C29.3036 117.79 29.4425 118.162 29.758 118.306C30.0734 118.45 30.4459 118.311 30.5899 117.996Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.5663 118.899C32.7104 118.583 32.5715 118.211 32.256 118.067C31.9406 117.923 31.5681 118.062 31.4241 118.377C31.28 118.692 31.4189 119.065 31.7343 119.209C32.0498 119.353 32.4222 119.214 32.5663 118.899Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.5572 119.804C34.7012 119.489 34.5623 119.116 34.2469 118.972C33.9315 118.828 33.559 118.967 33.4149 119.282C33.2708 119.598 33.4098 119.97 33.7252 120.114C34.0406 120.258 34.4131 120.119 34.5572 119.804Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.5336 120.707C36.6777 120.391 36.5387 120.019 36.2233 119.875C35.9079 119.731 35.5354 119.869 35.3913 120.185C35.2473 120.5 35.3862 120.873 35.7016 121.017C36.017 121.161 36.3895 121.022 36.5336 120.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.512 121.61C38.6561 121.295 38.5172 120.922 38.2018 120.778C37.8863 120.634 37.5139 120.773 37.3698 121.089C37.2257 121.404 37.3646 121.776 37.6801 121.921C37.9955 122.065 38.368 121.926 38.512 121.61Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.4885 122.513C40.6325 122.198 40.4936 121.825 40.1782 121.681C39.8628 121.537 39.4903 121.676 39.3462 121.991C39.2022 122.307 39.3411 122.679 39.6565 122.823C39.9719 122.967 40.3444 122.828 40.4885 122.513Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.4779 123.422C42.6219 123.106 42.483 122.734 42.1676 122.59C41.8522 122.446 41.4797 122.584 41.3356 122.9C41.1916 123.215 41.3305 123.588 41.6459 123.732C41.9613 123.876 42.3338 123.737 42.4779 123.422Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.4542 124.324C44.5983 124.009 44.4594 123.636 44.144 123.492C43.8285 123.348 43.4561 123.487 43.312 123.803C43.1679 124.118 43.3068 124.49 43.6223 124.635C43.9377 124.779 44.3102 124.64 44.4542 124.324Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.435 125.227C46.5791 124.912 46.4402 124.539 46.1247 124.395C45.8093 124.251 45.4368 124.39 45.2928 124.706C45.1487 125.021 45.2876 125.394 45.603 125.538C45.9185 125.682 46.291 125.543 46.435 125.227Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.4114 126.13C48.5555 125.815 48.4165 125.442 48.1011 125.298C47.7857 125.154 47.4132 125.293 47.2692 125.608C47.1251 125.924 47.264 126.296 47.5794 126.44C47.8948 126.584 48.2673 126.446 48.4114 126.13Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.4063 127.041C50.5503 126.726 50.4114 126.353 50.096 126.209C49.7806 126.065 49.4081 126.204 49.264 126.519C49.12 126.835 49.2589 127.207 49.5743 127.351C49.8897 127.495 50.2622 127.357 50.4063 127.041Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.3822 127.944C52.5262 127.628 52.3873 127.256 52.0719 127.112C51.7565 126.968 51.384 127.106 51.2399 127.422C51.0959 127.737 51.2348 128.11 51.5502 128.254C51.8656 128.398 52.2381 128.259 52.3822 127.944Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.3545 128.848C54.4985 128.532 54.3596 128.16 54.0442 128.016C53.7288 127.872 53.3563 128.011 53.2122 128.326C53.0681 128.642 53.2071 129.014 53.5225 129.158C53.8379 129.302 54.2104 129.163 54.3545 128.848Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.3309 129.751C56.475 129.435 56.336 129.063 56.0206 128.919C55.7052 128.774 55.3327 128.913 55.1886 129.229C55.0446 129.544 55.1835 129.917 55.4989 130.061C55.8143 130.205 56.1868 130.066 56.3309 129.751Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.3215 130.656C58.4656 130.341 58.3266 129.968 58.0112 129.824C57.6958 129.68 57.3233 129.819 57.1792 130.135C57.0352 130.45 57.1741 130.823 57.4895 130.967C57.8049 131.111 58.1774 130.972 58.3215 130.656Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.2979 131.559C60.442 131.244 60.3031 130.871 59.9877 130.727C59.6722 130.583 59.2998 130.722 59.1557 131.037C59.0116 131.353 59.1505 131.725 59.466 131.869C59.7814 132.013 60.1539 131.874 60.2979 131.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.2764 132.463C62.4205 132.147 62.2815 131.775 61.9661 131.631C61.6507 131.487 61.2782 131.625 61.1341 131.941C60.9901 132.256 61.129 132.629 61.4444 132.773C61.7598 132.917 62.1323 132.778 62.2764 132.463Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.2528 133.365C64.3969 133.05 64.258 132.677 63.9426 132.533C63.6271 132.389 63.2546 132.528 63.1106 132.844C62.9665 133.159 63.1054 133.532 63.4209 133.676C63.7363 133.82 64.1088 133.681 64.2528 133.365Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.2421 134.274C66.3862 133.958 66.2472 133.586 65.9318 133.442C65.6164 133.298 65.2439 133.437 65.0998 133.752C64.9558 134.068 65.0947 134.44 65.4101 134.584C65.7255 134.728 66.098 134.589 66.2421 134.274Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.2186 135.177C68.3627 134.861 68.2238 134.489 67.9084 134.345C67.593 134.201 67.2205 134.339 67.0764 134.655C66.9323 134.97 67.0713 135.343 67.3867 135.487C67.7021 135.631 68.0746 135.492 68.2186 135.177Z"
            fill="#B8B7B7"
          />
          <path
            d="M24.4376 113.126C24.5816 112.811 24.4427 112.438 24.1273 112.294C23.8119 112.15 23.4394 112.289 23.2953 112.605C23.1513 112.92 23.2902 113.293 23.6056 113.437C23.921 113.581 24.2935 113.442 24.4376 113.126Z"
            fill="#B8B7B7"
          />
          <path
            d="M26.4137 114.029C26.5577 113.713 26.4188 113.341 26.1034 113.197C25.788 113.053 25.4155 113.192 25.2714 113.507C25.1273 113.823 25.2663 114.195 25.5817 114.339C25.8971 114.483 26.2696 114.344 26.4137 114.029Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.4088 114.94C28.5529 114.625 28.414 114.252 28.0986 114.108C27.7831 113.964 27.4107 114.103 27.2666 114.418C27.1225 114.734 27.2614 115.106 27.5769 115.25C27.8923 115.394 28.2648 115.256 28.4088 114.94Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.3848 115.843C30.5289 115.527 30.39 115.155 30.0746 115.011C29.7592 114.867 29.3867 115.005 29.2426 115.321C29.0985 115.636 29.2375 116.009 29.5529 116.153C29.8683 116.297 30.2408 116.158 30.3848 115.843Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.357 116.747C32.5011 116.431 32.3622 116.059 32.0467 115.915C31.7313 115.771 31.3588 115.91 31.2148 116.225C31.0707 116.541 31.2096 116.913 31.525 117.057C31.8405 117.201 32.213 117.062 32.357 116.747Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.333 117.649C34.477 117.334 34.3381 116.961 34.0227 116.817C33.7073 116.673 33.3348 116.812 33.1907 117.128C33.0467 117.443 33.1856 117.816 33.501 117.96C33.8164 118.104 34.1889 117.965 34.333 117.649Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.3241 118.555C36.4682 118.24 36.3293 117.867 36.0138 117.723C35.6984 117.579 35.3259 117.718 35.1819 118.033C35.0378 118.349 35.1767 118.721 35.4921 118.865C35.8076 119.01 36.1801 118.871 36.3241 118.555Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.3005 119.458C38.4446 119.142 38.3056 118.77 37.9902 118.626C37.6748 118.482 37.3023 118.621 37.1583 118.936C37.0142 119.252 37.1531 119.624 37.4685 119.768C37.7839 119.912 38.1564 119.773 38.3005 119.458Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.279 120.362C40.4231 120.046 40.2842 119.674 39.9687 119.53C39.6533 119.385 39.2808 119.524 39.1368 119.84C38.9927 120.155 39.1316 120.528 39.447 120.672C39.7625 120.816 40.1349 120.677 40.279 120.362Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.2551 121.264C42.3992 120.949 42.2602 120.576 41.9448 120.432C41.6294 120.288 41.2569 120.427 41.1128 120.742C40.9688 121.058 41.1077 121.43 41.4231 121.574C41.7385 121.718 42.111 121.579 42.2551 121.264Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.2448 122.173C44.3889 121.857 44.25 121.485 43.9346 121.341C43.6191 121.197 43.2466 121.336 43.1026 121.651C42.9585 121.967 43.0974 122.339 43.4129 122.483C43.7283 122.627 44.1008 122.488 44.2448 122.173Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.2212 123.076C46.3653 122.76 46.2264 122.388 45.9109 122.244C45.5955 122.099 45.223 122.238 45.079 122.554C44.9349 122.869 45.0738 123.242 45.3892 123.386C45.7047 123.53 46.0771 123.391 46.2212 123.076Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.2017 123.978C48.3457 123.663 48.2068 123.29 47.8914 123.146C47.576 123.002 47.2035 123.141 47.0594 123.456C46.9154 123.772 47.0543 124.144 47.3697 124.288C47.6851 124.432 48.0576 124.294 48.2017 123.978Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.1782 124.881C50.3222 124.565 50.1833 124.193 49.8679 124.049C49.5525 123.905 49.18 124.044 49.0359 124.359C48.8919 124.675 49.0308 125.047 49.3462 125.191C49.6616 125.335 50.0341 125.196 50.1782 124.881Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.1729 125.792C52.317 125.477 52.1781 125.104 51.8627 124.96C51.5472 124.816 51.1748 124.955 51.0307 125.27C50.8866 125.586 51.0255 125.958 51.341 126.102C51.6564 126.246 52.0289 126.107 52.1729 125.792Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.1493 126.695C54.2933 126.379 54.1544 126.007 53.839 125.863C53.5236 125.719 53.1511 125.857 53.007 126.173C52.8629 126.488 53.0019 126.861 53.3173 127.005C53.6327 127.149 54.0052 127.01 54.1493 126.695Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.1211 127.599C56.2652 127.283 56.1263 126.911 55.8108 126.767C55.4954 126.622 55.1229 126.761 54.9789 127.077C54.8348 127.392 54.9737 127.765 55.2891 127.909C55.6046 128.053 55.9771 127.914 56.1211 127.599Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.0976 128.501C58.2416 128.186 58.1027 127.813 57.7873 127.669C57.4719 127.525 57.0994 127.664 56.9553 127.98C56.8112 128.295 56.9502 128.667 57.2656 128.812C57.581 128.956 57.9535 128.817 58.0976 128.501Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.0886 129.407C60.2326 129.092 60.0937 128.719 59.7783 128.575C59.4629 128.431 59.0904 128.57 58.9463 128.886C58.8023 129.201 58.9412 129.574 59.2566 129.718C59.572 129.862 59.9445 129.723 60.0886 129.407Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.065 130.31C62.209 129.995 62.0701 129.622 61.7547 129.478C61.4393 129.334 61.0668 129.473 60.9227 129.788C60.7787 130.104 60.9176 130.476 61.233 130.62C61.5484 130.764 61.9209 130.625 62.065 130.31Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.0431 131.213C64.1871 130.898 64.0482 130.525 63.7328 130.381C63.4174 130.237 63.0449 130.376 62.9008 130.692C62.7567 131.007 62.8957 131.38 63.2111 131.524C63.5265 131.668 63.899 131.529 64.0431 131.213Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.0196 132.116C66.1636 131.801 66.0247 131.428 65.7093 131.284C65.3939 131.14 65.0214 131.279 64.8773 131.594C64.7332 131.91 64.8722 132.282 65.1876 132.426C65.503 132.57 65.8755 132.431 66.0196 132.116Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.0093 133.025C68.1534 132.709 68.0144 132.337 67.699 132.193C67.3836 132.049 67.0111 132.188 66.8671 132.503C66.723 132.819 66.8619 133.191 67.1773 133.335C67.4927 133.479 67.8652 133.34 68.0093 133.025Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.9857 133.928C70.1297 133.612 69.9908 133.24 69.6754 133.096C69.36 132.952 68.9875 133.09 68.8434 133.406C68.6994 133.721 68.8383 134.094 69.1537 134.238C69.4691 134.382 69.8416 134.243 69.9857 133.928Z"
            fill="#B8B7B7"
          />
          <path
            d="M24.2164 110.991C24.3605 110.675 24.2215 110.303 23.9061 110.159C23.5907 110.015 23.2182 110.154 23.0741 110.469C22.9301 110.784 23.069 111.157 23.3844 111.301C23.6998 111.445 24.0723 111.306 24.2164 110.991Z"
            fill="#B8B7B7"
          />
          <path
            d="M26.1928 111.893C26.3368 111.578 26.1979 111.206 25.8825 111.061C25.5671 110.917 25.1946 111.056 25.0505 111.372C24.9065 111.687 25.0454 112.06 25.3608 112.204C25.6762 112.348 26.0487 112.209 26.1928 111.893Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.1876 112.805C28.3316 112.489 28.1927 112.117 27.8773 111.973C27.5619 111.828 27.1894 111.967 27.0453 112.283C26.9013 112.598 27.0402 112.971 27.3556 113.115C27.671 113.259 28.0435 113.12 28.1876 112.805Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.164 113.707C30.308 113.392 30.1691 113.019 29.8537 112.875C29.5383 112.731 29.1658 112.87 29.0217 113.186C28.8777 113.501 29.0166 113.873 29.332 114.018C29.6474 114.162 30.0199 114.023 30.164 113.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.1356 114.612C32.2796 114.296 32.1407 113.924 31.8253 113.78C31.5099 113.636 31.1374 113.775 30.9933 114.09C30.8493 114.405 30.9882 114.778 31.3036 114.922C31.619 115.066 31.9915 114.927 32.1356 114.612Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.112 115.514C34.256 115.199 34.1171 114.827 33.8017 114.682C33.4863 114.538 33.1138 114.677 32.9697 114.993C32.8256 115.308 32.9646 115.681 33.28 115.825C33.5954 115.969 33.9679 115.83 34.112 115.514Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.1029 116.42C36.2469 116.104 36.108 115.732 35.7926 115.588C35.4772 115.444 35.1047 115.583 34.9606 115.898C34.8166 116.213 34.9555 116.586 35.2709 116.73C35.5863 116.874 35.9588 116.735 36.1029 116.42Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.0792 117.322C38.2233 117.007 38.0844 116.634 37.769 116.49C37.4535 116.346 37.0811 116.485 36.937 116.801C36.7929 117.116 36.9318 117.489 37.2473 117.633C37.5627 117.777 37.9352 117.638 38.0792 117.322Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.0578 118.226C40.2018 117.911 40.0629 117.538 39.7475 117.394C39.4321 117.25 39.0596 117.389 38.9155 117.704C38.7714 118.02 38.9104 118.392 39.2258 118.536C39.5412 118.68 39.9137 118.541 40.0578 118.226Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.0342 119.129C42.1783 118.813 42.0394 118.441 41.7239 118.297C41.4085 118.153 41.036 118.292 40.892 118.607C40.7479 118.922 40.8868 119.295 41.2022 119.439C41.5177 119.583 41.8901 119.444 42.0342 119.129Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.0236 120.037C44.1676 119.722 44.0287 119.349 43.7133 119.205C43.3979 119.061 43.0254 119.2 42.8813 119.516C42.7373 119.831 42.8762 120.204 43.1916 120.348C43.507 120.492 43.8795 120.353 44.0236 120.037Z"
            fill="#B8B7B7"
          />
          <path
            d="M46 120.94C46.144 120.625 46.0051 120.252 45.6897 120.108C45.3743 119.964 45.0018 120.103 44.8577 120.418C44.7136 120.734 44.8526 121.106 45.168 121.25C45.4834 121.394 45.8559 121.255 46 120.94Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.9807 121.843C48.1247 121.528 47.9858 121.155 47.6704 121.011C47.355 120.867 46.9825 121.006 46.8384 121.322C46.6944 121.637 46.8333 122.009 47.1487 122.153C47.4641 122.298 47.8366 122.159 47.9807 121.843Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.9571 122.746C50.1012 122.431 49.9623 122.058 49.6468 121.914C49.3314 121.77 48.9589 121.909 48.8149 122.224C48.6708 122.54 48.8097 122.912 49.1251 123.056C49.4406 123.2 49.8131 123.061 49.9571 122.746Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.9519 123.657C52.0959 123.342 51.957 122.969 51.6416 122.825C51.3262 122.681 50.9537 122.82 50.8096 123.135C50.6656 123.451 50.8045 123.823 51.1199 123.967C51.4353 124.111 51.8078 123.972 51.9519 123.657Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.9279 124.559C54.0719 124.244 53.933 123.872 53.6176 123.727C53.3022 123.583 52.9297 123.722 52.7856 124.038C52.6416 124.353 52.7805 124.726 53.0959 124.87C53.4113 125.014 53.7838 124.875 53.9279 124.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.9 125.464C56.0441 125.148 55.9052 124.776 55.5898 124.632C55.2743 124.488 54.9019 124.627 54.7578 124.942C54.6137 125.257 54.7526 125.63 55.0681 125.774C55.3835 125.918 55.756 125.779 55.9 125.464Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.8765 126.366C58.0205 126.051 57.8816 125.678 57.5662 125.534C57.2508 125.39 56.8783 125.529 56.7342 125.845C56.5902 126.16 56.7291 126.533 57.0445 126.677C57.3599 126.821 57.7324 126.682 57.8765 126.366Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.8671 127.272C60.0112 126.957 59.8722 126.584 59.5568 126.44C59.2414 126.296 58.8689 126.435 58.7248 126.75C58.5808 127.066 58.7197 127.438 59.0351 127.582C59.3505 127.727 59.723 127.588 59.8671 127.272Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.8435 128.175C61.9876 127.859 61.8487 127.487 61.5333 127.343C61.2178 127.199 60.8453 127.338 60.7013 127.653C60.5572 127.969 60.6961 128.341 61.0116 128.485C61.327 128.629 61.6995 128.49 61.8435 128.175Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.822 129.078C63.966 128.763 63.8271 128.391 63.5117 128.246C63.1963 128.102 62.8238 128.241 62.6797 128.557C62.5357 128.872 62.6746 129.245 62.99 129.389C63.3054 129.533 63.6779 129.394 63.822 129.078Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.7984 129.981C65.9425 129.666 65.8036 129.293 65.4881 129.149C65.1727 129.005 64.8002 129.144 64.6562 129.459C64.5121 129.775 64.651 130.147 64.9664 130.291C65.2819 130.435 65.6544 130.297 65.7984 129.981Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.7878 130.89C67.9319 130.574 67.793 130.202 67.4775 130.058C67.1621 129.914 66.7896 130.053 66.6456 130.368C66.5015 130.683 66.6404 131.056 66.9558 131.2C67.2713 131.344 67.6437 131.205 67.7878 130.89Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.7642 131.792C69.9083 131.477 69.7694 131.105 69.454 130.961C69.1385 130.816 68.7661 130.955 68.622 131.271C68.4779 131.586 68.6168 131.959 68.9323 132.103C69.2477 132.247 69.6202 132.108 69.7642 131.792Z"
            fill="#B8B7B7"
          />
          <path
            d="M22.4572 112.232C22.6013 111.917 22.4624 111.544 22.147 111.4C21.8315 111.256 21.4591 111.395 21.315 111.711C21.1709 112.026 21.3098 112.399 21.6253 112.543C21.9407 112.687 22.3132 112.548 22.4572 112.232Z"
            fill="#B8B7B7"
          />
          <path
            d="M24.0144 108.818C24.1585 108.503 24.0196 108.131 23.7042 107.987C23.3887 107.842 23.0162 107.981 22.8722 108.297C22.7281 108.612 22.867 108.985 23.1825 109.129C23.4979 109.273 23.8704 109.134 24.0144 108.818Z"
            fill="#B8B7B7"
          />
          <path
            d="M25.9904 109.721C26.1345 109.406 25.9956 109.033 25.6802 108.889C25.3647 108.745 24.9923 108.884 24.8482 109.199C24.7041 109.515 24.843 109.887 25.1585 110.031C25.4739 110.175 25.8464 110.036 25.9904 109.721Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.9852 110.632C28.1293 110.317 27.9903 109.944 27.6749 109.8C27.3595 109.656 26.987 109.795 26.8429 110.11C26.6989 110.426 26.8378 110.798 27.1532 110.942C27.4686 111.086 27.8411 110.947 27.9852 110.632Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.9616 111.535C30.1056 111.219 29.9667 110.847 29.6513 110.703C29.3359 110.559 28.9634 110.698 28.8193 111.013C28.6753 111.328 28.8142 111.701 29.1296 111.845C29.445 111.989 29.8175 111.85 29.9616 111.535Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.9338 112.439C32.0779 112.124 31.9389 111.751 31.6235 111.607C31.3081 111.463 30.9356 111.602 30.7916 111.918C30.6475 112.233 30.7864 112.605 31.1018 112.749C31.4172 112.894 31.7897 112.755 31.9338 112.439Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.9098 113.342C34.0538 113.026 33.9149 112.654 33.5995 112.51C33.2841 112.366 32.9116 112.505 32.7675 112.82C32.6234 113.135 32.7624 113.508 33.0778 113.652C33.3932 113.796 33.7657 113.657 33.9098 113.342Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.9009 114.247C36.045 113.932 35.906 113.559 35.5906 113.415C35.2752 113.271 34.9027 113.41 34.7587 113.726C34.6146 114.041 34.7535 114.414 35.0689 114.558C35.3843 114.702 35.7568 114.563 35.9009 114.247Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.8773 115.15C38.0213 114.835 37.8824 114.462 37.567 114.318C37.2516 114.174 36.8791 114.313 36.735 114.628C36.591 114.944 36.7299 115.316 37.0453 115.46C37.3607 115.604 37.7332 115.466 37.8773 115.15Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.8558 116.054C39.9999 115.738 39.8609 115.366 39.5455 115.222C39.2301 115.078 38.8576 115.217 38.7135 115.532C38.5695 115.847 38.7084 116.22 39.0238 116.364C39.3392 116.508 39.7117 116.369 39.8558 116.054Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.8319 116.956C41.9759 116.641 41.837 116.268 41.5216 116.124C41.2062 115.98 40.8337 116.119 40.6896 116.435C40.5456 116.75 40.6845 117.122 40.9999 117.267C41.3153 117.411 41.6878 117.272 41.8319 116.956Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.8216 117.865C43.9656 117.55 43.8267 117.177 43.5113 117.033C43.1959 116.889 42.8234 117.028 42.6793 117.343C42.5352 117.659 42.6742 118.031 42.9896 118.175C43.305 118.319 43.6775 118.18 43.8216 117.865Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.798 118.768C45.9421 118.452 45.8031 118.08 45.4877 117.936C45.1723 117.792 44.7998 117.931 44.6557 118.246C44.5117 118.561 44.6506 118.934 44.966 119.078C45.2814 119.222 45.6539 119.083 45.798 118.768Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.7785 119.67C47.9225 119.355 47.7836 118.983 47.4682 118.838C47.1528 118.694 46.7803 118.833 46.6362 119.149C46.4922 119.464 46.6311 119.837 46.9465 119.981C47.2619 120.125 47.6344 119.986 47.7785 119.67Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.7548 120.573C49.8989 120.258 49.76 119.885 49.4446 119.741C49.1291 119.597 48.7567 119.736 48.6126 120.051C48.4685 120.367 48.6074 120.739 48.9229 120.883C49.2383 121.027 49.6108 120.888 49.7548 120.573Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.7497 121.484C51.8937 121.169 51.7548 120.796 51.4394 120.652C51.124 120.508 50.7515 120.647 50.6074 120.963C50.4633 121.278 50.6023 121.65 50.9177 121.794C51.2331 121.939 51.6056 121.8 51.7497 121.484Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.7261 122.387C53.8702 122.071 53.7312 121.699 53.4158 121.555C53.1004 121.411 52.7279 121.55 52.5838 121.865C52.4398 122.181 52.5787 122.553 52.8941 122.697C53.2095 122.841 53.582 122.702 53.7261 122.387Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.6976 123.291C55.8417 122.976 55.7027 122.603 55.3873 122.459C55.0719 122.315 54.6994 122.454 54.5553 122.77C54.4113 123.085 54.5502 123.457 54.8656 123.602C55.181 123.746 55.5535 123.607 55.6976 123.291Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.6741 124.194C57.8182 123.879 57.6792 123.506 57.3638 123.362C57.0484 123.218 56.6759 123.357 56.5318 123.672C56.3878 123.988 56.5267 124.36 56.8421 124.504C57.1575 124.648 57.53 124.509 57.6741 124.194Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.6652 125.1C59.8093 124.784 59.6704 124.412 59.355 124.268C59.0395 124.123 58.6671 124.262 58.523 124.578C58.3789 124.893 58.5178 125.266 58.8333 125.41C59.1487 125.554 59.5212 125.415 59.6652 125.1Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.6417 126.002C61.7858 125.687 61.6469 125.314 61.3315 125.17C61.016 125.026 60.6436 125.165 60.4995 125.481C60.3554 125.796 60.4943 126.168 60.8098 126.313C61.1252 126.457 61.4977 126.318 61.6417 126.002Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.6198 126.906C63.7639 126.59 63.625 126.218 63.3096 126.074C62.9941 125.93 62.6216 126.069 62.4776 126.384C62.3335 126.699 62.4724 127.072 62.7879 127.216C63.1033 127.36 63.4758 127.221 63.6198 126.906Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.5963 127.808C65.7403 127.493 65.6014 127.12 65.286 126.976C64.9706 126.832 64.5981 126.971 64.454 127.287C64.31 127.602 64.4489 127.975 64.7643 128.119C65.0797 128.263 65.4522 128.124 65.5963 127.808Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.586 128.717C67.7301 128.402 67.5912 128.029 67.2757 127.885C66.9603 127.741 66.5878 127.88 66.4438 128.195C66.2997 128.511 66.4386 128.883 66.754 129.027C67.0695 129.172 67.442 129.033 67.586 128.717Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.5624 129.62C69.7065 129.304 69.5675 128.932 69.2521 128.788C68.9367 128.644 68.5642 128.783 68.4202 129.098C68.2761 129.414 68.415 129.786 68.7304 129.93C69.0458 130.074 69.4183 129.935 69.5624 129.62Z"
            fill="#B8B7B7"
          />
          <path
            d="M25.7811 107.569C25.9252 107.254 25.7862 106.881 25.4708 106.737C25.1554 106.593 24.7829 106.732 24.6388 107.048C24.4948 107.363 24.6337 107.735 24.9491 107.88C25.2645 108.024 25.637 107.885 25.7811 107.569Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.7575 108.472C27.9015 108.157 27.7626 107.784 27.4472 107.64C27.1318 107.496 26.7593 107.635 26.6152 107.95C26.4712 108.266 26.6101 108.638 26.9255 108.782C27.2409 108.926 27.6134 108.787 27.7575 108.472Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.7523 109.383C29.8963 109.068 29.7574 108.695 29.442 108.551C29.1266 108.407 28.7541 108.546 28.61 108.861C28.466 109.177 28.6049 109.549 28.9203 109.693C29.2357 109.837 29.6082 109.698 29.7523 109.383Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.7287 110.286C31.8727 109.97 31.7338 109.598 31.4184 109.454C31.103 109.31 30.7305 109.449 30.5864 109.764C30.4423 110.079 30.5813 110.452 30.8967 110.596C31.2121 110.74 31.5846 110.601 31.7287 110.286Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.7003 111.19C33.8443 110.875 33.7054 110.502 33.39 110.358C33.0746 110.214 32.7021 110.353 32.558 110.669C32.414 110.984 32.5529 111.357 32.8683 111.501C33.1837 111.645 33.5562 111.506 33.7003 111.19Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.6767 112.093C35.8207 111.778 35.6818 111.405 35.3664 111.261C35.051 111.117 34.6785 111.256 34.5344 111.571C34.3903 111.887 34.5293 112.259 34.8447 112.403C35.1601 112.547 35.5326 112.408 35.6767 112.093Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.668 112.998C37.8121 112.683 37.6731 112.311 37.3577 112.166C37.0423 112.022 36.6698 112.161 36.5257 112.477C36.3817 112.792 36.5206 113.165 36.836 113.309C37.1514 113.453 37.5239 113.314 37.668 112.998Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.6444 113.901C39.7884 113.586 39.6495 113.213 39.3341 113.069C39.0187 112.925 38.6462 113.064 38.5021 113.379C38.3581 113.695 38.497 114.067 38.8124 114.211C39.1278 114.355 39.5003 114.217 39.6444 113.901Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.6225 114.805C41.7665 114.489 41.6276 114.117 41.3122 113.973C40.9968 113.828 40.6243 113.967 40.4802 114.283C40.3361 114.598 40.4751 114.971 40.7905 115.115C41.1059 115.259 41.4784 115.12 41.6225 114.805Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.599 115.707C43.743 115.392 43.6041 115.019 43.2887 114.875C42.9733 114.731 42.6008 114.87 42.4567 115.186C42.3126 115.501 42.4516 115.873 42.767 116.018C43.0824 116.162 43.4549 116.023 43.599 115.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.5886 116.616C45.7327 116.301 45.5938 115.928 45.2784 115.784C44.9629 115.64 44.5905 115.779 44.4464 116.094C44.3023 116.41 44.4412 116.782 44.7567 116.926C45.0721 117.07 45.4446 116.931 45.5886 116.616Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.5651 117.519C47.7092 117.203 47.5703 116.831 47.2549 116.687C46.9394 116.543 46.567 116.682 46.4229 116.997C46.2788 117.312 46.4177 117.685 46.7332 117.829C47.0486 117.973 47.4211 117.834 47.5651 117.519Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.5454 118.422C49.6894 118.106 49.5505 117.734 49.2351 117.59C48.9197 117.446 48.5472 117.585 48.4031 117.9C48.2591 118.215 48.398 118.588 48.7134 118.732C49.0288 118.876 49.4013 118.737 49.5454 118.422Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.5218 119.324C51.6659 119.009 51.527 118.636 51.2115 118.492C50.8961 118.348 50.5236 118.487 50.3796 118.803C50.2355 119.118 50.3744 119.491 50.6898 119.635C51.0053 119.779 51.3777 119.64 51.5218 119.324Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.5166 120.236C53.6607 119.92 53.5218 119.548 53.2063 119.404C52.8909 119.26 52.5184 119.398 52.3744 119.714C52.2303 120.029 52.3692 120.402 52.6846 120.546C53.0001 120.69 53.3726 120.551 53.5166 120.236Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.493 121.138C55.6371 120.823 55.4982 120.45 55.1827 120.306C54.8673 120.162 54.4948 120.301 54.3508 120.616C54.2067 120.932 54.3456 121.304 54.661 121.448C54.9765 121.592 55.3489 121.454 55.493 121.138Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.4646 122.042C57.6087 121.727 57.4698 121.354 57.1543 121.21C56.8389 121.066 56.4664 121.205 56.3224 121.521C56.1783 121.836 56.3172 122.209 56.6326 122.353C56.9481 122.497 57.3206 122.358 57.4646 122.042Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.4411 122.945C59.5852 122.63 59.4463 122.257 59.1308 122.113C58.8154 121.969 58.4429 122.108 58.2989 122.423C58.1548 122.739 58.2937 123.111 58.6091 123.255C58.9246 123.399 59.2971 123.261 59.4411 122.945Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.4319 123.851C61.576 123.535 61.4371 123.163 61.1216 123.019C60.8062 122.875 60.4337 123.014 60.2897 123.329C60.1456 123.644 60.2845 124.017 60.5999 124.161C60.9154 124.305 61.2878 124.166 61.4319 123.851Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.4083 124.753C63.5523 124.438 63.4134 124.065 63.098 123.921C62.7826 123.777 62.4101 123.916 62.266 124.232C62.122 124.547 62.2609 124.92 62.5763 125.064C62.8917 125.208 63.2642 125.069 63.4083 124.753Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.3868 125.657C65.5309 125.342 65.392 124.969 65.0765 124.825C64.7611 124.681 64.3886 124.82 64.2446 125.135C64.1005 125.451 64.2394 125.823 64.5548 125.967C64.8703 126.111 65.2427 125.972 65.3868 125.657Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.3632 126.56C67.5072 126.244 67.3683 125.872 67.0529 125.728C66.7375 125.584 66.365 125.722 66.2209 126.038C66.0769 126.353 66.2158 126.726 66.5312 126.87C66.8466 127.014 67.2191 126.875 67.3632 126.56Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.3525 127.468C69.4966 127.153 69.3576 126.78 69.0422 126.636C68.7268 126.492 68.3543 126.631 68.2103 126.947C68.0662 127.262 68.2051 127.634 68.5205 127.778C68.8359 127.923 69.2084 127.784 69.3525 127.468Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.3288 128.371C71.4729 128.056 71.334 127.683 71.0185 127.539C70.7031 127.395 70.3306 127.534 70.1866 127.849C70.0425 128.165 70.1814 128.537 70.4968 128.681C70.8123 128.825 71.1848 128.686 71.3288 128.371Z"
            fill="#B8B7B7"
          />
          <path
            d="M25.56 105.434C25.7041 105.119 25.5652 104.746 25.2497 104.602C24.9343 104.458 24.5618 104.597 24.4178 104.913C24.2737 105.228 24.4126 105.601 24.728 105.745C25.0435 105.889 25.416 105.75 25.56 105.434Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.5361 106.337C27.6802 106.022 27.5412 105.649 27.2258 105.505C26.9104 105.361 26.5379 105.5 26.3938 105.815C26.2498 106.131 26.3887 106.503 26.7041 106.647C27.0195 106.791 27.392 106.652 27.5361 106.337Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.5308 107.248C29.6748 106.933 29.5359 106.56 29.2205 106.416C28.9051 106.272 28.5326 106.411 28.3885 106.726C28.2445 107.042 28.3834 107.414 28.6988 107.558C29.0142 107.702 29.3867 107.563 29.5308 107.248Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.5072 108.151C31.6512 107.835 31.5123 107.463 31.1969 107.319C30.8815 107.175 30.509 107.314 30.3649 107.629C30.2209 107.944 30.3598 108.317 30.6752 108.461C30.9906 108.605 31.3631 108.466 31.5072 108.151Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.4795 109.055C33.6235 108.74 33.4846 108.367 33.1692 108.223C32.8538 108.079 32.4813 108.218 32.3372 108.533C32.1931 108.849 32.3321 109.221 32.6475 109.365C32.9629 109.509 33.3354 109.37 33.4795 109.055Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.4554 109.957C35.5995 109.642 35.4606 109.27 35.1451 109.125C34.8297 108.981 34.4572 109.12 34.3132 109.436C34.1691 109.751 34.308 110.124 34.6234 110.268C34.9389 110.412 35.3113 110.273 35.4554 109.957Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.4465 110.863C37.5906 110.548 37.4516 110.175 37.1362 110.031C36.8208 109.887 36.4483 110.026 36.3042 110.342C36.1602 110.657 36.2991 111.03 36.6145 111.174C36.9299 111.318 37.3024 111.179 37.4465 110.863Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.4229 111.766C39.567 111.451 39.4281 111.078 39.1127 110.934C38.7972 110.79 38.4248 110.929 38.2807 111.244C38.1366 111.56 38.2755 111.932 38.591 112.076C38.9064 112.22 39.2789 112.081 39.4229 111.766Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.4014 112.67C41.5455 112.354 41.4066 111.982 41.0912 111.838C40.7758 111.694 40.4033 111.833 40.2592 112.148C40.1151 112.463 40.2541 112.836 40.5695 112.98C40.8849 113.124 41.2574 112.985 41.4014 112.67Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.3775 113.572C43.5215 113.257 43.3826 112.884 43.0672 112.74C42.7518 112.596 42.3793 112.735 42.2352 113.05C42.0912 113.366 42.2301 113.738 42.5455 113.882C42.8609 114.026 43.2334 113.888 43.3775 113.572Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.3671 114.481C45.5112 114.166 45.3723 113.793 45.0569 113.649C44.7415 113.505 44.369 113.644 44.2249 113.959C44.0808 114.275 44.2198 114.647 44.5352 114.791C44.8506 114.935 45.2231 114.796 45.3671 114.481Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.3436 115.384C47.4877 115.068 47.3487 114.696 47.0333 114.552C46.7179 114.408 46.3454 114.547 46.2013 114.862C46.0573 115.177 46.1962 115.55 46.5116 115.694C46.827 115.838 47.1995 115.699 47.3436 115.384Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.324 116.287C49.4681 115.971 49.3291 115.599 49.0137 115.455C48.6983 115.31 48.3258 115.449 48.1817 115.765C48.0377 116.08 48.1766 116.453 48.492 116.597C48.8074 116.741 49.1799 116.602 49.324 116.287Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.3004 117.189C51.4444 116.874 51.3055 116.501 50.9901 116.357C50.6747 116.213 50.3022 116.352 50.1581 116.668C50.0141 116.983 50.153 117.355 50.4684 117.499C50.7838 117.644 51.1563 117.505 51.3004 117.189Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.2951 118.1C53.4392 117.785 53.3003 117.412 52.9849 117.268C52.6694 117.124 52.2969 117.263 52.1529 117.579C52.0088 117.894 52.1477 118.266 52.4632 118.411C52.7786 118.555 53.1511 118.416 53.2951 118.1Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.2716 119.003C55.4156 118.688 55.2767 118.315 54.9613 118.171C54.6459 118.027 54.2734 118.166 54.1293 118.481C53.9853 118.797 54.1242 119.169 54.4396 119.313C54.755 119.457 55.1275 119.318 55.2716 119.003Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.2432 119.907C57.3873 119.592 57.2484 119.219 56.933 119.075C56.6176 118.931 56.2451 119.07 56.101 119.385C55.9569 119.701 56.0958 120.073 56.4113 120.217C56.7267 120.362 57.0992 120.223 57.2432 119.907Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.2197 120.81C59.3638 120.494 59.2249 120.122 58.9095 119.978C58.5941 119.834 58.2216 119.973 58.0775 120.288C57.9334 120.604 58.0724 120.976 58.3878 121.12C58.7032 121.264 59.0757 121.125 59.2197 120.81Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.2108 121.716C61.3549 121.4 61.216 121.028 60.9006 120.884C60.5851 120.74 60.2127 120.878 60.0686 121.194C59.9245 121.509 60.0634 121.882 60.3789 122.026C60.6943 122.17 61.0668 122.031 61.2108 121.716Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.1873 122.618C63.3314 122.303 63.1925 121.93 62.8771 121.786C62.5616 121.642 62.1892 121.781 62.0451 122.097C61.901 122.412 62.0399 122.785 62.3554 122.929C62.6708 123.073 63.0433 122.934 63.1873 122.618Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.1653 123.522C65.3094 123.206 65.1705 122.834 64.855 122.69C64.5396 122.546 64.1671 122.685 64.0231 123C63.879 123.315 64.0179 123.688 64.3333 123.832C64.6488 123.976 65.0212 123.837 65.1653 123.522Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.1417 124.424C67.2858 124.109 67.1469 123.737 66.8315 123.592C66.516 123.448 66.1436 123.587 65.9995 123.903C65.8554 124.218 65.9943 124.591 66.3098 124.735C66.6252 124.879 66.9977 124.74 67.1417 124.424Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.1316 125.333C69.2756 125.018 69.1367 124.645 68.8213 124.501C68.5059 124.357 68.1334 124.496 67.9893 124.812C67.8452 125.127 67.9842 125.499 68.2996 125.644C68.615 125.788 68.9875 125.649 69.1316 125.333Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.108 126.236C71.2521 125.921 71.1131 125.548 70.7977 125.404C70.4823 125.26 70.1098 125.399 69.9657 125.714C69.8217 126.03 69.9606 126.402 70.276 126.546C70.5914 126.69 70.9639 126.551 71.108 126.236Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.3217 104.187C27.4658 103.872 27.3269 103.499 27.0115 103.355C26.696 103.211 26.3236 103.35 26.1795 103.666C26.0354 103.981 26.1743 104.353 26.4898 104.498C26.8052 104.642 27.1777 104.503 27.3217 104.187Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.2982 105.09C29.4422 104.775 29.3033 104.402 28.9879 104.258C28.6725 104.114 28.3 104.253 28.1559 104.568C28.0119 104.884 28.1508 105.256 28.4662 105.4C28.7816 105.544 29.1541 105.405 29.2982 105.09Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.2929 106.001C31.4369 105.686 31.298 105.313 30.9826 105.169C30.6672 105.025 30.2947 105.164 30.1506 105.479C30.0066 105.795 30.1455 106.167 30.4609 106.311C30.7763 106.455 31.1488 106.317 31.2929 106.001Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.2693 106.904C33.4134 106.588 33.2745 106.216 32.959 106.072C32.6436 105.928 32.2711 106.067 32.1271 106.382C31.983 106.697 32.1219 107.07 32.4373 107.214C32.7528 107.358 33.1252 107.219 33.2693 106.904Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.2411 107.808C35.3852 107.493 35.2463 107.12 34.9308 106.976C34.6154 106.832 34.2429 106.971 34.0989 107.286C33.9548 107.602 34.0937 107.974 34.4091 108.118C34.7246 108.262 35.097 108.123 35.2411 107.808Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.2174 108.711C37.3615 108.395 37.2226 108.023 36.9072 107.879C36.5917 107.735 36.2192 107.873 36.0752 108.189C35.9311 108.504 36.07 108.877 36.3855 109.021C36.7009 109.165 37.0734 109.026 37.2174 108.711Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.2082 109.616C39.3522 109.301 39.2133 108.928 38.8979 108.784C38.5825 108.64 38.21 108.779 38.0659 109.095C37.9218 109.41 38.0608 109.782 38.3762 109.926C38.6916 110.071 39.0641 109.932 39.2082 109.616Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.1847 110.519C41.3287 110.204 41.1898 109.831 40.8744 109.687C40.559 109.543 40.1865 109.682 40.0424 109.997C39.8983 110.313 40.0373 110.685 40.3527 110.829C40.6681 110.973 41.0406 110.834 41.1847 110.519Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.163 111.423C43.3071 111.107 43.1682 110.735 42.8528 110.591C42.5373 110.446 42.1649 110.585 42.0208 110.901C41.8767 111.216 42.0156 111.589 42.3311 111.733C42.6465 111.877 43.019 111.738 43.163 111.423Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.1395 112.325C45.2836 112.01 45.1447 111.637 44.8293 111.493C44.5139 111.349 44.1414 111.488 43.9973 111.804C43.8532 112.119 43.9922 112.491 44.3076 112.636C44.623 112.78 44.9955 112.641 45.1395 112.325Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.1289 113.234C47.2729 112.918 47.134 112.546 46.8186 112.402C46.5032 112.258 46.1307 112.397 45.9866 112.712C45.8426 113.028 45.9815 113.4 46.2969 113.544C46.6123 113.688 46.9848 113.549 47.1289 113.234Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.1053 114.137C49.2494 113.821 49.1105 113.449 48.795 113.305C48.4796 113.161 48.1071 113.299 47.9631 113.615C47.819 113.93 47.9579 114.303 48.2733 114.447C48.5888 114.591 48.9612 114.452 49.1053 114.137Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.0858 115.039C51.2298 114.724 51.0909 114.351 50.7755 114.207C50.4601 114.063 50.0876 114.202 49.9435 114.517C49.7995 114.833 49.9384 115.205 50.2538 115.349C50.5692 115.493 50.9417 115.355 51.0858 115.039Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.0622 115.942C53.2063 115.626 53.0674 115.254 52.7519 115.11C52.4365 114.966 52.064 115.105 51.92 115.42C51.7759 115.736 51.9148 116.108 52.2302 116.252C52.5457 116.396 52.9182 116.257 53.0622 115.942Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.0569 116.853C55.201 116.537 55.0621 116.165 54.7466 116.021C54.4312 115.877 54.0587 116.016 53.9147 116.331C53.7706 116.647 53.9095 117.019 54.2249 117.163C54.5404 117.307 54.9128 117.168 55.0569 116.853Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.0333 117.756C57.1774 117.44 57.0385 117.068 56.7231 116.924C56.4077 116.78 56.0352 116.918 55.8911 117.234C55.747 117.549 55.8859 117.922 56.2014 118.066C56.5168 118.21 56.8893 118.071 57.0333 117.756Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.0052 118.66C59.1493 118.344 59.0104 117.972 58.6949 117.828C58.3795 117.684 58.007 117.822 57.863 118.138C57.7189 118.453 57.8578 118.826 58.1732 118.97C58.4887 119.114 58.8611 118.975 59.0052 118.66Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.9816 119.562C61.1257 119.247 60.9867 118.874 60.6713 118.73C60.3559 118.586 59.9834 118.725 59.8393 119.041C59.6953 119.356 59.8342 119.728 60.1496 119.873C60.465 120.017 60.8375 119.878 60.9816 119.562Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.9727 120.468C63.1167 120.153 62.9778 119.78 62.6624 119.636C62.347 119.492 61.9745 119.631 61.8304 119.947C61.6864 120.262 61.8253 120.634 62.1407 120.779C62.4561 120.923 62.8286 120.784 62.9727 120.468Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.9491 121.371C65.0931 121.056 64.9542 120.683 64.6388 120.539C64.3234 120.395 63.9509 120.534 63.8068 120.849C63.6627 121.165 63.8017 121.537 64.1171 121.681C64.4325 121.825 64.805 121.686 64.9491 121.371Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.9272 122.274C67.0713 121.959 66.9324 121.586 66.6169 121.442C66.3015 121.298 65.929 121.437 65.785 121.753C65.6409 122.068 65.7798 122.441 66.0952 122.585C66.4107 122.729 66.7831 122.59 66.9272 122.274Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.9036 123.177C69.0477 122.862 68.9088 122.489 68.5934 122.345C68.278 122.201 67.9055 122.34 67.7614 122.655C67.6173 122.971 67.7563 123.343 68.0717 123.487C68.3871 123.631 68.7596 123.493 68.9036 123.177Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.8935 124.086C71.0375 123.77 70.8986 123.398 70.5832 123.254C70.2678 123.11 69.8953 123.249 69.7512 123.564C69.6071 123.88 69.7461 124.252 70.0615 124.396C70.3769 124.54 70.7494 124.401 70.8935 124.086Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.8698 124.989C73.0138 124.673 72.8749 124.301 72.5595 124.157C72.2441 124.013 71.8716 124.152 71.7275 124.467C71.5835 124.782 71.7224 125.155 72.0378 125.299C72.3532 125.443 72.7257 125.304 72.8698 124.989Z"
            fill="#B8B7B7"
          />
          <path
            d="M29.0884 102.938C29.2325 102.623 29.0935 102.25 28.7781 102.106C28.4627 101.962 28.0902 102.101 27.9462 102.416C27.8021 102.732 27.941 103.104 28.2564 103.248C28.5718 103.392 28.9443 103.254 29.0884 102.938Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.0648 103.841C31.2089 103.525 31.07 103.153 30.7546 103.009C30.4391 102.865 30.0667 103.004 29.9226 103.319C29.7785 103.634 29.9174 104.007 30.2329 104.151C30.5483 104.295 30.9208 104.156 31.0648 103.841Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.0595 104.752C33.2036 104.436 33.0647 104.064 32.7493 103.92C32.4338 103.776 32.0613 103.915 31.9173 104.23C31.7732 104.546 31.9121 104.918 32.2276 105.062C32.543 105.206 32.9155 105.067 33.0595 104.752Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.036 105.655C35.1801 105.339 35.0412 104.967 34.7258 104.823C34.4103 104.679 34.0378 104.817 33.8938 105.133C33.7497 105.448 33.8886 105.821 34.2041 105.965C34.5195 106.109 34.892 105.97 35.036 105.655Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.0077 106.559C37.1518 106.243 37.0129 105.871 36.6974 105.727C36.382 105.583 36.0095 105.722 35.8655 106.037C35.7214 106.352 35.8603 106.725 36.1757 106.869C36.4912 107.013 36.8636 106.874 37.0077 106.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.9841 107.461C39.1282 107.146 38.9892 106.774 38.6738 106.629C38.3584 106.485 37.9859 106.624 37.8418 106.94C37.6978 107.255 37.8367 107.628 38.1521 107.772C38.4675 107.916 38.84 107.777 38.9841 107.461Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.9752 108.367C41.1193 108.052 40.9804 107.679 40.665 107.535C40.3495 107.391 39.9771 107.53 39.833 107.846C39.6889 108.161 39.8278 108.533 40.1433 108.677C40.4587 108.822 40.8312 108.683 40.9752 108.367Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.9516 109.27C43.0957 108.954 42.9568 108.582 42.6413 108.438C42.3259 108.294 41.9534 108.433 41.8094 108.748C41.6653 109.064 41.8042 109.436 42.1196 109.58C42.4351 109.724 42.8076 109.585 42.9516 109.27Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.9298 110.173C45.0739 109.858 44.935 109.486 44.6196 109.341C44.3041 109.197 43.9316 109.336 43.7876 109.652C43.6435 109.967 43.7824 110.34 44.0979 110.484C44.4133 110.628 44.7858 110.489 44.9298 110.173Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.9062 111.076C47.0503 110.761 46.9114 110.388 46.5959 110.244C46.2805 110.1 45.908 110.239 45.764 110.554C45.6199 110.87 45.7588 111.242 46.0742 111.386C46.3897 111.53 46.7621 111.391 46.9062 111.076Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.896 111.985C49.04 111.669 48.9011 111.297 48.5857 111.153C48.2703 111.009 47.8978 111.148 47.7537 111.463C47.6096 111.779 47.7486 112.151 48.064 112.295C48.3794 112.439 48.7519 112.3 48.896 111.985Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.8724 112.888C51.0165 112.572 50.8775 112.2 50.5621 112.056C50.2467 111.911 49.8742 112.05 49.7301 112.366C49.5861 112.681 49.725 113.054 50.0404 113.198C50.3558 113.342 50.7283 113.203 50.8724 112.888Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.8528 113.79C52.9969 113.475 52.858 113.102 52.5425 112.958C52.2271 112.814 51.8546 112.953 51.7106 113.268C51.5665 113.584 51.7054 113.956 52.0208 114.1C52.3363 114.245 52.7087 114.106 52.8528 113.79Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.8293 114.693C54.9734 114.377 54.8345 114.005 54.519 113.861C54.2036 113.717 53.8311 113.856 53.6871 114.171C53.543 114.487 53.6819 114.859 53.9973 115.003C54.3128 115.147 54.6852 115.008 54.8293 114.693Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.8241 115.604C56.9681 115.289 56.8292 114.916 56.5138 114.772C56.1984 114.628 55.8259 114.767 55.6818 115.082C55.5377 115.398 55.6767 115.77 55.9921 115.914C56.3075 116.058 56.68 115.919 56.8241 115.604Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.8004 116.507C58.9444 116.191 58.8055 115.819 58.4901 115.675C58.1747 115.531 57.8022 115.67 57.6581 115.985C57.5141 116.3 57.653 116.673 57.9684 116.817C58.2838 116.961 58.6563 116.822 58.8004 116.507Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.7721 117.411C60.9162 117.096 60.7773 116.723 60.4618 116.579C60.1464 116.435 59.7739 116.574 59.6299 116.889C59.4858 117.205 59.6247 117.577 59.9401 117.721C60.2556 117.865 60.6281 117.727 60.7721 117.411Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.7485 118.314C62.8926 117.998 62.7536 117.626 62.4382 117.482C62.1228 117.338 61.7503 117.477 61.6062 117.792C61.4622 118.107 61.6011 118.48 61.9165 118.624C62.2319 118.768 62.6044 118.629 62.7485 118.314Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.7397 119.219C64.8838 118.904 64.7449 118.531 64.4294 118.387C64.114 118.243 63.7415 118.382 63.5975 118.698C63.4534 119.013 63.5923 119.386 63.9077 119.53C64.2232 119.674 64.5956 119.535 64.7397 119.219Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.7157 120.122C66.8597 119.806 66.7208 119.434 66.4054 119.29C66.09 119.146 65.7175 119.285 65.5734 119.6C65.4293 119.916 65.5683 120.288 65.8837 120.432C66.1991 120.576 66.5716 120.437 66.7157 120.122Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.6942 121.025C68.8382 120.71 68.6993 120.337 68.3839 120.193C68.0685 120.049 67.696 120.188 67.5519 120.504C67.4079 120.819 67.5468 121.192 67.8622 121.336C68.1776 121.48 68.5501 121.341 68.6942 121.025Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.6707 121.928C70.8147 121.613 70.6758 121.24 70.3604 121.096C70.045 120.952 69.6725 121.091 69.5284 121.406C69.3844 121.722 69.5233 122.094 69.8387 122.238C70.1541 122.383 70.5266 122.244 70.6707 121.928Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.6604 122.837C72.8044 122.522 72.6655 122.149 72.3501 122.005C72.0347 121.861 71.6622 122 71.5181 122.315C71.374 122.631 71.513 123.003 71.8284 123.147C72.1438 123.291 72.5163 123.152 72.6604 122.837Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.6364 123.739C74.7805 123.424 74.6416 123.052 74.3262 122.907C74.0107 122.763 73.6383 122.902 73.4942 123.218C73.3501 123.533 73.489 123.906 73.8045 124.05C74.1199 124.194 74.4924 124.055 74.6364 123.739Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.8674 100.803C29.0115 100.488 28.8725 100.115 28.5571 99.971C28.2417 99.827 27.8692 99.9659 27.7251 100.281C27.5811 100.597 27.72 100.969 28.0354 101.113C28.3508 101.257 28.7233 101.118 28.8674 100.803Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.8438 101.706C30.9879 101.39 30.849 101.018 30.5336 100.874C30.2181 100.73 29.8456 100.869 29.7016 101.184C29.5575 101.499 29.6964 101.872 30.0119 102.016C30.3273 102.16 30.6998 102.021 30.8438 101.706Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.8386 102.617C32.9826 102.301 32.8437 101.929 32.5283 101.785C32.2129 101.641 31.8404 101.78 31.6963 102.095C31.5523 102.41 31.6912 102.783 32.0066 102.927C32.322 103.071 32.6945 102.932 32.8386 102.617Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.815 103.52C34.959 103.204 34.8201 102.832 34.5047 102.688C34.1893 102.544 33.8168 102.682 33.6727 102.998C33.5287 103.313 33.6676 103.686 33.983 103.83C34.2984 103.974 34.6709 103.835 34.815 103.52Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.7868 104.424C36.9308 104.108 36.7919 103.736 36.4765 103.592C36.1611 103.448 35.7886 103.587 35.6445 103.902C35.5005 104.218 35.6394 104.59 35.9548 104.734C36.2702 104.878 36.6427 104.739 36.7868 104.424Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.7631 105.326C38.9072 105.011 38.7683 104.639 38.4529 104.495C38.1374 104.35 37.765 104.489 37.6209 104.805C37.4768 105.12 37.6157 105.493 37.9312 105.637C38.2466 105.781 38.6191 105.642 38.7631 105.326Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.7539 106.232C40.8979 105.917 40.759 105.544 40.4436 105.4C40.1282 105.256 39.7557 105.395 39.6116 105.71C39.4676 106.026 39.6065 106.398 39.9219 106.542C40.2373 106.686 40.6098 106.547 40.7539 106.232Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.7304 107.135C42.8744 106.819 42.7355 106.447 42.4201 106.303C42.1047 106.159 41.7322 106.298 41.5881 106.613C41.4441 106.928 41.583 107.301 41.8984 107.445C42.2138 107.589 42.5863 107.45 42.7304 107.135Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.7088 108.038C44.8528 107.723 44.7139 107.35 44.3985 107.206C44.0831 107.062 43.7106 107.201 43.5665 107.517C43.4225 107.832 43.5614 108.205 43.8768 108.349C44.1922 108.493 44.5647 108.354 44.7088 108.038Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.6853 108.941C46.8293 108.626 46.6904 108.253 46.375 108.109C46.0596 107.965 45.6871 108.104 45.543 108.419C45.399 108.735 45.5379 109.107 45.8533 109.251C46.1687 109.395 46.5412 109.256 46.6853 108.941Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.6745 109.85C48.8186 109.534 48.6797 109.162 48.3642 109.018C48.0488 108.874 47.6763 109.013 47.5323 109.328C47.3882 109.643 47.5271 110.016 47.8425 110.16C48.158 110.304 48.5305 110.165 48.6745 109.85Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.651 110.752C50.795 110.437 50.6561 110.064 50.3407 109.92C50.0253 109.776 49.6528 109.915 49.5087 110.231C49.3647 110.546 49.5036 110.919 49.819 111.063C50.1344 111.207 50.5069 111.068 50.651 110.752Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.6314 111.655C52.7755 111.34 52.6366 110.967 52.3212 110.823C52.0057 110.679 51.6332 110.818 51.4892 111.133C51.3451 111.449 51.484 111.821 51.7995 111.965C52.1149 112.109 52.4874 111.97 52.6314 111.655Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.6079 112.558C54.7519 112.242 54.613 111.87 54.2976 111.726C53.9822 111.582 53.6097 111.721 53.4656 112.036C53.3216 112.351 53.4605 112.724 53.7759 112.868C54.0913 113.012 54.4638 112.873 54.6079 112.558Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.6026 113.469C56.7466 113.153 56.6077 112.781 56.2923 112.637C55.9769 112.493 55.6044 112.632 55.4603 112.947C55.3162 113.262 55.4552 113.635 55.7706 113.779C56.086 113.923 56.4585 113.784 56.6026 113.469Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.579 114.371C58.7231 114.056 58.5842 113.684 58.2687 113.539C57.9533 113.395 57.5808 113.534 57.4368 113.85C57.2927 114.165 57.4316 114.538 57.747 114.682C58.0624 114.826 58.4349 114.687 58.579 114.371Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.5506 115.276C60.6947 114.96 60.5558 114.588 60.2403 114.444C59.9249 114.3 59.5524 114.439 59.4084 114.754C59.2643 115.07 59.4032 115.442 59.7186 115.586C60.0341 115.73 60.4066 115.591 60.5506 115.276Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.527 116.179C62.6711 115.863 62.5321 115.491 62.2167 115.347C61.9013 115.203 61.5288 115.341 61.3848 115.657C61.2407 115.972 61.3796 116.345 61.695 116.489C62.0104 116.633 62.3829 116.494 62.527 116.179Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.5184 117.084C64.6625 116.769 64.5235 116.396 64.2081 116.252C63.8927 116.108 63.5202 116.247 63.3761 116.562C63.2321 116.878 63.371 117.25 63.6864 117.394C64.0018 117.538 64.3743 117.4 64.5184 117.084Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.4947 117.987C66.6388 117.671 66.4999 117.299 66.1844 117.155C65.869 117.011 65.4965 117.15 65.3525 117.465C65.2084 117.781 65.3473 118.153 65.6627 118.297C65.9782 118.441 66.3506 118.302 66.4947 117.987Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.4728 118.89C68.6169 118.575 68.4779 118.202 68.1625 118.058C67.8471 117.914 67.4746 118.053 67.3306 118.369C67.1865 118.684 67.3254 119.056 67.6408 119.201C67.9562 119.345 68.3287 119.206 68.4728 118.89Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.4492 119.793C70.5933 119.478 70.4544 119.105 70.139 118.961C69.8235 118.817 69.4511 118.956 69.307 119.271C69.1629 119.587 69.3018 119.959 69.6173 120.103C69.9327 120.247 70.3052 120.108 70.4492 119.793Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.439 120.702C72.5831 120.386 72.4442 120.014 72.1288 119.87C71.8134 119.726 71.4409 119.865 71.2968 120.18C71.1527 120.495 71.2917 120.868 71.6071 121.012C71.9225 121.156 72.295 121.017 72.439 120.702Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.4155 121.605C74.5596 121.289 74.4206 120.917 74.1052 120.773C73.7898 120.628 73.4173 120.767 73.2732 121.083C73.1292 121.398 73.2681 121.771 73.5835 121.915C73.8989 122.059 74.2714 121.92 74.4155 121.605Z"
            fill="#B8B7B7"
          />
          <path
            d="M27.1082 102.045C27.2522 101.729 27.1133 101.357 26.7979 101.213C26.4825 101.069 26.11 101.208 25.9659 101.523C25.8219 101.838 25.9608 102.211 26.2762 102.355C26.5916 102.499 26.9641 102.36 27.1082 102.045Z"
            fill="#B8B7B7"
          />
          <path
            d="M28.6408 98.6893C28.7848 98.3739 28.6459 98.0014 28.3305 97.8573C28.0151 97.7132 27.6426 97.8522 27.4985 98.1676C27.3545 98.483 27.4934 98.8555 27.8088 98.9996C28.1242 99.1436 28.4967 99.0047 28.6408 98.6893Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.6168 99.5918C30.7608 99.2764 30.6219 98.9039 30.3065 98.7598C29.9911 98.6158 29.6186 98.7547 29.4745 99.0701C29.3305 99.3855 29.4694 99.758 29.7848 99.9021C30.1002 100.046 30.4727 99.9072 30.6168 99.5918Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.6115 100.503C32.7556 100.187 32.6167 99.8149 32.3013 99.6708C31.9858 99.5268 31.6133 99.6657 31.4693 99.9811C31.3252 100.297 31.4641 100.669 31.7796 100.813C32.095 100.957 32.4675 100.818 32.6115 100.503Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.5879 101.406C34.732 101.09 34.5931 100.718 34.2776 100.574C33.9622 100.43 33.5897 100.568 33.4457 100.884C33.3016 101.199 33.4405 101.572 33.7559 101.716C34.0714 101.86 34.4438 101.721 34.5879 101.406Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.5601 102.31C36.7042 101.994 36.5653 101.622 36.2499 101.478C35.9344 101.334 35.562 101.473 35.4179 101.788C35.2738 102.104 35.4127 102.476 35.7282 102.62C36.0436 102.764 36.4161 102.625 36.5601 102.31Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.5361 103.212C38.6802 102.897 38.5412 102.524 38.2258 102.38C37.9104 102.236 37.5379 102.375 37.3938 102.691C37.2498 103.006 37.3887 103.379 37.7041 103.523C38.0195 103.667 38.392 103.528 38.5361 103.212Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.5272 104.118C40.6713 103.803 40.5324 103.43 40.217 103.286C39.9015 103.142 39.5291 103.281 39.385 103.596C39.2409 103.912 39.3798 104.284 39.6953 104.428C40.0107 104.573 40.3832 104.434 40.5272 104.118Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.5037 105.021C42.6477 104.706 42.5088 104.333 42.1934 104.189C41.878 104.045 41.5055 104.184 41.3614 104.499C41.2174 104.815 41.3563 105.187 41.6717 105.331C41.9871 105.475 42.3596 105.336 42.5037 105.021Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.4821 105.925C44.6262 105.609 44.4873 105.237 44.1719 105.093C43.8564 104.949 43.484 105.087 43.3399 105.403C43.1958 105.718 43.3347 106.091 43.6502 106.235C43.9656 106.379 44.3381 106.24 44.4821 105.925Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.4582 106.827C46.6022 106.512 46.4633 106.139 46.1479 105.995C45.8325 105.851 45.46 105.99 45.3159 106.305C45.1718 106.621 45.3108 106.993 45.6262 107.137C45.9416 107.281 46.3141 107.142 46.4582 106.827Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.4478 107.736C48.5919 107.42 48.453 107.048 48.1376 106.904C47.8221 106.76 47.4497 106.899 47.3056 107.214C47.1615 107.53 47.3004 107.902 47.6159 108.046C47.9313 108.19 48.3038 108.051 48.4478 107.736Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.4243 108.639C50.5684 108.323 50.4295 107.951 50.1141 107.807C49.7986 107.663 49.4262 107.801 49.2821 108.117C49.138 108.432 49.2769 108.805 49.5924 108.949C49.9078 109.093 50.2803 108.954 50.4243 108.639Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.4049 109.541C52.5489 109.226 52.41 108.853 52.0946 108.709C51.7792 108.565 51.4067 108.704 51.2626 109.019C51.1186 109.335 51.2575 109.707 51.5729 109.851C51.8883 109.995 52.2608 109.857 52.4049 109.541Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.3812 110.444C54.5253 110.128 54.3864 109.756 54.071 109.612C53.7556 109.468 53.3831 109.607 53.239 109.922C53.0949 110.238 53.2339 110.61 53.5493 110.754C53.8647 110.898 54.2372 110.759 54.3812 110.444Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.376 111.355C56.5201 111.039 56.3811 110.667 56.0657 110.523C55.7503 110.379 55.3778 110.518 55.2338 110.833C55.0897 111.149 55.2286 111.521 55.544 111.665C55.8594 111.809 56.2319 111.67 56.376 111.355Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.3524 112.258C58.4965 111.942 58.3576 111.57 58.0422 111.426C57.7267 111.282 57.3543 111.42 57.2102 111.736C57.0661 112.051 57.205 112.424 57.5205 112.568C57.8359 112.712 58.2084 112.573 58.3524 112.258Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.3239 113.162C60.468 112.847 60.3291 112.474 60.0137 112.33C59.6982 112.186 59.3258 112.325 59.1817 112.64C59.0376 112.956 59.1765 113.328 59.492 113.472C59.8074 113.616 60.1799 113.477 60.3239 113.162Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.3004 114.065C62.4445 113.749 62.3056 113.377 61.9902 113.233C61.6747 113.089 61.3023 113.228 61.1582 113.543C61.0141 113.858 61.153 114.231 61.4685 114.375C61.7839 114.519 62.1564 114.38 62.3004 114.065Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.2916 114.97C64.4357 114.655 64.2968 114.282 63.9814 114.138C63.666 113.994 63.2935 114.133 63.1494 114.449C63.0053 114.764 63.1443 115.136 63.4597 115.281C63.7751 115.425 64.1476 115.286 64.2916 114.97Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.2681 115.873C66.4122 115.558 66.2733 115.185 65.9579 115.041C65.6425 114.897 65.27 115.036 65.1259 115.351C64.9818 115.667 65.1208 116.039 65.4362 116.183C65.7516 116.327 66.1241 116.188 66.2681 115.873Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.2462 116.776C68.3902 116.461 68.2513 116.088 67.9359 115.944C67.6205 115.8 67.248 115.939 67.1039 116.255C66.9599 116.57 67.0988 116.943 67.4142 117.087C67.7296 117.231 68.1021 117.092 68.2462 116.776Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.2226 117.679C70.3667 117.364 70.2278 116.991 69.9123 116.847C69.5969 116.703 69.2244 116.842 69.0804 117.157C68.9363 117.473 69.0752 117.845 69.3906 117.989C69.7061 118.133 70.0786 117.995 70.2226 117.679Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.2124 118.588C72.3565 118.272 72.2176 117.9 71.9022 117.756C71.5867 117.612 71.2142 117.751 71.0702 118.066C70.9261 118.382 71.065 118.754 71.3804 118.898C71.6959 119.042 72.0684 118.903 72.2124 118.588Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.1888 119.491C74.3329 119.175 74.194 118.803 73.8785 118.659C73.5631 118.515 73.1906 118.653 73.0466 118.969C72.9025 119.284 73.0414 119.657 73.3568 119.801C73.6723 119.945 74.0447 119.806 74.1888 119.491Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.4075 97.44C30.5516 97.1245 30.4126 96.752 30.0972 96.608C29.7818 96.4639 29.4093 96.6028 29.2652 96.9183C29.1212 97.2337 29.2601 97.6062 29.5755 97.7502C29.8909 97.8943 30.2634 97.7554 30.4075 97.44Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.3839 98.3427C32.5279 98.0272 32.389 97.6548 32.0736 97.5107C31.7582 97.3666 31.3857 97.5055 31.2416 97.821C31.0976 98.1364 31.2365 98.5089 31.5519 98.6529C31.8673 98.797 32.2398 98.6581 32.3839 98.3427Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.3787 99.2537C34.5227 98.9383 34.3838 98.5658 34.0684 98.4218C33.753 98.2777 33.3805 98.4166 33.2364 98.732C33.0924 99.0475 33.2313 99.4199 33.5467 99.564C33.8621 99.7081 34.2346 99.5692 34.3787 99.2537Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.3551 100.156C36.4991 99.841 36.3602 99.4685 36.0448 99.3245C35.7294 99.1804 35.3569 99.3193 35.2128 99.6347C35.0688 99.9502 35.2077 100.323 35.5231 100.467C35.8385 100.611 36.211 100.472 36.3551 100.156Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.3267 101.061C38.4708 100.745 38.3319 100.373 38.0165 100.229C37.701 100.085 37.3286 100.224 37.1845 100.539C37.0404 100.854 37.1793 101.227 37.4948 101.371C37.8102 101.515 38.1827 101.376 38.3267 101.061Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.3032 101.963C40.4472 101.648 40.3083 101.275 39.9929 101.131C39.6775 100.987 39.305 101.126 39.1609 101.442C39.0169 101.757 39.1558 102.13 39.4712 102.274C39.7866 102.418 40.1591 102.279 40.3032 101.963Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.2944 102.869C42.4385 102.554 42.2995 102.181 41.9841 102.037C41.6687 101.893 41.2962 102.032 41.1521 102.347C41.0081 102.663 41.147 103.035 41.4624 103.179C41.7778 103.323 42.1503 103.185 42.2944 102.869Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.2708 103.772C44.4148 103.456 44.2759 103.084 43.9605 102.94C43.6451 102.796 43.2726 102.935 43.1285 103.25C42.9845 103.565 43.1234 103.938 43.4388 104.082C43.7542 104.226 44.1267 104.087 44.2708 103.772Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.2489 104.675C46.3929 104.36 46.254 103.987 45.9386 103.843C45.6232 103.699 45.2507 103.838 45.1066 104.153C44.9626 104.469 45.1015 104.841 45.4169 104.985C45.7323 105.13 46.1048 104.991 46.2489 104.675Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.2253 105.578C48.3694 105.262 48.2305 104.89 47.915 104.746C47.5996 104.602 47.2271 104.741 47.0831 105.056C46.939 105.372 47.0779 105.744 47.3933 105.888C47.7088 106.032 48.0812 105.893 48.2253 105.578Z"
            fill="#B8B7B7"
          />
          <path
            d="M50.215 106.487C50.3591 106.171 50.2201 105.799 49.9047 105.655C49.5893 105.511 49.2168 105.65 49.0727 105.965C48.9287 106.28 49.0676 106.653 49.383 106.797C49.6984 106.941 50.0709 106.802 50.215 106.487Z"
            fill="#B8B7B7"
          />
          <path
            d="M52.1915 107.389C52.3356 107.074 52.1966 106.701 51.8812 106.557C51.5658 106.413 51.1933 106.552 51.0492 106.868C50.9052 107.183 51.0441 107.556 51.3595 107.7C51.6749 107.844 52.0474 107.705 52.1915 107.389Z"
            fill="#B8B7B7"
          />
          <path
            d="M54.1718 108.292C54.3158 107.977 54.1769 107.605 53.8615 107.46C53.5461 107.316 53.1736 107.455 53.0295 107.771C52.8855 108.086 53.0244 108.459 53.3398 108.603C53.6552 108.747 54.0277 108.608 54.1718 108.292Z"
            fill="#B8B7B7"
          />
          <path
            d="M56.1482 109.195C56.2922 108.88 56.1533 108.507 55.8379 108.363C55.5225 108.219 55.15 108.358 55.0059 108.673C54.8618 108.989 55.0008 109.361 55.3162 109.505C55.6316 109.649 56.0041 109.511 56.1482 109.195Z"
            fill="#B8B7B7"
          />
          <path
            d="M58.143 110.106C58.2871 109.791 58.1482 109.418 57.8328 109.274C57.5173 109.13 57.1448 109.269 57.0008 109.585C56.8567 109.9 56.9956 110.272 57.3111 110.416C57.6265 110.561 57.999 110.422 58.143 110.106Z"
            fill="#B8B7B7"
          />
          <path
            d="M60.1194 111.009C60.2635 110.693 60.1246 110.321 59.8091 110.177C59.4937 110.033 59.1212 110.172 58.9772 110.487C58.8331 110.803 58.972 111.175 59.2874 111.319C59.6029 111.463 59.9753 111.324 60.1194 111.009Z"
            fill="#B8B7B7"
          />
          <path
            d="M62.0911 111.913C62.2351 111.597 62.0962 111.225 61.7808 111.081C61.4654 110.937 61.0929 111.076 60.9488 111.391C60.8048 111.707 60.9437 112.079 61.2591 112.223C61.5745 112.367 61.947 112.228 62.0911 111.913Z"
            fill="#B8B7B7"
          />
          <path
            d="M64.0676 112.816C64.2117 112.5 64.0727 112.128 63.7573 111.984C63.4419 111.84 63.0694 111.979 62.9253 112.294C62.7813 112.609 62.9202 112.982 63.2356 113.126C63.551 113.27 63.9235 113.131 64.0676 112.816Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.0583 113.721C66.2024 113.406 66.0635 113.033 65.748 112.889C65.4326 112.745 65.0601 112.884 64.9161 113.2C64.772 113.515 64.9109 113.888 65.2263 114.032C65.5418 114.176 65.9142 114.037 66.0583 113.721Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.0346 114.624C68.1787 114.309 68.0398 113.936 67.7244 113.792C67.4089 113.648 67.0364 113.787 66.8924 114.102C66.7483 114.418 66.8872 114.79 67.2027 114.934C67.5181 115.078 67.8906 114.939 68.0346 114.624Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.0131 115.528C70.1572 115.212 70.0183 114.84 69.7029 114.696C69.3874 114.552 69.015 114.691 68.8709 115.006C68.7268 115.321 68.8657 115.694 69.1812 115.838C69.4966 115.982 69.8691 115.843 70.0131 115.528Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.9896 116.43C72.1336 116.115 71.9947 115.742 71.6793 115.598C71.3639 115.454 70.9914 115.593 70.8473 115.909C70.7033 116.224 70.8422 116.597 71.1576 116.741C71.473 116.885 71.8455 116.746 71.9896 116.43Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.9788 117.339C74.1229 117.024 73.984 116.651 73.6686 116.507C73.3531 116.363 72.9807 116.502 72.8366 116.817C72.6925 117.133 72.8314 117.505 73.1469 117.649C73.4623 117.793 73.8348 117.654 73.9788 117.339Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.9553 118.242C76.0993 117.926 75.9604 117.554 75.645 117.41C75.3296 117.266 74.9571 117.405 74.813 117.72C74.669 118.035 74.8079 118.408 75.1233 118.552C75.4387 118.696 75.8112 118.557 75.9553 118.242Z"
            fill="#B8B7B7"
          />
          <path
            d="M30.1864 95.3051C30.3305 94.9897 30.1916 94.6172 29.8761 94.4732C29.5607 94.3291 29.1882 94.468 29.0442 94.7834C28.9001 95.0989 29.039 95.4713 29.3544 95.6154C29.6699 95.7595 30.0424 95.6206 30.1864 95.3051Z"
            fill="#B8B7B7"
          />
          <path
            d="M32.1624 96.2077C32.3065 95.8923 32.1676 95.5198 31.8522 95.3757C31.5367 95.2317 31.1643 95.3706 31.0202 95.686C30.8761 96.0014 31.015 96.3739 31.3305 96.518C31.6459 96.6621 32.0184 96.5231 32.1624 96.2077Z"
            fill="#B8B7B7"
          />
          <path
            d="M34.1572 97.1187C34.3013 96.8033 34.1623 96.4308 33.8469 96.2868C33.5315 96.1427 33.159 96.2816 33.0149 96.597C32.8709 96.9125 33.0098 97.2849 33.3252 97.429C33.6406 97.5731 34.0131 97.4342 34.1572 97.1187Z"
            fill="#B8B7B7"
          />
          <path
            d="M36.1336 98.0214C36.2776 97.706 36.1387 97.3335 35.8233 97.1894C35.5079 97.0453 35.1354 97.1843 34.9913 97.4997C34.8473 97.8151 34.9862 98.1876 35.3016 98.3317C35.617 98.4757 35.9895 98.3368 36.1336 98.0214Z"
            fill="#B8B7B7"
          />
          <path
            d="M38.1059 98.9256C38.2499 98.6102 38.111 98.2377 37.7956 98.0936C37.4802 97.9496 37.1077 98.0885 36.9636 98.4039C36.8195 98.7193 36.9585 99.0918 37.2739 99.2359C37.5893 99.38 37.9618 99.241 38.1059 98.9256Z"
            fill="#B8B7B7"
          />
          <path
            d="M40.0819 99.828C40.2259 99.5126 40.087 99.1401 39.7716 98.996C39.4562 98.852 39.0837 98.9909 38.9396 99.3063C38.7956 99.6217 38.9345 99.9942 39.2499 100.138C39.5653 100.282 39.9378 100.143 40.0819 99.828Z"
            fill="#B8B7B7"
          />
          <path
            d="M42.0729 100.734C42.217 100.419 42.078 100.046 41.7626 99.9021C41.4472 99.758 41.0747 99.897 40.9306 100.212C40.7866 100.528 40.9255 100.9 41.2409 101.044C41.5563 101.188 41.9288 101.05 42.0729 100.734Z"
            fill="#B8B7B7"
          />
          <path
            d="M44.0493 101.637C44.1933 101.321 44.0544 100.949 43.739 100.805C43.4236 100.661 43.0511 100.8 42.907 101.115C42.763 101.43 42.9019 101.803 43.2173 101.947C43.5327 102.091 43.9052 101.952 44.0493 101.637Z"
            fill="#B8B7B7"
          />
          <path
            d="M46.0278 102.54C46.1719 102.225 46.0329 101.852 45.7175 101.708C45.4021 101.564 45.0296 101.703 44.8855 102.019C44.7415 102.334 44.8804 102.707 45.1958 102.851C45.5112 102.995 45.8837 102.856 46.0278 102.54Z"
            fill="#B8B7B7"
          />
          <path
            d="M48.0038 103.443C48.1479 103.128 48.009 102.755 47.6935 102.611C47.3781 102.467 47.0056 102.606 46.8616 102.921C46.7175 103.237 46.8564 103.609 47.1718 103.753C47.4873 103.897 47.8597 103.758 48.0038 103.443Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.9935 104.352C50.1376 104.036 49.9986 103.664 49.6832 103.52C49.3678 103.376 48.9953 103.515 48.8512 103.83C48.7072 104.145 48.8461 104.518 49.1615 104.662C49.4769 104.806 49.8494 104.667 49.9935 104.352Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.97 105.254C52.1141 104.939 51.9751 104.566 51.6597 104.422C51.3443 104.278 50.9718 104.417 50.8277 104.733C50.6837 105.048 50.8226 105.421 51.138 105.565C51.4534 105.709 51.8259 105.57 51.97 105.254Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.9506 106.157C54.0946 105.841 53.9557 105.469 53.6403 105.325C53.3249 105.181 52.9524 105.32 52.8083 105.635C52.6643 105.95 52.8032 106.323 53.1186 106.467C53.434 106.611 53.8065 106.472 53.9506 106.157Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.9269 107.06C56.071 106.744 55.9321 106.372 55.6166 106.228C55.3012 106.083 54.9287 106.222 54.7847 106.538C54.6406 106.853 54.7795 107.226 55.0949 107.37C55.4104 107.514 55.7828 107.375 55.9269 107.06Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.9217 107.971C58.0658 107.655 57.9269 107.283 57.6114 107.139C57.296 106.994 56.9235 107.133 56.7795 107.449C56.6354 107.764 56.7743 108.137 57.0897 108.281C57.4052 108.425 57.7776 108.286 57.9217 107.971Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.8982 108.873C60.0422 108.558 59.9033 108.185 59.5879 108.041C59.2725 107.897 58.9 108.036 58.7559 108.352C58.6118 108.667 58.7508 109.04 59.0662 109.184C59.3816 109.328 59.7541 109.189 59.8982 108.873Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.8697 109.778C62.0137 109.462 61.8748 109.09 61.5594 108.946C61.244 108.802 60.8715 108.941 60.7274 109.256C60.5833 109.571 60.7223 109.944 61.0377 110.088C61.3531 110.232 61.7256 110.093 61.8697 109.778Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.8462 110.68C63.9902 110.365 63.8513 109.992 63.5359 109.848C63.2205 109.704 62.848 109.843 62.7039 110.159C62.5598 110.474 62.6988 110.847 63.0142 110.991C63.3296 111.135 63.7021 110.996 63.8462 110.68Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.8374 111.586C65.9815 111.27 65.8426 110.898 65.5272 110.754C65.2117 110.61 64.8392 110.749 64.6952 111.064C64.5511 111.38 64.69 111.752 65.0054 111.896C65.3209 112.04 65.6934 111.901 65.8374 111.586Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.8139 112.489C67.9579 112.173 67.819 111.801 67.5036 111.657C67.1882 111.513 66.8157 111.651 66.6716 111.967C66.5276 112.282 66.6665 112.655 66.9819 112.799C67.2973 112.943 67.6698 112.804 67.8139 112.489Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.7919 113.392C69.936 113.077 69.797 112.704 69.4816 112.56C69.1662 112.416 68.7937 112.555 68.6496 112.87C68.5056 113.186 68.6445 113.558 68.9599 113.702C69.2753 113.846 69.6478 113.707 69.7919 113.392Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.7683 114.295C71.9124 113.979 71.7735 113.607 71.4581 113.463C71.1426 113.319 70.7701 113.458 70.6261 113.773C70.482 114.088 70.6209 114.461 70.9364 114.605C71.2518 114.749 71.6243 114.61 71.7683 114.295Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.7581 115.204C73.9022 114.888 73.7633 114.516 73.4479 114.372C73.1324 114.227 72.76 114.366 72.6159 114.682C72.4718 114.997 72.6107 115.37 72.9262 115.514C73.2416 115.658 73.6141 115.519 73.7581 115.204Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.7345 116.106C75.8786 115.791 75.7397 115.418 75.4242 115.274C75.1088 115.13 74.7363 115.269 74.5923 115.585C74.4482 115.9 74.5871 116.272 74.9025 116.416C75.218 116.561 75.5905 116.422 75.7345 116.106Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.948 94.0581C32.0921 93.7427 31.9532 93.3702 31.6377 93.2261C31.3223 93.0821 30.9498 93.221 30.8058 93.5364C30.6617 93.8518 30.8006 94.2243 31.116 94.3684C31.4315 94.5125 31.804 94.3735 31.948 94.0581Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.9245 94.9608C34.0686 94.6453 33.9297 94.2729 33.6142 94.1288C33.2988 93.9847 32.9263 94.1236 32.7823 94.4391C32.6382 94.7545 32.7771 95.127 33.0925 95.271C33.408 95.4151 33.7805 95.2762 33.9245 94.9608Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.9192 95.8719C36.0633 95.5565 35.9244 95.184 35.6089 95.0399C35.2935 94.8959 34.921 95.0348 34.777 95.3502C34.6329 95.6656 34.7718 96.0381 35.0872 96.1822C35.4027 96.3262 35.7751 96.1873 35.9192 95.8719Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.8956 96.7746C38.0397 96.4591 37.9007 96.0866 37.5853 95.9426C37.2699 95.7985 36.8974 95.9374 36.7533 96.2529C36.6093 96.5683 36.7482 96.9408 37.0636 97.0848C37.379 97.2289 37.7515 97.09 37.8956 96.7746Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.8675 97.6787C40.0115 97.3632 39.8726 96.9908 39.5572 96.8467C39.2418 96.7026 38.8693 96.8415 38.7252 97.157C38.5811 97.4724 38.7201 97.8449 39.0355 97.9889C39.3509 98.133 39.7234 97.9941 39.8675 97.6787Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.8438 98.5813C41.9879 98.2659 41.849 97.8934 41.5336 97.7493C41.2181 97.6053 40.8456 97.7442 40.7016 98.0596C40.5575 98.375 40.6964 98.7475 41.0119 98.8916C41.3273 99.0357 41.6998 98.8967 41.8438 98.5813Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.8345 99.487C43.9786 99.1716 43.8396 98.7991 43.5242 98.655C43.2088 98.511 42.8363 98.6499 42.6922 98.9653C42.5482 99.2807 42.6871 99.6532 43.0025 99.7973C43.3179 99.9414 43.6904 99.8024 43.8345 99.487Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.811 100.39C45.9551 100.074 45.8161 99.7018 45.5007 99.5577C45.1853 99.4136 44.8128 99.5525 44.6687 99.868C44.5247 100.183 44.6636 100.556 44.979 100.7C45.2944 100.844 45.6669 100.705 45.811 100.39Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.7894 101.293C47.9335 100.978 47.7945 100.605 47.4791 100.461C47.1637 100.317 46.7912 100.456 46.6471 100.772C46.5031 101.087 46.642 101.459 46.9574 101.604C47.2728 101.748 47.6453 101.609 47.7894 101.293Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.7659 102.196C49.91 101.881 49.771 101.508 49.4556 101.364C49.1402 101.22 48.7677 101.359 48.6236 101.674C48.4796 101.99 48.6185 102.362 48.9339 102.506C49.2493 102.65 49.6218 102.511 49.7659 102.196Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.7552 103.105C51.8993 102.789 51.7604 102.417 51.4449 102.273C51.1295 102.129 50.757 102.268 50.613 102.583C50.4689 102.898 50.6078 103.271 50.9232 103.415C51.2387 103.559 51.6111 103.42 51.7552 103.105Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.7316 104.007C53.8757 103.692 53.7367 103.319 53.4213 103.175C53.1059 103.031 52.7334 103.17 52.5893 103.486C52.4453 103.801 52.5842 104.174 52.8996 104.318C53.215 104.462 53.5875 104.323 53.7316 104.007Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.7121 104.91C55.8562 104.594 55.7173 104.222 55.4018 104.078C55.0864 103.934 54.7139 104.073 54.5699 104.388C54.4258 104.704 54.5647 105.076 54.8801 105.22C55.1956 105.364 55.5681 105.225 55.7121 104.91Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.6886 105.813C57.8327 105.497 57.6938 105.125 57.3783 104.981C57.0629 104.837 56.6904 104.975 56.5464 105.291C56.4023 105.606 56.5412 105.979 56.8566 106.123C57.1721 106.267 57.5446 106.128 57.6886 105.813Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.6833 106.724C59.8274 106.408 59.6885 106.036 59.373 105.892C59.0576 105.748 58.6851 105.886 58.5411 106.202C58.397 106.517 58.5359 106.89 58.8513 107.034C59.1668 107.178 59.5392 107.039 59.6833 106.724Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.6597 107.626C61.8038 107.311 61.6648 106.938 61.3494 106.794C61.034 106.65 60.6615 106.789 60.5174 107.105C60.3734 107.42 60.5123 107.793 60.8277 107.937C61.1431 108.081 61.5156 107.942 61.6597 107.626Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.6315 108.53C63.7756 108.215 63.6366 107.843 63.3212 107.698C63.0058 107.554 62.6333 107.693 62.4892 108.009C62.3452 108.324 62.4841 108.697 62.7995 108.841C63.1149 108.985 63.4874 108.846 63.6315 108.53Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.6079 109.433C65.752 109.118 65.6131 108.745 65.2977 108.601C64.9822 108.457 64.6097 108.596 64.4657 108.911C64.3216 109.227 64.4605 109.599 64.776 109.743C65.0914 109.887 65.4639 109.748 65.6079 109.433Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.599 110.339C67.7431 110.023 67.6042 109.651 67.2887 109.507C66.9733 109.363 66.6008 109.502 66.4568 109.817C66.3127 110.133 66.4516 110.505 66.767 110.649C67.0825 110.793 67.455 110.654 67.599 110.339Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.5755 111.242C69.7195 110.926 69.5806 110.554 69.2652 110.41C68.9498 110.266 68.5773 110.405 68.4332 110.72C68.2891 111.035 68.4281 111.408 68.7435 111.552C69.0589 111.696 69.4314 111.557 69.5755 111.242Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.5535 112.145C71.6976 111.83 71.5586 111.457 71.2432 111.313C70.9278 111.169 70.5553 111.308 70.4112 111.623C70.2672 111.939 70.4061 112.311 70.7215 112.455C71.0369 112.599 71.4094 112.461 71.5535 112.145Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.53 113.048C73.6741 112.732 73.5351 112.36 73.2197 112.216C72.9043 112.072 72.5318 112.211 72.3877 112.526C72.2437 112.842 72.3826 113.214 72.698 113.358C73.0134 113.502 73.3859 113.363 73.53 113.048Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.5198 113.957C75.6639 113.641 75.5249 113.269 75.2095 113.125C74.8941 112.981 74.5216 113.119 74.3775 113.435C74.2335 113.75 74.3724 114.123 74.6878 114.267C75.0032 114.411 75.3757 114.272 75.5198 113.957Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.4961 114.859C77.6402 114.544 77.5013 114.171 77.1858 114.027C76.8704 113.883 76.4979 114.022 76.3539 114.338C76.2098 114.653 76.3487 115.025 76.6641 115.17C76.9796 115.314 77.3521 115.175 77.4961 114.859Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.7148 92.8088C33.8589 92.4934 33.72 92.1209 33.4045 91.9768C33.0891 91.8328 32.7166 91.9717 32.5726 92.2871C32.4285 92.6025 32.5674 92.975 32.8828 93.1191C33.1983 93.2631 33.5707 93.1242 33.7148 92.8088Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.6912 93.7116C35.8352 93.3961 35.6963 93.0237 35.3809 92.8796C35.0655 92.7355 34.693 92.8744 34.5489 93.1899C34.4049 93.5053 34.5438 93.8778 34.8592 94.0218C35.1746 94.1659 35.5471 94.027 35.6912 93.7116Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.6859 94.6226C37.8299 94.3072 37.691 93.9347 37.3756 93.7906C37.0602 93.6465 36.6877 93.7855 36.5436 94.1009C36.3996 94.4163 36.5385 94.7888 36.8539 94.9328C37.1693 95.0769 37.5418 94.938 37.6859 94.6226Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.6624 95.5253C39.8065 95.2099 39.6676 94.8374 39.3522 94.6933C39.0367 94.5492 38.6643 94.6882 38.5202 95.0036C38.3761 95.319 38.515 95.6915 38.8305 95.8356C39.1459 95.9796 39.5184 95.8407 39.6624 95.5253Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.6342 96.4293C41.7782 96.1139 41.6393 95.7414 41.3239 95.5974C41.0085 95.4533 40.636 95.5922 40.4919 95.9076C40.3479 96.2231 40.4868 96.5955 40.8022 96.7396C41.1176 96.8837 41.4901 96.7448 41.6342 96.4293Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.6105 97.332C43.7546 97.0166 43.6156 96.6441 43.3002 96.5C42.9848 96.3559 42.6123 96.4949 42.4682 96.8103C42.3242 97.1257 42.4631 97.4982 42.7785 97.6423C43.0939 97.7863 43.4664 97.6474 43.6105 97.332Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.6016 98.2379C45.7457 97.9225 45.6068 97.55 45.2914 97.406C44.9759 97.2619 44.6035 97.4008 44.4594 97.7162C44.3153 98.0317 44.4542 98.4041 44.7697 98.5482C45.0851 98.6923 45.4576 98.5534 45.6016 98.2379Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.578 99.1405C47.7221 98.8251 47.5832 98.4526 47.2678 98.3085C46.9523 98.1645 46.5798 98.3034 46.4358 98.6188C46.2917 98.9342 46.4306 99.3067 46.7461 99.4508C47.0615 99.5949 47.434 99.4559 47.578 99.1405Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.5562 100.044C49.7002 99.7287 49.5613 99.3562 49.2459 99.2121C48.9305 99.068 48.558 99.207 48.4139 99.5224C48.2699 99.8378 48.4088 100.21 48.7242 100.354C49.0396 100.498 49.4121 100.36 49.5562 100.044Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.5326 100.947C51.6767 100.631 51.5378 100.259 51.2223 100.115C50.9069 99.9708 50.5344 100.11 50.3904 100.425C50.2463 100.741 50.3852 101.113 50.7006 101.257C51.0161 101.401 51.3886 101.262 51.5326 100.947Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.5224 101.856C53.6664 101.54 53.5275 101.168 53.2121 101.024C52.8967 100.88 52.5242 101.018 52.3801 101.334C52.236 101.649 52.375 102.022 52.6904 102.166C53.0058 102.31 53.3783 102.171 53.5224 101.856Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.4987 102.758C55.6428 102.443 55.5039 102.07 55.1885 101.926C54.873 101.782 54.5006 101.921 54.3565 102.236C54.2124 102.552 54.3513 102.924 54.6668 103.068C54.9822 103.213 55.3547 103.074 55.4987 102.758Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.4793 103.661C57.6233 103.345 57.4844 102.973 57.169 102.829C56.8536 102.685 56.4811 102.824 56.337 103.139C56.193 103.455 56.3319 103.827 56.6473 103.971C56.9627 104.115 57.3352 103.976 57.4793 103.661Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.4558 104.563C59.5998 104.248 59.4609 103.876 59.1455 103.731C58.8301 103.587 58.4576 103.726 58.3135 104.042C58.1695 104.357 58.3084 104.73 58.6238 104.874C58.9392 105.018 59.3117 104.879 59.4558 104.563Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.4505 105.475C61.5946 105.159 61.4557 104.787 61.1402 104.643C60.8248 104.499 60.4523 104.637 60.3083 104.953C60.1642 105.268 60.3031 105.641 60.6185 105.785C60.934 105.929 61.3065 105.79 61.4505 105.475Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.4268 106.377C63.5709 106.062 63.432 105.689 63.1166 105.545C62.8011 105.401 62.4287 105.54 62.2846 105.855C62.1405 106.171 62.2794 106.543 62.5949 106.687C62.9103 106.831 63.2828 106.693 63.4268 106.377Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.3985 107.282C65.5425 106.966 65.4036 106.594 65.0882 106.45C64.7728 106.306 64.4003 106.445 64.2562 106.76C64.1121 107.075 64.2511 107.448 64.5665 107.592C64.8819 107.736 65.2544 107.597 65.3985 107.282Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.3749 108.184C67.519 107.869 67.38 107.496 67.0646 107.352C66.7492 107.208 66.3767 107.347 66.2327 107.663C66.0886 107.978 66.2275 108.351 66.5429 108.495C66.8583 108.639 67.2308 108.5 67.3749 108.184Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.3662 109.09C69.5102 108.774 69.3713 108.402 69.0559 108.258C68.7405 108.114 68.368 108.253 68.2239 108.568C68.0799 108.884 68.2188 109.256 68.5342 109.4C68.8496 109.544 69.2221 109.405 69.3662 109.09Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.3421 109.992C71.4862 109.677 71.3473 109.304 71.0318 109.16C70.7164 109.016 70.3439 109.155 70.1999 109.471C70.0558 109.786 70.1947 110.159 70.5101 110.303C70.8256 110.447 71.1981 110.308 71.3421 109.992Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.3206 110.896C73.4647 110.581 73.3258 110.208 73.0104 110.064C72.6949 109.92 72.3225 110.059 72.1784 110.374C72.0343 110.69 72.1732 111.062 72.4887 111.206C72.8041 111.35 73.1766 111.211 73.3206 110.896Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.2971 111.799C75.4412 111.483 75.3023 111.111 74.9869 110.967C74.6714 110.823 74.299 110.962 74.1549 111.277C74.0108 111.592 74.1497 111.965 74.4652 112.109C74.7806 112.253 75.1531 112.114 75.2971 111.799Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.2868 112.707C77.4309 112.392 77.292 112.02 76.9766 111.875C76.6611 111.731 76.2886 111.87 76.1446 112.186C76.0005 112.501 76.1394 112.874 76.4549 113.018C76.7703 113.162 77.1428 113.023 77.2868 112.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.2628 113.61C79.4068 113.295 79.2679 112.922 78.9525 112.778C78.6371 112.634 78.2646 112.773 78.1205 113.088C77.9765 113.404 78.1154 113.776 78.4308 113.92C78.7462 114.064 79.1187 113.925 79.2628 113.61Z"
            fill="#B8B7B7"
          />
          <path
            d="M33.4937 90.6739C33.6377 90.3585 33.4988 89.986 33.1834 89.8419C32.868 89.6979 32.4955 89.8368 32.3514 90.1522C32.2074 90.4676 32.3463 90.8401 32.6617 90.9842C32.9771 91.1282 33.3496 90.9893 33.4937 90.6739Z"
            fill="#B8B7B7"
          />
          <path
            d="M35.4702 91.5766C35.6143 91.2611 35.4754 90.8886 35.16 90.7446C34.8445 90.6005 34.4721 90.7394 34.328 91.0549C34.1839 91.3703 34.3228 91.7428 34.6383 91.8868C34.9537 92.0309 35.3262 91.892 35.4702 91.5766Z"
            fill="#B8B7B7"
          />
          <path
            d="M37.4649 92.4877C37.6089 92.1723 37.47 91.7998 37.1546 91.6557C36.8392 91.5116 36.4667 91.6506 36.3226 91.966C36.1786 92.2814 36.3175 92.6539 36.6329 92.798C36.9483 92.942 37.3208 92.8031 37.4649 92.4877Z"
            fill="#B8B7B7"
          />
          <path
            d="M39.4413 93.3903C39.5854 93.0749 39.4465 92.7024 39.131 92.5583C38.8156 92.4142 38.4431 92.5532 38.2991 92.8686C38.155 93.184 38.2939 93.5565 38.6093 93.7005C38.9248 93.8446 39.2972 93.7057 39.4413 93.3903Z"
            fill="#B8B7B7"
          />
          <path
            d="M41.4132 94.2943C41.5572 93.9789 41.4183 93.6064 41.1029 93.4624C40.7875 93.3183 40.415 93.4572 40.2709 93.7726C40.1269 94.088 40.2658 94.4605 40.5812 94.6046C40.8966 94.7487 41.2691 94.6097 41.4132 94.2943Z"
            fill="#B8B7B7"
          />
          <path
            d="M43.3895 95.197C43.5336 94.8816 43.3947 94.5091 43.0793 94.365C42.7639 94.2209 42.3914 94.3599 42.2473 94.6753C42.1032 94.9907 42.2422 95.3632 42.5576 95.5072C42.873 95.6513 43.2455 95.5124 43.3895 95.197Z"
            fill="#B8B7B7"
          />
          <path
            d="M45.3801 96.1028C45.5242 95.7874 45.3853 95.4149 45.0699 95.2708C44.7545 95.1268 44.382 95.2657 44.2379 95.5811C44.0938 95.8965 44.2328 96.269 44.5482 96.4131C44.8636 96.5571 45.2361 96.4182 45.3801 96.1028Z"
            fill="#B8B7B7"
          />
          <path
            d="M47.3566 97.0054C47.5007 96.69 47.3618 96.3175 47.0464 96.1735C46.731 96.0294 46.3585 96.1683 46.2144 96.4837C46.0703 96.7992 46.2093 97.1717 46.5247 97.3157C46.8401 97.4598 47.2126 97.3209 47.3566 97.0054Z"
            fill="#B8B7B7"
          />
          <path
            d="M49.335 97.9091C49.4791 97.5936 49.3402 97.2212 49.0248 97.0771C48.7093 96.933 48.3369 97.0719 48.1928 97.3874C48.0487 97.7028 48.1876 98.0753 48.5031 98.2193C48.8185 98.3634 49.191 98.2245 49.335 97.9091Z"
            fill="#B8B7B7"
          />
          <path
            d="M51.3115 98.8118C51.4556 98.4964 51.3167 98.1239 51.0013 97.9799C50.6858 97.8358 50.3134 97.9747 50.1693 98.2901C50.0252 98.6056 50.1641 98.9781 50.4796 99.1221C50.795 99.2662 51.1675 99.1273 51.3115 98.8118Z"
            fill="#B8B7B7"
          />
          <path
            d="M53.3009 99.7205C53.445 99.4051 53.3061 99.0326 52.9907 98.8885C52.6752 98.7444 52.3027 98.8834 52.1587 99.1988C52.0146 99.5142 52.1535 99.8867 52.469 100.031C52.7844 100.175 53.1569 100.036 53.3009 99.7205Z"
            fill="#B8B7B7"
          />
          <path
            d="M55.2773 100.623C55.4214 100.308 55.2825 99.9352 54.967 99.7911C54.6516 99.647 54.2791 99.7859 54.1351 100.101C53.991 100.417 54.1299 100.789 54.4453 100.933C54.7608 101.077 55.1332 100.938 55.2773 100.623Z"
            fill="#B8B7B7"
          />
          <path
            d="M57.2579 101.526C57.402 101.21 57.263 100.838 56.9476 100.694C56.6322 100.549 56.2597 100.688 56.1156 101.004C55.9716 101.319 56.1105 101.692 56.4259 101.836C56.7413 101.98 57.1138 101.841 57.2579 101.526Z"
            fill="#B8B7B7"
          />
          <path
            d="M59.2343 102.428C59.3784 102.113 59.2395 101.74 58.9241 101.596C58.6086 101.452 58.2362 101.591 58.0921 101.907C57.948 102.222 58.0869 102.594 58.4024 102.739C58.7178 102.883 59.0903 102.744 59.2343 102.428Z"
            fill="#B8B7B7"
          />
          <path
            d="M61.229 103.339C61.3731 103.024 61.2342 102.651 60.9188 102.507C60.6033 102.363 60.2308 102.502 60.0868 102.818C59.9427 103.133 60.0816 103.506 60.3971 103.65C60.7125 103.794 61.085 103.655 61.229 103.339Z"
            fill="#B8B7B7"
          />
          <path
            d="M63.2054 104.242C63.3495 103.927 63.2106 103.554 62.8951 103.41C62.5797 103.266 62.2072 103.405 62.0632 103.72C61.9191 104.036 62.058 104.408 62.3734 104.552C62.6889 104.696 63.0613 104.557 63.2054 104.242Z"
            fill="#B8B7B7"
          />
          <path
            d="M65.1772 105.146C65.3213 104.831 65.1824 104.458 64.8669 104.314C64.5515 104.17 64.179 104.309 64.035 104.624C63.8909 104.94 64.0298 105.312 64.3452 105.456C64.6607 105.601 65.0331 105.462 65.1772 105.146Z"
            fill="#B8B7B7"
          />
          <path
            d="M67.1536 106.049C67.2977 105.734 67.1588 105.361 66.8434 105.217C66.528 105.073 66.1555 105.212 66.0114 105.527C65.8673 105.843 66.0063 106.215 66.3217 106.359C66.6371 106.503 67.0096 106.364 67.1536 106.049Z"
            fill="#B8B7B7"
          />
          <path
            d="M69.1449 106.955C69.2889 106.639 69.15 106.267 68.8346 106.123C68.5192 105.979 68.1467 106.118 68.0026 106.433C67.8585 106.748 67.9975 107.121 68.3129 107.265C68.6283 107.409 69.0008 107.27 69.1449 106.955Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.1212 107.857C71.2652 107.542 71.1263 107.169 70.8109 107.025C70.4955 106.881 70.123 107.02 69.9789 107.336C69.8349 107.651 69.9738 108.024 70.2892 108.168C70.6046 108.312 70.9771 108.173 71.1212 107.857Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.0993 108.761C73.2433 108.445 73.1044 108.073 72.789 107.929C72.4736 107.785 72.1011 107.924 71.957 108.239C71.813 108.555 71.9519 108.927 72.2673 109.071C72.5827 109.215 72.9552 109.076 73.0993 108.761Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.0757 109.664C75.2198 109.348 75.0809 108.976 74.7654 108.832C74.45 108.687 74.0775 108.826 73.9335 109.142C73.7894 109.457 73.9283 109.83 74.2437 109.974C74.5592 110.118 74.9316 109.979 75.0757 109.664Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.0655 110.572C77.2096 110.257 77.0707 109.884 76.7552 109.74C76.4398 109.596 76.0673 109.735 75.9233 110.051C75.7792 110.366 75.9181 110.739 76.2335 110.883C76.549 111.027 76.9214 110.888 77.0655 110.572Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.042 111.475C79.186 111.16 79.0471 110.787 78.7317 110.643C78.4163 110.499 78.0438 110.638 77.8997 110.953C77.7556 111.269 77.8946 111.641 78.21 111.785C78.5254 111.929 78.8979 111.79 79.042 111.475Z"
            fill="#B8B7B7"
          />
          <path
            d="M31.7346 91.9154C31.8786 91.6 31.7397 91.2275 31.4243 91.0834C31.1089 90.9394 30.7364 91.0783 30.5923 91.3937C30.4483 91.7091 30.5872 92.0816 30.9026 92.2257C31.218 92.3698 31.5905 92.2308 31.7346 91.9154Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.6574 139.459C68.8015 139.144 68.6626 138.771 68.3472 138.627C68.0317 138.483 67.6592 138.622 67.5152 138.937C67.3711 139.253 67.51 139.625 67.8255 139.769C68.1409 139.913 68.5134 139.774 68.6574 139.459Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.6339 140.362C70.7779 140.046 70.639 139.674 70.3236 139.53C70.0082 139.386 69.6357 139.525 69.4916 139.84C69.3476 140.155 69.4865 140.528 69.8019 140.672C70.1173 140.816 70.4898 140.677 70.6339 140.362Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.6286 141.273C72.7727 140.957 72.6338 140.585 72.3183 140.441C72.0029 140.297 71.6304 140.436 71.4864 140.751C71.3423 141.067 71.4812 141.439 71.7966 141.583C72.1121 141.727 72.4846 141.588 72.6286 141.273Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.6049 142.175C74.749 141.86 74.6101 141.488 74.2947 141.344C73.9792 141.199 73.6068 141.338 73.4627 141.654C73.3186 141.969 73.4575 142.342 73.773 142.486C74.0884 142.63 74.4609 142.491 74.6049 142.175Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.5767 143.08C76.7207 142.764 76.5818 142.392 76.2664 142.248C75.951 142.104 75.5785 142.243 75.4344 142.558C75.2904 142.873 75.4293 143.246 75.7447 143.39C76.0601 143.534 76.4326 143.395 76.5767 143.08Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.5531 143.982C78.6972 143.667 78.5583 143.295 78.2428 143.15C77.9274 143.006 77.5549 143.145 77.4109 143.461C77.2668 143.776 77.4057 144.149 77.7211 144.293C78.0366 144.437 78.4091 144.298 78.5531 143.982Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.5442 144.888C80.6883 144.573 80.5494 144.2 80.2339 144.056C79.9185 143.912 79.546 144.051 79.402 144.366C79.2579 144.682 79.3968 145.054 79.7122 145.198C80.0277 145.342 80.4001 145.204 80.5442 144.888Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.5208 145.791C82.6648 145.475 82.5259 145.103 82.2105 144.959C81.8951 144.815 81.5226 144.954 81.3785 145.269C81.2345 145.585 81.3734 145.957 81.6888 146.101C82.0042 146.245 82.3767 146.106 82.5208 145.791Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.4989 146.694C84.6429 146.379 84.504 146.006 84.1886 145.862C83.8732 145.718 83.5007 145.857 83.3566 146.173C83.2126 146.488 83.3515 146.861 83.6669 147.005C83.9823 147.149 84.3548 147.01 84.4989 146.694Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.4752 147.597C86.6192 147.282 86.4803 146.909 86.1649 146.765C85.8495 146.621 85.477 146.76 85.3329 147.075C85.1889 147.391 85.3278 147.763 85.6432 147.907C85.9586 148.051 86.3311 147.912 86.4752 147.597Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.465 148.506C88.6091 148.19 88.4701 147.818 88.1547 147.674C87.8393 147.53 87.4668 147.669 87.3227 147.984C87.1787 148.3 87.3176 148.672 87.633 148.816C87.9484 148.96 88.3209 148.821 88.465 148.506Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.4414 149.408C90.5855 149.093 90.4466 148.721 90.1312 148.576C89.8157 148.432 89.4432 148.571 89.2992 148.887C89.1551 149.202 89.294 149.575 89.6095 149.719C89.9249 149.863 90.2974 149.724 90.4414 149.408Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.422 150.311C92.566 149.996 92.4271 149.623 92.1117 149.479C91.7963 149.335 91.4238 149.474 91.2797 149.789C91.1356 150.105 91.2746 150.477 91.59 150.621C91.9054 150.766 92.2779 150.627 92.422 150.311Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.3983 151.214C94.5424 150.898 94.4035 150.526 94.0881 150.382C93.7726 150.238 93.4002 150.377 93.2561 150.692C93.112 151.008 93.2509 151.38 93.5664 151.524C93.8818 151.668 94.2543 151.529 94.3983 151.214Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.393 152.125C96.5371 151.81 96.3982 151.437 96.0828 151.293C95.7673 151.149 95.3948 151.288 95.2508 151.603C95.1067 151.919 95.2456 152.291 95.5611 152.435C95.8765 152.579 96.249 152.44 96.393 152.125Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.3695 153.028C98.5135 152.712 98.3746 152.34 98.0592 152.196C97.7438 152.052 97.3713 152.191 97.2272 152.506C97.0832 152.821 97.2221 153.194 97.5375 153.338C97.8529 153.482 98.2254 153.343 98.3695 153.028Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.341 153.932C100.485 153.617 100.346 153.244 100.031 153.1C99.7153 152.956 99.3428 153.095 99.1988 153.411C99.0547 153.726 99.1936 154.098 99.5091 154.242C99.8245 154.387 100.197 154.248 100.341 153.932Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.317 154.835C102.461 154.519 102.323 154.147 102.007 154.003C101.692 153.859 101.319 153.998 101.175 154.313C101.031 154.629 101.17 155.001 101.485 155.145C101.801 155.289 102.173 155.15 102.317 154.835Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.309 155.74C104.453 155.425 104.314 155.052 103.998 154.908C103.683 154.764 103.311 154.903 103.166 155.219C103.022 155.534 103.161 155.906 103.477 156.051C103.792 156.195 104.165 156.056 104.309 155.74Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.285 156.643C106.429 156.327 106.29 155.955 105.974 155.811C105.659 155.667 105.287 155.806 105.143 156.121C104.998 156.436 105.137 156.809 105.453 156.953C105.768 157.097 106.141 156.958 106.285 156.643Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.263 157.546C108.407 157.231 108.269 156.858 107.953 156.714C107.638 156.57 107.265 156.709 107.121 157.025C106.977 157.34 107.116 157.713 107.431 157.857C107.747 158.001 108.119 157.862 108.263 157.546Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.24 158.449C110.384 158.134 110.245 157.761 109.929 157.617C109.614 157.473 109.241 157.612 109.097 157.927C108.953 158.243 109.092 158.615 109.408 158.759C109.723 158.904 110.096 158.765 110.24 158.449Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.23 159.358C112.374 159.043 112.235 158.67 111.919 158.526C111.604 158.382 111.231 158.521 111.087 158.836C110.943 159.152 111.082 159.524 111.398 159.668C111.713 159.812 112.085 159.673 112.23 159.358Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.205 160.26C114.35 159.945 114.211 159.573 113.895 159.428C113.58 159.284 113.207 159.423 113.063 159.739C112.919 160.054 113.058 160.427 113.373 160.571C113.689 160.715 114.061 160.576 114.205 160.26Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.4245 138.21C70.5685 137.895 70.4296 137.522 70.1142 137.378C69.7988 137.234 69.4263 137.373 69.2822 137.688C69.1381 138.004 69.2771 138.376 69.5925 138.52C69.9079 138.664 70.2804 138.526 70.4245 138.21Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.4009 139.113C72.545 138.797 72.406 138.425 72.0906 138.281C71.7752 138.137 71.4027 138.276 71.2587 138.591C71.1146 138.907 71.2535 139.279 71.5689 139.423C71.8843 139.567 72.2568 139.428 72.4009 139.113Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.3956 140.024C74.5397 139.708 74.4007 139.336 74.0853 139.192C73.7699 139.048 73.3974 139.187 73.2533 139.502C73.1093 139.818 73.2482 140.19 73.5636 140.334C73.879 140.478 74.2515 140.339 74.3956 140.024Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.3722 140.927C76.5162 140.611 76.3773 140.239 76.0619 140.095C75.7465 139.951 75.374 140.089 75.2299 140.405C75.0858 140.72 75.2248 141.093 75.5402 141.237C75.8556 141.381 76.2281 141.242 76.3722 140.927Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.3439 141.831C78.488 141.515 78.349 141.143 78.0336 140.999C77.7182 140.855 77.3457 140.993 77.2016 141.309C77.0576 141.624 77.1965 141.997 77.5119 142.141C77.8273 142.285 78.1998 142.146 78.3439 141.831Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.3204 142.733C80.4645 142.418 80.3255 142.045 80.0101 141.901C79.6947 141.757 79.3222 141.896 79.1781 142.212C79.0341 142.527 79.173 142.9 79.4884 143.044C79.8038 143.188 80.1763 143.049 80.3204 142.733Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.3114 143.639C82.4554 143.324 82.3165 142.951 82.0011 142.807C81.6857 142.663 81.3132 142.802 81.1691 143.117C81.0251 143.433 81.164 143.805 81.4794 143.949C81.7948 144.094 82.1673 143.955 82.3114 143.639Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.2874 144.542C84.4315 144.226 84.2926 143.854 83.9772 143.71C83.6617 143.566 83.2893 143.705 83.1452 144.02C83.0011 144.335 83.14 144.708 83.4555 144.852C83.7709 144.996 84.1434 144.857 84.2874 144.542Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.266 145.445C86.41 145.13 86.2711 144.757 85.9557 144.613C85.6403 144.469 85.2678 144.608 85.1237 144.924C84.9796 145.239 85.1186 145.612 85.434 145.756C85.7494 145.9 86.1219 145.761 86.266 145.445Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.2423 146.348C88.3864 146.033 88.2475 145.66 87.9321 145.516C87.6166 145.372 87.2441 145.511 87.1001 145.826C86.956 146.142 87.0949 146.514 87.4104 146.658C87.7258 146.802 88.0983 146.663 88.2423 146.348Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.2321 147.257C90.3761 146.941 90.2372 146.569 89.9218 146.425C89.6064 146.281 89.2339 146.42 89.0898 146.735C88.9458 147.051 89.0847 147.423 89.4001 147.567C89.7155 147.711 90.088 147.572 90.2321 147.257Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.2081 148.159C92.3522 147.844 92.2132 147.471 91.8978 147.327C91.5824 147.183 91.2099 147.322 91.0658 147.638C90.9218 147.953 91.0607 148.326 91.3761 148.47C91.6915 148.614 92.064 148.475 92.2081 148.159Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.1889 149.062C94.3329 148.747 94.194 148.375 93.8786 148.23C93.5632 148.086 93.1907 148.225 93.0466 148.541C92.9026 148.856 93.0415 149.229 93.3569 149.373C93.6723 149.517 94.0448 149.378 94.1889 149.062Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.1653 149.965C96.3094 149.65 96.1705 149.277 95.855 149.133C95.5396 148.989 95.1671 149.128 95.0231 149.444C94.879 149.759 95.0179 150.131 95.3333 150.275C95.6488 150.42 96.0212 150.281 96.1653 149.965Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.16 150.876C98.3041 150.561 98.1651 150.188 97.8497 150.044C97.5343 149.9 97.1618 150.039 97.0177 150.355C96.8737 150.67 97.0126 151.042 97.328 151.187C97.6434 151.331 98.0159 151.192 98.16 150.876Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.137 151.779C100.281 151.464 100.142 151.091 99.8263 150.947C99.5109 150.803 99.1384 150.942 98.9943 151.257C98.8502 151.573 98.9892 151.945 99.3046 152.089C99.62 152.233 99.9925 152.094 100.137 151.779Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.108 152.683C102.252 152.367 102.113 151.995 101.798 151.851C101.483 151.707 101.11 151.846 100.966 152.161C100.822 152.477 100.961 152.849 101.276 152.993C101.592 153.137 101.964 152.998 102.108 152.683Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.085 153.586C104.229 153.27 104.09 152.898 103.774 152.754C103.459 152.61 103.086 152.748 102.942 153.064C102.798 153.379 102.937 153.752 103.253 153.896C103.568 154.04 103.941 153.901 104.085 153.586Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.075 154.491C106.219 154.176 106.08 153.803 105.765 153.659C105.45 153.515 105.077 153.654 104.933 153.97C104.789 154.285 104.928 154.658 105.243 154.802C105.559 154.946 105.931 154.807 106.075 154.491Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.052 155.394C108.196 155.079 108.057 154.706 107.741 154.562C107.426 154.418 107.054 154.557 106.909 154.872C106.765 155.188 106.904 155.56 107.22 155.704C107.535 155.848 107.908 155.71 108.052 155.394Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.03 156.298C110.174 155.982 110.035 155.61 109.72 155.466C109.404 155.322 109.032 155.461 108.888 155.776C108.744 156.091 108.883 156.464 109.198 156.608C109.514 156.752 109.886 156.613 110.03 156.298Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.007 157.2C112.151 156.885 112.012 156.513 111.696 156.368C111.381 156.224 111.008 156.363 110.864 156.679C110.72 156.994 110.859 157.367 111.175 157.511C111.49 157.655 111.863 157.516 112.007 157.2Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.996 158.109C114.14 157.794 114.001 157.421 113.686 157.277C113.37 157.133 112.998 157.272 112.854 157.587C112.71 157.903 112.849 158.275 113.164 158.419C113.479 158.563 113.852 158.424 113.996 158.109Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.972 159.012C116.116 158.696 115.978 158.324 115.662 158.18C115.347 158.036 114.974 158.175 114.83 158.49C114.686 158.805 114.825 159.178 115.14 159.322C115.456 159.466 115.828 159.327 115.972 159.012Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.203 136.075C70.3471 135.759 70.2082 135.387 69.8928 135.243C69.5773 135.099 69.2048 135.238 69.0608 135.553C68.9167 135.869 69.0556 136.241 69.3711 136.385C69.6865 136.529 70.059 136.39 70.203 136.075Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.1795 136.978C72.3235 136.662 72.1846 136.29 71.8692 136.146C71.5538 136.002 71.1813 136.14 71.0372 136.456C70.8932 136.771 71.0321 137.144 71.3475 137.288C71.6629 137.432 72.0354 137.293 72.1795 136.978Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.1743 137.889C74.3183 137.573 74.1794 137.201 73.864 137.057C73.5486 136.913 73.1761 137.052 73.032 137.367C72.888 137.682 73.0269 138.055 73.3423 138.199C73.6577 138.343 74.0302 138.204 74.1743 137.889Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.1507 138.791C76.2947 138.476 76.1558 138.103 75.8404 137.959C75.525 137.815 75.1525 137.954 75.0084 138.27C74.8643 138.585 75.0033 138.958 75.3187 139.102C75.6341 139.246 76.0066 139.107 76.1507 138.791Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.1224 139.696C78.2665 139.38 78.1275 139.008 77.8121 138.864C77.4967 138.719 77.1242 138.858 76.9801 139.174C76.8361 139.489 76.975 139.862 77.2904 140.006C77.6058 140.15 77.9783 140.011 78.1224 139.696Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.0988 140.598C80.2429 140.283 80.104 139.91 79.7886 139.766C79.4731 139.622 79.1007 139.761 78.9566 140.077C78.8125 140.392 78.9514 140.764 79.2669 140.909C79.5823 141.053 79.9548 140.914 80.0988 140.598Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.0899 141.504C82.234 141.188 82.0951 140.816 81.7797 140.672C81.4642 140.528 81.0917 140.667 80.9477 140.982C80.8036 141.298 80.9425 141.67 81.258 141.814C81.5734 141.958 81.9459 141.819 82.0899 141.504Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.0665 142.407C84.2106 142.091 84.0716 141.719 83.7562 141.575C83.4408 141.431 83.0683 141.57 82.9242 141.885C82.7802 142.2 82.9191 142.573 83.2345 142.717C83.5499 142.861 83.9224 142.722 84.0665 142.407Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.0445 143.31C86.1886 142.995 86.0497 142.622 85.7342 142.478C85.4188 142.334 85.0463 142.473 84.9023 142.788C84.7582 143.104 84.8971 143.476 85.2125 143.62C85.528 143.765 85.9005 143.626 86.0445 143.31Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.0208 144.213C88.1649 143.897 88.026 143.525 87.7106 143.381C87.3951 143.237 87.0227 143.376 86.8786 143.691C86.7345 144.007 86.8734 144.379 87.1889 144.523C87.5043 144.667 87.8768 144.528 88.0208 144.213Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.0107 145.122C90.1548 144.806 90.0159 144.434 89.7004 144.29C89.385 144.146 89.0125 144.285 88.8685 144.6C88.7244 144.915 88.8633 145.288 89.1787 145.432C89.4942 145.576 89.8666 145.437 90.0107 145.122Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.987 146.024C92.1311 145.709 91.9922 145.336 91.6767 145.192C91.3613 145.048 90.9888 145.187 90.8448 145.503C90.7007 145.818 90.8396 146.191 91.155 146.335C91.4705 146.479 91.843 146.34 91.987 146.024Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.9676 146.927C94.1116 146.612 93.9727 146.239 93.6573 146.095C93.3419 145.951 92.9694 146.09 92.8253 146.405C92.6812 146.721 92.8202 147.093 93.1356 147.237C93.451 147.381 93.8235 147.242 93.9676 146.927Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.944 147.83C96.0881 147.514 95.9491 147.142 95.6337 146.998C95.3183 146.854 94.9458 146.993 94.8017 147.308C94.6577 147.624 94.7966 147.996 95.112 148.14C95.4274 148.284 95.7999 148.145 95.944 147.83Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.9387 148.741C98.0827 148.425 97.9438 148.053 97.6284 147.909C97.313 147.765 96.9405 147.904 96.7964 148.219C96.6524 148.535 96.7913 148.907 97.1067 149.051C97.4221 149.195 97.7946 149.056 97.9387 148.741Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.9152 149.644C100.059 149.328 99.9203 148.956 99.6049 148.812C99.2895 148.667 98.917 148.806 98.7729 149.122C98.6289 149.437 98.7678 149.81 99.0832 149.954C99.3986 150.098 99.7711 149.959 99.9152 149.644Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.887 150.548C102.031 150.232 101.892 149.86 101.577 149.716C101.261 149.572 100.889 149.711 100.745 150.026C100.6 150.341 100.739 150.714 101.055 150.858C101.37 151.002 101.743 150.863 101.887 150.548Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.863 151.45C104.007 151.135 103.868 150.762 103.553 150.618C103.237 150.474 102.865 150.613 102.721 150.929C102.577 151.244 102.716 151.617 103.031 151.761C103.347 151.905 103.719 151.766 103.863 151.45Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.854 152.356C105.999 152.041 105.86 151.668 105.544 151.524C105.229 151.38 104.856 151.519 104.712 151.834C104.568 152.15 104.707 152.522 105.022 152.666C105.338 152.81 105.71 152.672 105.854 152.356Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.83 153.259C107.975 152.943 107.836 152.571 107.52 152.427C107.205 152.283 106.832 152.421 106.688 152.737C106.544 153.052 106.683 153.425 106.998 153.569C107.314 153.713 107.686 153.574 107.83 153.259Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.809 154.162C109.953 153.847 109.814 153.474 109.499 153.33C109.183 153.186 108.811 153.325 108.667 153.641C108.523 153.956 108.662 154.328 108.977 154.473C109.292 154.617 109.665 154.478 109.809 154.162Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.785 155.065C111.929 154.75 111.79 154.377 111.475 154.233C111.16 154.089 110.787 154.228 110.643 154.543C110.499 154.859 110.638 155.231 110.953 155.375C111.269 155.519 111.641 155.38 111.785 155.065Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.775 155.974C113.919 155.658 113.78 155.286 113.465 155.142C113.149 154.998 112.777 155.137 112.633 155.452C112.489 155.767 112.628 156.14 112.943 156.284C113.259 156.428 113.631 156.289 113.775 155.974Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.751 156.876C115.895 156.561 115.756 156.188 115.441 156.044C115.125 155.9 114.753 156.039 114.609 156.355C114.465 156.67 114.604 157.042 114.919 157.187C115.234 157.331 115.607 157.192 115.751 156.876Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.9652 134.828C72.1093 134.513 71.9704 134.14 71.655 133.996C71.3395 133.852 70.967 133.991 70.823 134.306C70.6789 134.622 70.8178 134.994 71.1333 135.138C71.4487 135.282 71.8212 135.144 71.9652 134.828Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.9415 135.731C74.0856 135.415 73.9467 135.043 73.6313 134.899C73.3159 134.755 72.9434 134.894 72.7993 135.209C72.6552 135.524 72.7942 135.897 73.1096 136.041C73.425 136.185 73.7975 136.046 73.9415 135.731Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.9363 136.642C76.0804 136.326 75.9415 135.954 75.626 135.81C75.3106 135.666 74.9381 135.805 74.7941 136.12C74.65 136.436 74.7889 136.808 75.1043 136.952C75.4197 137.096 75.7922 136.957 75.9363 136.642Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.9127 137.544C78.0568 137.229 77.9179 136.857 77.6025 136.712C77.287 136.568 76.9146 136.707 76.7705 137.023C76.6264 137.338 76.7653 137.711 77.0808 137.855C77.3962 137.999 77.7687 137.86 77.9127 137.544Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.8844 138.449C80.0284 138.133 79.8895 137.761 79.5741 137.617C79.2587 137.473 78.8862 137.612 78.7421 137.927C78.598 138.242 78.737 138.615 79.0524 138.759C79.3678 138.903 79.7403 138.764 79.8844 138.449Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.8609 139.351C82.005 139.036 81.8661 138.663 81.5506 138.519C81.2352 138.375 80.8627 138.514 80.7187 138.83C80.5746 139.145 80.7135 139.518 81.0289 139.662C81.3444 139.806 81.7169 139.667 81.8609 139.351Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.8516 140.257C83.9956 139.942 83.8567 139.569 83.5413 139.425C83.2259 139.281 82.8534 139.42 82.7093 139.735C82.5653 140.051 82.7042 140.423 83.0196 140.567C83.335 140.711 83.7075 140.572 83.8516 140.257Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.828 141.16C85.9721 140.844 85.8332 140.472 85.5178 140.328C85.2023 140.184 84.8298 140.323 84.6858 140.638C84.5417 140.953 84.6806 141.326 84.9961 141.47C85.3115 141.614 85.684 141.475 85.828 141.16Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.8065 142.063C87.9506 141.748 87.8117 141.375 87.4963 141.231C87.1808 141.087 86.8084 141.226 86.6643 141.542C86.5202 141.857 86.6591 142.23 86.9746 142.374C87.29 142.518 87.6625 142.379 87.8065 142.063Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.7829 142.966C89.9269 142.651 89.788 142.278 89.4726 142.134C89.1572 141.99 88.7847 142.129 88.6406 142.444C88.4965 142.76 88.6355 143.132 88.9509 143.276C89.2663 143.42 89.6388 143.281 89.7829 142.966Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.7723 143.875C91.9164 143.559 91.7774 143.187 91.462 143.043C91.1466 142.899 90.7741 143.037 90.6301 143.353C90.486 143.668 90.6249 144.041 90.9403 144.185C91.2557 144.329 91.6282 144.19 91.7723 143.875Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.7488 144.777C93.8929 144.462 93.754 144.089 93.4385 143.945C93.1231 143.801 92.7506 143.94 92.6066 144.256C92.4625 144.571 92.6014 144.944 92.9168 145.088C93.2322 145.232 93.6047 145.093 93.7488 144.777Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.7292 145.68C95.8733 145.364 95.7344 144.992 95.4189 144.848C95.1035 144.704 94.731 144.843 94.587 145.158C94.4429 145.474 94.5818 145.846 94.8972 145.99C95.2127 146.134 95.5851 145.995 95.7292 145.68Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.7056 146.583C97.8497 146.267 97.7108 145.895 97.3954 145.751C97.08 145.606 96.7075 145.745 96.5634 146.061C96.4193 146.376 96.5583 146.749 96.8737 146.893C97.1891 147.037 97.5616 146.898 97.7056 146.583Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.7004 147.494C99.8445 147.178 99.7055 146.806 99.3901 146.662C99.0747 146.518 98.7022 146.656 98.5582 146.972C98.4141 147.287 98.553 147.66 98.8684 147.804C99.1838 147.948 99.5563 147.809 99.7004 147.494Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.677 148.396C101.821 148.081 101.682 147.708 101.367 147.564C101.051 147.42 100.679 147.559 100.535 147.875C100.391 148.19 100.529 148.563 100.845 148.707C101.16 148.851 101.533 148.712 101.677 148.396Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.649 149.3C103.793 148.985 103.654 148.612 103.338 148.468C103.023 148.324 102.65 148.463 102.506 148.779C102.362 149.094 102.501 149.467 102.817 149.611C103.132 149.755 103.505 149.616 103.649 149.3Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.625 150.203C105.769 149.888 105.63 149.515 105.315 149.371C104.999 149.227 104.627 149.366 104.483 149.681C104.339 149.997 104.478 150.369 104.793 150.513C105.108 150.657 105.481 150.519 105.625 150.203Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.616 151.109C107.76 150.794 107.621 150.421 107.306 150.277C106.991 150.133 106.618 150.272 106.474 150.587C106.33 150.903 106.469 151.275 106.784 151.419C107.1 151.563 107.472 151.424 107.616 151.109Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.593 152.012C109.737 151.696 109.598 151.324 109.282 151.18C108.967 151.036 108.594 151.174 108.45 151.49C108.306 151.805 108.445 152.178 108.761 152.322C109.076 152.466 109.448 152.327 109.593 152.012Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.571 152.915C111.715 152.6 111.576 152.227 111.26 152.083C110.945 151.939 110.573 152.078 110.428 152.393C110.284 152.709 110.423 153.081 110.739 153.225C111.054 153.369 111.427 153.231 111.571 152.915Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.547 153.818C113.691 153.502 113.552 153.13 113.237 152.986C112.921 152.842 112.549 152.981 112.405 153.296C112.261 153.611 112.4 153.984 112.715 154.128C113.03 154.272 113.403 154.133 113.547 153.818Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.537 154.727C115.681 154.411 115.542 154.039 115.226 153.895C114.911 153.75 114.539 153.889 114.395 154.205C114.25 154.52 114.389 154.893 114.705 155.037C115.02 155.181 115.393 155.042 115.537 154.727Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.513 155.629C117.657 155.314 117.518 154.941 117.203 154.797C116.888 154.653 116.515 154.792 116.371 155.108C116.227 155.423 116.366 155.795 116.681 155.94C116.997 156.084 117.369 155.945 117.513 155.629Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.7319 133.579C73.876 133.263 73.737 132.891 73.4216 132.747C73.1062 132.603 72.7337 132.742 72.5896 133.057C72.4456 133.372 72.5845 133.745 72.8999 133.889C73.2153 134.033 73.5878 133.894 73.7319 133.579Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.7083 134.481C75.8523 134.166 75.7134 133.793 75.398 133.649C75.0826 133.505 74.7101 133.644 74.566 133.96C74.422 134.275 74.5609 134.648 74.8763 134.792C75.1917 134.936 75.5642 134.797 75.7083 134.481Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.703 135.393C77.847 135.077 77.7081 134.705 77.3927 134.561C77.0773 134.416 76.7048 134.555 76.5607 134.871C76.4167 135.186 76.5556 135.559 76.871 135.703C77.1864 135.847 77.5589 135.708 77.703 135.393Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.6794 136.295C79.8235 135.98 79.6846 135.607 79.3691 135.463C79.0537 135.319 78.6812 135.458 78.5372 135.773C78.3931 136.089 78.532 136.461 78.8474 136.605C79.1629 136.75 79.5353 136.611 79.6794 136.295Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.6511 137.199C81.7952 136.884 81.6563 136.512 81.3409 136.368C81.0254 136.223 80.653 136.362 80.5089 136.678C80.3648 136.993 80.5037 137.366 80.8192 137.51C81.1346 137.654 81.5071 137.515 81.6511 137.199Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.6276 138.102C83.7716 137.787 83.6327 137.414 83.3173 137.27C83.0019 137.126 82.6294 137.265 82.4853 137.58C82.3413 137.896 82.4802 138.268 82.7956 138.412C83.111 138.556 83.4835 138.418 83.6276 138.102Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.6187 139.008C85.7628 138.692 85.6239 138.32 85.3085 138.176C84.993 138.032 84.6206 138.171 84.4765 138.486C84.3324 138.802 84.4713 139.174 84.7868 139.318C85.1022 139.462 85.4747 139.323 85.6187 139.008Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.5951 139.911C87.7392 139.595 87.6003 139.223 87.2848 139.079C86.9694 138.935 86.5969 139.073 86.4529 139.389C86.3088 139.704 86.4477 140.077 86.7631 140.221C87.0786 140.365 87.4511 140.226 87.5951 139.911Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.5732 140.814C89.7173 140.499 89.5784 140.126 89.2629 139.982C88.9475 139.838 88.575 139.977 88.431 140.292C88.2869 140.608 88.4258 140.98 88.7412 141.124C89.0567 141.268 89.4291 141.129 89.5732 140.814Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.5498 141.717C91.6938 141.401 91.5549 141.029 91.2395 140.885C90.9241 140.741 90.5516 140.88 90.4075 141.195C90.2635 141.51 90.4024 141.883 90.7178 142.027C91.0332 142.171 91.4057 142.032 91.5498 141.717Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.5395 142.626C93.6835 142.31 93.5446 141.938 93.2292 141.794C92.9138 141.649 92.5413 141.788 92.3972 142.104C92.2531 142.419 92.3921 142.792 92.7075 142.936C93.0229 143.08 93.3954 142.941 93.5395 142.626Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.5158 143.528C95.6599 143.213 95.521 142.84 95.2056 142.696C94.8901 142.552 94.5176 142.691 94.3736 143.007C94.2295 143.322 94.3684 143.694 94.6839 143.838C94.9993 143.983 95.3718 143.844 95.5158 143.528Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.4965 144.431C97.6405 144.115 97.5016 143.743 97.1862 143.599C96.8708 143.455 96.4983 143.594 96.3542 143.909C96.2102 144.224 96.3491 144.597 96.6645 144.741C96.9799 144.885 97.3524 144.746 97.4965 144.431Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.4728 145.333C99.6169 145.018 99.4779 144.645 99.1625 144.501C98.8471 144.357 98.4746 144.496 98.3306 144.812C98.1865 145.127 98.3254 145.5 98.6408 145.644C98.9562 145.788 99.3287 145.649 99.4728 145.333Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.468 146.245C101.612 145.929 101.473 145.557 101.157 145.413C100.842 145.268 100.469 145.407 100.325 145.723C100.181 146.038 100.32 146.411 100.636 146.555C100.951 146.699 101.324 146.56 101.468 146.245Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.444 147.147C103.588 146.832 103.449 146.459 103.134 146.315C102.818 146.171 102.446 146.31 102.302 146.625C102.158 146.941 102.297 147.313 102.612 147.457C102.927 147.602 103.3 147.463 103.444 147.147Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.416 148.052C105.56 147.736 105.421 147.364 105.105 147.22C104.79 147.076 104.417 147.215 104.273 147.53C104.129 147.845 104.268 148.218 104.584 148.362C104.899 148.506 105.271 148.367 105.416 148.052Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.392 148.954C107.536 148.639 107.397 148.266 107.082 148.122C106.766 147.978 106.394 148.117 106.25 148.433C106.106 148.748 106.245 149.121 106.56 149.265C106.875 149.409 107.248 149.27 107.392 148.954Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.383 149.86C109.527 149.544 109.388 149.172 109.073 149.028C108.757 148.884 108.385 149.023 108.241 149.338C108.097 149.653 108.235 150.026 108.551 150.17C108.866 150.314 109.239 150.175 109.383 149.86Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.359 150.762C111.503 150.447 111.364 150.074 111.049 149.93C110.734 149.786 110.361 149.925 110.217 150.241C110.073 150.556 110.212 150.928 110.527 151.073C110.843 151.217 111.215 151.078 111.359 150.762Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.338 151.666C113.482 151.351 113.343 150.978 113.027 150.834C112.712 150.69 112.339 150.829 112.195 151.144C112.051 151.46 112.19 151.832 112.506 151.976C112.821 152.12 113.194 151.981 113.338 151.666Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.314 152.569C115.458 152.253 115.319 151.881 115.004 151.737C114.688 151.593 114.316 151.732 114.172 152.047C114.028 152.362 114.167 152.735 114.482 152.879C114.798 153.023 115.17 152.884 115.314 152.569Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.303 153.477C117.448 153.162 117.309 152.789 116.993 152.645C116.678 152.501 116.305 152.64 116.161 152.956C116.017 153.271 116.156 153.643 116.472 153.788C116.787 153.932 117.159 153.793 117.303 153.477Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.28 154.38C119.424 154.065 119.285 153.692 118.97 153.548C118.654 153.404 118.282 153.543 118.138 153.858C117.994 154.174 118.133 154.546 118.448 154.69C118.763 154.834 119.136 154.695 119.28 154.38Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.5108 131.444C73.6549 131.128 73.516 130.756 73.2006 130.612C72.8851 130.468 72.5126 130.607 72.3686 130.922C72.2245 131.238 72.3634 131.61 72.6789 131.754C72.9943 131.898 73.3668 131.759 73.5108 131.444Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.4873 132.347C75.6313 132.031 75.4924 131.659 75.177 131.515C74.8616 131.37 74.4891 131.509 74.345 131.825C74.201 132.14 74.3399 132.513 74.6553 132.657C74.9707 132.801 75.3432 132.662 75.4873 132.347Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.482 133.258C77.6261 132.942 77.4872 132.57 77.1717 132.426C76.8563 132.282 76.4838 132.42 76.3398 132.736C76.1957 133.051 76.3346 133.424 76.65 133.568C76.9655 133.712 77.338 133.573 77.482 133.258Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.4585 134.16C79.6025 133.845 79.4636 133.472 79.1482 133.328C78.8328 133.184 78.4603 133.323 78.3162 133.639C78.1721 133.954 78.3111 134.326 78.6265 134.471C78.9419 134.615 79.3144 134.476 79.4585 134.16Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.4302 135.064C81.5743 134.749 81.4353 134.376 81.1199 134.232C80.8045 134.088 80.432 134.227 80.2879 134.543C80.1439 134.858 80.2828 135.23 80.5982 135.374C80.9136 135.519 81.2861 135.38 81.4302 135.064Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.4066 135.967C83.5507 135.652 83.4118 135.279 83.0964 135.135C82.7809 134.991 82.4085 135.13 82.2644 135.445C82.1203 135.761 82.2592 136.133 82.5747 136.277C82.8901 136.421 83.2626 136.282 83.4066 135.967Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.3973 136.873C85.5414 136.557 85.4024 136.185 85.087 136.041C84.7716 135.897 84.3991 136.036 84.2551 136.351C84.111 136.666 84.2499 137.039 84.5653 137.183C84.8807 137.327 85.2532 137.188 85.3973 136.873Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.3737 137.775C87.5178 137.46 87.3789 137.087 87.0635 136.943C86.748 136.799 86.3756 136.938 86.2315 137.254C86.0874 137.569 86.2263 137.942 86.5418 138.086C86.8572 138.23 87.2297 138.091 87.3737 137.775Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.3521 138.679C89.4962 138.364 89.3573 137.991 89.0419 137.847C88.7264 137.703 88.3539 137.842 88.2099 138.157C88.0658 138.473 88.2047 138.845 88.5202 138.989C88.8356 139.133 89.2081 138.994 89.3521 138.679Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.3286 139.582C91.4726 139.266 91.3337 138.894 91.0183 138.75C90.7029 138.606 90.3304 138.745 90.1863 139.06C90.0423 139.375 90.1812 139.748 90.4966 139.892C90.812 140.036 91.1845 139.897 91.3286 139.582Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.3179 140.49C93.462 140.175 93.323 139.802 93.0076 139.658C92.6922 139.514 92.3197 139.653 92.1756 139.969C92.0316 140.284 92.1705 140.657 92.4859 140.801C92.8013 140.945 93.1738 140.806 93.3179 140.49Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.2945 141.393C95.4386 141.078 95.2997 140.705 94.9842 140.561C94.6688 140.417 94.2963 140.556 94.1523 140.871C94.0082 141.187 94.1471 141.559 94.4625 141.703C94.778 141.847 95.1505 141.709 95.2945 141.393Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.275 142.296C97.4191 141.98 97.2802 141.608 96.9648 141.464C96.6493 141.32 96.2769 141.458 96.1328 141.774C95.9887 142.089 96.1276 142.462 96.4431 142.606C96.7585 142.75 97.131 142.611 97.275 142.296Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.2514 143.198C99.3954 142.883 99.2565 142.51 98.9411 142.366C98.6257 142.222 98.2532 142.361 98.1091 142.676C97.9651 142.992 98.104 143.364 98.4194 143.508C98.7348 143.652 99.1073 143.514 99.2514 143.198Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.246 144.109C101.39 143.794 101.251 143.421 100.936 143.277C100.621 143.133 100.248 143.272 100.104 143.588C99.9599 143.903 100.099 144.275 100.414 144.42C100.73 144.564 101.102 144.425 101.246 144.109Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.223 145.012C103.367 144.696 103.228 144.324 102.912 144.18C102.597 144.036 102.224 144.175 102.08 144.49C101.936 144.806 102.075 145.178 102.391 145.322C102.706 145.466 103.078 145.327 103.223 145.012Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.194 145.916C105.338 145.601 105.199 145.228 104.884 145.084C104.569 144.94 104.196 145.079 104.052 145.395C103.908 145.71 104.047 146.082 104.362 146.226C104.678 146.371 105.05 146.232 105.194 145.916Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.171 146.819C107.315 146.504 107.176 146.131 106.86 145.987C106.545 145.843 106.173 145.982 106.028 146.297C105.884 146.613 106.023 146.985 106.339 147.129C106.654 147.273 107.027 147.134 107.171 146.819Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.162 147.725C109.306 147.409 109.167 147.037 108.852 146.893C108.536 146.749 108.164 146.888 108.02 147.203C107.876 147.518 108.015 147.891 108.33 148.035C108.645 148.179 109.018 148.04 109.162 147.725Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.138 148.627C111.282 148.312 111.143 147.939 110.828 147.795C110.513 147.651 110.14 147.79 109.996 148.106C109.852 148.421 109.991 148.794 110.306 148.938C110.622 149.082 110.994 148.943 111.138 148.627Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.116 149.531C113.26 149.215 113.122 148.843 112.806 148.699C112.491 148.555 112.118 148.694 111.974 149.009C111.83 149.324 111.969 149.697 112.284 149.841C112.6 149.985 112.972 149.846 113.116 149.531Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.093 150.433C115.237 150.118 115.098 149.745 114.783 149.601C114.467 149.457 114.095 149.596 113.951 149.912C113.806 150.227 113.945 150.6 114.261 150.744C114.576 150.888 114.949 150.749 115.093 150.433Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.083 151.342C117.227 151.027 117.088 150.654 116.772 150.51C116.457 150.366 116.084 150.505 115.94 150.821C115.796 151.136 115.935 151.508 116.251 151.653C116.566 151.797 116.938 151.658 117.083 151.342Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.059 152.245C119.203 151.93 119.064 151.557 118.749 151.413C118.433 151.269 118.061 151.408 117.917 151.723C117.773 152.039 117.911 152.411 118.227 152.555C118.542 152.699 118.915 152.56 119.059 152.245Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.7512 132.685C71.8953 132.37 71.7564 131.997 71.441 131.853C71.1255 131.709 70.7531 131.848 70.609 132.163C70.4649 132.479 70.6038 132.851 70.9193 132.995C71.2347 133.14 71.6072 133.001 71.7512 132.685Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.3087 129.271C73.4527 128.956 73.3138 128.583 72.9984 128.439C72.683 128.295 72.3105 128.434 72.1664 128.749C72.0224 129.065 72.1613 129.437 72.4767 129.581C72.7921 129.725 73.1646 129.586 73.3087 129.271Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.285 130.174C75.4291 129.858 75.2901 129.486 74.9747 129.342C74.6593 129.198 74.2868 129.337 74.1427 129.652C73.9987 129.967 74.1376 130.34 74.453 130.484C74.7684 130.628 75.1409 130.489 75.285 130.174Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.2799 131.085C77.4239 130.769 77.285 130.397 76.9696 130.253C76.6542 130.109 76.2817 130.248 76.1376 130.563C75.9936 130.879 76.1325 131.251 76.4479 131.395C76.7633 131.539 77.1358 131.4 77.2799 131.085Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.2562 131.988C79.4002 131.672 79.2613 131.3 78.9459 131.156C78.6305 131.011 78.258 131.15 78.1139 131.466C77.9699 131.781 78.1088 132.154 78.4242 132.298C78.7396 132.442 79.1121 132.303 79.2562 131.988Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.2278 132.892C81.3719 132.577 81.233 132.204 80.9175 132.06C80.6021 131.916 80.2296 132.055 80.0856 132.37C79.9415 132.686 80.0804 133.058 80.3958 133.202C80.7113 133.346 81.0837 133.207 81.2278 132.892Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.2041 133.795C83.3482 133.479 83.2093 133.107 82.8938 132.963C82.5784 132.819 82.2059 132.958 82.0619 133.273C81.9178 133.589 82.0567 133.961 82.3721 134.105C82.6876 134.249 83.0601 134.11 83.2041 133.795Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.1955 134.7C85.3395 134.385 85.2006 134.012 84.8852 133.868C84.5698 133.724 84.1973 133.863 84.0532 134.178C83.9091 134.494 84.0481 134.866 84.3635 135.01C84.6789 135.154 85.0514 135.016 85.1955 134.7Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.172 135.603C87.316 135.287 87.1771 134.915 86.8617 134.771C86.5463 134.627 86.1738 134.766 86.0297 135.081C85.8856 135.397 86.0246 135.769 86.34 135.913C86.6554 136.057 87.0279 135.918 87.172 135.603Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.15 136.506C89.294 136.191 89.1551 135.818 88.8397 135.674C88.5243 135.53 88.1518 135.669 88.0077 135.985C87.8637 136.3 88.0026 136.673 88.318 136.817C88.6334 136.961 89.0059 136.822 89.15 136.506Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.1264 137.409C91.2705 137.094 91.1316 136.721 90.8162 136.577C90.5007 136.433 90.1282 136.572 89.9842 136.887C89.8401 137.203 89.979 137.575 90.2945 137.719C90.6099 137.863 90.9824 137.724 91.1264 137.409Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.1162 138.318C93.2603 138.002 93.1214 137.63 92.806 137.486C92.4905 137.342 92.118 137.481 91.974 137.796C91.8299 138.111 91.9688 138.484 92.2843 138.628C92.5997 138.772 92.9722 138.633 93.1162 138.318Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.0926 139.22C95.2366 138.905 95.0977 138.533 94.7823 138.389C94.4669 138.244 94.0944 138.383 93.9503 138.699C93.8062 139.014 93.9452 139.387 94.2606 139.531C94.576 139.675 94.9485 139.536 95.0926 139.22Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.0728 140.123C97.2169 139.808 97.078 139.436 96.7626 139.292C96.4471 139.147 96.0747 139.286 95.9306 139.602C95.7865 139.917 95.9254 140.29 96.2409 140.434C96.5563 140.578 96.9288 140.439 97.0728 140.123Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.0494 141.026C99.1935 140.711 99.0545 140.338 98.7391 140.194C98.4237 140.05 98.0512 140.189 97.9072 140.504C97.7631 140.82 97.902 141.192 98.2174 141.336C98.5328 141.481 98.9053 141.342 99.0494 141.026Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.044 141.937C101.188 141.622 101.049 141.249 100.734 141.105C100.418 140.961 100.046 141.1 99.9018 141.416C99.7578 141.731 99.8967 142.103 100.212 142.248C100.528 142.392 100.9 142.253 101.044 141.937Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.02 142.84C103.165 142.524 103.026 142.152 102.71 142.008C102.395 141.864 102.022 142.003 101.878 142.318C101.734 142.634 101.873 143.006 102.188 143.15C102.504 143.294 102.876 143.155 103.02 142.84Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.992 143.744C105.136 143.429 104.997 143.056 104.682 142.912C104.367 142.768 103.994 142.907 103.85 143.223C103.706 143.538 103.845 143.91 104.16 144.054C104.476 144.199 104.848 144.06 104.992 143.744Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.969 144.647C107.113 144.332 106.974 143.959 106.658 143.815C106.343 143.671 105.97 143.81 105.826 144.125C105.682 144.441 105.821 144.813 106.137 144.957C106.452 145.101 106.825 144.962 106.969 144.647Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.959 145.552C109.103 145.237 108.964 144.865 108.649 144.72C108.334 144.576 107.961 144.715 107.817 145.031C107.673 145.346 107.812 145.719 108.127 145.863C108.443 146.007 108.815 145.868 108.959 145.552Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.936 146.455C111.08 146.14 110.941 145.767 110.626 145.623C110.31 145.479 109.938 145.618 109.794 145.933C109.65 146.249 109.788 146.621 110.104 146.765C110.419 146.909 110.792 146.771 110.936 146.455Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.914 147.359C113.058 147.043 112.919 146.671 112.604 146.527C112.289 146.383 111.916 146.522 111.772 146.837C111.628 147.153 111.767 147.525 112.082 147.669C112.398 147.813 112.77 147.674 112.914 147.359Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.891 148.261C115.035 147.946 114.896 147.574 114.58 147.43C114.265 147.285 113.893 147.424 113.749 147.74C113.604 148.055 113.743 148.428 114.059 148.572C114.374 148.716 114.747 148.577 114.891 148.261Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.88 149.17C117.024 148.855 116.885 148.482 116.57 148.338C116.254 148.194 115.882 148.333 115.738 148.648C115.594 148.964 115.733 149.336 116.048 149.48C116.364 149.624 116.736 149.485 116.88 149.17Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.856 150.073C119.001 149.757 118.862 149.385 118.546 149.241C118.231 149.097 117.858 149.236 117.714 149.551C117.57 149.866 117.709 150.239 118.024 150.383C118.34 150.527 118.712 150.388 118.856 150.073Z"
            fill="#B8B7B7"
          />
          <path
            d="M75.0756 128.022C75.2196 127.707 75.0807 127.335 74.7653 127.19C74.4499 127.046 74.0774 127.185 73.9333 127.501C73.7893 127.816 73.9282 128.189 74.2436 128.333C74.559 128.477 74.9315 128.338 75.0756 128.022Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.0519 128.925C77.196 128.61 77.0571 128.237 76.7416 128.093C76.4262 127.949 76.0537 128.088 75.9097 128.403C75.7656 128.719 75.9045 129.091 76.2199 129.235C76.5354 129.379 76.9078 129.241 77.0519 128.925Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.0467 129.836C79.1908 129.521 79.0519 129.148 78.7364 129.004C78.421 128.86 78.0485 128.999 77.9045 129.314C77.7604 129.63 77.8993 130.002 78.2147 130.146C78.5302 130.29 78.9026 130.152 79.0467 129.836Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.0232 130.739C81.1672 130.423 81.0283 130.051 80.7129 129.907C80.3975 129.763 80.025 129.902 79.8809 130.217C79.7368 130.533 79.8758 130.905 80.1912 131.049C80.5066 131.193 80.8791 131.054 81.0232 130.739Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.9948 131.643C83.1388 131.328 82.9999 130.955 82.6845 130.811C82.3691 130.667 81.9966 130.806 81.8525 131.121C81.7085 131.437 81.8474 131.809 82.1628 131.953C82.4782 132.097 82.8507 131.958 82.9948 131.643Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.9713 132.546C85.1153 132.23 84.9764 131.858 84.661 131.714C84.3456 131.57 83.9731 131.709 83.829 132.024C83.685 132.339 83.8239 132.712 84.1393 132.856C84.4547 133 84.8272 132.861 84.9713 132.546Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.962 133.451C87.1061 133.136 86.9671 132.763 86.6517 132.619C86.3363 132.475 85.9638 132.614 85.8197 132.93C85.6757 133.245 85.8146 133.618 86.13 133.762C86.4454 133.906 86.8179 133.767 86.962 133.451Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.9384 134.354C89.0825 134.039 88.9436 133.666 88.6282 133.522C88.3127 133.378 87.9403 133.517 87.7962 133.832C87.6521 134.148 87.791 134.52 88.1065 134.664C88.4219 134.808 88.7944 134.669 88.9384 134.354Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.917 135.258C91.061 134.942 90.9221 134.57 90.6067 134.426C90.2913 134.282 89.9188 134.421 89.7747 134.736C89.6306 135.051 89.7696 135.424 90.085 135.568C90.4004 135.712 90.7729 135.573 90.917 135.258Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.8933 136.16C93.0373 135.845 92.8984 135.472 92.583 135.328C92.2676 135.184 91.8951 135.323 91.751 135.639C91.607 135.954 91.7459 136.327 92.0613 136.471C92.3767 136.615 92.7492 136.476 92.8933 136.16Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.8827 137.069C95.0267 136.754 94.8878 136.381 94.5724 136.237C94.257 136.093 93.8845 136.232 93.7404 136.547C93.5963 136.863 93.7353 137.235 94.0507 137.379C94.3661 137.523 94.7386 137.384 94.8827 137.069Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.8591 137.972C97.0032 137.656 96.8642 137.284 96.5488 137.14C96.2334 136.996 95.8609 137.135 95.7168 137.45C95.5728 137.765 95.7117 138.138 96.0271 138.282C96.3425 138.426 96.715 138.287 96.8591 137.972Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.8399 138.874C98.984 138.559 98.8451 138.186 98.5297 138.042C98.2142 137.898 97.8417 138.037 97.6977 138.353C97.5536 138.668 97.6925 139.041 98.008 139.185C98.3234 139.329 98.6959 139.19 98.8399 138.874Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.816 139.777C100.96 139.462 100.821 139.089 100.506 138.945C100.19 138.801 99.8179 138.94 99.6738 139.255C99.5298 139.571 99.6687 139.943 99.9841 140.087C100.3 140.231 100.672 140.092 100.816 139.777Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.811 140.688C102.955 140.373 102.816 140 102.501 139.856C102.185 139.712 101.813 139.851 101.669 140.166C101.525 140.482 101.663 140.854 101.979 140.998C102.294 141.142 102.667 141.003 102.811 140.688Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.787 141.591C104.931 141.275 104.792 140.903 104.477 140.759C104.162 140.615 103.789 140.754 103.645 141.069C103.501 141.384 103.64 141.757 103.955 141.901C104.271 142.045 104.643 141.906 104.787 141.591Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.759 142.495C106.903 142.179 106.765 141.807 106.449 141.663C106.134 141.519 105.761 141.658 105.617 141.973C105.473 142.289 105.612 142.661 105.927 142.805C106.243 142.949 106.615 142.81 106.759 142.495Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.735 143.397C108.879 143.082 108.741 142.71 108.425 142.565C108.11 142.421 107.737 142.56 107.593 142.876C107.449 143.191 107.588 143.564 107.903 143.708C108.219 143.852 108.591 143.713 108.735 143.397Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.726 144.303C110.87 143.988 110.731 143.615 110.416 143.471C110.101 143.327 109.728 143.466 109.584 143.782C109.44 144.097 109.579 144.47 109.894 144.614C110.21 144.758 110.582 144.619 110.726 144.303Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.703 145.206C112.847 144.891 112.708 144.518 112.393 144.374C112.077 144.23 111.705 144.369 111.561 144.684C111.417 145 111.556 145.372 111.871 145.516C112.186 145.66 112.559 145.522 112.703 145.206Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.681 146.11C114.825 145.794 114.687 145.422 114.371 145.278C114.056 145.134 113.683 145.273 113.539 145.588C113.395 145.903 113.534 146.276 113.849 146.42C114.165 146.564 114.537 146.425 114.681 146.11Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.657 147.012C116.801 146.697 116.663 146.324 116.347 146.18C116.032 146.036 115.659 146.175 115.515 146.491C115.371 146.806 115.51 147.178 115.825 147.322C116.141 147.467 116.513 147.328 116.657 147.012Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.647 147.921C118.791 147.606 118.652 147.233 118.337 147.089C118.021 146.945 117.649 147.084 117.505 147.399C117.361 147.715 117.5 148.087 117.815 148.231C118.131 148.375 118.503 148.236 118.647 147.921Z"
            fill="#B8B7B7"
          />
          <path
            d="M120.624 148.824C120.768 148.508 120.629 148.136 120.313 147.992C119.998 147.848 119.625 147.987 119.481 148.302C119.337 148.617 119.476 148.99 119.792 149.134C120.107 149.278 120.479 149.139 120.624 148.824Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.8541 125.887C74.9981 125.572 74.8592 125.199 74.5438 125.055C74.2284 124.911 73.8559 125.05 73.7118 125.366C73.5678 125.681 73.7067 126.053 74.0221 126.197C74.3375 126.342 74.71 126.203 74.8541 125.887Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.8305 126.79C76.9745 126.474 76.8356 126.102 76.5202 125.958C76.2048 125.814 75.8323 125.953 75.6882 126.268C75.5442 126.584 75.6831 126.956 75.9985 127.1C76.3139 127.244 76.6864 127.105 76.8305 126.79Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.8253 127.701C78.9693 127.386 78.8304 127.013 78.515 126.869C78.1996 126.725 77.8271 126.864 77.683 127.179C77.539 127.495 77.6779 127.867 77.9933 128.011C78.3087 128.155 78.6812 128.016 78.8253 127.701Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.8017 128.604C80.9458 128.288 80.8069 127.916 80.4914 127.772C80.176 127.628 79.8035 127.767 79.6595 128.082C79.5154 128.397 79.6543 128.77 79.9697 128.914C80.2852 129.058 80.6577 128.919 80.8017 128.604Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.7735 129.508C82.9175 129.193 82.7786 128.82 82.4632 128.676C82.1478 128.532 81.7753 128.671 81.6312 128.986C81.4871 129.302 81.6261 129.674 81.9415 129.818C82.2569 129.962 82.6294 129.823 82.7735 129.508Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.7498 130.411C84.8939 130.095 84.755 129.723 84.4396 129.579C84.1241 129.435 83.7517 129.573 83.6076 129.889C83.4635 130.204 83.6024 130.577 83.9179 130.721C84.2333 130.865 84.6058 130.726 84.7498 130.411Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.7409 131.316C86.885 131.001 86.7461 130.628 86.4307 130.484C86.1152 130.34 85.7427 130.479 85.5987 130.795C85.4546 131.11 85.5935 131.483 85.909 131.627C86.2244 131.771 86.5969 131.632 86.7409 131.316Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.7174 132.219C88.8614 131.904 88.7225 131.531 88.4071 131.387C88.0917 131.243 87.7192 131.382 87.5751 131.697C87.4311 132.013 87.57 132.385 87.8854 132.529C88.2008 132.673 88.5733 132.534 88.7174 132.219Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.6955 133.123C90.8396 132.807 90.7007 132.435 90.3852 132.291C90.0698 132.146 89.6973 132.285 89.5533 132.601C89.4092 132.916 89.5481 133.289 89.8635 133.433C90.179 133.577 90.5515 133.438 90.6955 133.123Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.6718 134.025C92.8159 133.71 92.677 133.337 92.3616 133.193C92.0461 133.049 91.6737 133.188 91.5296 133.503C91.3855 133.819 91.5244 134.191 91.8399 134.335C92.1553 134.479 92.5278 134.341 92.6718 134.025Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.6616 134.934C94.8057 134.619 94.6668 134.246 94.3514 134.102C94.0359 133.958 93.6635 134.097 93.5194 134.412C93.3753 134.728 93.5142 135.1 93.8297 135.244C94.1451 135.388 94.5176 135.249 94.6616 134.934Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.638 135.837C96.7821 135.521 96.6432 135.149 96.3277 135.005C96.0123 134.861 95.6398 135 95.4958 135.315C95.3517 135.63 95.4906 136.003 95.806 136.147C96.1215 136.291 96.494 136.152 96.638 135.837Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.6184 136.739C98.7625 136.424 98.6236 136.051 98.3082 135.907C97.9927 135.763 97.6202 135.902 97.4762 136.218C97.3321 136.533 97.471 136.905 97.7865 137.05C98.1019 137.194 98.4744 137.055 98.6184 136.739Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.595 137.642C100.739 137.327 100.6 136.954 100.285 136.81C99.9693 136.666 99.5968 136.805 99.4527 137.12C99.3087 137.436 99.4476 137.808 99.763 137.952C100.078 138.096 100.451 137.957 100.595 137.642Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.59 138.553C102.734 138.238 102.595 137.865 102.279 137.721C101.964 137.577 101.591 137.716 101.447 138.031C101.303 138.347 101.442 138.719 101.758 138.863C102.073 139.007 102.446 138.869 102.59 138.553Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.566 139.456C104.71 139.14 104.571 138.768 104.256 138.624C103.94 138.48 103.568 138.619 103.424 138.934C103.28 139.25 103.419 139.622 103.734 139.766C104.05 139.91 104.422 139.771 104.566 139.456Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.538 140.36C106.682 140.044 106.543 139.672 106.228 139.528C105.912 139.384 105.54 139.523 105.396 139.838C105.252 140.153 105.391 140.526 105.706 140.67C106.021 140.814 106.394 140.675 106.538 140.36Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.514 141.262C108.659 140.947 108.52 140.575 108.204 140.43C107.889 140.286 107.516 140.425 107.372 140.741C107.228 141.056 107.367 141.429 107.683 141.573C107.998 141.717 108.37 141.578 108.514 141.262Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.505 142.168C110.649 141.853 110.51 141.48 110.195 141.336C109.879 141.192 109.507 141.331 109.363 141.647C109.219 141.962 109.358 142.334 109.673 142.479C109.988 142.623 110.361 142.484 110.505 142.168Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.481 143.071C112.626 142.756 112.487 142.383 112.171 142.239C111.856 142.095 111.483 142.234 111.339 142.549C111.195 142.865 111.334 143.237 111.649 143.381C111.965 143.525 112.337 143.386 112.481 143.071Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.46 143.975C114.604 143.659 114.465 143.287 114.15 143.143C113.834 142.999 113.462 143.138 113.318 143.453C113.174 143.768 113.312 144.141 113.628 144.285C113.943 144.429 114.316 144.29 114.46 143.975Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.436 144.877C116.58 144.562 116.441 144.189 116.126 144.045C115.811 143.901 115.438 144.04 115.294 144.356C115.15 144.671 115.289 145.043 115.604 145.188C115.92 145.332 116.292 145.193 116.436 144.877Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.426 145.786C118.57 145.47 118.431 145.098 118.115 144.954C117.8 144.81 117.427 144.949 117.283 145.264C117.139 145.58 117.278 145.952 117.594 146.096C117.909 146.24 118.282 146.101 118.426 145.786Z"
            fill="#B8B7B7"
          />
          <path
            d="M120.402 146.689C120.546 146.373 120.407 146.001 120.092 145.857C119.776 145.713 119.404 145.851 119.26 146.167C119.116 146.482 119.255 146.855 119.57 146.999C119.886 147.143 120.258 147.004 120.402 146.689Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.6164 124.64C76.7604 124.325 76.6215 123.952 76.3061 123.808C75.9907 123.664 75.6182 123.803 75.4741 124.118C75.33 124.434 75.469 124.806 75.7844 124.95C76.0998 125.094 76.4723 124.955 76.6164 124.64Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.5924 125.543C78.7364 125.227 78.5975 124.855 78.2821 124.711C77.9667 124.567 77.5942 124.705 77.4501 125.021C77.3061 125.336 77.445 125.709 77.7604 125.853C78.0758 125.997 78.4483 125.858 78.5924 125.543Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.5871 126.454C80.7311 126.138 80.5922 125.766 80.2768 125.622C79.9614 125.478 79.5889 125.617 79.4448 125.932C79.3007 126.247 79.4397 126.62 79.7551 126.764C80.0705 126.908 80.443 126.769 80.5871 126.454Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.5634 127.356C82.7075 127.041 82.5686 126.668 82.2532 126.524C81.9377 126.38 81.5653 126.519 81.4212 126.835C81.2771 127.15 81.416 127.522 81.7315 127.667C82.0469 127.811 82.4194 127.672 82.5634 127.356Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.5356 128.261C84.6797 127.945 84.5408 127.573 84.2253 127.429C83.9099 127.285 83.5374 127.424 83.3934 127.739C83.2493 128.054 83.3882 128.427 83.7036 128.571C84.0191 128.715 84.3915 128.576 84.5356 128.261Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.5116 129.163C86.6557 128.848 86.5168 128.475 86.2013 128.331C85.8859 128.187 85.5134 128.326 85.3694 128.642C85.2253 128.957 85.3642 129.329 85.6796 129.473C85.9951 129.618 86.3676 129.479 86.5116 129.163Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.5028 130.069C88.6469 129.754 88.508 129.381 88.1926 129.237C87.8771 129.093 87.5046 129.232 87.3606 129.547C87.2165 129.863 87.3554 130.235 87.6709 130.379C87.9863 130.523 88.3588 130.384 88.5028 130.069Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.4793 130.972C90.6233 130.656 90.4844 130.284 90.169 130.14C89.8536 129.996 89.4811 130.135 89.337 130.45C89.193 130.765 89.3319 131.138 89.6473 131.282C89.9627 131.426 90.3352 131.287 90.4793 130.972Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.4577 131.875C92.6017 131.56 92.4628 131.187 92.1474 131.043C91.832 130.899 91.4595 131.038 91.3154 131.354C91.1714 131.669 91.3103 132.041 91.6257 132.186C91.9411 132.33 92.3136 132.191 92.4577 131.875Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.4337 132.778C94.5777 132.462 94.4388 132.09 94.1234 131.946C93.808 131.802 93.4355 131.941 93.2914 132.256C93.1474 132.572 93.2863 132.944 93.6017 133.088C93.9171 133.232 94.2896 133.093 94.4337 132.778Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.4234 133.687C96.5674 133.371 96.4285 132.999 96.1131 132.855C95.7977 132.711 95.4252 132.849 95.2811 133.165C95.1371 133.48 95.276 133.853 95.5914 133.997C95.9068 134.141 96.2793 134.002 96.4234 133.687Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.3999 134.589C98.544 134.274 98.4051 133.901 98.0897 133.757C97.7742 133.613 97.4017 133.752 97.2577 134.068C97.1136 134.383 97.2525 134.756 97.5679 134.9C97.8834 135.044 98.2559 134.905 98.3999 134.589Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.38 135.492C100.524 135.177 100.385 134.804 100.07 134.66C99.7545 134.516 99.382 134.655 99.238 134.971C99.0939 135.286 99.2328 135.659 99.5482 135.803C99.8637 135.947 100.236 135.808 100.38 135.492Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.357 136.395C102.501 136.079 102.362 135.707 102.046 135.563C101.731 135.419 101.358 135.558 101.214 135.873C101.07 136.189 101.209 136.561 101.525 136.705C101.84 136.849 102.213 136.71 102.357 136.395Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.351 137.306C104.496 136.991 104.357 136.618 104.041 136.474C103.726 136.33 103.353 136.469 103.209 136.784C103.065 137.1 103.204 137.472 103.519 137.616C103.835 137.76 104.207 137.621 104.351 137.306Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.328 138.209C106.472 137.893 106.333 137.521 106.018 137.377C105.702 137.233 105.33 137.372 105.186 137.687C105.042 138.002 105.18 138.375 105.496 138.519C105.811 138.663 106.184 138.524 106.328 138.209Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.3 139.113C108.444 138.797 108.305 138.425 107.989 138.281C107.674 138.137 107.301 138.276 107.157 138.591C107.013 138.907 107.152 139.279 107.468 139.423C107.783 139.567 108.156 139.428 108.3 139.113Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.276 140.016C110.42 139.7 110.281 139.328 109.966 139.184C109.65 139.04 109.278 139.178 109.134 139.494C108.99 139.809 109.129 140.182 109.444 140.326C109.759 140.47 110.132 140.331 110.276 140.016Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.267 140.921C112.411 140.606 112.272 140.233 111.957 140.089C111.641 139.945 111.269 140.084 111.125 140.4C110.981 140.715 111.12 141.088 111.435 141.232C111.751 141.376 112.123 141.237 112.267 140.921Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.244 141.824C114.388 141.509 114.249 141.136 113.933 140.992C113.618 140.848 113.245 140.987 113.101 141.302C112.957 141.618 113.096 141.99 113.412 142.134C113.727 142.278 114.099 142.139 114.244 141.824Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.222 142.728C116.366 142.412 116.227 142.04 115.911 141.896C115.596 141.752 115.223 141.89 115.079 142.206C114.935 142.521 115.074 142.894 115.39 143.038C115.705 143.182 116.078 143.043 116.222 142.728Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.198 143.63C118.342 143.315 118.203 142.942 117.888 142.798C117.572 142.654 117.2 142.793 117.056 143.108C116.912 143.424 117.051 143.796 117.366 143.94C117.681 144.084 118.054 143.946 118.198 143.63Z"
            fill="#B8B7B7"
          />
          <path
            d="M120.188 144.539C120.332 144.224 120.193 143.851 119.877 143.707C119.562 143.563 119.19 143.702 119.046 144.017C118.901 144.333 119.04 144.705 119.356 144.849C119.671 144.993 120.044 144.854 120.188 144.539Z"
            fill="#B8B7B7"
          />
          <path
            d="M122.164 145.442C122.308 145.126 122.169 144.754 121.854 144.61C121.539 144.466 121.166 144.604 121.022 144.92C120.878 145.235 121.017 145.608 121.332 145.752C121.648 145.896 122.02 145.757 122.164 145.442Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.383 123.391C78.527 123.075 78.3881 122.703 78.0727 122.559C77.7573 122.415 77.3848 122.554 77.2407 122.869C77.0966 123.185 77.2356 123.557 77.551 123.701C77.8664 123.845 78.2389 123.706 78.383 123.391Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.3595 124.294C80.5036 123.978 80.3647 123.606 80.0492 123.462C79.7338 123.317 79.3613 123.456 79.2173 123.772C79.0732 124.087 79.2121 124.46 79.5275 124.604C79.843 124.748 80.2155 124.609 80.3595 124.294Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.3542 125.205C82.4983 124.889 82.3594 124.517 82.0439 124.373C81.7285 124.229 81.356 124.367 81.212 124.683C81.0679 124.998 81.2068 125.371 81.5222 125.515C81.8377 125.659 82.2101 125.52 82.3542 125.205Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.3305 126.107C84.4746 125.792 84.3357 125.419 84.0203 125.275C83.7048 125.131 83.3323 125.27 83.1883 125.586C83.0442 125.901 83.1831 126.274 83.4986 126.418C83.814 126.562 84.1865 126.423 84.3305 126.107Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.3021 127.012C86.4462 126.696 86.3073 126.324 85.9919 126.18C85.6764 126.036 85.304 126.175 85.1599 126.49C85.0158 126.806 85.1547 127.178 85.4702 127.322C85.7856 127.466 86.1581 127.327 86.3021 127.012Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.2786 127.914C88.4226 127.599 88.2837 127.227 87.9683 127.082C87.6529 126.938 87.2804 127.077 87.1363 127.393C86.9923 127.708 87.1312 128.081 87.4466 128.225C87.762 128.369 88.1345 128.23 88.2786 127.914Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.2699 128.82C90.4139 128.505 90.275 128.132 89.9596 127.988C89.6442 127.844 89.2717 127.983 89.1276 128.298C88.9835 128.614 89.1225 128.986 89.4379 129.13C89.7533 129.274 90.1258 129.135 90.2699 128.82Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.2462 129.723C92.3902 129.407 92.2513 129.035 91.9359 128.891C91.6205 128.747 91.248 128.885 91.1039 129.201C90.9599 129.516 91.0988 129.889 91.4142 130.033C91.7296 130.177 92.1021 130.038 92.2462 129.723Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.2243 130.626C94.3684 130.311 94.2295 129.938 93.9141 129.794C93.5986 129.65 93.2261 129.789 93.0821 130.104C92.938 130.42 93.0769 130.792 93.3924 130.936C93.7078 131.08 94.0803 130.942 94.2243 130.626Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.2008 131.529C96.3449 131.213 96.206 130.841 95.8906 130.697C95.5751 130.553 95.2026 130.692 95.0586 131.007C94.9145 131.322 95.0534 131.695 95.3689 131.839C95.6843 131.983 96.0568 131.844 96.2008 131.529Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.1906 132.438C98.3346 132.122 98.1957 131.75 97.8803 131.606C97.5649 131.461 97.1924 131.6 97.0483 131.916C96.9043 132.231 97.0432 132.604 97.3586 132.748C97.674 132.892 98.0465 132.753 98.1906 132.438Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.167 133.34C100.311 133.025 100.172 132.652 99.8568 132.508C99.5414 132.364 99.1689 132.503 99.0248 132.819C98.8808 133.134 99.0197 133.506 99.3351 133.651C99.6505 133.795 100.023 133.656 100.167 133.34Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.147 134.243C102.291 133.928 102.152 133.555 101.837 133.411C101.522 133.267 101.149 133.406 101.005 133.722C100.861 134.037 101 134.409 101.315 134.554C101.631 134.698 102.003 134.559 102.147 134.243Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.124 135.146C104.268 134.83 104.129 134.458 103.813 134.314C103.498 134.17 103.126 134.309 102.981 134.624C102.837 134.94 102.976 135.312 103.292 135.456C103.607 135.6 103.98 135.461 104.124 135.146Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.118 136.057C106.263 135.742 106.124 135.369 105.808 135.225C105.493 135.081 105.12 135.22 104.976 135.535C104.832 135.851 104.971 136.223 105.287 136.367C105.602 136.511 105.974 136.372 106.118 136.057Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.095 136.96C108.239 136.644 108.1 136.272 107.785 136.128C107.469 135.984 107.097 136.123 106.953 136.438C106.809 136.753 106.948 137.126 107.263 137.27C107.578 137.414 107.951 137.275 108.095 136.96Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.067 137.864C110.211 137.548 110.072 137.176 109.757 137.032C109.441 136.888 109.069 137.027 108.925 137.342C108.78 137.657 108.919 138.03 109.235 138.174C109.55 138.318 109.923 138.179 110.067 137.864Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.043 138.766C112.187 138.451 112.048 138.078 111.733 137.934C111.418 137.79 111.045 137.929 110.901 138.245C110.757 138.56 110.896 138.933 111.211 139.077C111.527 139.221 111.899 139.082 112.043 138.766Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.034 139.672C114.178 139.357 114.039 138.984 113.724 138.84C113.408 138.696 113.036 138.835 112.892 139.15C112.748 139.466 112.886 139.838 113.202 139.982C113.517 140.126 113.89 139.988 114.034 139.672Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.01 140.575C116.154 140.259 116.015 139.887 115.7 139.743C115.384 139.599 115.012 139.738 114.868 140.053C114.724 140.369 114.863 140.741 115.178 140.885C115.494 141.029 115.866 140.89 116.01 140.575Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.989 141.479C118.133 141.163 117.994 140.791 117.678 140.647C117.363 140.502 116.99 140.641 116.846 140.957C116.702 141.272 116.841 141.645 117.157 141.789C117.472 141.933 117.845 141.794 117.989 141.479Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.965 142.381C120.109 142.066 119.97 141.693 119.655 141.549C119.339 141.405 118.967 141.544 118.823 141.859C118.679 142.175 118.818 142.547 119.133 142.691C119.449 142.835 119.821 142.697 119.965 142.381Z"
            fill="#B8B7B7"
          />
          <path
            d="M121.954 143.29C122.099 142.974 121.96 142.602 121.644 142.458C121.329 142.314 120.956 142.453 120.812 142.768C120.668 143.083 120.807 143.456 121.123 143.6C121.438 143.744 121.81 143.605 121.954 143.29Z"
            fill="#B8B7B7"
          />
          <path
            d="M123.931 144.193C124.075 143.877 123.936 143.505 123.621 143.361C123.305 143.216 122.933 143.355 122.789 143.671C122.645 143.986 122.784 144.359 123.099 144.503C123.414 144.647 123.787 144.508 123.931 144.193Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.162 121.256C78.3061 120.94 78.1672 120.568 77.8517 120.424C77.5363 120.28 77.1638 120.419 77.0198 120.734C76.8757 121.05 77.0146 121.422 77.33 121.566C77.6455 121.71 78.0179 121.571 78.162 121.256Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.1381 122.158C80.2821 121.843 80.1432 121.47 79.8278 121.326C79.5124 121.182 79.1399 121.321 78.9958 121.637C78.8518 121.952 78.9907 122.325 79.3061 122.469C79.6215 122.613 79.994 122.474 80.1381 122.158Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.1328 123.069C82.2768 122.754 82.1379 122.382 81.8225 122.237C81.5071 122.093 81.1346 122.232 80.9905 122.548C80.8465 122.863 80.9854 123.236 81.3008 123.38C81.6162 123.524 81.9887 123.385 82.1328 123.069Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.1092 123.972C84.2532 123.657 84.1143 123.284 83.7989 123.14C83.4835 122.996 83.111 123.135 82.9669 123.45C82.8228 123.766 82.9618 124.138 83.2772 124.282C83.5926 124.426 83.9651 124.287 84.1092 123.972Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.0811 124.877C86.2252 124.561 86.0863 124.189 85.7709 124.045C85.4554 123.901 85.083 124.04 84.9389 124.355C84.7948 124.671 84.9337 125.043 85.2492 125.187C85.5646 125.331 85.9371 125.192 86.0811 124.877Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.0572 125.779C88.2012 125.464 88.0623 125.091 87.7469 124.947C87.4315 124.803 87.059 124.942 86.9149 125.258C86.7708 125.573 86.9098 125.946 87.2252 126.09C87.5406 126.234 87.9131 126.095 88.0572 125.779Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.0484 126.685C90.1925 126.369 90.0536 125.997 89.7381 125.853C89.4227 125.709 89.0502 125.848 88.9062 126.163C88.7621 126.478 88.901 126.851 89.2164 126.995C89.5319 127.139 89.9044 127 90.0484 126.685Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.0249 127.587C92.1689 127.272 92.03 126.9 91.7146 126.755C91.3992 126.611 91.0267 126.75 90.8826 127.066C90.7386 127.381 90.8775 127.754 91.1929 127.898C91.5083 128.042 91.8808 127.903 92.0249 127.587Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.0034 128.491C94.1474 128.176 94.0085 127.803 93.6931 127.659C93.3777 127.515 93.0052 127.654 92.8611 127.969C92.7171 128.285 92.856 128.657 93.1714 128.801C93.4868 128.945 93.8593 128.807 94.0034 128.491Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.9794 129.394C96.1235 129.078 95.9845 128.706 95.6691 128.562C95.3537 128.418 94.9812 128.557 94.8371 128.872C94.6931 129.187 94.832 129.56 95.1474 129.704C95.4628 129.848 95.8353 129.709 95.9794 129.394Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.9691 130.302C98.1131 129.987 97.9742 129.614 97.6588 129.47C97.3434 129.326 96.9709 129.465 96.8268 129.781C96.6828 130.096 96.8217 130.469 97.1371 130.613C97.4525 130.757 97.825 130.618 97.9691 130.302Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.9456 131.205C100.09 130.89 99.9508 130.517 99.6354 130.373C99.3199 130.229 98.9475 130.368 98.8034 130.683C98.6593 130.999 98.7982 131.371 99.1137 131.515C99.4291 131.659 99.8016 131.52 99.9456 131.205Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.926 132.108C102.07 131.793 101.931 131.42 101.616 131.276C101.3 131.132 100.928 131.271 100.784 131.586C100.64 131.902 100.779 132.274 101.094 132.418C101.409 132.562 101.782 132.424 101.926 132.108Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.902 133.011C104.046 132.695 103.907 132.323 103.592 132.179C103.277 132.035 102.904 132.174 102.76 132.489C102.616 132.805 102.755 133.177 103.07 133.321C103.386 133.465 103.758 133.326 103.902 133.011Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.897 133.922C106.041 133.606 105.902 133.234 105.587 133.09C105.271 132.946 104.899 133.085 104.755 133.4C104.611 133.716 104.75 134.088 105.065 134.232C105.381 134.376 105.753 134.237 105.897 133.922Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.873 134.825C108.018 134.509 107.879 134.137 107.563 133.993C107.248 133.849 106.875 133.987 106.731 134.303C106.587 134.618 106.726 134.991 107.042 135.135C107.357 135.279 107.729 135.14 107.873 134.825Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.845 135.729C109.989 135.413 109.851 135.041 109.535 134.897C109.22 134.752 108.847 134.891 108.703 135.207C108.559 135.522 108.698 135.895 109.013 136.039C109.329 136.183 109.701 136.044 109.845 135.729Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.822 136.631C111.966 136.316 111.827 135.943 111.512 135.799C111.196 135.655 110.824 135.794 110.68 136.11C110.535 136.425 110.674 136.797 110.99 136.942C111.305 137.086 111.678 136.947 111.822 136.631Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.813 137.537C113.957 137.222 113.818 136.849 113.502 136.705C113.187 136.561 112.815 136.7 112.671 137.015C112.526 137.331 112.665 137.703 112.981 137.847C113.296 137.992 113.669 137.853 113.813 137.537Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.789 138.44C115.933 138.124 115.794 137.752 115.479 137.608C115.164 137.464 114.791 137.603 114.647 137.918C114.503 138.234 114.642 138.606 114.957 138.75C115.273 138.894 115.645 138.755 115.789 138.44Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.767 139.343C117.911 139.028 117.772 138.655 117.457 138.511C117.142 138.367 116.769 138.506 116.625 138.822C116.481 139.137 116.62 139.51 116.935 139.654C117.251 139.798 117.623 139.659 117.767 139.343Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.744 140.246C119.888 139.931 119.749 139.558 119.433 139.414C119.118 139.27 118.745 139.409 118.601 139.724C118.457 140.04 118.596 140.412 118.912 140.556C119.227 140.7 119.6 140.561 119.744 140.246Z"
            fill="#B8B7B7"
          />
          <path
            d="M121.733 141.155C121.877 140.839 121.739 140.467 121.423 140.323C121.108 140.179 120.735 140.318 120.591 140.633C120.447 140.949 120.586 141.321 120.901 141.465C121.217 141.609 121.589 141.47 121.733 141.155Z"
            fill="#B8B7B7"
          />
          <path
            d="M123.71 142.058C123.854 141.742 123.715 141.37 123.4 141.226C123.084 141.081 122.712 141.22 122.568 141.536C122.424 141.851 122.562 142.224 122.878 142.368C123.193 142.512 123.566 142.373 123.71 142.058Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.4024 122.497C76.5464 122.182 76.4075 121.809 76.0921 121.665C75.7767 121.521 75.4042 121.66 75.2601 121.976C75.1161 122.291 75.255 122.663 75.5704 122.808C75.8858 122.952 76.2583 122.813 76.4024 122.497Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.935 119.142C78.0791 118.826 77.9402 118.454 77.6247 118.31C77.3093 118.166 76.9368 118.305 76.7928 118.62C76.6487 118.935 76.7876 119.308 77.103 119.452C77.4185 119.596 77.791 119.457 77.935 119.142Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.9115 120.044C80.0555 119.729 79.9166 119.356 79.6012 119.212C79.2858 119.068 78.9133 119.207 78.7692 119.523C78.6251 119.838 78.7641 120.211 79.0795 120.355C79.3949 120.499 79.7674 120.36 79.9115 120.044Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.9062 120.956C82.0503 120.64 81.9114 120.268 81.5959 120.124C81.2805 119.979 80.908 120.118 80.764 120.434C80.6199 120.749 80.7588 121.122 81.0742 121.266C81.3897 121.41 81.7621 121.271 81.9062 120.956Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.8825 121.858C84.0266 121.543 83.8877 121.17 83.5723 121.026C83.2568 120.882 82.8843 121.021 82.7403 121.336C82.5962 121.652 82.7351 122.024 83.0506 122.168C83.366 122.313 83.7385 122.174 83.8825 121.858Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.8541 122.763C85.9982 122.447 85.8593 122.075 85.5439 121.931C85.2285 121.787 84.856 121.926 84.7119 122.241C84.5678 122.556 84.7068 122.929 85.0222 123.073C85.3376 123.217 85.7101 123.078 85.8541 122.763Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.8306 123.665C87.9747 123.35 87.8357 122.978 87.5203 122.834C87.2049 122.689 86.8324 122.828 86.6883 123.144C86.5443 123.459 86.6832 123.832 86.9986 123.976C87.314 124.12 87.6865 123.981 87.8306 123.665Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.8218 124.571C89.9659 124.255 89.8269 123.883 89.5115 123.739C89.1961 123.595 88.8236 123.734 88.6796 124.049C88.5355 124.365 88.6744 124.737 88.9898 124.881C89.3052 125.025 89.6777 124.886 89.8218 124.571Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.7982 125.474C91.9423 125.158 91.8034 124.786 91.488 124.642C91.1725 124.497 90.8001 124.636 90.656 124.952C90.5119 125.267 90.6508 125.64 90.9663 125.784C91.2817 125.928 91.6542 125.789 91.7982 125.474Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.7763 126.377C93.9204 126.062 93.7815 125.689 93.4661 125.545C93.1506 125.401 92.7781 125.54 92.6341 125.855C92.49 126.171 92.6289 126.543 92.9444 126.687C93.2598 126.831 93.6323 126.692 93.7763 126.377Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.7528 127.28C95.8968 126.964 95.7579 126.592 95.4425 126.448C95.1271 126.304 94.7546 126.443 94.6105 126.758C94.4665 127.073 94.6054 127.446 94.9208 127.59C95.2362 127.734 95.6087 127.595 95.7528 127.28Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.7426 128.188C97.8866 127.873 97.7477 127.501 97.4323 127.356C97.1169 127.212 96.7444 127.351 96.6003 127.667C96.4563 127.982 96.5952 128.355 96.9106 128.499C97.226 128.643 97.5985 128.504 97.7426 128.188Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.719 129.091C99.8631 128.776 99.7242 128.403 99.4087 128.259C99.0933 128.115 98.7208 128.254 98.5768 128.569C98.4327 128.885 98.5716 129.257 98.887 129.401C99.2025 129.545 99.575 129.407 99.719 129.091Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.699 129.994C101.843 129.679 101.704 129.306 101.389 129.162C101.074 129.018 100.701 129.157 100.557 129.472C100.413 129.788 100.552 130.16 100.867 130.304C101.183 130.448 101.555 130.31 101.699 129.994Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.676 130.897C103.82 130.581 103.681 130.209 103.365 130.065C103.05 129.921 102.678 130.06 102.533 130.375C102.389 130.691 102.528 131.063 102.844 131.207C103.159 131.351 103.532 131.212 103.676 130.897Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.67 131.808C105.814 131.493 105.676 131.12 105.36 130.976C105.045 130.832 104.672 130.971 104.528 131.286C104.384 131.602 104.523 131.974 104.838 132.118C105.154 132.262 105.526 132.123 105.67 131.808Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.647 132.711C107.791 132.395 107.652 132.023 107.337 131.879C107.021 131.735 106.649 131.874 106.505 132.189C106.361 132.504 106.5 132.877 106.815 133.021C107.13 133.165 107.503 133.026 107.647 132.711Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.619 133.615C109.763 133.299 109.624 132.927 109.308 132.783C108.993 132.639 108.62 132.778 108.476 133.093C108.332 133.408 108.471 133.781 108.787 133.925C109.102 134.069 109.474 133.93 109.619 133.615Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.595 134.517C111.739 134.202 111.6 133.83 111.285 133.685C110.969 133.541 110.597 133.68 110.453 133.996C110.309 134.311 110.448 134.684 110.763 134.828C111.079 134.972 111.451 134.833 111.595 134.517Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.586 135.423C113.73 135.108 113.591 134.735 113.275 134.591C112.96 134.447 112.588 134.586 112.443 134.901C112.299 135.217 112.438 135.589 112.754 135.733C113.069 135.877 113.442 135.739 113.586 135.423Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.562 136.326C115.706 136.01 115.567 135.638 115.252 135.494C114.937 135.35 114.564 135.489 114.42 135.804C114.276 136.12 114.415 136.492 114.73 136.636C115.046 136.78 115.418 136.641 115.562 136.326Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.541 137.23C117.685 136.914 117.546 136.542 117.23 136.398C116.915 136.253 116.542 136.392 116.398 136.708C116.254 137.023 116.393 137.396 116.709 137.54C117.024 137.684 117.397 137.545 117.541 137.23Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.517 138.132C119.661 137.817 119.522 137.444 119.207 137.3C118.891 137.156 118.519 137.295 118.375 137.61C118.231 137.926 118.37 138.298 118.685 138.442C119.001 138.586 119.373 138.448 119.517 138.132Z"
            fill="#B8B7B7"
          />
          <path
            d="M121.506 139.041C121.65 138.725 121.512 138.353 121.196 138.209C120.881 138.065 120.508 138.204 120.364 138.519C120.22 138.834 120.359 139.207 120.674 139.351C120.99 139.495 121.362 139.356 121.506 139.041Z"
            fill="#B8B7B7"
          />
          <path
            d="M123.483 139.943C123.627 139.628 123.488 139.256 123.173 139.111C122.857 138.967 122.485 139.106 122.341 139.422C122.196 139.737 122.335 140.11 122.651 140.254C122.966 140.398 123.339 140.259 123.483 139.943Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.7019 117.893C79.8459 117.578 79.707 117.205 79.3916 117.061C79.0762 116.917 78.7037 117.056 78.5596 117.372C78.4156 117.687 78.5545 118.059 78.8699 118.203C79.1853 118.348 79.5578 118.209 79.7019 117.893Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.6783 118.796C81.8224 118.48 81.6835 118.108 81.368 117.964C81.0526 117.82 80.6801 117.959 80.5361 118.274C80.392 118.59 80.5309 118.962 80.8463 119.106C81.1618 119.25 81.5342 119.111 81.6783 118.796Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.6731 119.707C83.8171 119.392 83.6782 119.019 83.3628 118.875C83.0474 118.731 82.6749 118.87 82.5308 119.185C82.3867 119.501 82.5257 119.873 82.8411 120.017C83.1565 120.161 83.529 120.022 83.6731 119.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.6495 120.61C85.7936 120.294 85.6546 119.922 85.3392 119.778C85.0238 119.634 84.6513 119.772 84.5072 120.088C84.3632 120.403 84.5021 120.776 84.8175 120.92C85.1329 121.064 85.5054 120.925 85.6495 120.61Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.6211 121.514C87.7652 121.198 87.6263 120.826 87.3108 120.682C86.9954 120.538 86.6229 120.677 86.4789 120.992C86.3348 121.307 86.4737 121.68 86.7891 121.824C87.1046 121.968 87.4771 121.829 87.6211 121.514Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.5977 122.416C89.7417 122.101 89.6028 121.729 89.2874 121.584C88.972 121.44 88.5995 121.579 88.4554 121.895C88.3114 122.21 88.4503 122.583 88.7657 122.727C89.0811 122.871 89.4536 122.732 89.5977 122.416Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.5883 123.322C91.7324 123.007 91.5935 122.634 91.2781 122.49C90.9626 122.346 90.5902 122.485 90.4461 122.8C90.302 123.116 90.4409 123.488 90.7564 123.632C91.0718 123.776 91.4443 123.638 91.5883 123.322Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.5648 124.225C93.7088 123.909 93.5699 123.537 93.2545 123.393C92.9391 123.249 92.5666 123.388 92.4225 123.703C92.2785 124.018 92.4174 124.391 92.7328 124.535C93.0482 124.679 93.4207 124.54 93.5648 124.225Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.5433 125.128C95.6874 124.813 95.5484 124.441 95.233 124.296C94.9176 124.152 94.5451 124.291 94.401 124.607C94.257 124.922 94.3959 125.295 94.7113 125.439C95.0267 125.583 95.3992 125.444 95.5433 125.128Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.5196 126.031C97.6637 125.716 97.5248 125.343 97.2093 125.199C96.8939 125.055 96.5214 125.194 96.3774 125.509C96.2333 125.825 96.3722 126.197 96.6876 126.341C97.0031 126.485 97.3756 126.347 97.5196 126.031Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.5091 126.94C99.6531 126.624 99.5142 126.252 99.1988 126.108C98.8834 125.964 98.5109 126.103 98.3668 126.418C98.2227 126.733 98.3617 127.106 98.6771 127.25C98.9925 127.394 99.365 127.255 99.5091 126.94Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.485 127.842C101.629 127.527 101.491 127.154 101.175 127.01C100.86 126.866 100.487 127.005 100.343 127.321C100.199 127.636 100.338 128.009 100.653 128.153C100.969 128.297 101.341 128.158 101.485 127.842Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.466 128.745C103.61 128.43 103.471 128.057 103.156 127.913C102.841 127.769 102.468 127.908 102.324 128.223C102.18 128.539 102.319 128.911 102.634 129.055C102.95 129.199 103.322 129.061 103.466 128.745Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.442 129.648C105.586 129.332 105.448 128.96 105.132 128.816C104.817 128.672 104.444 128.811 104.3 129.126C104.156 129.441 104.295 129.814 104.61 129.958C104.926 130.102 105.298 129.963 105.442 129.648Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.437 130.559C107.581 130.243 107.442 129.871 107.127 129.727C106.811 129.583 106.439 129.722 106.295 130.037C106.151 130.352 106.29 130.725 106.605 130.869C106.921 131.013 107.293 130.874 107.437 130.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.414 131.461C109.558 131.146 109.419 130.773 109.103 130.629C108.788 130.485 108.415 130.624 108.271 130.94C108.127 131.255 108.266 131.628 108.582 131.772C108.897 131.916 109.27 131.777 109.414 131.461Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.386 132.366C111.53 132.05 111.391 131.678 111.076 131.534C110.76 131.39 110.388 131.528 110.244 131.844C110.1 132.159 110.238 132.532 110.554 132.676C110.869 132.82 111.242 132.681 111.386 132.366Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.362 133.268C113.506 132.953 113.367 132.58 113.051 132.436C112.736 132.292 112.364 132.431 112.219 132.746C112.075 133.062 112.214 133.434 112.53 133.578C112.845 133.722 113.218 133.584 113.362 133.268Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.353 134.174C115.497 133.859 115.358 133.486 115.043 133.342C114.727 133.198 114.355 133.337 114.211 133.652C114.066 133.968 114.205 134.34 114.521 134.484C114.836 134.628 115.209 134.49 115.353 134.174Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.329 135.077C117.473 134.761 117.334 134.389 117.019 134.245C116.704 134.101 116.331 134.24 116.187 134.555C116.043 134.871 116.182 135.243 116.497 135.387C116.813 135.531 117.185 135.392 117.329 135.077Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.308 135.98C119.452 135.665 119.313 135.293 118.997 135.148C118.682 135.004 118.31 135.143 118.166 135.459C118.021 135.774 118.16 136.147 118.476 136.291C118.791 136.435 119.164 136.296 119.308 135.98Z"
            fill="#B8B7B7"
          />
          <path
            d="M121.284 136.883C121.428 136.567 121.289 136.195 120.973 136.051C120.658 135.907 120.286 136.046 120.141 136.361C119.997 136.677 120.136 137.049 120.452 137.193C120.767 137.337 121.14 137.198 121.284 136.883Z"
            fill="#B8B7B7"
          />
          <path
            d="M123.274 137.792C123.418 137.476 123.279 137.104 122.963 136.96C122.648 136.816 122.275 136.955 122.131 137.27C121.987 137.585 122.126 137.958 122.442 138.102C122.757 138.246 123.13 138.107 123.274 137.792Z"
            fill="#B8B7B7"
          />
          <path
            d="M125.25 138.694C125.394 138.379 125.255 138.007 124.94 137.862C124.624 137.718 124.252 137.857 124.108 138.173C123.964 138.488 124.103 138.861 124.418 139.005C124.733 139.149 125.106 139.01 125.25 138.694Z"
            fill="#B8B7B7"
          />
          <path
            d="M79.4806 115.758C79.6247 115.442 79.4858 115.07 79.1703 114.926C78.8549 114.781 78.4824 114.92 78.3384 115.236C78.1943 115.551 78.3332 115.924 78.6486 116.068C78.9641 116.212 79.3365 116.073 79.4806 115.758Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.4571 116.66C81.6011 116.345 81.4622 115.972 81.1468 115.828C80.8314 115.684 80.4589 115.823 80.3148 116.139C80.1707 116.454 80.3097 116.827 80.6251 116.971C80.9405 117.115 81.313 116.976 81.4571 116.66Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.4518 117.571C83.5959 117.256 83.457 116.883 83.1415 116.739C82.8261 116.595 82.4536 116.734 82.3096 117.05C82.1655 117.365 82.3044 117.738 82.6198 117.882C82.9353 118.026 83.3077 117.887 83.4518 117.571Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.4282 118.474C85.5723 118.159 85.4334 117.786 85.118 117.642C84.8025 117.498 84.4301 117.637 84.286 117.952C84.1419 118.268 84.2808 118.64 84.5963 118.784C84.9117 118.928 85.2842 118.789 85.4282 118.474Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.3998 119.379C87.5439 119.063 87.405 118.691 87.0895 118.547C86.7741 118.403 86.4016 118.541 86.2576 118.857C86.1135 119.172 86.2524 119.545 86.5678 119.689C86.8833 119.833 87.2557 119.694 87.3998 119.379Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.3762 120.281C89.5203 119.966 89.3814 119.593 89.066 119.449C88.7505 119.305 88.3781 119.444 88.234 119.76C88.0899 120.075 88.2288 120.448 88.5443 120.592C88.8597 120.736 89.2322 120.597 89.3762 120.281Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.3674 121.187C91.5115 120.871 91.3725 120.499 91.0571 120.355C90.7417 120.211 90.3692 120.35 90.2251 120.665C90.0811 120.98 90.22 121.353 90.5354 121.497C90.8508 121.641 91.2233 121.502 91.3674 121.187Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.344 122.089C93.488 121.774 93.3491 121.401 93.0337 121.257C92.7183 121.113 92.3458 121.252 92.2017 121.568C92.0576 121.883 92.1966 122.256 92.512 122.4C92.8274 122.544 93.1999 122.405 93.344 122.089Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.322 122.993C95.466 122.677 95.3271 122.305 95.0117 122.161C94.6963 122.017 94.3238 122.156 94.1797 122.471C94.0357 122.787 94.1746 123.159 94.49 123.303C94.8054 123.447 95.1779 123.308 95.322 122.993Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.2984 123.896C97.4424 123.58 97.3035 123.208 96.9881 123.064C96.6727 122.919 96.3002 123.058 96.1561 123.374C96.0121 123.689 96.151 124.062 96.4664 124.206C96.7818 124.35 97.1543 124.211 97.2984 123.896Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.2882 124.804C99.4322 124.489 99.2933 124.116 98.9779 123.972C98.6625 123.828 98.29 123.967 98.1459 124.283C98.0019 124.598 98.1408 124.97 98.4562 125.115C98.7716 125.259 99.1441 125.12 99.2882 124.804Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.264 125.707C101.409 125.392 101.27 125.019 100.954 124.875C100.639 124.731 100.266 124.87 100.122 125.185C99.9782 125.501 100.117 125.873 100.433 126.017C100.748 126.161 101.12 126.022 101.264 125.707Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.245 126.61C103.389 126.295 103.25 125.922 102.935 125.778C102.619 125.634 102.247 125.773 102.103 126.088C101.959 126.404 102.097 126.776 102.413 126.92C102.728 127.064 103.101 126.925 103.245 126.61Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.221 127.513C105.365 127.197 105.226 126.825 104.911 126.681C104.596 126.537 104.223 126.676 104.079 126.991C103.935 127.306 104.074 127.679 104.389 127.823C104.705 127.967 105.077 127.828 105.221 127.513Z"
            fill="#B8B7B7"
          />
          <path
            d="M107.216 128.424C107.36 128.108 107.221 127.736 106.906 127.592C106.59 127.448 106.218 127.587 106.074 127.902C105.93 128.218 106.069 128.59 106.384 128.734C106.699 128.878 107.072 128.739 107.216 128.424Z"
            fill="#B8B7B7"
          />
          <path
            d="M109.193 129.327C109.337 129.011 109.198 128.639 108.882 128.495C108.567 128.351 108.194 128.489 108.05 128.805C107.906 129.12 108.045 129.493 108.361 129.637C108.676 129.781 109.049 129.642 109.193 129.327Z"
            fill="#B8B7B7"
          />
          <path
            d="M111.164 130.23C111.308 129.915 111.169 129.543 110.854 129.398C110.539 129.254 110.166 129.393 110.022 129.709C109.878 130.024 110.017 130.397 110.332 130.541C110.648 130.685 111.02 130.546 111.164 130.23Z"
            fill="#B8B7B7"
          />
          <path
            d="M113.141 131.133C113.285 130.818 113.146 130.445 112.83 130.301C112.515 130.157 112.143 130.296 111.999 130.611C111.854 130.927 111.993 131.299 112.309 131.443C112.624 131.588 112.997 131.449 113.141 131.133Z"
            fill="#B8B7B7"
          />
          <path
            d="M115.131 132.039C115.275 131.723 115.137 131.351 114.821 131.207C114.506 131.063 114.133 131.202 113.989 131.517C113.845 131.833 113.984 132.205 114.299 132.349C114.615 132.493 114.987 132.354 115.131 132.039Z"
            fill="#B8B7B7"
          />
          <path
            d="M117.108 132.942C117.252 132.626 117.113 132.254 116.798 132.11C116.482 131.966 116.11 132.105 115.966 132.42C115.821 132.735 115.96 133.108 116.276 133.252C116.591 133.396 116.964 133.257 117.108 132.942Z"
            fill="#B8B7B7"
          />
          <path
            d="M119.086 133.845C119.23 133.53 119.091 133.157 118.776 133.013C118.461 132.869 118.088 133.008 117.944 133.324C117.8 133.639 117.939 134.012 118.254 134.156C118.57 134.3 118.942 134.161 119.086 133.845Z"
            fill="#B8B7B7"
          />
          <path
            d="M121.063 134.748C121.207 134.433 121.068 134.06 120.752 133.916C120.437 133.772 120.064 133.911 119.92 134.226C119.776 134.542 119.915 134.914 120.231 135.058C120.546 135.202 120.919 135.063 121.063 134.748Z"
            fill="#B8B7B7"
          />
          <path
            d="M123.052 135.657C123.196 135.341 123.057 134.969 122.742 134.825C122.426 134.681 122.054 134.819 121.91 135.135C121.766 135.45 121.905 135.823 122.22 135.967C122.535 136.111 122.908 135.972 123.052 135.657Z"
            fill="#B8B7B7"
          />
          <path
            d="M125.028 136.559C125.173 136.244 125.034 135.871 124.718 135.727C124.403 135.583 124.03 135.722 123.886 136.038C123.742 136.353 123.881 136.725 124.196 136.87C124.512 137.014 124.884 136.875 125.028 136.559Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.2427 114.511C81.3868 114.195 81.2478 113.823 80.9324 113.679C80.617 113.535 80.2445 113.674 80.1004 113.989C79.9564 114.304 80.0953 114.677 80.4107 114.821C80.7261 114.965 81.0986 114.826 81.2427 114.511Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.2187 115.413C83.3628 115.098 83.2239 114.725 82.9084 114.581C82.593 114.437 82.2205 114.576 82.0765 114.892C81.9324 115.207 82.0713 115.58 82.3867 115.724C82.7022 115.868 83.0746 115.729 83.2187 115.413Z"
            fill="#B8B7B7"
          />
          <path
            d="M85.2134 116.324C85.3575 116.009 85.2185 115.636 84.9031 115.492C84.5877 115.348 84.2152 115.487 84.0712 115.803C83.9271 116.118 84.066 116.491 84.3814 116.635C84.6968 116.779 85.0693 116.64 85.2134 116.324Z"
            fill="#B8B7B7"
          />
          <path
            d="M87.1898 117.227C87.3339 116.912 87.195 116.539 86.8796 116.395C86.5641 116.251 86.1917 116.39 86.0476 116.705C85.9035 117.021 86.0424 117.393 86.3579 117.537C86.6733 117.681 87.0458 117.542 87.1898 117.227Z"
            fill="#B8B7B7"
          />
          <path
            d="M89.1619 118.131C89.306 117.816 89.1671 117.443 88.8517 117.299C88.5363 117.155 88.1638 117.294 88.0197 117.61C87.8756 117.925 88.0146 118.298 88.33 118.442C88.6454 118.586 89.0179 118.447 89.1619 118.131Z"
            fill="#B8B7B7"
          />
          <path
            d="M91.1381 119.034C91.2821 118.718 91.1432 118.346 90.8278 118.202C90.5124 118.058 90.1399 118.197 89.9958 118.512C89.8518 118.827 89.9907 119.2 90.3061 119.344C90.6215 119.488 90.994 119.349 91.1381 119.034Z"
            fill="#B8B7B7"
          />
          <path
            d="M93.1292 119.94C93.2732 119.624 93.1343 119.252 92.8189 119.108C92.5035 118.964 92.131 119.103 91.9869 119.418C91.8429 119.733 91.9818 120.106 92.2972 120.25C92.6126 120.394 92.9851 120.255 93.1292 119.94Z"
            fill="#B8B7B7"
          />
          <path
            d="M95.1056 120.842C95.2497 120.527 95.1108 120.154 94.7953 120.01C94.4799 119.866 94.1074 120.005 93.9634 120.321C93.8193 120.636 93.9582 121.009 94.2736 121.153C94.5891 121.297 94.9615 121.158 95.1056 120.842Z"
            fill="#B8B7B7"
          />
          <path
            d="M97.0841 121.746C97.2282 121.431 97.0893 121.058 96.7739 120.914C96.4584 120.77 96.0859 120.909 95.9419 121.224C95.7978 121.54 95.9367 121.912 96.2522 122.056C96.5676 122.2 96.9401 122.061 97.0841 121.746Z"
            fill="#B8B7B7"
          />
          <path
            d="M99.0601 122.648C99.2042 122.333 99.0653 121.961 98.7499 121.816C98.4344 121.672 98.062 121.811 97.9179 122.127C97.7738 122.442 97.9127 122.815 98.2282 122.959C98.5436 123.103 98.9161 122.964 99.0601 122.648Z"
            fill="#B8B7B7"
          />
          <path
            d="M101.05 123.557C101.194 123.242 101.055 122.869 100.74 122.725C100.424 122.581 100.052 122.72 99.9076 123.036C99.7635 123.351 99.9024 123.724 100.218 123.868C100.533 124.012 100.906 123.873 101.05 123.557Z"
            fill="#B8B7B7"
          />
          <path
            d="M103.026 124.46C103.17 124.145 103.031 123.772 102.716 123.628C102.401 123.484 102.028 123.623 101.884 123.938C101.74 124.254 101.879 124.626 102.194 124.77C102.51 124.914 102.882 124.775 103.026 124.46Z"
            fill="#B8B7B7"
          />
          <path
            d="M105.007 125.363C105.151 125.047 105.012 124.675 104.697 124.531C104.381 124.386 104.009 124.525 103.865 124.841C103.72 125.156 103.859 125.529 104.175 125.673C104.49 125.817 104.863 125.678 105.007 125.363Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.983 126.265C107.127 125.95 106.988 125.577 106.673 125.433C106.358 125.289 105.985 125.428 105.841 125.743C105.697 126.059 105.836 126.431 106.151 126.575C106.467 126.719 106.839 126.581 106.983 126.265Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.978 127.176C109.122 126.861 108.983 126.488 108.668 126.344C108.352 126.2 107.98 126.339 107.836 126.655C107.692 126.97 107.831 127.342 108.146 127.487C108.461 127.631 108.834 127.492 108.978 127.176Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.954 128.079C111.098 127.764 110.96 127.391 110.644 127.247C110.329 127.103 109.956 127.242 109.812 127.557C109.668 127.873 109.807 128.245 110.122 128.389C110.438 128.533 110.81 128.394 110.954 128.079Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.926 128.983C113.07 128.668 112.931 128.296 112.616 128.152C112.3 128.007 111.928 128.146 111.784 128.462C111.64 128.777 111.779 129.15 112.094 129.294C112.409 129.438 112.782 129.299 112.926 128.983Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.902 129.886C115.046 129.571 114.908 129.198 114.592 129.054C114.277 128.91 113.904 129.049 113.76 129.364C113.616 129.68 113.755 130.052 114.07 130.196C114.386 130.341 114.758 130.202 114.902 129.886Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.894 130.792C117.038 130.476 116.899 130.104 116.583 129.96C116.268 129.816 115.896 129.955 115.751 130.27C115.607 130.585 115.746 130.958 116.062 131.102C116.377 131.246 116.75 131.107 116.894 130.792Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.87 131.694C119.014 131.379 118.875 131.006 118.56 130.862C118.244 130.718 117.872 130.857 117.728 131.173C117.584 131.488 117.723 131.861 118.038 132.005C118.354 132.149 118.726 132.01 118.87 131.694Z"
            fill="#B8B7B7"
          />
          <path
            d="M120.848 132.598C120.992 132.282 120.853 131.91 120.538 131.766C120.222 131.622 119.85 131.761 119.706 132.076C119.562 132.392 119.701 132.764 120.016 132.908C120.332 133.052 120.704 132.913 120.848 132.598Z"
            fill="#B8B7B7"
          />
          <path
            d="M122.825 133.5C122.969 133.185 122.83 132.813 122.514 132.668C122.199 132.524 121.826 132.663 121.682 132.979C121.538 133.294 121.677 133.667 121.993 133.811C122.308 133.955 122.681 133.816 122.825 133.5Z"
            fill="#B8B7B7"
          />
          <path
            d="M124.814 134.409C124.958 134.094 124.82 133.721 124.504 133.577C124.189 133.433 123.816 133.572 123.672 133.888C123.528 134.203 123.667 134.575 123.982 134.72C124.298 134.864 124.67 134.725 124.814 134.409Z"
            fill="#B8B7B7"
          />
          <path
            d="M126.791 135.312C126.935 134.996 126.796 134.624 126.481 134.48C126.165 134.336 125.793 134.475 125.649 134.79C125.504 135.106 125.643 135.478 125.959 135.622C126.274 135.766 126.647 135.627 126.791 135.312Z"
            fill="#B8B7B7"
          />
          <path
            d="M83.0094 113.262C83.1534 112.946 83.0145 112.574 82.6991 112.43C82.3837 112.286 82.0112 112.424 81.8671 112.74C81.723 113.055 81.862 113.428 82.1774 113.572C82.4928 113.716 82.8653 113.577 83.0094 113.262Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.9857 114.164C85.1298 113.849 84.9909 113.476 84.6755 113.332C84.36 113.188 83.9876 113.327 83.8435 113.643C83.6994 113.958 83.8383 114.33 84.1538 114.475C84.4692 114.619 84.8417 114.48 84.9857 114.164Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.9806 115.075C87.1246 114.76 86.9857 114.387 86.6703 114.243C86.3549 114.099 85.9824 114.238 85.8383 114.554C85.6942 114.869 85.8332 115.242 86.1486 115.386C86.464 115.53 86.8365 115.391 86.9806 115.075Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.957 115.978C89.1011 115.662 88.9621 115.29 88.6467 115.146C88.3313 115.002 87.9588 115.141 87.8147 115.456C87.6707 115.772 87.8096 116.144 88.125 116.288C88.4404 116.432 88.8129 116.293 88.957 115.978Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.9285 116.883C91.0726 116.567 90.9336 116.195 90.6182 116.051C90.3028 115.907 89.9303 116.045 89.7862 116.361C89.6422 116.676 89.7811 117.049 90.0965 117.193C90.4119 117.337 90.7844 117.198 90.9285 116.883Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.9049 117.785C93.049 117.47 92.9101 117.097 92.5947 116.953C92.2792 116.809 91.9067 116.948 91.7627 117.264C91.6186 117.579 91.7575 117.951 92.073 118.096C92.3884 118.24 92.7609 118.101 92.9049 117.785Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.8963 118.691C95.0403 118.375 94.9014 118.003 94.586 117.859C94.2706 117.715 93.8981 117.854 93.754 118.169C93.61 118.484 93.7489 118.857 94.0643 119.001C94.3797 119.145 94.7522 119.006 94.8963 118.691Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.8726 119.593C97.0167 119.278 96.8778 118.905 96.5624 118.761C96.2469 118.617 95.8745 118.756 95.7304 119.072C95.5863 119.387 95.7252 119.759 96.0407 119.904C96.3561 120.048 96.7286 119.909 96.8726 119.593Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.8507 120.497C98.9947 120.181 98.8558 119.809 98.5404 119.665C98.225 119.521 97.8525 119.66 97.7084 119.975C97.5644 120.291 97.7033 120.663 98.0187 120.807C98.3341 120.951 98.7066 120.812 98.8507 120.497Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.827 121.399C100.971 121.084 100.832 120.712 100.517 120.568C100.202 120.423 99.829 120.562 99.685 120.878C99.5409 121.193 99.6798 121.566 99.9953 121.71C100.311 121.854 100.683 121.715 100.827 121.399Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.817 122.308C102.961 121.993 102.822 121.62 102.507 121.476C102.191 121.332 101.819 121.471 101.675 121.787C101.531 122.102 101.67 122.475 101.985 122.619C102.3 122.763 102.673 122.624 102.817 122.308Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.793 123.211C104.937 122.896 104.799 122.523 104.483 122.379C104.168 122.235 103.795 122.374 103.651 122.689C103.507 123.005 103.646 123.377 103.961 123.521C104.277 123.665 104.649 123.526 104.793 123.211Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.774 124.114C106.918 123.799 106.779 123.426 106.463 123.282C106.148 123.138 105.775 123.277 105.631 123.592C105.487 123.908 105.626 124.28 105.942 124.424C106.257 124.568 106.63 124.429 106.774 124.114Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.75 125.017C108.894 124.701 108.755 124.329 108.44 124.185C108.124 124.041 107.752 124.179 107.608 124.495C107.464 124.81 107.603 125.183 107.918 125.327C108.233 125.471 108.606 125.332 108.75 125.017Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.745 125.928C110.889 125.612 110.75 125.24 110.435 125.096C110.119 124.952 109.747 125.091 109.603 125.406C109.459 125.721 109.597 126.094 109.913 126.238C110.228 126.382 110.601 126.243 110.745 125.928Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.721 126.83C112.865 126.515 112.726 126.143 112.411 125.998C112.096 125.854 111.723 125.993 111.579 126.309C111.435 126.624 111.574 126.997 111.889 127.141C112.205 127.285 112.577 127.146 112.721 126.83Z"
            fill="#B8B7B7"
          />
          <path
            d="M114.693 127.734C114.837 127.419 114.698 127.046 114.383 126.902C114.068 126.758 113.695 126.897 113.551 127.213C113.407 127.528 113.546 127.901 113.861 128.045C114.177 128.189 114.549 128.05 114.693 127.734Z"
            fill="#B8B7B7"
          />
          <path
            d="M116.67 128.637C116.814 128.322 116.675 127.949 116.359 127.805C116.044 127.661 115.671 127.8 115.527 128.115C115.383 128.431 115.522 128.803 115.838 128.947C116.153 129.091 116.526 128.952 116.67 128.637Z"
            fill="#B8B7B7"
          />
          <path
            d="M118.66 129.543C118.804 129.228 118.665 128.855 118.35 128.711C118.034 128.567 117.662 128.706 117.518 129.021C117.374 129.337 117.513 129.709 117.828 129.853C118.144 129.997 118.516 129.858 118.66 129.543Z"
            fill="#B8B7B7"
          />
          <path
            d="M120.637 130.446C120.781 130.13 120.642 129.758 120.326 129.614C120.011 129.47 119.638 129.608 119.494 129.924C119.35 130.239 119.489 130.612 119.805 130.756C120.12 130.9 120.493 130.761 120.637 130.446Z"
            fill="#B8B7B7"
          />
          <path
            d="M122.615 131.349C122.759 131.034 122.62 130.661 122.305 130.517C121.989 130.373 121.617 130.512 121.473 130.827C121.329 131.143 121.468 131.515 121.783 131.659C122.098 131.804 122.471 131.665 122.615 131.349Z"
            fill="#B8B7B7"
          />
          <path
            d="M124.591 132.252C124.736 131.936 124.597 131.564 124.281 131.42C123.966 131.276 123.593 131.415 123.449 131.73C123.305 132.046 123.444 132.418 123.759 132.562C124.075 132.706 124.447 132.567 124.591 132.252Z"
            fill="#B8B7B7"
          />
          <path
            d="M126.581 133.16C126.725 132.845 126.586 132.473 126.271 132.329C125.955 132.184 125.583 132.323 125.439 132.639C125.295 132.954 125.433 133.327 125.749 133.471C126.064 133.615 126.437 133.476 126.581 133.16Z"
            fill="#B8B7B7"
          />
          <path
            d="M128.557 134.063C128.701 133.748 128.562 133.375 128.247 133.231C127.932 133.087 127.559 133.226 127.415 133.542C127.271 133.857 127.41 134.229 127.725 134.374C128.041 134.518 128.413 134.379 128.557 134.063Z"
            fill="#B8B7B7"
          />
          <path
            d="M81.0288 112.368C81.1729 112.053 81.034 111.68 80.7186 111.536C80.4031 111.392 80.0306 111.531 79.8866 111.846C79.7425 112.162 79.8814 112.534 80.1969 112.678C80.5123 112.822 80.8848 112.683 81.0288 112.368Z"
            fill="#B8B7B7"
          />
          <path
            d="M66.8969 140.695C67.041 140.379 66.9021 140.007 66.5867 139.863C66.2712 139.719 65.8987 139.857 65.7547 140.173C65.6106 140.488 65.7495 140.861 66.065 141.005C66.3804 141.149 66.7529 141.01 66.8969 140.695Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.8735 141.597C69.0176 141.282 68.8786 140.909 68.5632 140.765C68.2478 140.621 67.8753 140.76 67.7312 141.076C67.5872 141.391 67.7261 141.764 68.0415 141.908C68.3569 142.052 68.7294 141.913 68.8735 141.597Z"
            fill="#B8B7B7"
          />
          <path
            d="M70.8682 142.508C71.0122 142.193 70.8733 141.82 70.5579 141.676C70.2425 141.532 69.87 141.671 69.7259 141.987C69.5819 142.302 69.7208 142.675 70.0362 142.819C70.3516 142.963 70.7241 142.824 70.8682 142.508Z"
            fill="#B8B7B7"
          />
          <path
            d="M72.8446 143.411C72.9887 143.096 72.8498 142.723 72.5344 142.579C72.2189 142.435 71.8464 142.574 71.7024 142.889C71.5583 143.205 71.6972 143.577 72.0127 143.721C72.3281 143.865 72.7006 143.727 72.8446 143.411Z"
            fill="#B8B7B7"
          />
          <path
            d="M74.8164 144.315C74.9604 144 74.8215 143.627 74.5061 143.483C74.1907 143.339 73.8182 143.478 73.6741 143.794C73.5301 144.109 73.669 144.481 73.9844 144.625C74.2998 144.77 74.6723 144.631 74.8164 144.315Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.7927 145.218C76.9368 144.902 76.7979 144.53 76.4825 144.386C76.167 144.242 75.7946 144.381 75.6505 144.696C75.5064 145.012 75.6453 145.384 75.9608 145.528C76.2762 145.672 76.6487 145.533 76.7927 145.218Z"
            fill="#B8B7B7"
          />
          <path
            d="M78.7833 146.123C78.9274 145.808 78.7885 145.436 78.4731 145.292C78.1577 145.147 77.7852 145.286 77.6411 145.602C77.497 145.917 77.6359 146.29 77.9514 146.434C78.2668 146.578 78.6393 146.439 78.7833 146.123Z"
            fill="#B8B7B7"
          />
          <path
            d="M80.7599 147.026C80.904 146.711 80.7651 146.338 80.4496 146.194C80.1342 146.05 79.7617 146.189 79.6177 146.504C79.4736 146.82 79.6125 147.192 79.9279 147.336C80.2434 147.481 80.6158 147.342 80.7599 147.026Z"
            fill="#B8B7B7"
          />
          <path
            d="M82.7384 147.93C82.8825 147.614 82.7436 147.242 82.4282 147.098C82.1127 146.954 81.7402 147.093 81.5962 147.408C81.4521 147.724 81.591 148.096 81.9065 148.24C82.2219 148.384 82.5944 148.245 82.7384 147.93Z"
            fill="#B8B7B7"
          />
          <path
            d="M84.7147 148.833C84.8588 148.517 84.7199 148.145 84.4045 148.001C84.089 147.857 83.7166 147.995 83.5725 148.311C83.4284 148.626 83.5673 148.999 83.8828 149.143C84.1982 149.287 84.5707 149.148 84.7147 148.833Z"
            fill="#B8B7B7"
          />
          <path
            d="M86.7041 149.741C86.8482 149.426 86.7093 149.053 86.3938 148.909C86.0784 148.765 85.7059 148.904 85.5619 149.22C85.4178 149.535 85.5567 149.907 85.8721 150.051C86.1876 150.196 86.5601 150.057 86.7041 149.741Z"
            fill="#B8B7B7"
          />
          <path
            d="M88.6806 150.644C88.8246 150.328 88.6857 149.956 88.3703 149.812C88.0549 149.668 87.6824 149.807 87.5383 150.122C87.3943 150.438 87.5332 150.81 87.8486 150.954C88.164 151.098 88.5365 150.959 88.6806 150.644Z"
            fill="#B8B7B7"
          />
          <path
            d="M90.6611 151.547C90.8052 151.231 90.6662 150.859 90.3508 150.715C90.0354 150.57 89.6629 150.709 89.5188 151.025C89.3748 151.34 89.5137 151.713 89.8291 151.857C90.1445 152.001 90.517 151.862 90.6611 151.547Z"
            fill="#B8B7B7"
          />
          <path
            d="M92.6375 152.449C92.7815 152.134 92.6426 151.761 92.3272 151.617C92.0118 151.473 91.6393 151.612 91.4952 151.927C91.3512 152.243 91.4901 152.615 91.8055 152.759C92.1209 152.903 92.4934 152.765 92.6375 152.449Z"
            fill="#B8B7B7"
          />
          <path
            d="M94.6322 153.36C94.7762 153.045 94.6373 152.672 94.3219 152.528C94.0065 152.384 93.634 152.523 93.4899 152.839C93.3459 153.154 93.4848 153.526 93.8002 153.671C94.1156 153.815 94.4881 153.676 94.6322 153.36Z"
            fill="#B8B7B7"
          />
          <path
            d="M96.6087 154.263C96.7528 153.947 96.6139 153.575 96.2985 153.431C95.983 153.287 95.6105 153.426 95.4665 153.741C95.3224 154.057 95.4613 154.429 95.7768 154.573C96.0922 154.717 96.4647 154.578 96.6087 154.263Z"
            fill="#B8B7B7"
          />
          <path
            d="M98.5802 155.167C98.7243 154.852 98.5854 154.479 98.2699 154.335C97.9545 154.191 97.582 154.33 97.438 154.646C97.2939 154.961 97.4328 155.334 97.7482 155.478C98.0637 155.622 98.4362 155.483 98.5802 155.167Z"
            fill="#B8B7B7"
          />
          <path
            d="M100.557 156.07C100.701 155.755 100.562 155.382 100.246 155.238C99.931 155.094 99.5585 155.233 99.4144 155.548C99.2704 155.864 99.4093 156.236 99.7247 156.38C100.04 156.524 100.413 156.386 100.557 156.07Z"
            fill="#B8B7B7"
          />
          <path
            d="M102.548 156.976C102.692 156.66 102.553 156.288 102.238 156.144C101.922 156 101.55 156.138 101.406 156.454C101.262 156.769 101.401 157.142 101.716 157.286C102.031 157.43 102.404 157.291 102.548 156.976Z"
            fill="#B8B7B7"
          />
          <path
            d="M104.524 157.878C104.668 157.563 104.53 157.19 104.214 157.046C103.899 156.902 103.526 157.041 103.382 157.357C103.238 157.672 103.377 158.045 103.692 158.189C104.008 158.333 104.38 158.194 104.524 157.878Z"
            fill="#B8B7B7"
          />
          <path
            d="M106.503 158.782C106.647 158.466 106.508 158.094 106.192 157.95C105.877 157.806 105.504 157.945 105.36 158.26C105.216 158.575 105.355 158.948 105.671 159.092C105.986 159.236 106.358 159.097 106.503 158.782Z"
            fill="#B8B7B7"
          />
          <path
            d="M108.479 159.684C108.623 159.369 108.484 158.996 108.169 158.852C107.853 158.708 107.481 158.847 107.337 159.163C107.193 159.478 107.331 159.851 107.647 159.995C107.962 160.139 108.335 160 108.479 159.684Z"
            fill="#B8B7B7"
          />
          <path
            d="M110.469 160.593C110.613 160.278 110.474 159.905 110.158 159.761C109.843 159.617 109.47 159.756 109.326 160.072C109.182 160.387 109.321 160.759 109.637 160.903C109.952 161.048 110.325 160.909 110.469 160.593Z"
            fill="#B8B7B7"
          />
          <path
            d="M112.445 161.496C112.589 161.181 112.45 160.808 112.135 160.664C111.819 160.52 111.447 160.659 111.303 160.974C111.159 161.29 111.298 161.662 111.613 161.806C111.929 161.95 112.301 161.811 112.445 161.496Z"
            fill="#B8B7B7"
          />
          <path
            d="M68.29 137.235C68.4341 136.92 68.2951 136.547 67.9797 136.403C67.6643 136.259 67.2918 136.398 67.1478 136.714C67.0037 137.029 67.1426 137.401 67.458 137.546C67.7734 137.69 68.1459 137.551 68.29 137.235Z"
            fill="#B8B7B7"
          />
          <path
            d="M71.5421 130.524C71.6861 130.209 71.5472 129.836 71.2318 129.692C70.9164 129.548 70.5439 129.687 70.3998 130.002C70.2558 130.318 70.3947 130.69 70.7101 130.834C71.0255 130.978 71.398 130.839 71.5421 130.524Z"
            fill="#B8B7B7"
          />
          <path
            d="M73.096 127.122C73.24 126.806 73.1011 126.434 72.7857 126.29C72.4703 126.146 72.0978 126.285 71.9537 126.6C71.8097 126.916 71.9486 127.288 72.264 127.432C72.5794 127.576 72.9519 127.437 73.096 127.122Z"
            fill="#B8B7B7"
          />
          <path
            d="M76.1685 120.395C76.3126 120.079 76.1737 119.707 75.8583 119.563C75.5428 119.419 75.1704 119.558 75.0263 119.873C74.8822 120.189 75.0211 120.561 75.3366 120.705C75.652 120.849 76.0245 120.71 76.1685 120.395Z"
            fill="#B8B7B7"
          />
          <path
            d="M77.7105 117.009C77.8545 116.693 77.7156 116.321 77.4002 116.177C77.0848 116.033 76.7123 116.172 76.5682 116.487C76.4242 116.802 76.5631 117.175 76.8785 117.319C77.1939 117.463 77.5664 117.324 77.7105 117.009Z"
            fill="#B8B7B7"
          />
        </mask>
        <g mask="url(#mask1_67_13425)">
          <path
            d="M34.2754 125.892L108.209 159.66L121.658 130.214L47.7245 96.4461L34.2754 125.892Z"
            fill="#B8B7B7"
          />
        </g>
        <path
          d="M101.303 135.286L105.37 137.637L100.48 141.953L98.0473 140.132L101.303 135.286Z"
          fill="#10C695"
        />
        <path
          d="M101.21 135.41L105.37 137.637L110.946 127.219L106.787 124.993L101.21 135.41Z"
          fill="#96D7E3"
        />
        <path
          d="M96.1775 130.26L100.337 132.486L104.598 124.526L100.439 122.299L96.1775 130.26Z"
          fill="#96D7E3"
        />
        <path
          d="M96.1762 130.262L100.336 132.488L96.615 137.069L93.2453 135.266L96.1762 130.262Z"
          fill="#10C695"
        />
        <path
          d="M93.243 135.275L96.5951 137.067L93.7484 140.475L91.7485 139.405L93.243 135.275Z"
          fill="#96D7E3"
        />
        <path
          d="M95.5293 143.011L97.2364 144.066L100.49 141.954L98.0577 140.132L95.5293 143.011Z"
          fill="#96D7E3"
        />
        <path
          d="M107.103 139.184L109.422 141.481L105.677 142.89L104.746 141.563L107.103 139.184Z"
          fill="#96D7E3"
        />
        <path
          d="M115.913 132.62L119.029 134.328L116.3 138.467L114.284 137.307L115.913 132.62Z"
          fill="#10C695"
        />
        <path
          d="M115.531 133.69L119.037 134.451L120.944 125.669L117.437 124.908L115.531 133.69Z"
          fill="#96D7E3"
        />
        <path
          d="M114.501 136.704L116.298 138.484L111.667 141.32L111.082 140.154L114.501 136.704Z"
          fill="#96D7E3"
        />
        <path
          d="M111.267 134.949L113.926 137.583L109.427 141.488L107.085 139.168L111.267 134.949Z"
          fill="#10C695"
        />
        <path
          d="M111.121 128.249L115.712 129.244L113.886 137.671L109.809 136.463L111.121 128.249Z"
          fill="#96D7E3"
        />
        <path
          d="M109.937 105.444L110.282 111.079L98.3555 119.571L95.6186 115.728L109.937 105.444Z"
          fill="#96D7E3"
        />
        <path
          d="M98.3559 119.571L95.619 115.728L91.2444 122.864L93.2278 124.478L98.3559 119.571Z"
          fill="#10C695"
        />
        <path
          d="M110.709 103.729L126.9 112.83L122.78 124.294L101.874 116.752L110.709 103.729Z"
          fill="#10C695"
        />
        <path
          d="M99.6455 123.778L116.175 130.717L122.82 124.204L101.875 116.748L99.6455 123.778Z"
          fill="#10C695"
        />
        <path
          d="M100.509 13.8643L75.8587 13.5715L84.0117 67.7135L101.498 68.4722L100.509 13.8643Z"
          fill="#96D7E3"
        />
        <path
          d="M163.515 45.2169L182.011 58.8271L126.9 112.829L110.709 103.728L163.515 45.2169Z"
          fill="#96D7E3"
        />
        <path
          d="M95.3004 87.0287L100.256 88.0072L99.7463 95.2137L95.1451 94.3731L95.3004 87.0287Z"
          fill="#96D7E3"
        />
        <path
          d="M89.2823 85.8621L94.8488 86.9621L94.2573 95.1632L89.0865 94.2185L89.2823 85.8621Z"
          fill="#96D7E3"
        />
        <path
          d="M83.5604 84.7116L88.674 85.7186L86.249 94.2385L82.0977 92.2199L83.5604 84.7116Z"
          fill="#96D7E3"
        />
        <path
          d="M97.0424 75.6171L101.582 73.3984L108.793 85.4584L103.946 87.4318L97.0424 75.6171Z"
          fill="#96D7E3"
        />
        <path
          d="M101.497 68.4745L84.0106 67.7159L80.9606 75.4557L102.309 81.0628L101.497 68.4745Z"
          fill="#10C695"
        />
        <path
          d="M100.256 88.0073L82.8126 84.5641L80.9611 75.4562L102.323 80.9306L100.256 88.0073Z"
          fill="#10C695"
        />
        <path
          d="M81.5261 78.2368L82.8124 84.564L81.0361 87.88L78.273 84.7177L81.5261 78.2368Z"
          fill="#96D7E3"
        />
        <path
          d="M94.2575 95.1628L89.0867 94.2181L90.0129 101.29L93.9465 101.822L94.2575 95.1628Z"
          fill="#10C695"
        />
        <path
          d="M86.2662 94.2485L82.0987 92.2225L82.6627 99.3625L86.394 98.8092L86.2662 94.2485Z"
          fill="#10C695"
        />
        <path
          d="M81.0378 87.8802L78.2746 84.7179L77.3321 91.3786L80.7578 90.9073L81.0378 87.8802Z"
          fill="#10C695"
        />
        <path
          d="M99.7468 95.2136L95.145 94.3732L94.5378 98.5445L99.005 99.4072L99.7468 95.2136Z"
          fill="#10C695"
        />
        <path
          d="M108.792 85.4591L103.946 87.4325L107.804 94.0979L109.669 92.9253L108.792 85.4591Z"
          fill="#10C695"
        />
        <path
          d="M94.5358 98.5453L99.003 99.4081L97.4134 103.419L95.0547 102.995L94.5358 98.5453Z"
          fill="#96D7E3"
        />
        <path
          d="M90.0129 101.29L93.9465 101.822L93.8721 105.173L92.0799 105.908L90.0129 101.29Z"
          fill="#96D7E3"
        />
        <path
          d="M82.6631 99.3624L86.3945 98.8092L87.1979 103.05L85.209 103.59L82.6631 99.3624Z"
          fill="#96D7E3"
        />
        <path
          d="M77.3319 91.379L80.7576 90.9077L81.8277 94.873L79.8672 95.2502L77.3319 91.379Z"
          fill="#96D7E3"
        />
        <path
          d="M24.6728 130.689L110.982 170.109L110.982 201.004L5.90399 153.011L24.6728 130.689Z"
          fill="#96D7E3"
        />
      </g>
    </svg>
  );
};
