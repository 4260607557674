import React from "react";

export const Cloud = () => {
  return (
    <svg
      width="194"
      height="216"
      viewBox="0 0 194 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100", height: "100" }}
    >
      <g opacity="0.5" clip-path="url(#clip0_44_13338)">
        <path
          d="M134.804 24.2343L109.698 5.45867L104.654 12.3268L129.76 31.1024L134.804 24.2343Z"
          fill="#0CB08A"
        />
        <path
          d="M106.334 4.9086L90.2748 31.9588L97.5418 36.3521L113.601 9.30185L106.334 4.9086Z"
          fill="#0CB08A"
        />
        <path
          d="M94.0675 42.3925C98.7045 42.3925 102.463 38.5993 102.463 33.9201C102.463 29.241 98.7045 25.4478 94.0675 25.4478C89.4306 25.4478 85.6716 29.241 85.6716 33.9201C85.6716 38.5993 89.4306 42.3925 94.0675 42.3925Z"
          fill="#0CB08A"
        />
        <path
          d="M107.783 21.6245C113.7 21.6245 118.497 16.7837 118.497 10.8122C118.497 4.8408 113.7 0 107.783 0C101.865 0 97.0682 4.8408 97.0682 10.8122C97.0682 16.7837 101.865 21.6245 107.783 21.6245Z"
          fill="#0CB08A"
        />
        <path
          d="M139.608 34.8377C137.259 38.8598 132.122 40.2056 128.121 37.8352C124.135 35.4647 122.801 30.2804 125.151 26.243"
          fill="#0CB08A"
        />
        <path
          d="M128.894 20.9057C131.243 16.8836 136.381 15.5378 140.381 17.9082C144.382 20.2787 145.701 25.463 143.352 29.5004"
          fill="#0CB08A"
        />
        <path
          d="M73.8354 37.6058C78.5032 31.8709 86.884 31.045 92.5671 35.7553C98.2502 40.4656 99.0686 48.9227 94.4009 54.6576"
          fill="#0CB08A"
        />
        <path
          d="M123.486 175.917L155.158 209.344L161.796 202.939L130.124 169.512L123.486 175.917Z"
          fill="#0CB08A"
        />
        <path
          d="M156.844 200.555L111.377 206.341L112.526 215.535L157.993 209.75L156.844 200.555Z"
          fill="#0CB08A"
        />
        <path
          d="M117.41 207.462L106.617 200.766L101.808 208.661L112.601 215.357L117.41 207.462Z"
          fill="#0CB08A"
        />
        <path
          d="M156.491 216C161.513 216 165.584 211.892 165.584 206.824C165.584 201.756 161.513 197.648 156.491 197.648C151.469 197.648 147.398 201.756 147.398 206.824C147.398 211.892 151.469 216 156.491 216Z"
          fill="#0CB08A"
        />
        <path
          d="M129.379 185.49C134.401 185.49 138.472 181.382 138.472 176.314C138.472 171.247 134.401 167.138 129.379 167.138C124.357 167.138 120.286 171.247 120.286 176.314C120.286 181.382 124.357 185.49 129.379 185.49Z"
          fill="#0CB08A"
        />
        <path
          d="M105.464 194.299C108.965 197.939 108.874 203.735 105.267 207.268C101.66 210.8 95.9163 210.709 92.4155 207.069"
          fill="#0CB08A"
        />
        <path
          d="M103.946 197.143L98.9966 189.499L96.3956 191.214L101.345 198.858L103.946 197.143Z"
          fill="#0CB08A"
        />
        <path
          d="M99.0539 189.608L90.0778 190.75L90.4664 193.861L99.4425 192.718L99.0539 189.608Z"
          fill="#0CB08A"
        />
        <path
          d="M87.2352 205.015L96.1434 206.612L96.6868 203.525L87.7786 201.929L87.2352 205.015Z"
          fill="#0CB08A"
        />
        <path
          d="M111.953 164.794L144.987 162.955L144.15 147.654L111.116 149.493L111.953 164.794Z"
          fill="#0CB08A"
        />
        <path
          d="M126.69 157.53L124.13 176.503L133.231 177.753L135.79 158.78L126.69 157.53Z"
          fill="#0CB08A"
        />
        <path
          d="M86.4383 195.478L85.2178 204.525L88.2963 204.948L89.5168 195.901L86.4383 195.478Z"
          fill="#0CB08A"
        />
        <path
          d="M63.4846 38.0493C77.2151 34.3483 93.0521 41.9796 98.8262 55.0858C104.994 51.9201 112.602 51.8131 118.861 54.7799C125.12 57.7468 129.879 63.7417 131.394 70.5471C139.639 67.045 149.49 67.6414 157.249 72.1223C165.008 76.6032 170.525 84.8615 171.722 93.7927C177.936 97.2642 184.073 101.21 188.468 106.838C192.863 112.466 195.303 120.082 193.273 126.948C191.181 134.044 184.695 139.137 177.723 141.477C170.752 143.816 163.266 143.847 155.915 143.847C116.103 143.893 76.2754 143.939 36.4631 143.985C28.2339 144 19.5803 143.893 12.3817 139.84C4.39496 135.344 -0.606207 125.954 0.0606157 116.748C0.727439 107.541 7.06226 98.9923 15.6097 95.7196C12.6242 88.5777 14.4428 79.6618 19.9744 74.294C25.8848 68.559 33.4624 68.4061 40.9035 70.3483C42.4645 63.1911 42.1462 57.0586 46.5564 50.5131C50.5724 44.5794 56.5587 39.915 63.4846 38.0493Z"
          fill="#27FFB8"
        />
        <path
          d="M90.5364 77.7502C100.069 75.181 111.071 80.4877 115.088 89.5718C119.361 87.3849 124.65 87.2931 129 89.3577C133.349 91.4223 136.653 95.582 137.699 100.308C143.428 97.8759 150.262 98.2889 155.658 101.409C161.053 104.513 164.872 110.248 165.706 116.457C170.025 118.858 174.283 121.611 177.345 125.511C180.406 129.41 182.088 134.702 180.679 139.473C179.224 144.398 174.723 147.93 169.888 149.567C165.054 151.188 159.84 151.218 154.748 151.218C127.09 151.249 99.4475 151.28 71.7895 151.31C66.0761 151.31 60.0595 151.249 55.0735 148.435C49.5267 145.3 46.0411 138.785 46.5109 132.393C46.9807 126 51.3757 120.066 57.3164 117.788C55.2402 112.833 56.5132 106.639 60.3475 102.907C64.4545 98.9159 69.7133 98.8241 74.8812 100.17C75.9723 95.1997 75.745 90.9329 78.8063 86.3908C81.5646 82.277 85.717 79.0501 90.5364 77.7502Z"
          fill="#0D9071"
        />
        <path
          d="M54.8311 104.62C62.257 102.617 70.8197 106.746 73.9416 113.827C77.2757 112.114 81.3827 112.053 84.7775 113.658C88.1571 115.264 90.7334 118.506 91.5518 122.192C96.0074 120.296 101.327 120.617 105.525 123.048C109.723 125.465 112.708 129.93 113.345 134.763C116.709 136.644 120.028 138.77 122.407 141.813C124.787 144.856 126.105 148.97 124.999 152.687C123.862 156.525 120.362 159.278 116.588 160.547C112.814 161.817 108.768 161.832 104.797 161.832C83.262 161.862 61.7418 161.878 40.2064 161.908C35.7508 161.908 31.0831 161.862 27.1882 159.675C22.869 157.244 20.1563 152.167 20.52 147.181C20.8837 142.195 24.3088 137.577 28.9311 135.803C27.3246 131.934 28.2945 127.132 31.2952 124.226C34.493 121.122 38.5848 121.045 42.6009 122.1C43.4496 118.231 43.2677 114.912 45.6622 111.38C47.8446 108.138 51.0878 105.63 54.8311 104.62Z"
          fill="#51DF9B"
        />
      </g>
      <defs>
        <clipPath id="clip0_44_13338">
          <rect width="194" height="216" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
